import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { StyledSearch } from "../../components/StyledSearch";
import AddNewClaimsModal from "../../components/manual-claims/AddNewClaimsModal";
import AddBatchClaimsModal from "../../components/manual-claims/AddBatchClaimsModal";
import { Toast } from "primereact/toast";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import batchClaimsApi from "../../api/batchClaimsApi";
import { batchesType } from "../../types/data";
import formatMoney from "../../utils/formatMoney";

const ManualClaims = () => {
  const toast = useRef<Toast>(null);
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);
  const [fetchedClaims, setFetchedClaims] = useState<any[]>([]);
  const [fetchedBatches, setFetchedBatches] = useState<batchesType[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<batchesType | null>(null);
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const fetchListOfBatches = () => {
    batchClaimsApi
      .getAllBatchByFlag(sessionStorage.getItem("token") || "")
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedBatches(res.info);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = fetchedBatches.filter((data: batchesType) => {
      return (
        data?.batch_number.toLowerCase().includes(query.toLowerCase()) ||
        data?.hsp_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  // const handleDeleteBatch = () => {
  //   if (!selectedBatch) return;
  //   batchClaimsApi
  //     .(sessionStorage.getItem("token") || "", selectedBatch.id)
  //     .then((res: any) => {
  //       if (res.status === 200) {
  //         show("success", "Batch deleted successfully");
  //         setTriggerRefresh(!triggerRefresh);
  //       }
  //     })
  //     .catch((error) => {
  //       show("error", "An error occurred");
  //     });
  // };

  useEffect(() => {
    fetchListOfBatches();
  }, [triggerRefresh]);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedBatches;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedBatches]);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* breadcrumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Manual Claims",
                  url: "/manual-claims",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-between my-2">
                      <h3 className="card-title mb-3">Manual Claims</h3>
                      <div className="flex gap-4 items-center">
                        <StyledSearch
                          placeholder="🔍 by HSP Name or Batch No"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <button
                          className="btn btn-primary bg-[#5F76E8] hover:bg-[#5F76E8] rounded-pill"
                          data-toggle="modal"
                          data-target="#add-batch-claims-modal"
                        >
                          <i className="fa fa-tasks mr-1 " />
                          Add Batch
                        </button>
                        <button
                          className="btn btn-primary bg-[#5F76E8] hover:bg-[#5F76E8] rounded-pill"
                          data-toggle="modal"
                          data-target="#add-new-claims-modal"
                        >
                          <i className="fa fa-plus mr-1 " />
                          Add New Claims
                        </button>
                      </div>
                    </div>

                    <MasterTableComponent
                      headers={[
                        "S/N",
                        "HSP Name",
                        "Batch No",
                        "No. Claims",
                        "Total Amount",
                        "Actions",
                      ]}
                      tableRows={
                        tableRows &&
                        tableRows.map((data: batchesType, index: number) => {
                          return {
                            "S/N": index + 1,
                            "HSP Name": data.hsp_name,
                            "Batch No": data.batch_number || "-",
                            "No. Claims": data.number_of_claims,
                            "Total Amount": formatMoney(
                              data.total_amount_of_claims || 0
                            ),
                            Actions: (
                              <div className=" flex gap-2">
                                {/* <i 
                                className="fa fa-plus text-primary" /> */}
                                <a
                                  href="/view-manual-batch-claim"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "selectedBatch",
                                      JSON.stringify(data)
                                    );
                                  }}
                                >
                                  <i className="fa fa-eye text-primary  " />
                                </a>
                                {/* <button
                                  type="button"
                                  data-target="#deleteModal"
                                  data-toggle="modal"
                                  className="mx-1"
                                  onClick={() => {
                                    setSelectedBatch(data);
                                  }}
                                >
                                  <i className="fa fa-trash  text-primary" />
                                </button> */}
                              </div>
                            ),
                          };
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Delete Surgery
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p className="text-center text-sm">
                            Are you sure you want to delete. <br />
                            batch number:{" "}
                            <span className="text-red-500">
                              {selectedBatch?.batch_number} <br />
                            </span>
                            cannot be reversed.
                          </p>
                          <div className="flex justify-end gap-4 mt-4">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                            >
                              <i className="fas fa-times" />{" "}
                              {<span className="mx-2">Cancel</span>}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                            >
                              <i className="fas fa-trash" />{" "}
                              {<span className="mx-2">Delete </span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/*  Modal for adding New Claims */}
          <AddNewClaimsModal fetchedBatches={fetchedBatches} show={show} />
          {/* /.modal */}

          {/*  Modal for adding Batch Claims */}
          <AddBatchClaimsModal
            setTriggerRefresh={setTriggerRefresh}
            triggerRefresh={triggerRefresh}
          />
          {/* /.modal */}

          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
      {/* </TableContainer> */}
    </>
  );
};

export default ManualClaims;
