import React from "react";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { StyledSearch } from "../StyledSearch";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function ViewApprovedClaimTable() {
  const navigate = useNavigate();
  const headers = [
    "SN",
    "Claim Number",
    // <h2>Staff Name{"/"}ID</h2>,
    "Patient Number",
    "Patient ID",
    "Date Attended",
    "Total Amount",
    "Status",
    "Actions",
  ];
  const rows = [
    {
      sn: "1",
      claim_number: "Francis Hospital",
      patient_number: "001",
      patient_id: "5",
      date_attended: "3/10",
      total_amount: "3453.00",
      status: "Vetted",
    },
    {
      sn: "2",
      claim_number: "Francis Hospital",
      patient_number: "002",
      patient_id: "3",
      date_attended: "7/10",
      total_amount: "3453.00",
      status: "Approved",
    },
  ];
  return (
    <>
      <TableContainer component={Paper}>
        <div className="justify-end w-full flex mb-4">
          <StyledSearch />
        </div>
        <Table
          className="table table-sm table-striped table-bordered table-hover display"
          id="default_order"
          aria-label="simple table"
          sx={{ width: "100%" }}
        >
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((staff) => (
              <TableRow key={staff.sn}>
                <TableCell
                  sx={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  {staff.sn}
                </TableCell>
                <TableCell>{staff.claim_number}</TableCell>
                <TableCell>{staff.patient_number}</TableCell>
                <TableCell>{staff.patient_id}</TableCell>
                <TableCell>{staff.date_attended}</TableCell>
                <TableCell>{staff.total_amount}</TableCell>
                <TableCell>
                  <span className="badge badge-success rounded-pill">
                    {staff.status}
                  </span>
                </TableCell>
                <TableCell>
                  <a href="/fn-view-approved-claims-details">
                    <i className="fas fa-eye text-info cursor-pointer ml-2" />
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex justify-end">
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={5}
            page={0}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default ViewApprovedClaimTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5F76E8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
