import React, { useState } from "react";

function AuthorizationCalls() {
  const [currentStep, setCurrentStep] = useState(1);

  const handlePrevious = () => {
    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive" id="myTable">
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <h3 className="card-title mb-3">
                    Authorization Call Records
                  </h3>
                </div>
                <div className="col-sm-6 col-md-6 text-right mb-3">
                  <button
                    type="button"
                    className="btn btn-info btn-sm bg-[#5F76E8]"
                    data-toggle="modal"
                    data-target="#bs-example-modal-lg"
                  >
                    <i className="fas fa-plus" /> Add Call
                  </button>
                </div>
              </div>
              {/*  Modal content for the above example */}
              <div
                className="modal fade"
                id="bs-example-modal-lg"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myLargeModalLabel">
                        {" "}
                        Add Staff Records Call
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        ×
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body">
                              <form id="signUpForm">
                                <div className="form-body">
                                  {/* step one */}
                                  {currentStep === 1 && (
                                    <div className="">
                                      <h4 className="card-title py-3">
                                        Receipient Details
                                        {/* <i
                                        className="fas fa-file"
                                        id="benefit"
                                      /> */}
                                      </h4>
                                      <hr className="bg-dark" />
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-address-book" />{" "}
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="First Name"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-address-book" />{" "}
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Last Name"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-envelope" />{" "}
                                              E-mail Address
                                            </label>
                                            <input
                                              type="email"
                                              className="form-control"
                                              placeholder="me@gmail.com"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-phone" />{" "}
                                              Phone Number
                                            </label>
                                            <input
                                              type="tel"
                                              className="form-control"
                                              placeholder="0240000000"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-history" />{" "}
                                              Staff ID
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="Staff ID"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-phone" />{" "}
                                              Call Summary
                                            </label>
                                            <textarea
                                              className="form-control"
                                              placeholder="Call Summary"
                                            ></textarea>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-clock" />{" "}
                                              Call Start Time
                                            </label>
                                            <input
                                              type="datetime-local"
                                              className="form-control"
                                              placeholder="Branch Office/Location"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-clock" />{" "}
                                              Call End Time
                                            </label>
                                            <input
                                              type="datetime-local"
                                              className="form-control"
                                              placeholder="Branch Office/Location"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>
                                              <i className="fas fa-phone" />{" "}
                                              Follow up
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Follow up"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* start previous / next buttons */}
                                  <div className="form-footer d-flex justify-content-end">
                                    <div>
                                      <button
                                        type="button"
                                        className="text-end text-white"
                                        id="submit"
                                        // onClick={handlePrevious}
                                      >
                                        <span>
                                          <i className="fa fa-save mr-1" />
                                        </span>
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                  {/* end previous / next buttons */}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              <a href="viewstaf.html"></a>
              <table
                id="default_order"
                className="table table-sm table-striped table-bordered table-hover display"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th scope="col">HSP</th>
                    <th scope="col">Attendant Name</th>
                    <th scope="col">Attendant Contact </th>
                    <th scope="col">Policy Holder </th>
                    <th scope="col">Recipients Name (officer)</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Follow up </th>
                    <th scope="col">Type of Authorization </th>
                    <th scope="col">Authorization Details </th>
                    <th scope="col">Limits</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Francis Anane</td>
                    <td>Francis Anane</td>
                    <td>Francis Anane</td>
                    <td>Francis Anane</td>
                    <td>Francis Anane</td>
                    <td>Francis Anane</td>
                    <td>Francis Anane</td>
                    <td className="">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Rem, quidem.
                    </td>
                    <td>10:00</td>
                    <td>Lorem ipsum dolor sit amet.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorizationCalls;
