import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllRadiologyCategory = async (token: string) => {
  let url = `${BASE_URL}/setup-services/radiology/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllRadiology = async (token: string) => {
  let url = `${BASE_URL}/setup-services/radiology/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createRadiology = async (
  token: string,
  radiologyData: {
    radiology_name: string;
    radiology_category: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/radiology/`;
  try {
    const response = await axios.post(url, radiologyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateRadiology = async (
  token: string,
  activateRadiologyData: {
    radiology_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/radiology/status-change`;
  try {
    const response = await axios.post(url, activateRadiologyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateRadiology = async (
  token: string,
  deactivateRadiologyData: {
    radiology_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/radiology/status-change`;
  try {
    const response = await axios.post(url, deactivateRadiologyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createRadiologyCategory = async (
  token: string,
  radiologyCategoryData: {
    radiology_category: string;
    radiology_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/radiology/category/`;
  try {
    const response = await axios.post(url, radiologyCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const viewRadiologyDetails = async (token: string, radiology_id: string) => {
  let url = `${BASE_URL}/setup-services/radiology/details`;
  try {
    const response = await axios.post(url, radiology_id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// {{base_url}}/radiology/bulk_upload
const bulkRadiologyUpload = async (token: string, file: any) => {
  let url = `${BASE_URL}/setup-services/radiology/bulk_upload`;
  try {
    const response = await axios.post(url, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllRadiologyCategory,
  getAllRadiology,
  createRadiology,
  activateRadiology,
  deactivateRadiology,
  createRadiologyCategory,
  viewRadiologyDetails,
  bulkRadiologyUpload,
};
