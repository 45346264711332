import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import queryString from "query-string";
import axios from "axios";
import { CashflowSetupType, RevenueSetupType } from "../../types/data";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import cashflowApi from "../../api/cashflowApi";

const ViewRevenueSetupDetails = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { cashflow_id } = queryString.parse(search);

  const [cashflowDetails, setCashflowDetails] = useState<CashflowSetupType>();

  const handleFetchCashflowDetails = () => {
    // const token = sessionStorage.getItem("token");
    cashflowApi
      .getCashflowDetails(
        sessionStorage.getItem("token") || "",
        cashflow_id as string
      )
      .then((response) => {
        console.log(response.info);
        setCashflowDetails(response.info);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (cashflow_id) {
      handleFetchCashflowDetails();
    }
  }, [cashflow_id]);

  // @ desc - handle submit
  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/revenue/update_revenue`,
        {
          revenue_id: cashflow_id,
          revenue_type: cashflowDetails!.cash_flow_type,
          applied_to: cashflowDetails!.applied_to,
          percentage: cashflowDetails!.percentage,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        alert(response.data.message);
        if (response.data.status === 200) {
          window.location.href = `/view-revenue-setup?revenue_id=${cashflow_id}`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />

        <Sidebar />

        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Schemes",
                  url: "/revenue-setup",
                },
                {
                  title: "Cashflow Setup",
                  url: "/revenue-setup",
                },
                {
                  title: "View Revenue Setup Details",
                  url: "",
                },
              ]}
            />
            {cashflowDetails && (
              <div className="col-md-8 col-sm-12 offset-md-2">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="m-2">
                      <form onSubmit={handleSubmit}>
                        <div className="card-header text center">
                          <h4 className="text-center">Revenue Setup Details</h4>
                        </div>
                        <fieldset>
                          <table className="table " id="dataTable">
                            <tbody>
                              <tr>
                                <td>Cashflow Type</td>
                                <td>
                                  <div className="col-12">
                                    <div className="form-group mb-3">
                                      <select
                                        className="form-control plantype"
                                        name="plan_type"
                                        id="mySelection"
                                        disabled
                                        onChange={(e) =>
                                          setCashflowDetails(
                                            (cashflowDetails: any) => {
                                              return {
                                                ...cashflowDetails,
                                                revenue_type: e.target.value,
                                              };
                                            }
                                          )
                                        }
                                      >
                                        <option disabled selected>
                                          {cashflowDetails.cash_flow_type}
                                        </option>
                                        <option
                                          value="Sponsored"
                                          className="generic"
                                        >
                                          Sponsored
                                        </option>
                                        <option
                                          value="Co-payment"
                                          className="custom"
                                        >
                                          Co-payment
                                        </option>
                                        <option
                                          value="Out Of Pocket"
                                          className="custom"
                                        >
                                          Out Of Pocket
                                        </option>
                                        <option
                                          value="Tax Deductions (Withholding)"
                                          className="custom"
                                        >
                                          Tax Deductions (Withholding)
                                        </option>
                                        <option
                                          value="Tax Deductions (Exemptions)"
                                          className="custom"
                                        >
                                          Tax Deductions (Exemptions)
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Revenue Percentage</td>
                                <td>
                                  <input
                                    type="text"
                                    defaultValue={cashflowDetails.percentage}
                                    id=""
                                    name=""
                                    disabled
                                    className="form-control"
                                    onChange={(e) =>
                                      setCashflowDetails(
                                        (cashflowDetails: any) => {
                                          return {
                                            ...cashflowDetails,
                                            percentage: e.target.value,
                                          };
                                        }
                                      )
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>Revenue Applied to</td>
                                <td>
                                  <input
                                    type="text"
                                    defaultValue={cashflowDetails.applied_to}
                                    className="form-control"
                                    id=""
                                    name=""
                                    disabled
                                    onChange={(e) =>
                                      setCashflowDetails(
                                        (cashflowDetails: any) => {
                                          return {
                                            ...cashflowDetails,
                                            applied_to: e.target.value,
                                          };
                                        }
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                        {/* <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded-0 bg-[#5F76E8]"
                          >
                            Update
                          </button>
                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default ViewRevenueSetupDetails;
