import React from "react";

function ViewHspNavBar() {
  return (
    <ul className="nav nav-tabs nav-bordered mb-3 justify-content-center">
      <li className="nav-item">
        <a
          href="#hspDetailsTab"
          data-toggle="tab"
          aria-expanded="true"
          className="nav-link active"
        >
          <i className="mdi mdi-account-circle d-block mr-1" />
          <span className="d-lg-block">HSP Details</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#conditionsTab"
          data-toggle="tab"
          aria-expanded="true"
          className="nav-link"
        >
          <i className="mdi mdi-account-circle d-block mr-1" />
          <span className="d-lg-block">Conditions</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#consultationTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-home-variant d-block mr-1" />
          <span className="d-none d-lg-block">Consultation</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#investigationsAndPricingTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-settings-outline d-block mr-1" />
          <span className="d-lg-block">Investigations</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#medsAndConsumablesTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-settings-outline d-block mr-1" />
          <span className="d-lg-block">Med &amp; Consumables</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#sugeryAndProceduresTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-settings-outline d-block mr-1" />
          <span className="d-lg-block">Surgery and Procedure</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#dentalTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-settings-outline d-block mr-1" />
          <span className="d-lg-block">Dental</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#opticalTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-settings-outline d-block mr-1" />
          <span className="d-lg-block">Optical</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#claimHistoryTab"
          data-toggle="tab"
          aria-expanded="false"
          className="nav-link"
        >
          <i className="mdi mdi-settings-outline d-block mr-1" />
          <span className="d-lg-block">Claim History</span>
        </a>
      </li>
    </ul>
  );
}

export default ViewHspNavBar;
