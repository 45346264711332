import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllCompanyProfiles = async (token: string) => {
  let url = `${BASE_URL}/company/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createCompanyProfile = async (token: string, companyData: FormData) => {
  let url = `${BASE_URL}/company/`;
  try {
    const response = await axios.post(url, companyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteCompanyProfile = async (token: string, companyId: string) => {
  let url = `${BASE_URL}/company/delete`;
  try {
    const response = await axios.post(
      url,
      { company_profile_id: companyId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const viewCompanyProfileDetails = async (token: string, companyId: string) => {
  let url = `${BASE_URL}/company/details`;
  try {
    const response = await axios.post(
      url,
      { company_profile_id: companyId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllCompanyProfiles,
  createCompanyProfile,
  deleteCompanyProfile,
  viewCompanyProfileDetails,
};
