// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import axios from "axios";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import convertTimestampToDate from "../../utils/convertTimestampToDate";

const ViewSurgery = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { surgery_id } = queryString.parse(search);
  const [selectedSurgery, setSelectedSurgery] = useState<any>({});
  const [surgeryName, setSurgeryName] = useState<any>("");
  const [medication, setMedication] = useState<any>([]);
  const toast = useRef<Toast>(null);

  const handleFetchSurgeryDetails = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/details`,
        {
          surgery_id: surgery_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setMedication(res.data.info[0]);
          surgeryName(res.data.info[0].surgery_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleFetchSurgeryDetails();
  }, [surgery_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/edit`,
        {
          medication_id: surgery_id,
          medication_code: medication.medication_code,
          medication_name: medication.medication_name,
          medication_category: medication.medication_category,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchSurgeryDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Services",
                  url: "/surgery",
                },
                {
                  title: "Surgeries",
                  url: "/surgery",
                },
                {
                  title: `${medication.surgery_name} Details`,
                  url: "/view-surgery",
                },
              ]}
            />
            <div className="page-breadcrumb">
              <div className="row">
                <div className="col-7 align-self-center mb-2">
                  <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                    Surgery Details
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 offset-md-2">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">Surgery Details</h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Surgery category</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.surgery_category}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      surgery_category: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Surgery code</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.surgery_code}
                                  readOnly
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Surgery Name</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.surgery_name}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      surgery_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>status</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.status}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At</td>
                              <td>
                                <input
                                  type="email"
                                  defaultValue={convertTimestampToDate(Number(medication.updated_at))}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block rounded-pill bg-[#5F76E8]"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewSurgery;
