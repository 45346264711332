import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { HspDentalsType, HspSurgeriesType } from "../../../types/data";
import hspDentalsApi from "../../../api/hspDentalsApi";
import dentalApi from "../../../api/dentalApi";
import { StyledSearch } from "../../StyledSearch";
import SaveAllServicesModal from "./SaveAllServicesModal";
import MasterTableComponent from "../../ui/MasterTableComponent";
import formatMoney from "../../../utils/formatMoney";
import EditHspDentalModal from "./EditHspDentalModal";
import closeAnyModal from "../../../utils/closeAnyModal";

interface DentalTabProps {
  fetchedCashflows: any[];
}

const DentalTab: React.FC<DentalTabProps> = ({ fetchedCashflows }) => {
  const toast = useRef<Toast>(null);
  const [token, setToken] = useState<string>("");
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>({});
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [fetchedDentals, setFetchedDentals] = useState<HspDentalsType[]>([]);
  const [includedData, setIncludedData] = useState<HspDentalsType[]>([]);
  const [alreadyAddedDentals, setAlreadyAddedDentals] = useState<any[]>([]);
  const [fetchedDentalsCategories, setFetchedDentalsCategories] = useState<any>(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });
  const [hspName, setHspName] = useState<string>("");
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [createNewDentalsBody, setCreateNewDentalsBody] = useState<
    HspDentalsType[]
  >([]);
  const [createNewRecordIsLoading, setCreateNewRecordIsLoading] =
    useState(false);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleFetchAlreadyAddedDentals = async () => {
    const selectedHSP = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    const res = await hspDentalsApi.getAllHspDentals(
      sessionStorage.getItem("token") || "",
      selectedHSP?.id
    );
    if (res?.status === 200) {
      setAlreadyAddedDentals(res?.info);
    }
  };

  const handleAddNewDentalToList = async (e: any) => {
    setFetchIsLoading(true);
    const category: any = fetchedDentalsCategories.find(
      (item: any) => item.id === e.target.value
    );
    setSelectedCategory({
      name: category?.dental_category,
      id: category?.id,
    });

    const res = await hspDentalsApi.getAllHspDentalsByCategory(
      sessionStorage.getItem("token") || "",
      e.target.value
    );
    const response = res?.data ? res?.data : res?.info;
    if (response?.length > 0) {
      const tempThings = response?.map((item: any) => {
        const alreadyAdded = alreadyAddedDentals.find(
          (dental: any) => dental.service_name === item.dental_name
        );

        if (alreadyAdded) {
          return {
            service_name: item.dental_name,
            dental_code: item.dental_code,
            dental_included: true,
            standard_rate: alreadyAdded.standard_rate,
            discount_rate: alreadyAdded.discount_rate,
            net_rate: alreadyAdded.net_rate,
            dental_approval: alreadyAdded.dental_approval,
            dental_revenue_type: alreadyAdded.dental_revenue_type,
            has_been_added: true,
            dental_id: alreadyAdded?.id,
          };
        } else {
          return {
            service_name: item.dental_name,
            dental_code: item.dental_code,
            dental_included: false,
            standard_rate: 0.0,
            discount_rate: 0.0,
            net_rate: 0.0,
            dental_approval: false,
            dental_revenue_type: "",
            has_been_added: false,
          };
        }
      });
      setCreateNewDentalsBody(tempThings);
    } else {
      setCreateNewDentalsBody([]);
    }
    setFetchIsLoading(false);
  };

  const handleCreateNewDental = async () => {
    setCreateNewRecordIsLoading(true);
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    const includedDat = getIncludedData();
    let dataToSave: HspDentalsType[] = includedDat.map(
      (item: HspDentalsType) => ({
        ...item,
        hsp_owner_id: hspId,
        hsp_category: selectedCategory.name,
      })
    );
    // remove has_been_added property
    dataToSave = dataToSave.map((item: HspDentalsType) => {
      delete item.has_been_added;
      return item;
    });
    const response = await hspDentalsApi.createHspDentals(token, dataToSave);
    if (response.status === 200) {
      setCreateNewRecordIsLoading(false);
      setCreateNewDentalsBody([]);
      setIncludedData([]);
      setFetchedDentals(response.data);
      show("success", response?.message);
      document
        .getElementById("saveAllServicesModal-dentals")
        ?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
    } else {
      setCreateNewRecordIsLoading(false);
      show("error", "Something went wrong, please try again later");
    }
  };

  const handleFetchAllDentals = async () => {
    const response = await dentalApi.getAllDentalCategory(
      sessionStorage.getItem("token") || ""
    );
    if (response.status === 200) {
      setFetchedDentalsCategories(response.info);
    }
  };

  const handleUpdateDental = async () => {
    setIsUpdateLoading(true);
    const response = await hspDentalsApi.editHspDentals(token, [
      {
        hsp_dental_id: selectedItemToEdit?.dental_id,
        service_name: selectedItemToEdit?.service_name,
        dental_included: selectedItemToEdit?.dental_included,
        standard_rate: selectedItemToEdit?.standard_rate,
        discount_rate: selectedItemToEdit?.discount_rate,
        net_rate: selectedItemToEdit?.net_rate,
        dental_approval: selectedItemToEdit?.dental_approval,
        dental_revenue_type: selectedItemToEdit?.dental_revenue_type,
      },
    ]);
    if (response.status === 200) {
      show("success", response?.message);
      await handleFetchAlreadyAddedDentals();
      setIsUpdateLoading(false);
      closeAnyModal("EditHspDentalModal");
    } else {
      show("error", "Something went wrong, please try again later");
      setIsUpdateLoading(false);
    }
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setHspName(selectedHsp?.hsp_name);
    handleFetchAllDentals();
    handleFetchAlreadyAddedDentals();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = createNewDentalsBody.filter((data: any) => {
      return (
        data?.dental_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.service_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  const getIncludedData = () => {
    const data = tableRows.filter(
      (dental: HspDentalsType) =>
        dental.dental_included === true && dental.has_been_added === false
    );
    setIncludedData(data);
    return data;
  };

  const toggleSaveAllModal = () => {
    const included_data = getIncludedData();
    if (included_data.length === 0) {
      show("error", "No services selected");
    }
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : createNewDentalsBody;
    setTableRows(dataToRender);
  }, [searchQuery, createNewDentalsBody]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane" id="dentalTab">
        <div className="row">
          <div className="col-md-8 offset-md-2 ">
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  <select
                    name=""
                    id=""
                    className="col-md-12 border-2 py-2 rounded-pill"
                    onChange={handleAddNewDentalToList}
                  >
                    <option value="">select multiple options</option>
                    {fetchedDentalsCategories.length > 0 &&
                      fetchedDentalsCategories.map((item: any, idx: number) => {
                        return (
                          <option key={`dentals-${idx}`} value={item.id}>
                            {item.dental_category} - {item.dental_initials}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive" id="myTable">
              <div className="table-responsive" id="myTable">
                <div className="flex justify-end gap-4">
                  <StyledSearch
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="🔍 by Investigation or Code"
                  />
                  <button
                    onClick={toggleSaveAllModal}
                    data-target={`${
                      includedData.length === 0
                        ? ""
                        : "#saveAllServicesModal-dentals"
                    }`}
                    data-toggle="modal"
                    className={`btn btn-primary rounded-pill mb-1 bg-primary ${
                      tableRows.length === 0 && "disabled"
                    }`}
                  >
                    <i className="fas fa-save" /> Save All
                  </button>
                </div>
                <MasterTableComponent
                  headers={[
                    "Code",
                    "Service Name",
                    "Included",
                    "Std Tariff",
                    "Agreed Tariff",
                    "Net Tariff",
                    "Authorization",
                    "Cashflow Type",
                    "Action",
                  ]}
                  loading={fetchIsLoading}
                  emptyTableMessage="No Dentals Available"
                  tableRows={
                    tableRows &&
                    tableRows.map((item: HspDentalsType, idx: number) => ({
                      Code: item?.dental_code,
                      "Service Name": item.service_name,
                      Included: (
                        <input
                          type="checkbox"
                          className=""
                          disabled={item.has_been_added}
                          checked={item.dental_included}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].dental_included = e.target.checked;
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Std Tariff": (
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          value={item.standard_rate}
                          disabled={item.has_been_added}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].standard_rate = Number(e.target.value);
                            newBody[idx].net_rate =
                              parseFloat(e.target.value) -
                              parseFloat(newBody[idx].net_rate);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Agreed Tariff": (
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          disabled={item.has_been_added}
                          value={item.discount_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].discount_rate = Number(e.target.value);
                            newBody[idx].net_rate =
                              parseFloat(newBody[idx].standard_rate) -
                              parseFloat(e.target.value);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Net Tariff": (
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          disabled
                          value={item.net_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].net_rate = Number(e.target.value);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      Authorization: (
                        <input
                          type="checkbox"
                          disabled={item.has_been_added}
                          className=""
                          checked={item.dental_approval}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].dental_approval = e.target.checked;
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Cashflow Type": (
                        <div className="form-group mb-3">
                          <select
                            className="form-control plantype"
                            name="plan_type"
                            id="mySelection"
                            disabled={item.has_been_added}
                            value={item.dental_revenue_type}
                            // placeholder="Select Type"
                            onChange={(e) => {
                              const newBody = [...tableRows];
                              newBody[idx].dental_revenue_type = e.target.value;
                              setTableRows(newBody);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {fetchedCashflows?.map(
                              (cashflow: any, idx: number) => (
                                <option
                                  key={idx}
                                  value={
                                    cashflow?.cash_flow_type +
                                    " - " +
                                    cashflow?.percentage +
                                    "%"
                                  }
                                >
                                  {cashflow?.cash_flow_type} -{" "}
                                  {cashflow?.percentage
                                    ? `${cashflow?.percentage}%`
                                    : ""}{" "}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ),
                      Action: (
                        <button
                          disabled={!item.has_been_added}
                          className={`btn ${
                            item.has_been_added
                              ? "text-primary"
                              : "text-gray-500 cursor-not-allowed"
                          }`}
                          data-toggle="modal"
                          data-target="#EditHspDentalModal"
                          onClick={() => {
                            setSelectedItemToEdit(item);
                          }}
                        >
                          <i className="fas fa-edit" />
                        </button>
                      ),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* save all modal */}
      <SaveAllServicesModal
        selectedCategory={selectedCategory}
        hspName={hspName}
        type={"Dentals"}
        handleSaveAllServices={handleCreateNewDental}
        itemsToSave={includedData.map((dental: HspDentalsType) => ({
          Code: dental.dental_code,
          "Service Name": dental.service_name,
          Included: dental.dental_included ? "Yes" : "No",
          "Std. Tariff": formatMoney(dental.standard_rate),
          "Agreed Tariff": formatMoney(dental.discount_rate),
          "Net Tariff": formatMoney(dental.net_rate),
          Authorization: dental.dental_approval ? "Yes" : "No",
          "Cashflow Type": dental.dental_revenue_type,
        }))}
        selector="saveAllServicesModal-dentals"
      />
      {/* // edit modal */}
      <EditHspDentalModal
        serviceType="Dental"
        itemToEdit={selectedItemToEdit}
        setItemToEdit={setSelectedItemToEdit}
        fetchedCashflows={fetchedCashflows}
        handleUpdate={handleUpdateDental}
        loading={isUpdateLoading}
      />
    </>
  );
};

export default DentalTab;
