import React, { useState } from "react";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer";
import DeliveryHistoryTable from "../../../components/tables/DeliveryHistoryTable";

const MedDeliveryHistory = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handlePrevious = () => {
    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 2) {
      return 2;
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <h3 className="card-title mb-3">
                            Delivery History List
                          </h3>
                        </div>
                        <div className="col-sm-6 col-md-6 text-right mb-3">
                          {/* <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8]"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-plus" /> Add Delivery
                          </button> */}
                        </div>
                      </div>
                      {/*  Modal content for the above example */}
                      <div
                        className="modal fade"
                        id="bs-example-modal-lg"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4
                                className="modal-title"
                                id="myLargeModalLabel"
                              >
                                {" "}
                                Add Delivery
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                              >
                                ×
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <form id="signUpForm">
                                        <div className="form-body">
                                          {/* step one */}
                                          {currentStep === 1 && (
                                            <div className="">
                                              <h4 className="card-title py-3">
                                                Client Details
                                                {/* <i
                                                  className="fas fa-file"
                                                  id="benefit"
                                                /> */}
                                              </h4>
                                              <hr className="bg-dark" />
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-address-book" />{" "}
                                                      First Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="First Name"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-address-book" />{" "}
                                                      Last Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Last Name"
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-envelope" />{" "}
                                                      E-mail Address
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      placeholder="me@gmail.com"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-phone" />{" "}
                                                      Phone Number
                                                    </label>
                                                    <input
                                                      type="tel"
                                                      className="form-control"
                                                      placeholder="0240000000"
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-history" />{" "}
                                                      Staff ID
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      placeholder="Staff ID"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-map-pin" />{" "}
                                                      Branch Office/Location
                                                    </label>
                                                    <input
                                                      type="string"
                                                      className="form-control"
                                                      placeholder="Branch Office/Location"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-clock" />{" "}
                                                      Delivery Start Time
                                                    </label>
                                                    <input
                                                      type="datetime-local"
                                                      className="form-control"
                                                      placeholder="Branch Office/Location"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-clock" />{" "}
                                                      Delivery End Time
                                                    </label>
                                                    <input
                                                      type="datetime-local"
                                                      className="form-control"
                                                      placeholder="Branch Office/Location"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {/* step two */}
                                          {currentStep === 2 && (
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                  <h4 className="card-title py-3">
                                                    More Details
                                                  </h4>
                                                  <hr className="bg-dark" />
                                                </div>

                                                {/* dep 3 */}

                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-address-book" />{" "}
                                                      Receiver's First Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="First Name"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-address-book" />{" "}
                                                      Receiver's Last Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Last Name"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-envelope" />{" "}
                                                      E-mail Address
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      placeholder="me@gmail.com"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-phone" />{" "}
                                                      Phone Number
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      placeholder="0500000000"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-phone" />{" "}
                                                      Client's Remarks
                                                    </label>
                                                    <textarea className="form-control"></textarea>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {/* start previous / next buttons */}
                                          <div className="form-footer d-flex justify-content-between">
                                            <div className="mt-1">
                                              <button
                                                type="button"
                                                id="prevBtn"
                                                className="text-start btn-primary text-white"
                                                onClick={handlePrevious}
                                              >
                                                <span>
                                                  <i className="fa fa-arrow-left" />
                                                </span>
                                                Previous
                                              </button>
                                            </div>
                                            <div>
                                              <button
                                                type="button"
                                                className="text-end text-white"
                                                id="nextBtn"
                                                onClick={handleNext}
                                              >
                                                <span>
                                                  <i className="fa fa-arrow-right" />
                                                </span>
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                          {/* end previous / next buttons */}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}

                      {/* History Table */}
                      <DeliveryHistoryTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default MedDeliveryHistory;
