import "primereact/resources/themes/lara-light-indigo/theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "./App.css";

import ViewManageScheme from "./pages/system-admin/view-manage-scheme";
import AccountSettings from "./pages/system-admin/account-settings";
import ViewedMedVett from "./pages/medical-vetting/view-med-vetting";
import MedicalVetting from "./pages/medical-vetting/medical-vetting";
import ChangePassword from "./pages/change-password";
import ViewUserSetup from "./pages/system-admin/view-user-setup";
import AccreditedHsp from "./pages/hsp/accredited-hsp";
import ResetPassword from "./pages/resetPassword";
import ManageScheme from "./pages/system-admin/manage-scheme";
import Consultation from "./pages/system-admin/consultation";
import ProtectedRoute from "./ProtectedRoute";
import UserSetup from "./pages/system-admin/user-setup";
import ViewStaff from "./pages/policy-admin/view-staff";
import Dashboard from "./pages/dashboard";
import ViewHsp from "./pages/hsp/view-hsp";
import Login from "./pages/login";
import Staff from "./pages/policy-admin/staff";
import Medication from "./pages/system-admin/medication";
import Surgery from "./pages/system-admin/surgery";
import MedVettingDetails from "./pages/medical-vetting/med-vetting-details";
import Labs from "./pages/system-admin/labs";
import Radiology from "./pages/system-admin/radiology";
import ViewRevenueSetupDetails from "./pages/system-admin/view-revenue-setup";
import ViewMedication from "./pages/system-admin/view-medication";
import ViewSurgery from "./pages/system-admin/view-surgery";
import ViewLabs from "./pages/system-admin/view-labs";
import ViewRadiology from "./pages/system-admin/view-radiology";
import ViewConsultation from "./pages/system-admin/view-consultation";
import DashboardHsp from "./pages/hsp/dashboard-hsp";
import NewMedDeliveries from "./pages/policy-admin/med-delivery/new-deliveries";
import MedDeliveryHistory from "./pages/policy-admin/med-delivery/history";
import Crm from "./pages/policy-admin/crm";
import Authorization from "./pages/policy-admin/authorization";
import ApprovedClaims from "./pages/claims/approved-claims";
import NewClaims from "./pages/claims/new-claims";
import VettedClaims from "./pages/claims/vetted-claims/vetted-claims";
import ViewVettedBatchClaims from "./pages/claims/vetted-claims/view-vetted-batch-claim";
import ViewVettedClaimDetails from "./pages/claims/vetted-claims/view-vetted-claim-details";
import ViewApprovedBatchClaims from "./pages/claims/approved-claims/view-approved-batch-claim";
import ViewApprovedClaimDetails from "./pages/claims/approved-claims/view-approved-claim-details";
import PaidClaims from "./pages/claims/paid-claims/paid-claims";
import ViewNewBatchClaims from "./pages/claims/new-claims/view-new-batch-claim";
import ViewNewClaimDetails from "./pages/claims/new-claims/view-new-claim-details";
import ViewPaidBatchClaims from "./pages/claims/paid-claims/view-paid-batch-claim";
import ViewPaidBatchDetails from "./pages/claims/paid-claims/view-paid-batch-details";
import ManualClaims from "./pages/manual-claims/manual-claims";
import ViewManualBatchClaims from "./pages/manual-claims/view-manual-batch-claim";
import ViewManualClaimDetails from "./pages/manual-claims/view-manual-claim-details";
import ViewClaimsNewRefund from "./pages/claims/refund/view-claims-new-refund";
import ViewClaimsNewRefundDetails from "./pages/claims/refund/view-claims-new-refund-details";
import CompanySetup from "./pages/system-admin/company-setup";
import ViewCompanySetupDetails from "./pages/system-admin/view-company-setup";
import EditCompanySetupDetails from "./pages/system-admin/edit-company-setup";
import FinancialsApprovedClaims from "./pages/financials/approved-claims/approved-claims";
import FinancialViewApprovedBatchClaims from "./pages/financials/approved-claims/fn-view-approved-batch-claim";
import FinancialViewApprovedClaimDetails from "./pages/financials/approved-claims/view-approved-claim-details";
import FinancialsViewNewRefund from "./pages/financials/refund/fn-view-new-refund";
import FinancialsViewNewRefundDetails from "./pages/financials/refund/fn-view-new-refund-details";
import FinancialsPaidClaims from "./pages/financials/paid-claims/paid-claims";
import ViewPaidClaims from "./pages/financials/paid-claims/view-paid-claims";
import NewDeliveryClaimDetails from "./pages/policy-admin/med-delivery/new-delivery-claim-details";
import ViewContractDetails from "./pages/hsp/view-contract-details";
import Optical from "./pages/system-admin/optical";
import Dental from "./pages/system-admin/dental";
import ViewDental from "./pages/system-admin/view-dental";
import ViewOptical from "./pages/system-admin/view-optical";
import CashflowSetup from "./pages/system-admin/cashflow-setup";
import DiagnosisPage from "./pages/system-admin/diagnosis";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/reset-password" element={<ResetPassword />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/med-vetting-details" element={<MedVettingDetails />} />

          <Route path="/account-settings" element={<AccountSettings />} />

          <Route path="/change-password" element={<ChangePassword />} />

          <Route path="/view-user-setup" element={<ViewUserSetup />} />

          <Route path="/accredited-hsp" element={<AccreditedHsp />} />
          <Route path="/dashboard-hsp" element={<DashboardHsp />} />

          <Route path="/cashflow-setup" element={<CashflowSetup />} />

          <Route
            path="/view-cashflow-setup"
            element={<ViewRevenueSetupDetails />}
          />

          <Route path="/diagnosis" element={<DiagnosisPage />} />

          <Route path="/view-manage-scheme" element={<ViewManageScheme />} />

          <Route path="/manage-scheme" element={<ManageScheme />} />

          <Route path="/view-surgery" element={<ViewSurgery />} />

          <Route path="/view-consultation" element={<ViewConsultation />} />

          <Route path="/consultation" element={<Consultation />} />

          <Route path="/dental" element={<Dental />} />

          <Route path="/view-dental" element={<ViewDental />} />

          <Route path="/optical" element={<Optical />} />

          <Route path="/view-optical" element={<ViewOptical />} />

          <Route path="/user-setup" element={<UserSetup />} />

          {/* Manual Claims */}

          <Route path="/manual-claims" element={<ManualClaims />} />

          <Route
            path="/view-manual-batch-claim"
            element={<ViewManualBatchClaims />}
          />

          <Route
            path="/view-manual-claims-details"
            element={<ViewManualClaimDetails />}
          />

          {/*  Claims  */}

          <Route path="/new-claims" element={<NewClaims />} />

          <Route
            path="/new-claims-batch-view"
            element={<ViewNewBatchClaims />}
          />

          <Route
            path="/view-new-claims-details"
            element={<ViewNewClaimDetails />}
          />

          <Route path="/vetted-claims" element={<VettedClaims />} />

          <Route
            path="/vetted-claims-batch-view"
            element={<ViewVettedBatchClaims />}
          />

          <Route
            path="/view-vetted-claims-details"
            element={<ViewVettedClaimDetails />}
          />

          <Route path="/approved-claims" element={<ApprovedClaims />} />

          <Route
            path="/approved-claims-batch-view"
            element={<ViewApprovedBatchClaims />}
          />

          <Route
            path="/view-approved-claims-details"
            element={<ViewApprovedClaimDetails />}
          />

          <Route path="/paid-claims" element={<PaidClaims />} />

          <Route
            path="/view-paid-batch-claims"
            element={<ViewPaidBatchClaims />}
          />

          <Route
            path="/view-paid-batch-details"
            element={<ViewPaidBatchDetails />}
          />

          {/* --- End Claims ----  */}

          {/* ------------- Claims - Refund ---------------- */}
          <Route
            path="/view-claims-new-refund"
            element={<ViewClaimsNewRefund />}
          />

          {/* <Route
            path="/view-claims-new-refund"
            element={<ViewClaimsNewRefund />}
          /> */}

          <Route
            path="/view-claims-new-refund-details"
            element={<ViewClaimsNewRefundDetails />}
          />
          {/* ----------- End Claims Refund */}

          {/* ----- Medical Vetting ---------- */}

          <Route path="/medical-vetting" element={<MedicalVetting />} />

          <Route path="/view-med-vetting" element={<ViewedMedVett />} />

          {/* ----- End Medical Vetting ---------- */}

          {/* Policy Management */}
          <Route path="/staff" element={<Staff />} />

          <Route path="/authorization" element={<Authorization />} />

          <Route path="/new-deliveries" element={<NewMedDeliveries />} />

          <Route
            path="/new-delivery-claim-details"
            element={<NewDeliveryClaimDetails />}
          />

          <Route path="/history" element={<MedDeliveryHistory />} />

          <Route path="/crm" element={<Crm />} />

          {/* -- End Policy Managment -- */}

          {/* manage scheme */}
          <Route path="/company-setup" element={<CompanySetup />} />

          <Route
            path="/view-company-setup"
            element={<ViewCompanySetupDetails />}
          />
          <Route
            path="/edit-company-setup"
            element={<EditCompanySetupDetails />}
          />

          {/* ----------end manage scheme -------- */}

          {/* --------------------Fianncials -----------------  */}

          <Route
            path="/fn-approved-claims"
            element={<FinancialsApprovedClaims />}
          />

          <Route
            path="/fn-approved-claims-batch-view"
            element={<FinancialViewApprovedBatchClaims />}
          />

          <Route
            path="/fn-view-approved-claims-details"
            element={<FinancialViewApprovedClaimDetails />}
          />

          <Route path="/fn-paid-claims" element={<FinancialsPaidClaims />} />

          <Route path="/fn-paid-claims-details" element={<ViewPaidClaims />} />

          <Route
            path="/fn-view-new-refund"
            element={<FinancialsViewNewRefund />}
          />

          <Route
            path="/fn-view-new-refund-details"
            element={<FinancialsViewNewRefundDetails />}
          />

          {/* --------------------End  Fianncials -----------------  */}

          <Route path="/view-staff" element={<ViewStaff />} />

          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/view-hsp" element={<ViewHsp />} />

          <Route
            path="/view-contract-details"
            element={<ViewContractDetails />}
          />

          <Route path="/view-medication" element={<ViewMedication />} />

          <Route path="/medication" element={<Medication />} />

          <Route path="/surgery" element={<Surgery />} />

          <Route path="/view-labs" element={<ViewLabs />} />

          <Route path="/labs" element={<Labs />} />

          <Route path="/view-radiology" element={<ViewRadiology />} />

          <Route path="/radiology" element={<Radiology />} />
        </Route>

        <Route path="*" element={<h1>404 - Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
