import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllSurgery = async (token: string) => {
  let url = `${BASE_URL}/setup-services/surgery/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllSurgeryCategory = async (token: string) => {
  let url = `${BASE_URL}/setup-services/surgery/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createSurgeryCategoryData = async (
  token: string,
  surgeryCatgoryData: {
    surgery_category: string;
    surgery_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/category/`;
  try {
    const response = await axios.post(url, surgeryCatgoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createSurgery = async (
  token: string,
  surgeryData: {
    surgery_name: string;
    surgery_category: string;
    surgery_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/`;
  try {
    const response = await axios.post(url, surgeryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getSurgeryDetails = async (
  token: string,
  surgeryDetailsData: {
    surgery_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/details`;
  try {
    const response = await axios.post(url, surgeryDetailsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editSurgery = async (
  token: string,
  surgeryData: {
    surgery_id: string;
    surgery_name: string;
    surgery_category: string;
    surgery_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/edit`;
  try {
    const response = await axios.post(url, surgeryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editSurgeryCategory = async (
  token: string,
  surgeryData: {
    surgery_category_id: string;
    surgery_category: string;
    surgery_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/category/edit`;
  try {
    const response = await axios.post(url, surgeryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteSurgeryCategory = async (
  token: string,
  surgeryData: {
    surgery_category_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/category/delete`;
  try {
    const response = await axios.post(url, surgeryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteSurgery = async (
  token: string,
  surgeryData: {
    surgery_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/delete`;
  try {
    const response = await axios.post(url, surgeryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeSurgeryStatus = async (
  token: string,
  surgeryData: {
    surgery_id: string;
    status_flag: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/surgery/change-status`;
  try {
    const response = await axios.post(url, surgeryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// {{base_url}}/surgery/bulk_upload
const bulkSurgeryUpload = async (token: string, surgeryFile: FormData) => {
  let url = `${BASE_URL}/setup-services/surgery/bulk_upload`;
  try {
    const response = await axios.post(url, surgeryFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("response", response);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllSurgery,
  getAllSurgeryCategory,
  createSurgeryCategoryData,
  createSurgery,
  getSurgeryDetails,
  editSurgery,
  editSurgeryCategory,
  deleteSurgeryCategory,
  deleteSurgery,
  changeSurgeryStatus,
  bulkSurgeryUpload,
};
