import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const createConsultation = async (
  token: string,
  consultationData: {
    consultation_name: string;
    consultation_category: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/consultation/`;
  try {
    const response = await axios.post(url, consultationData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateConsultaion = async (token: string, consultationId: string) => {
  let url = `${BASE_URL}/setup-services/consultation/status-change`;
  try {
    const response = await axios.post(
      url,
      { consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateConsultaion = async (token: string, consultationId: string) => {
  let url = `${BASE_URL}/setup-services/consultation/status-change`;
  try {
    const response = await axios.post(
      url,
      { consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getConsultationDetails = async (
  token: string,
  consultationId: string
) => {
  let url = `${BASE_URL}/setup-services/consultation/details`;
  try {
    const response = await axios.post(
      url,
      { consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllConsultationsCategories = async (token: string) => {
  let url = `${BASE_URL}/setup-services/consultation/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllConsultations = async (token: string) => {
  let url = `${BASE_URL}/setup-services/consultation/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createConsultationCategory = async (
  token: string,
  consultationCategoryData: {
    consultation_category: string;
    consultation_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/consultation/category/`;
  try {
    const response = await axios.post(url, consultationCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// {{base_url}}/consultation/bulk_upload
const bulkConsultationUpload = async (
  token: string,
  consultationFile: FormData
) => {
  let url = `${BASE_URL}/setup-services/consultation/bulk_upload`;
  try {
    const response = await axios.post(url, consultationFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  createConsultation,
  activateConsultaion,
  deactivateConsultaion,
  getConsultationDetails,
  getAllConsultationsCategories,
  getAllConsultations,
  createConsultationCategory,
  bulkConsultationUpload,
};
