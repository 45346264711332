import React, { useState } from "react";
import CustomComboBox from "../../ui/CustomComboBox";

function ConfirmNewRefundModal() {
  return (
    <div
      className="modal fade"
      id="confirm-new-refund-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md h-[90vh] overflow-y-scroll">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              Confirm Refund
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <form>
                <>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="mt-3">
                        <p className="text text-lg">
                          Are you sure you want to confirm receipt of refund claim number:<br/>  <span className="text-primary">001</span>{" "}?
                        </p>
                      </div>
                    </div>
                  </div>
                </>
                <div className="buttons float-right mt-3 flex gap-4">
                  <button
                    //   onClick={handleNext}
                    className="btn btn-primary btn-sm closs flex items-center justify-center"
                  >
                    <i className="fas fa-check mr-2" />
                    Confirm
                  </button>
                  <button
                    //   onClick={handleNext}
                    className="btn btn-danger btn-sm closs flex items-center justify-center"
                  >
                    <i className="fas fa-times mr-2" />
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ConfirmNewRefundModal;
