import React, { useEffect, useRef, useState } from "react";
import { ConsultationType, HspConsultationType } from "../../../types/data";
import hspConsultationApi from "../../../api/hspConsultationApi";
import { Toast } from "primereact/toast";
import consultationApi from "../../../api/consultationApi";
import { StyledSearch } from "../../StyledSearch";
import SaveAllServicesModal from "./SaveAllServicesModal";
import MasterTableComponent from "../../ui/MasterTableComponent";
import formatMoney from "../../../utils/formatMoney";
import EditHspServiceModal from "./EditHspServiceModal";
import closeAnyModal from "../../../utils/closeAnyModal";

interface ConsultationTabProps {
  fetchedCashflows: any[];
}

const ConsultationTab: React.FC<ConsultationTabProps> = ({
  fetchedCashflows,
}) => {
  const [token, setToken] = useState<string>("");
  const toast = useRef<Toast>(null);
  const [selectedConsultation, setSelectedConsultation] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>({});
  const [fetchedConsultations, setFetchedConsultations] = useState([{}]);
  const [
    fetchedAlreadyAddedConsultations,
    setFetchedAlreadyAddedConsultations,
  ] = useState<any[]>([]);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [createNewConsultationIsLoading, setCreateNewConsultationIsLoading] =
    useState(false);
  const [hspName, setHspName] = useState<string>("");
  const [createNewConsultationBody, setCreateNewConsultationBody] = useState<
    any[]
  >([]);
  const [includedData, setIncludedData] = useState<HspConsultationType[]>([]);

  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [selectedConsultationDetails, setSelectedConsultationDetails] =
    useState<any>({});
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // todo: add toast message
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };
  const handleFetchAlreadyAddedConsultations = async () => {
    const tk = sessionStorage.getItem("token");
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    const response = await hspConsultationApi.getAllHspConsultations(
      !tk ? "" : tk,
      hspId
    );
    setFetchedAlreadyAddedConsultations(response.info);
  };

  const handleFetchHspConsultations = async () => {
    const tk = sessionStorage.getItem("token");
    const response = await consultationApi.getAllConsultationsCategories(
      !tk ? "" : tk
    );

    setFetchedConsultations(response.info);
  };
  const handleAddNewConsultationToHSPConsultation = async (e: any) => {
    setFetchIsLoading(true);
    const category: any = fetchedConsultations.find(
      (consultation: any) => consultation.id === e.target.value
    );
    setSelectedCategory({
      name: category?.consultation_category || "",
      id: category?.id || "",
    });
    const res = await hspConsultationApi.getAllHspConsultationsByCategory(
      sessionStorage.getItem("token") || "",
      e.target.value
    );
    const response = res?.data ? res?.data : res?.info;
    if (response.length > 0) {
      const temConsultations = response.map(
        (consultation: ConsultationType) => {
          const alreadyAddedConsultation =
            fetchedAlreadyAddedConsultations?.find(
              (addedConsultation: any) =>
                addedConsultation?.service_name ===
                consultation?.consultation_name
            );

          if (alreadyAddedConsultation) {
            return {
              consultation_code: consultation?.consultation_code || "",
              service_name: alreadyAddedConsultation?.service_name || "",
              consultation_included:
                alreadyAddedConsultation?.consultation_included,
              standard_rate: alreadyAddedConsultation?.standard_rate || 0.0,
              discount_rate: alreadyAddedConsultation?.discount_rate || 0.0,
              net_rate: alreadyAddedConsultation?.net_rate || 0.0,
              consultation_approval:
                alreadyAddedConsultation?.consultation_approval || false,
              consultation_revenue_type:
                alreadyAddedConsultation?.consultation_revenue_type || "",
              consultation_id: alreadyAddedConsultation?.id || "",
              has_been_added: true,
            };
          } else {
            return {
              consultation_code: consultation?.consultation_code || "",
              service_name: consultation?.consultation_name || "",
              consultation_included: false,
              standard_rate: 0.0,
              discount_rate: 0.0,
              net_rate: 0.0,
              consultation_approval: false,
              consultation_revenue_type: "",
              has_been_added: false,
            };
          }
        }
      );
      setCreateNewConsultationBody(temConsultations);
    } else {
      setCreateNewConsultationBody([]);
    }

    setFetchIsLoading(false);
  };

  const handleCreateHspConsultation = async () => {
    setCreateNewConsultationIsLoading(true);
    const includedDat = getIncludedData();
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    let dataToSave: HspConsultationType[] = includedDat.map(
      (consultation: HspConsultationType) => ({
        ...consultation,
        hsp_owner_id: hspId,
        hsp_category: selectedCategory.name,
      })
    );
    // delete has_been_added property
    dataToSave = dataToSave.map((consultation: any) => {
      delete consultation.has_been_added;
      return consultation;
    });
    const response = await hspConsultationApi.createHspConsultation(
      token,
      dataToSave
    );
    setCreateNewConsultationIsLoading(false);
    if (response.status === 200) {
      show("success", response?.message);
      document
        .getElementById("saveAllServicesModal-consultation")
        ?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      handleFetchHspConsultations();
    } else {
      show("error", response?.message);
    }
  };

  const handleUpdateConsultationService = async () => {
    setIsUpdateLoading(true);
    const response = await hspConsultationApi.editHspConsultation(token, [
      {
        hsp_consultation_id: selectedItemToEdit?.consultation_id,
        service_name: selectedItemToEdit?.service_name,
        consultation_included: selectedItemToEdit?.consultation_included,
        standard_rate: selectedItemToEdit?.standard_rate,
        discount_rate: selectedItemToEdit?.discount_rate,
        net_rate: selectedItemToEdit?.net_rate,
        consultation_approval: selectedItemToEdit?.consultation_approval,
        consultation_revenue_type:
          selectedItemToEdit?.consultation_revenue_type,
      },
    ]);
    if (response.status === 200) {
      show("success", response?.message);
      handleFetchHspConsultations();
      setIsUpdateLoading(false);
      closeAnyModal("editHspServiceModal");
    } else {
      show("error", response?.message);
      setIsUpdateLoading(false);
    }
  };

  const handleDeleteConsultation = async () => {
    const response = await hspConsultationApi.deleteHspConsultation(
      token,
      selectedConsultation.consultation_id
    );
    if (response.data) {
      handleFetchHspConsultations();
    }
  };

  const handleActivateConsultation = async () => {
    const response = await hspConsultationApi.activateHspConsultation(
      token,
      selectedConsultation.consultation_id
    );
    if (response.data) {
      handleFetchHspConsultations();
    }
  };

  const handleDeactivateConsultation = async () => {
    const response = await hspConsultationApi.deactivateHspConsultation(
      token,
      selectedConsultation.consultation_id
    );
    if (response.data) {
      handleFetchHspConsultations();
    }
  };

  const handleFetchConsultationDetails = async () => {
    const response = await hspConsultationApi.getHspConsultationDetails(
      token,
      selectedConsultation.consultation_id
    );
    setSelectedConsultationDetails(response.data);
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setHspName(selectedHsp?.hsp_name);
    const fn = async () => {
      await handleFetchHspConsultations();
      await handleFetchAlreadyAddedConsultations();
    };
    fn();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = createNewConsultationBody.filter((data: any) => {
      return (
        data?.consultation_code
          ?.toLowerCase()
          ?.includes(query?.toLowerCase()) ||
        data?.service_name?.toLowerCase()?.includes(query?.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  const getIncludedData = () => {
    const data = tableRows.filter(
      (consultation: HspConsultationType) =>
        consultation.consultation_included === true &&
        consultation.has_been_added === false
    );
    setIncludedData(data);
    return data;
  };

  const toggleSaveAllModal = () => {
    const included_data = getIncludedData();
    if (included_data.length === 0) {
      show("error", "No services selected");
    }
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : createNewConsultationBody;
    setTableRows(dataToRender);
  }, [searchQuery, createNewConsultationBody]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane overflow-y-scroll" id="consultationTab">
        <div className="tab-content">
          <div className="row">
            <div className="col-md-8 offset-md-2 ">
              <div className="card shadow">
                <div className="card-body">
                  <div className="row">
                    <select
                      name=""
                      id=""
                      className="col-md-12 border-2 py-2 rounded-pill"
                      onChange={handleAddNewConsultationToHSPConsultation}
                    >
                      <option value="">Select</option>
                      {fetchedConsultations?.length > 0 &&
                        fetchedConsultations?.map(
                          (consultation: any, idx: number) => (
                            <option key={idx} value={consultation?.id}>
                              {consultation?.consultation_initials} -{" "}
                              {consultation?.consultation_category}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow">
            <div className="card-body">
              <div className="table-responsive" id="myTable">
                <div className="flex justify-end gap-4">
                  <StyledSearch
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search by Service Name or Code"
                  />
                  <button
                    data-target={
                      includedData.length == 0
                        ? ""
                        : "#saveAllServicesModal-consultation"
                    }
                    data-toggle="modal"
                    className={`btn btn-primary rounded-pill mb-1 bg-primary ${
                      tableRows.length === 0 && "disabled"
                    }`}
                    onClick={toggleSaveAllModal}
                  >
                    <i className="fas fa-save" /> Save All
                  </button>
                </div>
                <MasterTableComponent
                  headers={[
                    "Code",
                    "Service Name",
                    "Included",
                    "Std Tariff",
                    "Agreed Tariff",
                    "Net Tariff",
                    "Authorization",
                    "Cashflow Type",
                    "Action",
                  ]}
                  loading={fetchIsLoading}
                  emptyTableMessage="No Consultations Available"
                  tableRows={
                    tableRows &&
                    tableRows.map(
                      (consultationBody: HspConsultationType, idx: number) => ({
                        Code: consultationBody?.consultation_code,
                        "Service Name": consultationBody.service_name,
                        Included: (
                          <input
                            type="checkbox"
                            className=""
                            disabled={consultationBody?.has_been_added === true}
                            checked={consultationBody.consultation_included}
                            onChange={(e: any) => {
                              const newConsultationBody = [...tableRows];
                              newConsultationBody[idx].consultation_included =
                                e.target.checked;
                              setTableRows(newConsultationBody);
                            }}
                          />
                        ),
                        "Std Tariff": (
                          <input
                            type="number"
                            min={"0"}
                            disabled={consultationBody?.has_been_added === true}
                            className="form-control"
                            value={consultationBody.standard_rate}
                            onChange={(e: any) => {
                              const newConsultationBody = [...tableRows];
                              newConsultationBody[idx].standard_rate =
                                e.target.value;
                              newConsultationBody[idx].net_rate = (
                                parseFloat(e.target.value) -
                                newConsultationBody[idx].discount_rate
                              ).toFixed(2);
                              setTableRows(newConsultationBody);
                            }}
                          />
                        ),
                        "Agreed Tariff": (
                          <input
                            type="number"
                            min={"0"}
                            disabled={consultationBody?.has_been_added === true}
                            className="form-control"
                            value={consultationBody.discount_rate}
                            onChange={(e: any) => {
                              const newConsultationBody = [...tableRows];
                              newConsultationBody[idx].discount_rate =
                                e.target.value;
                              newConsultationBody[idx].net_rate = (
                                newConsultationBody[idx].standard_rate -
                                parseFloat(e.target.value)
                              ).toFixed(2);
                              setTableRows(newConsultationBody);
                            }}
                          />
                        ),
                        "Net Tariff": (
                          <input
                            type="number"
                            min={"0"}
                            disabled
                            className="form-control"
                            value={consultationBody.net_rate}
                            onChange={(e: any) => {
                              const newConsultationBody = [...tableRows];
                              newConsultationBody[idx].net_rate =
                                e.target.value;
                              setTableRows(newConsultationBody);
                            }}
                          />
                        ),
                        Authorization: (
                          <input
                            disabled={consultationBody?.has_been_added === true}
                            type="checkbox"
                            className=""
                            checked={consultationBody.consultation_approval}
                            onChange={(e: any) => {
                              const newConsultationBody = [...tableRows];
                              newConsultationBody[idx].consultation_approval =
                                e.target.checked;
                              setTableRows(newConsultationBody);
                            }}
                          />
                        ),
                        "Cashflow Type": (
                          <div className="form-group mb-3">
                            <select
                              className="form-control plantype"
                              name="plan_type"
                              id="mySelection"
                              // placeholder="Select Type"
                              disabled={
                                consultationBody?.has_been_added === true
                              }
                              value={consultationBody.consultation_revenue_type}
                              onChange={(e: any) => {
                                const newConsultationBody = [...tableRows];
                                newConsultationBody[
                                  idx
                                ].consultation_revenue_type = e.target.value;
                                setTableRows(newConsultationBody);
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              {fetchedCashflows?.map(
                                (cashflow: any, idx: number) => (
                                  <option
                                    key={idx}
                                    value={
                                      cashflow?.cash_flow_type +
                                      " - " +
                                      cashflow?.percentage +
                                      "%"
                                    }
                                  >
                                    {cashflow?.cash_flow_type} -{" "}
                                    {cashflow?.percentage
                                      ? `${cashflow?.percentage}%`
                                      : ""}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        ),
                        Action: (
                          <button
                            disabled={consultationBody?.has_been_added != true}
                            className={`btn 
                            ${
                              consultationBody?.has_been_added === true
                                ? "text-primary"
                                : "text-gray-500 cursor-not-allowed"
                            }
                            `}
                            data-toggle="modal"
                            data-target="#editHspServiceModal"
                          >
                            <i
                              className="fas fa-edit"
                              onClick={() => {
                                setSelectedItemToEdit(consultationBody);
                              }}
                            />
                          </button>
                        ),
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* save all modal */}
      <SaveAllServicesModal
        selectedCategory={selectedCategory}
        hspName={hspName}
        type={"Consultation"}
        selector={"saveAllServicesModal-consultation"}
        handleSaveAllServices={handleCreateHspConsultation}
        itemsToSave={includedData?.map((consultation: HspConsultationType) => ({
          Code: consultation?.consultation_code,
          "Service Name": consultation?.service_name,
          Included: consultation?.consultation_included ? "Yes" : "No",
          "Std. Tariff": formatMoney(consultation?.standard_rate),
          "Agreed Tariff": formatMoney(consultation?.discount_rate),
          "Net Tariff": formatMoney(consultation?.net_rate),
          Authorization: consultation?.consultation_approval ? "Yes" : "No",
          "Cashflow Type": consultation?.consultation_revenue_type,
        }))}
      />
      {/* // edit modal */}
      <EditHspServiceModal
        serviceType="Consultation"
        itemToEdit={selectedItemToEdit}
        setItemToEdit={setSelectedItemToEdit}
        fetchedCashflows={fetchedCashflows}
        handleUpdate={handleUpdateConsultationService}
        loading={isUpdateLoading}
      />
    </>
  );
};

export default ConsultationTab;
