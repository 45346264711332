import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import queryString from "query-string";
import axios from "axios";
import setupUserApi from "../../api/setupUserApi";
import { Toast } from "primereact/toast";
import { UpdateUserDetailsType } from "../../types/data";

const ViewUserSetup = () => {
  //todo: @ desc - get staff_id from location search
  const { search } = useLocation();
  const { staff_id } = queryString.parse(search);
  const toast = useRef<Toast>(null);

  const [user, setUser] = useState<UpdateUserDetailsType | null>(null);
  const [currentUserRole, setCurrentUserRole] = useState<string>("");

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleFetchUserDetails = () => {
    setupUserApi
      .getUserDetails(localStorage.getItem("token") || "", {
        user_id: staff_id as string,
      })
      .then((response) => {
        setUser(response.info);
      })
      .catch((err) => {
        console.log(err);
        show("error", "Error fetching user details");
      });
  };

  useEffect(() => {
    if (staff_id) {
      handleFetchUserDetails();
    }
    let userDetails = sessionStorage.getItem("userDetails");
    if (userDetails) {
      setCurrentUserRole(JSON.parse(userDetails).role);
    }
  }, [staff_id]);

  // todo: @desc - handle update
  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (!user) {
      show("error", "Error updating user");
      return;
    }

    setupUserApi
      .updateUserDetails(localStorage.getItem("token") || "", {
        user_id: staff_id as string,
        full_name: user.full_name,
        email: user.email,
        contact: user.contact,
        role: user.role,
        policy_admin: {
          policy_admin_dashboard: user?.policy_admin?.policy_admin_dashboard,
          policy_admin_staff: user?.policy_admin?.policy_admin_staff,
          // policy_admin_reminders: user?.policy_admin?.policy_admin_reminders,
          // policy_admin_complaints: user?.policy_admin?.policy_admin_complaints,
          policy_admin_reports: user?.policy_admin?.policy_admin_reports,
        },
        claims_section: {
          claims_dashboard: user?.claims_section?.claims_dashboard,
          claims_claim_list: user?.claims_section?.claims_claim_list,
          claims_med_vetting: user?.claims_section?.claims_med_vetting,
          claims_refund: user?.claims_section?.claims_refund,
          claims_reports: user?.claims_section?.claims_reports,
        },
        manage_hsp: {
          manage_hsp_accredited_hsp:
            user?.manage_hsp?.manage_hsp_accredited_hsp,
          manage_hsp_qos_survey: user?.manage_hsp?.manage_hsp_qos_survey,
          manage_hsp_reports: user?.manage_hsp?.manage_hsp_reports,
        },
        finance_section: {
          finance_dashboard: user?.finance_section?.finance_dashboard,
          finance_approved_claims:
            user?.finance_section?.finance_approved_claims,
          finance_paid_claims: user?.finance_section?.finance_paid_claims,
          finance_refund: user?.finance_section?.finance_refund,
          finance_reports: user?.finance_section?.finance_reports,
        },
        system_admin: {
          system_admin_manage_scheme:
            user?.system_admin?.system_admin_manage_scheme,
          system_admin_setup_services:
            user?.system_admin?.system_admin_setup_services,
          system_admin_user_setup: user?.system_admin?.system_admin_user_setup,
          system_admin_hsp_classification:
            user?.system_admin?.system_admin_hsp_classification,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          show("success", response.message);
          handleFetchUserDetails();
        }
      })
      .catch((err) => {
        console.log(err);
        // alert("Error updating user");
        show("error", "Error updating user");
      });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />

        <Sidebar />

        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="page-breadcrumb">
              <div className="row">
                <div className="col-7 align-self-center mb-2">
                  <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                    User Details
                  </h3>
                </div>
              </div>
            </div>
            {user && (
              <div className="col-md-8 col-sm-12 offset-md-2">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="m-2">
                      <section>
                        <div className="card-header text center">
                          <h4 className="text-center">{user.full_name}</h4>
                        </div>
                        <fieldset>
                          <table className="table " id="dataTable">
                            <tbody>
                              <tr>
                                <td>Full Name</td>
                                <td>
                                  <input
                                    type="text"
                                    defaultValue={user.full_name}
                                    id=""
                                    name=""
                                    className="form-control"
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Contact</td>
                                <td>
                                  <input
                                    type="text"
                                    defaultValue={user.contact}
                                    id=""
                                    name=""
                                    className="form-control"
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>
                                  <input
                                    type="text"
                                    defaultValue={user.email}
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                      </section>
                      {currentUserRole?.toLowerCase() === "super-admin" && (
                        <form onSubmit={handleUpdate}>
                          <fieldset style={{ overflowX: "scroll" }}>
                            <h4 className="cad-title">Authorization</h4>

                            <table className="table " id="dataTable">
                              <tbody>
                                <tr>
                                  <td>Policy Admin</td>
                                  <td></td>
                                  <td className="text-center font-8">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Dashboard
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.policy_admin.policy_admin_dashboard
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            policy_admin: {
                                              ...user.policy_admin,
                                              policy_admin_dashboard:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>

                                  <td className="text-center font-8">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Staff
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.policy_admin.policy_admin_staff
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            policy_admin: {
                                              ...user.policy_admin,
                                              policy_admin_staff:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>

                                  <td className="text-center font-8">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      CRM
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.policy_admin.policy_admin_crm
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            policy_admin: {
                                              ...user.policy_admin,
                                              policy_admin_crm:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>

                                  <td className="text-center font-8">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Med Delivery
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.policy_admin
                                          .policy_admin_med_delivery
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            policy_admin: {
                                              ...user.policy_admin,
                                              policy_admin_med_delivery:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>

                                  <td className="text-center font-8">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Authorization
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.policy_admin
                                          .policy_admin_authorization
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            policy_admin: {
                                              ...user.policy_admin,
                                              policy_admin_authorization:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center font-8">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Report
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.policy_admin.policy_admin_reports
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            policy_admin: {
                                              ...user.policy_admin,
                                              policy_admin_reports:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>

                                  <td className="text-center"></td>
                                </tr>

                                <tr>
                                  <td> Claims</td>
                                  <td></td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Dashboard
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.claims_section.claims_dashboard
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            claims_section: {
                                              ...user.claims_section,
                                              claims_dashboard:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Claim List
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.claims_section.claims_claim_list
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            claims_section: {
                                              ...user.claims_section,
                                              claims_claim_list:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Manual Claims
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.claims_section.claims_manual
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            claims_section: {
                                              ...user.claims_section,
                                              claims_manual: e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Med Vetting
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.claims_section.claims_med_vetting
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            claims_section: {
                                              ...user.claims_section,
                                              claims_med_vetting:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Refund
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.claims_section.claims_refund
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            claims_section: {
                                              ...user.claims_section,
                                              claims_refund: e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Report
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.claims_section.claims_reports
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            claims_section: {
                                              ...user.claims_section,
                                              claims_reports: e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center"></td>
                                  <td className="text-center"></td>
                                </tr>
                                <tr>
                                  <td> Manage HSP's</td>
                                  <td></td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Accredited HSP
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.manage_hsp
                                          .manage_hsp_accredited_hsp
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            manage_hsp: {
                                              ...user.manage_hsp,
                                              manage_hsp_accredited_hsp:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      QOs Survey
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.manage_hsp.manage_hsp_qos_survey
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            manage_hsp: {
                                              ...user.manage_hsp,
                                              manage_hsp_qos_survey:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Report
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.manage_hsp.manage_hsp_reports
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            manage_hsp: {
                                              ...user.manage_hsp,
                                              manage_hsp_reports:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center"></td>
                                  <td className="text-center"></td>
                                  <td className="text-center"></td>
                                </tr>
                                <tr>
                                  <td> Finance</td>
                                  <td></td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Dashboard
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.finance_section.finance_dashboard
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            finance_section: {
                                              ...user.finance_section,
                                              finance_dashboard:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Approved Claims
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.finance_section
                                          .finance_approved_claims
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            finance_approved_claims:
                                              e.target.checked,
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Paid Claims
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.finance_section.finance_paid_claims
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            finance_section: {
                                              ...user.finance_section,
                                              finance_paid_claims:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Refund
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.finance_section.finance_refund
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            finance_section: {
                                              ...user.finance_section,
                                              finance_refund: e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Report
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.finance_section.finance_reports
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            finance_section: {
                                              ...user.finance_section,
                                              finance_reports: e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center"></td>
                                </tr>
                                <tr>
                                  <td> System Admin</td>
                                  <td></td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Manage Scheme
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.system_admin
                                          .system_admin_manage_scheme
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            system_admin: {
                                              ...user.system_admin,
                                              system_admin_manage_scheme:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Setup Services
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.system_admin
                                          .system_admin_setup_services
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            system_admin: {
                                              ...user.system_admin,
                                              system_admin_setup_services:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      User Setup
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.system_admin
                                          .system_admin_user_setup
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            system_admin: {
                                              ...user.system_admin,
                                              system_admin_user_setup:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <h5
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      Company Setup
                                    </h5>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        user.system_admin
                                          .system_admin_hsp_classification
                                      }
                                      onChange={(e) =>
                                        setUser((user: any) => {
                                          return {
                                            ...user,
                                            system_admin: {
                                              ...user.system_admin,
                                              system_admin_hsp_classification:
                                                e.target.checked,
                                            },
                                          };
                                        })
                                      }
                                    />
                                  </td>
                                  <td className="text-center"></td>
                                  <td className="text-center"></td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="submit-btn py-3">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm float-right bg-[#5F76E8]"
                              >
                                <i className="fas fa-paper-plane" /> update
                              </button>
                            </div>
                          </fieldset>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default ViewUserSetup;
