import React, { useState } from "react";
import CustomComboBox from "../../ui/CustomComboBox";

function AddNewRefundModal() {
  const [currentStep, setCurrentStep] = useState(1);

  const [diagnosis, setDiagnosis] = useState([[]]);

  function addNewDiagnosis() {
    setDiagnosis((prev) => [...prev, []]);
  }

  const handlePrevious = (e: any) => {
    e.preventDefault();

    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    if (currentStep === 4) {
      return 4;
    }
    setCurrentStep(currentStep + 1);
  };

  const [HSPType, setHSPType] = useState("");

  const handleHSPChange = (e: any) => {
    setHSPType(e.target.value);
  };

  return (
    <div
      className="modal fade"
      id="add-new-refund-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg h-[90vh] overflow-y-scroll">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {currentStep === 1
                ? "Patient Details"
                : currentStep === 2
                ? "Clinic & Diagnosis"
                : currentStep === 3
                ? "HSP Name"
                : currentStep === 4
                ? "Attachments"
                : ""}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <form>
                {currentStep === 1 && (
                  <>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label>Patient ID</label>
                        <CustomComboBox placeholder="Staff/Dependant ID" />
                      </div>

                      <div className="col-12 mb-3">
                        <label>Patient Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Patient Name"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Relationship</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="relationship"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Age</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="34 years"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Sex</label>
                        <select
                          className="form-control"
                          // placeholder="Member ID"
                          disabled={true}
                        >
                          <option value="">Select Gender</option>
                          <option value="">Male</option>
                          <option value="">Female</option>
                          <option value="">Other</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <div className="row">
                      <div className="col-6 mb-3">
                        <label>HSP Type</label>
                        <select
                          className="form-control"
                          onChange={handleHSPChange}
                          value={HSPType}
                        >
                          <option value="">Select HSP type</option>
                          <option value="Pharmacy">Pharmacy</option>
                          <option value="Hospital">Hospital</option>
                          <option value="Lab & Diagnostics">
                            Lab {"&"} Diagnostics
                          </option>
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <label>HSP Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="HSP Name"
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <label>Date Attended</label>
                        <input type="datetime-local" className="form-control" />
                      </div>
                      <div className="col-6 mb-3">
                        <label> </label>
                        <input
                          type="datetime-local"
                          className="form-control mt-2"
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>General Clinic</label>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                          />
                          <label>OPD</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                          />
                          <label>IPD</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                          />
                          <label>
                            Maternity{"("}ANC{")"}
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                          />
                          <label>
                            Maternity{"("}PNC{")"}
                          </label>
                        </div>
                      </div>
                      {HSPType === "Pharmacy" ? (
                        <></>
                      ) : HSPType === "Lab & Diagnostics" ? (
                        <>
                          <div className="col-12">
                            <label>Initial Diagnosis</label>
                            <div className="col-12 flex items-end">
                              <div className="col-11">
                                {diagnosis.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <select
                                        id=""
                                        name=""
                                        className="form-control col-12 mb-3"
                                        // value={data.diagnosis}
                                        // onChange={handleChange}
                                      >
                                        <option value="Diagnosis 1">
                                          Diagnosis 1
                                        </option>
                                        <option value="Diagnosis 2">
                                          Diagnosis 2
                                        </option>
                                        <option value="Diagnosis 3">
                                          Diagnosis 3
                                        </option>
                                        <option value="Diagnosis 4">
                                          Diagnosis 4
                                        </option>
                                      </select>
                                    </div>
                                  );
                                })}
                              </div>
                              <button
                                onClick={addNewDiagnosis}
                                type="button"
                                className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-12 mb-3">
                            <label>Special Clinic</label>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Dental</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Obs {"&"} Gyne</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>ENT</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Optical{"/"}phthalmology</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Optical{"/"}phthalmology</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Dermatology</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Urology</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Cardio</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Pediatrics</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Psychiatry{"/"}Psychologist</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Neurology</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                placeholder=""
                                className="mr-2"
                              />
                              <label>Oncology</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <label>Initial Diagnosis</label>
                            <div className="col-12 flex items-end">
                              <div className="col-11">
                                {diagnosis.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <select
                                        id=""
                                        name=""
                                        className="form-control col-12 mb-3"
                                        // value={data.diagnosis}
                                        // onChange={handleChange}
                                      >
                                        <option value="Diagnosis 1">
                                          Diagnosis 1
                                        </option>
                                        <option value="Diagnosis 2">
                                          Diagnosis 2
                                        </option>
                                        <option value="Diagnosis 3">
                                          Diagnosis 3
                                        </option>
                                        <option value="Diagnosis 4">
                                          Diagnosis 4
                                        </option>
                                      </select>
                                    </div>
                                  );
                                })}
                              </div>
                              <button
                                onClick={addNewDiagnosis}
                                type="button"
                                className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-12">
                        <label>Final Diagnosis</label>

                        <div className="col-12 flex items-end">
                          <div className="col-11">
                            {diagnosis.map((item, index) => {
                              return (
                                <div key={index}>
                                  <select
                                    id=""
                                    name=""
                                    className="form-control col-12 mb-3"
                                    // value={data.diagnosis}
                                    // onChange={handleChange}
                                  >
                                    <option value="Diagnosis 1">
                                      Diagnosis 1
                                    </option>
                                    <option value="Diagnosis 2">
                                      Diagnosis 2
                                    </option>
                                    <option value="Diagnosis 3">
                                      Diagnosis 3
                                    </option>
                                    <option value="Diagnosis 4">
                                      Diagnosis 4
                                    </option>
                                  </select>
                                </div>
                              );
                            })}
                          </div>
                          <button
                            onClick={addNewDiagnosis}
                            type="button"
                            className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    {HSPType === "Pharmacy" ? (
                      <div className="row">
                        <div className="col-12 mb-4">
                          <label className="text-xl">
                            Medications/Consumables
                          </label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : HSPType === "Lab & Diagnostics" ? (
                      <div className="row">
                        <div className="col-12 mb-4">
                          <label className="text-xl">Investigations</label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12 mb-4">
                          <label className="text-xl">Consultation</label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          <label className="text-xl">Investigations</label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          <label className="text-xl">
                            Surgery and Procedures
                          </label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          <label className="text-xl">
                            Admission/Ward/Accommodation
                          </label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          <label className="text-xl">
                            Medications/Consumables
                          </label>
                          <div className="flex col-12">
                            <div className="col-2">
                              <label>QTY</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-5">
                              <label>Service</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Unit Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="col-2">
                              <label>Total Cost</label>
                              <input type="number" className="form-control" />
                            </div>
                            <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {currentStep === 4 && (
                  <>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="mt-3">
                          <label htmlFor="" className="text">
                            Attachments
                          </label>
                          <input
                            type="file"
                            name=""
                            id=""
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="buttons mt-3 flex justify-between">
                  {currentStep != 1 && (
                    <div
                      onClick={handlePrevious}
                      className="btn btn-primary btn-sm flex items-center justify-center"
                    >
                      <i className="fas fa-backward mr-2" />
                      Previous
                    </div>
                  )}
                  {currentStep === 1 && (
                    <div
                      onClick={handlePrevious}
                      // className="btn btn-primary btn-sm flex items-center justify-center"
                    ></div>
                  )}
                  {currentStep === 4 ? (
                    <div
                      //   onClick={handleNext}
                      className="btn btn-primary btn-sm closs flex items-center justify-center"
                    >
                      <i className="fas fa-save mr-2" />
                      Save
                    </div>
                  ) : (
                    <div
                      onClick={handleNext}
                      className="btn btn-primary btn-sm closs flex items-center justify-center"
                    >
                      Next
                      <i className="fas fa-forward ml-2" />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default AddNewRefundModal;
