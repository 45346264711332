import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { GiMoneyStack } from "react-icons/gi";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { FaStethoscope } from "react-icons/fa";
import NewClaimEmailModal from "../../../components/new-claim/NewClaimEmailModal";
import NewClaimSMSModal from "../../../components/new-claim/NewClaimSMSModal";
import NewClaimPDFModal from "../../../components/new-claim/NewClaimPDFModal";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import AddNewRefundModal from "../../../components/claims/refund/AddNewRefundModal";
import NewRefundClaimsCallSummaryModal from "../../../components/claims/refund/NewRefundClaimsCallSummaryModal";

const FinancialsViewNewRefundDetails = () => {
  const headers = ["Qty", "Claim Item", "Unit Cost", "Total Cost", ""];
  const navigate = useNavigate();
  const rows = [
    {
      qty: "1",
      claim_item: "Medication LTFYKGHJB, VJSHD FJHKDSKVJDSGLKSD GRD",
      unit_cost: "200.00",
      total_cost: "600.00",
    },
    {
      qty: "4",
      claim_item: "Consultation",
      unit_cost: "200.00",
      total_cost: "800.00",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Financials",
                  url: "/view-claims-new-refund",
                },
                {
                  title: "Approved Refund",
                  url: "/view-claims-new-refund",
                },
                {
                  title: "Approved Refund Details",
                  url: "/view-claims-new-refund-details",
                },
              ]}
            />
            <div className="row">
              <div className="col-11 mx-auto">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-end gap-4 mb-2">
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#call-summary-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-phone mr-2" />
                        Call Summary
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#sms-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-comment mr-2" />
                        SMS
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#email-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-envelope mr-2" />
                        Email
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#pdf-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-file mr-2" />
                        PDF
                      </button>
                    </div>
                    <ul className="nav nav-pills bg-nav-pills nav-justified">
                      {/* <li className="nav-item">
                        <a
                          href="#adminreview"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded active"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Claim Details
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#benefits"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Benefits</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#attachments"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Attachments</span>
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <a
                          href="#claimdetails"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded active"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Claim Details
                          </span>
                        </a>
                      </li>
                        <li className="nav-item">
                        <a
                          href="#benefits"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Benefits</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#attachments"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Attachments</span>
                        </a>
                      </li> 

                      <li className="nav-item">
                        <a
                          href="#adminreview"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Admin Review{" "}
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#medicalreview"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Medical Review{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="policy-details">
                        <div className="card-header text center">
                          <a>
                            <img
                              className="avatar mx-auto d-block rounded-circle mb-2"
                              style={{ width: "50px" }}
                              src="assets/images/img1.jpg"
                              alt="PAPA"
                            />
                          </a>
                          <div className="text-center">
                            <h3 className="font-bold text-2xl">
                              Francis Amoah
                            </h3>
                            <p className="text-xl">Male| 64yrs | Father </p>
                          </div>
                        </div>
                      </div>
                      {/* pane */}
                      <div
                        className="tab-pane process_claim active"
                        id="claimdetails"
                      >
                        <form>
                          <fieldset>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                            />
                            <a href="#">
                              <input
                                type="hidden"
                                name="id"
                                defaultValue={796}
                              />
                            </a>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="icon-user mr-1" />
                                    Patient ID
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="staff_id"
                                      name="staff_id"
                                      className="form-control"
                                      placeholder="Staff/Dependants ID"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-address-card mr-1" />
                                    Ghana Card
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="ghana_card"
                                      name="ghana_card"
                                      className="form-control"
                                      placeholder="Ghana Card No."
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-calendar mr-1" />
                                    Date Visited
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      disabled={true}
                                      type="date"
                                      id="date_visited"
                                      name="date_visited"
                                      className="form-control"
                                    />
                                    <input
                                      disabled={true}
                                      type="date"
                                      id="date_visited"
                                      name="date_visited"
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div className="bg-[#F7F7F7] p-4 card-title text-xl">
                            <h2>HSP DETAILS</h2>{" "}
                          </div>
                          <fieldset>
                            <div className="row">
                              <div className="col-6 my-4">
                                <label htmlFor="">HSP Name</label>
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  placeholder="HSP Name"
                                />
                              </div>
                              <div className="col-6 my-4">
                                <label htmlFor="">HSP Type</label>
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  placeholder="HSP Type"
                                />
                              </div>
                            </div>
                          </fieldset>

                          <div className="bg-[#F7F7F7] p-4 card-title text-xl">
                            <h2>
                              Clinic & Diagnosis{"("}ICD 10{")"}
                            </h2>
                          </div>
                          <fieldset>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td className="font-bold">General Clinic</td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        IPD
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        OPD
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Maternity {"("}ANC {")"}
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Maternity {"("}PNC {")"}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Special Clinic</td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Dental
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Obs {"&"} Gyne
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        ENT
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Optical{"/"}Ophthalmology
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">
                                    Initial Diagnosis
                                  </td>
                                  <td>
                                    <div className="my-2 flex gap-2">
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        Cholera
                                      </span>
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        Maleria
                                      </span>
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        HIV
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Final Diagnosis</td>
                                  <td>
                                    <div className="my-2 flex gap-2">
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        Cholera
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                      {/* pane */}
                      <div className="tab-pane process_claim" id="benefits">
                        <div className="card-shadow shadow-sm pb-2 ">
                          <div className="bg-[#F7F7F7] p-2 card-title text-xl mt-4">
                            <h2>Treatment & Service Costing</h2>
                          </div>
                          <div className="px-2">
                            <h3 className="text-xl mb-4">Consultation</h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-[#0054] cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />
                                      <span className="ml-2">
                                        comment goes here
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h3 className="text-xl mb-4 mt-6">
                              Investigations
                            </h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        gap: "4px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-danger cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />{" "}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h3 className="text-xl mb-4 mt-6">
                              Surgery and Procedures
                            </h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-danger cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h3 className="text-xl mb-4 mt-6">Medications</h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      backgroundColor: "#43e932",
                                    }}
                                  >
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-danger cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>

                            <div className="flex gap-4 justify-end mb-2 pr-24">
                              {/* add a decline and approve button */}
                              <h3 className="btn flex items-center justify-center">
                                Proposed Claim Amount:
                              </h3>
                              <h3 className="btn  btn-warning  flex items-center justify-center">
                                12,892.00
                              </h3>
                            </div>
                            <div className="flex gap-4 justify-end mb-2 pr-24">
                              {/* add a decline and approve button */}
                              <h3 className="btn flex items-center justify-center">
                                Accepted Total Amount:
                              </h3>
                              <h3 className="btn  btn-primary  flex items-center justify-center">
                                12,892.00
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane process_claim" id="attachments">
                        <div className="card-body">
                          <fieldset>
                            <div className=" p-4 w-[90%] mx-auto mt-4">
                              <div className="row">
                                <button
                                  data-toggle="modal"
                                  data-target="#pdf-modal"
                                  className="form-control flex justify-between items-center"
                                >
                                  <h2>Fle Tile</h2>
                                  <i className="fa fa-eye" />
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="tab-pane process_claim" id="adminreview">
                        <div className="card-body">
                          <fieldset>
                            <div className=" p-4 w-[90%] mx-auto mt-4">
                              <p className="mt-4 mb-2 text-left w-full">
                                Please validate claim information. You may
                                request further information from the claimant as
                                well.
                              </p>
                              <div className="flex justify-between gap-4">
                                <textarea className="w-full  border-[2px] min-h-[15vh] p-2"></textarea>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center h-8 my-auto"
                                >
                                  <i className="fa fa-save mr-2" />
                                  Save
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className="buttons flex justify-end mt-3  gap-4 w-[90%] ">
                          <button
                            className="btn btn-info btn-sm closs flex items-center justify-center"
                            data-dismiss="modal"
                          >
                            <i className="fas fa-check mr-2" />
                            Vetted
                          </button>
                          <button
                            className="btn btn-warning btn-sm flex gap-2 items-center justify-center "
                            data-dismiss="modal"
                          >
                            <FaStethoscope /> Medical Vetting
                          </button>
                        </div>
                        <hr className="my-2" />
                        <div className="mt-6 w-full ">
                          <div className="flex w-[80%] justify-between items-center mx-auto">
                            <h3 className="font-bold ml-2 text-xl ">
                              Status |{" "}
                              <span className="badge badge-success rounded-pill text-lg">
                                Passed
                              </span>
                            </h3>
                            <h3 className="font-bold ml-2 text-md">
                              Ama Sapong |{" "}
                              <span className="font-normal text-md">
                                04-SEP-2023 10:12:00 PM
                              </span>
                            </h3>
                          </div>
                          <hr className="my-2" />

                          <div className="bg-[#7676] p-4 w-[80%] mt-2 mx-auto">
                            <div className="flex justify-end mb-3 gap-2">
                              <div className="flex font-bold">Kofi Meanu |</div>
                              <div className="flex">
                                30-JAN-2023 10:12:00 PM
                              </div>
                            </div>
                            <h4>
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Ipsam sunt dolorum praesentium
                              aperiam ipsa numquam, error explicabo voluptatum!
                              Consequuntur vero quis culpa consectetur, impedit
                              magni. Itaque impedit aliquid, unde nam accusamus
                              iusto ad. Tenetur esse, aspernatur dolores sint
                              asperiores ipsa totam aut quibusdam, fuga, earum
                              alias quas recusandae. Ab accusamus illum deleniti
                              qui nisi totam saepe quidem explicabo perspiciatis
                              in.
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane process_claim"
                        id="medicalreview"
                      >
                        <div className="col-8">
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="custom-control custom-checkbox flex mb-4 gap-24">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="customCheck20"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck20"
                                    >
                                      Pass
                                    </label>
                                  </div>
                                  {/* <div>
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck22"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck22"
                              >
                                Advice
                              </label>
                            </div> */}
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="customCheck21"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck21"
                                    >
                                      Reject
                                    </label>
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="" /> Diagnosis vs
                                    Investigation mismatch
                                  </label>
                                  <textarea
                                    name=""
                                    id="form-control"
                                    cols={10}
                                    rows={2}
                                    defaultValue={
                                      "                                                "
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-right">
                              <button
                                type="submit"
                                id="comment_btn"
                                className="btn btn-primary btn-sm btn-rounded bg-primary"
                              >
                                <i className="fas fa-save" /> Save
                              </button>
                            </div>
                          </form>
                          <hr />
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="" /> Diagnosis vs Medication
                                    mismatch
                                  </label>
                                  <textarea
                                    name=""
                                    id="form-control"
                                    cols={10}
                                    rows={2}
                                    defaultValue={
                                      "                                                     \n                                                "
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-right">
                              <button
                                type="submit"
                                id="comment_btn"
                                className="btn btn-primary btn-sm btn-rounded bg-primary"
                              >
                                <i className="fas fa-save" /> Save
                              </button>
                            </div>
                          </form>
                          <hr />
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="" /> Diagnosis vs
                                    Gender-Lab-Med mismatch
                                  </label>
                                  <textarea
                                    name=""
                                    id="form-control"
                                    cols={10}
                                    rows={2}
                                    defaultValue={
                                      "                                                \n                                                "
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-right">
                              <button
                                type="submit"
                                id="comment_btn"
                                className="btn btn-primary btn-sm btn-rounded bg-primary"
                              >
                                <i className="fas fa-save" /> Save
                              </button>
                            </div>
                          </form>
                          <hr />
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="" /> Diagnosis vs
                                    Gender-Specialty mismatch
                                  </label>
                                  <textarea
                                    name=""
                                    id="form-control"
                                    cols={10}
                                    rows={2}
                                    defaultValue={
                                      "                                                    My Comment\n                                                "
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-right">
                              <button
                                type="submit"
                                id="comment_btn"
                                className="btn btn-primary btn-sm btn-rounded bg-primary"
                              >
                                <i className="fas fa-save" /> Save
                              </button>
                            </div>
                          </form>
                          <hr />
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="" /> Other Comments
                                  </label>
                                  <textarea
                                    name=""
                                    id="form-control"
                                    cols={10}
                                    rows={2}
                                    defaultValue={
                                      "                                                    \n                                                "
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-right">
                              <button
                                type="submit"
                                id="comment_btn"
                                className="btn btn-primary btn-sm btn-rounded bg-primary"
                              >
                                <i className="fas fa-save" /> Save
                              </button>
                            </div>
                          </form>
                          <hr className="my-2" />
                          <div className="mt-6 w-full ">
                            <div className="flex w-[80%] justify-between items-center mx-auto">
                              <h3 className="font-bold ml-2 text-xl ">
                                Status |{" "}
                                <span className="badge badge-success rounded-pill text-lg">
                                  Passed
                                </span>
                              </h3>
                              <h3 className="font-bold ml-2 text-md">
                                Ama Sapong |{" "}
                                <span className="font-normal text-md">
                                  04-SEP-2023 10:12:00 PM
                                </span>
                              </h3>
                            </div>
                            <hr className="my-2" />

                            <div className="bg-[#7676] p-4 w-[80%] mt-2 mx-auto">
                              <div className="flex justify-end mb-3 gap-2">
                                <div className="flex font-bold">
                                  Kofi Meanu |
                                </div>
                                <div className="flex">
                                  30-JAN-2023 10:12:00 PM
                                </div>
                              </div>
                              <h4>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Ipsam sunt dolorum praesentium
                                aperiam ipsa numquam, error explicabo
                                voluptatum! Consequuntur vero quis culpa
                                consectetur, impedit magni. Itaque impedit
                                aliquid, unde nam accusamus iusto ad. Tenetur
                                esse, aspernatur dolores sint asperiores ipsa
                                totam aut quibusdam, fuga, earum alias quas
                                recusandae. Ab accusamus illum deleniti qui nisi
                                totam saepe quidem explicabo perspiciatis in.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  Modal for Flagging Claims */}
          <div
            className="modal fade"
            id="flag-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    Flag claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <form>
                      <div className="form-body">
                        <div className="col-12 mb-3">
                          <label>
                            You are flagging receipt of claims from{" "}
                            <span className="text-[#5F76E8]">
                              Francis Hospital
                            </span>{" "}
                            with Batch No.{" "}
                            <span className="text-[#5F76E8]">001</span>
                          </label>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-question mr-1" />
                              Please select a reason for flagging.
                            </label>
                            <select
                              className="form-control"
                              // placeholder=" select one"
                            >
                              <option value="">Select a comment</option>
                              <option
                                value={"diagnosis vs medication mismatch"}
                              >
                                diagnosis vs medication mismatch
                              </option>
                              <option
                                value={"diagnosis vs investigation mismatch"}
                              >
                                diagnosis vs investigation mismatch
                              </option>
                              <option
                                value={" diagnosis vs medication-age mismatch"}
                              >
                                diagnosis vs medication-age mismatch
                              </option>
                              <option value={"no related diagnosis"}>
                                no related diagnosis
                              </option>
                              <option value={"Others"}>Others</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        >
                          <i className="fas fa-check mr-2" />
                          Proceed
                        </button>
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/*  new refund */}

          <NewRefundClaimsCallSummaryModal />

          {/*  SMS Modal */}
          <NewClaimSMSModal />

          {/* Email Modal */}
          <NewClaimEmailModal />

          {/* Email Modal */}
          <NewClaimPDFModal />

          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </TableContainer>
  );
};

export default FinancialsViewNewRefundDetails;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
