import React from "react";

interface DeleteMedDeliveryModalProps {
  handlePrevious: () => void;
}

const DeleteMedDeliveryModal: React.FC<DeleteMedDeliveryModalProps> = ({
  handlePrevious,
}) => {
  return (
    <div
      className="modal fade"
      id="deleteModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Delete Delivery Claim
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form id="deleteForm">
                      <div className="form-body">
                        <div className="">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-question" /> Reason
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Reason for deleting this claim"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* start send buttons */}
                        <div className="form-footer d-flex justify-between mt-4">
                          <div>
                            <button
                              type="button"
                              className="btn bg-danger rounded-pill text-end text-white"
                              id="nextBtn"
                              onClick={handlePrevious}
                            >
                              <span>
                                <i className="fa fa-times mr-2" />
                                Cancel
                              </span>
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn bg-primary rounded-pill text-end text-white"
                              id="nextBtn"
                            >
                              <span>
                                Delete
                                <i className="fa fa-trash ml-2" />
                              </span>
                            </button>
                          </div>
                        </div>
                        {/* end send buttons */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default DeleteMedDeliveryModal;
