import { GiTakeMyMoney, GiHospitalCross, GiRadioactive } from "react-icons/gi";
import { CiHospital1, CiViewList } from "react-icons/ci";
import { MdMedicationLiquid } from "react-icons/md";
import { PiOfficeChairThin } from "react-icons/pi";
import { HiOutlineMapPin } from "react-icons/hi2";
import { FaPeopleRobbery, FaRegEye, FaTeeth } from "react-icons/fa6";
import { AiOutlineControl } from "react-icons/ai";
import { TbTruckReturn } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { GrServices } from "react-icons/gr";
import { TfiTruck } from "react-icons/tfi";
import { BiHistory } from "react-icons/bi";
import { MdLogin } from "react-icons/md";
import { BsBuildingCheck } from "react-icons/bs";
import { PermissionsType } from "../types/data";

const Sidebar = () => {
  const location = useLocation();
  const [permissions, setPermissions] = useState<PermissionsType | null>(null);
  const currentLocation = location.pathname;
  const [userDetails, setUserDetails] = useState<any>([]);

  const currentcolor = (route: string) => {
    // if the currentLocation is in the route, return active
    if (currentLocation.includes(route)) {
      return "active";
    } else {
      return undefined;
    }
  };

  const handleDropdown = (which: string) => {
    if (which === "my_side_subview_1") {
      const dropdown = document.querySelector(".my_side_subview_1");
      dropdown?.classList.toggle("active");
    } else if (which === "my_side_subview_2") {
      const dropdown1 = document.querySelector(".my_side_subview_2");
      dropdown1?.classList.toggle("active");
    } else if (which === "my_side_subview_3") {
      const dropdown2 = document.querySelector(".my_side_subview_3");
      dropdown2?.classList.toggle("active");
    } else if (which === "my_side_subview_4") {
      const dropdown3 = document.querySelector(".my_side_subview_4");
      dropdown3?.classList.toggle("active");
    } else if (which === "my_side_subview_5") {
      const dropdown3 = document.querySelector(".my_side_subview_5");
      dropdown3?.classList.toggle("active");
    } else if (which === "my_side_subview_6") {
      const dropdown3 = document.querySelector(".my_side_subview_6");
      dropdown3?.classList.toggle("active");
    } else {
      return;
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  // get user details
  useEffect(() => {
    const usr = sessionStorage.getItem("userDetails");
    const user = JSON.parse(usr || "{}");
    setUserDetails(user);
    setPermissions({
      policy_admin: {
        policy_admin_dashboard:
          user?.policy_admin?.policy_admin_dashboard || false,
        policy_admin_staff: user?.policy_admin?.policy_admin_staff || false,
        policy_admin_authorization:
          user?.policy_admin?.policy_admin_authorization || false,
        policy_admin_med_delivery:
          user?.policy_admin?.policy_admin_med_delivery || false,
        policy_admin_reports: user?.policy_admin?.policy_admin_reports || false,
      },
      manage_hsp: {
        manage_hsp_reports: user?.manage_hsp?.manage_hsp_reports || false,
        manage_hsp_accredited_hsp:
          user?.manage_hsp?.manage_hsp_accredited_hsp || false,
        manage_hsp_qos_survey: user?.manage_hsp?.manage_hsp_qos_survey || false,
      },
      finance_section: {
        finance_dashboard: user?.finance_section?.finance_dashboard || false,
        finance_approved_claims:
          user?.finance_section?.finance_approved_claims || false,
        finance_paid_claims:
          user?.finance_section?.finance_paid_claims || false,
        finance_refund: user?.finance_section?.finance_refund || false,
        finance_reports: user?.finance_section?.finance_reports || false,
      },
      system_admin: {
        system_admin_setup_services:
          user?.system_admin?.system_admin_setup_services || false,
        system_admin_manage_scheme:
          user?.system_admin?.system_admin_manage_scheme || false,
        system_admin_user_setup:
          user?.system_admin?.system_admin_user_setup || false,
        system_admin_hsp_classification:
          user?.system_admin?.system_admin_hsp_classification || false,
      },
      claims_section: {
        claims_dashboard: user?.claims_section?.claims_dashboard || false,
        claims_manual: user?.claims_section?.claims_manual || false,
        claims_claim_list: user?.claims_section?.claims_claim_list || false,
        claims_med_vetting: user?.claims_section?.claims_med_vetting || false,
        claims_refund: user?.claims_section?.claims_refund || false,
        claims_reports: user?.claims_section?.claims_reports || false,
      },
    });
  }, []);

  return (
    <>
      <aside className="left-sidebar bg-black mt-10" data-sidebarbg="skin6">
        {/* Sidebar scroll*/}
        <div className="scroll-sidebar" data-sidebarbg="skin6">
          {/* Sidebar navigation*/}
          {permissions && (
            <nav className="sidebar-nav">
              <ul id="sidebarnav">
                <li className="sidebar-item">
                  {" "}
                  <a
                    className={`sidebar-link sidebar-link ${currentcolor(
                      "/dashboard"
                    )}`}
                    href="/dashboard"
                    aria-expanded="false"
                  >
                    <RxDashboard size={20} className="mr-2" />
                    <span className="hide-menu">Dashboard</span>
                  </a>
                </li>

                {/* Claims */}

                <>
                  {permissions.claims_section.claims_dashboard === true && (
                    <>
                      <li className="list-divider" />
                      <li className="nav-small-cap">
                        <span className="hide-menu">Claims</span>
                      </li>

                      <li className="sidebar-item">
                        {" "}
                        <a
                          className={`sidebar-link sidebar-link ${currentcolor(
                            "/dashboard"
                          )}`}
                          href="/dashboard"
                          aria-expanded="false"
                        >
                          <RxDashboard size={20} className="mr-2" />
                          <span className="hide-menu">Dashboard</span>
                        </a>
                      </li>
                    </>
                  )}

                  {/* {permissions.claims_section.claims_manual === true && ( */}
                  {true && (
                    <li className="sidebar-item">
                      <a
                        href="/manual-claims"
                        className={`sidebar-link sidebar-link ${currentcolor(
                          "/manual-claims"
                        )} `}
                      >
                        <span className="hide-menu">
                          <i className="fa fa-edit" /> Manual Claims
                        </span>
                      </a>
                    </li>
                  )}

                  {permissions.claims_section.claims_claim_list === true && (
                    <>
                      <li className="sidebar-item">
                        <a
                          className="sidebar-link has-arrow"
                          href="#/"
                          onClick={() => handleDropdown("my_side_subview_1")}
                          aria-expanded="false"
                        >
                          <CiViewList size={20} className="mr-2" />
                          <span className="hide-menu">Claim List </span>
                        </a>
                        <ul
                          className="my_side_subview_1 in"
                          id="my_side_subview"
                        >
                          <li className="sidebar-item">
                            <a href="/new-claims" className="sidebar-link">
                              <span className="hide-menu"> New Claims</span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a href="/vetted-claims" className="sidebar-link">
                              <span className="hide-menu"> Vetted Claims</span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a href="/approved-claims" className="sidebar-link">
                              <span className="hide-menu">
                                {" "}
                                Approved Vetting
                              </span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a href="/paid-claims" className="sidebar-link">
                              <span className="hide-menu"> Paid Claims</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  )}

                  {permissions.claims_section.claims_med_vetting === true && (
                    <li className="sidebar-item">
                      <a href="/medical-vetting" className="sidebar-link">
                        <span className="hide-menu">
                          <i className="icon-paypal" /> Medical Vetting
                        </span>
                      </a>
                    </li>
                  )}

                  {permissions.claims_section.claims_refund === true && (
                    <li className="sidebar-item">
                      <a
                        className={`sidebar-link has-arrow ${currentcolor(
                          "/claims-refund"
                        )}`}
                        href="#"
                        onClick={() => handleDropdown("my_side_subview_2")}
                        aria-expanded="false"
                      >
                        <GiTakeMyMoney size={20} className="mr-2" />
                        <span className="hide-menu">Refund</span>
                      </a>
                      <ul
                        className="my_side_subview_2 in"
                        id="my_side_subview"
                        onClick={() => {
                          // alert("here");
                        }}
                      >
                        <li className="sidebar-item">
                          <a
                            href="/view-claims-new-refund"
                            className="sidebar-link"
                          >
                            <span className="hide-menu">New Refund</span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a
                            href="/fn-view-new-refund"
                            className="sidebar-link"
                          >
                            <span className="hide-menu">Approved Refund</span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a
                            href="/fn-view-new-refund-details"
                            className="sidebar-link"
                          >
                            <span className="hide-menu"> Paid Refund</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                  {permissions.claims_section.claims_reports === true && (
                    <li className="sidebar-item">
                      <a href="claims_report.html" className="sidebar-link">
                        <span className="hide-menu">
                          <i className="icon-chart" /> Reports
                        </span>
                      </a>
                    </li>
                  )}
                </>
                <>
                  {/* Policy Admin */}
                  {permissions.policy_admin.policy_admin_dashboard === true && (
                    <>
                      <li className="list-divider" />
                      <li className="nav-small-cap">
                        <span className="hide-menu">Policy Admin</span>
                      </li>

                      <li className="sidebar-item">
                        {" "}
                        <a
                          className={`sidebar-link sidebar-link ${currentcolor(
                            "/system-admin"
                          )}`}
                          href="/system-admin"
                          aria-expanded="false"
                        >
                          <RxDashboard size={20} className="mr-2" />
                          <span className="hide-menu">Dashboard</span>
                        </a>
                      </li>
                    </>
                  )}

                  {permissions.policy_admin.policy_admin_staff === true && (
                    <li className="sidebar-item">
                      <a
                        className={`sidebar-link sidebar-link ${currentcolor(
                          "/staff"
                        )}`}
                        href="/staff"
                        aria-expanded="false"
                      >
                        <i data-feather="user" className="fas fa-users" />
                        <span className="hide-menu">Staff</span>
                      </a>
                    </li>
                  )}

                  {/* {permissions.policy_admin.policy_admin_authorization === */}
                  {true && (
                    <li className="sidebar-item">
                      <a
                        className={`sidebar-link sidebar-link ${currentcolor(
                          "/authorization"
                        )}`}
                        href="/authorization"
                        aria-expanded="false"
                      >
                        <i className="icon-key" />
                        <span className="hide-menu">Authorization</span>
                      </a>
                    </li>
                  )}

                  {/* {permissions.policy_admin.policy_admin_med_delivery === */}
                  {true && (
                    <li className="sidebar-item">
                      <a
                        className={`sidebar-link has-arrow`}
                        href="#/"
                        onClick={() => handleDropdown("my_side_subview_3")}
                        aria-expanded="false"
                      >
                        <TfiTruck size={20} className="mr-2" />
                        <span className="hide-menu">Med Delivery</span>
                      </a>
                      <ul
                        className="my_side_subview_3 ml-3"
                        // id="my_side_subview"
                      >
                        <li className="sidebar-item">
                          <a
                            href="/new-deliveries"
                            className={`sidebar-link sidebar-link ${currentcolor(
                              "/new-deliveries"
                            )}`}
                          >
                            <span className="hide-menu">
                              <TbTruckReturn
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              New Deliveries
                            </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a
                            href="/history"
                            className={`sidebar-link sidebar-link ${currentcolor(
                              "/history"
                            )}`}
                          >
                            <span className="hide-menu">
                              <BiHistory
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Delivery History
                            </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a
                            href="/map-view"
                            className={`sidebar-link sidebar-link ${currentcolor(
                              "/map-view"
                            )}`}
                          >
                            <span className="hide-menu">
                              <HiOutlineMapPin
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Map View
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                  {/* <li className="sidebar-item">
                  <a
                    className={`sidebar-link sidebar-link ${currentcolor(
                    x  "/policy-admin/crm"
                    )}`}
                    href="/policy-admin/crm"
                    aria-expanded="false"
                  >
                    <FaArrowsDownToPeople size={20} className="mr-2" />
                    <span className="hide-menu">CRM</span>
                  </a>
                </li> */}

                  {/* <li className="sidebar-item">
                  <a
                    className="sidebar-link"
                    href="assuredlives.html"
                    aria-expanded="false">
                    <i data-feather="user" className="feather-icon" />
                    <span className="hide-menu">Complaint Manager</span>
                  </a>
                </li> */}

                  {permissions.policy_admin.policy_admin_reports === true && (
                    <li className="sidebar-item">
                      <a
                        className="sidebar-link"
                        href="assuredlives.html"
                        aria-expanded="false"
                      >
                        <i className="icon-chart" />{" "}
                        <span className="hide-menu">Reports</span>
                      </a>
                    </li>
                  )}
                </>

                <>
                  {(permissions.manage_hsp.manage_hsp_accredited_hsp === true ||
                    permissions.manage_hsp.manage_hsp_reports === true ||
                    permissions.manage_hsp.manage_hsp_qos_survey === true) && (
                    <>
                      <li className="list-divider" />
                      <li className="nav-small-cap">
                        <span className="hide-menu">Manage HSP'S</span>
                      </li>
                      <li className="sidebar-item">
                        {" "}
                        <a
                          className={`sidebar-link sidebar-link ${currentcolor(
                            "/dashboard-hsp"
                          )}`}
                          href="/dashboard-hsp"
                          aria-expanded="false"
                        >
                          <RxDashboard size={20} className="mr-2" />
                          <span className="hide-menu">Dashboard</span>
                        </a>
                      </li>
                    </>
                  )}

                  {permissions.manage_hsp.manage_hsp_accredited_hsp ===
                    true && (
                    <li className="sidebar-item">
                      {" "}
                      <a
                        className={`sidebar-link sidebar-link ${currentcolor(
                          "/accredited-hsp"
                        )}`}
                        href="/accredited-hsp"
                        aria-expanded="false"
                      >
                        <CiHospital1 size={20} className="mr-2" />
                        <span className="hide-menu">Accredited HSP</span>
                      </a>
                    </li>
                  )}

                  {/* <li className="sidebar-item">
                  <a href="managePlan.html" className="sidebar-link">
                    <span className="hide-menu">
                      <i data-feather="edit-3" className="feather-icon" /> QOs
                      Survey
                    </span>
                  </a>
                </li> */}

                  {permissions.manage_hsp.manage_hsp_reports === true && (
                    <li className="sidebar-item">
                      <a href="pricing.html" className="sidebar-link">
                        <span className="hide-menu">
                          <i className="icon-chart" /> Report
                        </span>
                      </a>
                    </li>
                  )}
                </>

                {/* Finance */}
                <>
                  {(permissions.finance_section.finance_dashboard === true ||
                    permissions.finance_section.finance_approved_claims ===
                      true ||
                    permissions.finance_section.finance_paid_claims === true ||
                    permissions.finance_section.finance_refund === true ||
                    permissions.finance_section.finance_reports === true) && (
                    <>
                      <li className="list-divider" />
                      <li className="nav-small-cap">
                        <span className="hide-menu">Financials</span>
                      </li>
                    </>
                  )}

                  {permissions.finance_section.finance_dashboard === true && (
                    <li className="sidebar-item">
                      {" "}
                      <a
                        className={`sidebar-link sidebar-link ${currentcolor(
                          "/dashboard"
                        )}`}
                        href="/dashboard"
                        aria-expanded="false"
                      >
                        <RxDashboard size={20} className="mr-2" />
                        <span className="hide-menu">Dashboard</span>
                      </a>
                    </li>
                  )}

                  {permissions.finance_section.finance_approved_claims ===
                    true && (
                    <li className="sidebar-item">
                      <a
                        href="/fn-approved-claims"
                        className={`sidebar-link sidebar-link ${currentcolor(
                          "/fn-approved-claims"
                        )}`}
                      >
                        <span className="hide-menu">
                          {/* <LuFolderCheck size={20} className="mr-2" /> */}
                          <i
                            data-feather="folder"
                            className="feather-icon"
                          />{" "}
                          Approved Claims
                        </span>
                      </a>
                    </li>
                  )}

                  {permissions.finance_section.finance_paid_claims === true && (
                    <li className="sidebar-item">
                      <a href="/fn-paid-claims" className="sidebar-link">
                        <span className="hide-menu">
                          <i
                            data-feather="credit-card"
                            className="feather-icon"
                          />{" "}
                          Paid Claims
                        </span>
                      </a>
                    </li>
                  )}

                  {/* {userDetails.finance_refund === true && (
                    <li className="sidebar-item">
                      <a href="fin_settlement.html" className="sidebar-link">
                        <span className="hide-menu">
                          <GiTakeMyMoney size={20} className="feather-icon" />
                          Refund
                        </span>
                      </a>
                    </li>
                  )} */}
                  {permissions.finance_section.finance_refund === true && (
                    <li className="sidebar-item">
                      <a
                        className={`sidebar-link has-arrow`}
                        href="#/"
                        onClick={() => handleDropdown("my_side_subview_4")}
                        aria-expanded="false"
                      >
                        <GiTakeMyMoney size={20} className="feather-icon" />
                        <span className="hide-menu">Refund</span>
                      </a>
                      <ul
                        className="my_side_subview_4 in ml-3"
                        id="my_side_subview"
                      >
                        <li className="sidebar-item">
                          <a
                            href="/fn-view-new-refund"
                            className={`sidebar-link sidebar-link ${currentcolor(
                              "/fn-view-new-refund"
                            )}`}
                          >
                            <span className="hide-menu">
                              <TbTruckReturn
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Approved Refund
                            </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a
                            href="/fn-view-new-refund-details"
                            className={`sidebar-link sidebar-link ${currentcolor(
                              "/fn-view-new-refund-details"
                            )}`}
                          >
                            <span className="hide-menu">
                              <BiHistory
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Paid Refund
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                  {permissions.finance_section.finance_reports === true && (
                    <li className="sidebar-item">
                      <a href="fin_settlement.html" className="sidebar-link">
                        <span className="hide-menu">
                          <PiOfficeChairThin
                            size={20}
                            className="feather-icon"
                          />
                          Reports
                        </span>
                      </a>
                    </li>
                  )}
                </>

                <li className="list-divider" />

                {/* System Admin */}
                <>
                  <li className="nav-small-cap">
                    <span className="hide-menu">System Admin</span>
                  </li>

                  {permissions.system_admin.system_admin_setup_services ===
                    true && (
                    <li className="sidebar-item">
                      {" "}
                      <a
                        className="sidebar-link has-arrow"
                        href="#/"
                        onClick={() => handleDropdown("my_side_subview_5")}
                        aria-expanded="false"
                      >
                        {/* <i data-feather="feather" className="feather-icon" /> */}
                        <GrServices size={20} className="mr-2" />
                        <span className="hide-menu">Setup Service</span>
                      </a>
                      <ul
                        className="my_side_subview_5 in "
                        id="my_side_subview"
                      >
                        <li className="sidebar-item">
                          <a href="/medication" className="sidebar-link">
                            <MdMedicationLiquid size={20} className="mr-2" />
                            <span className="hide-menu"> Medication </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/surgery" className="sidebar-link">
                            <span className="hide-menu">
                              {" "}
                              <GiHospitalCross
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Surgery and Procedure{" "}
                            </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/labs" className="sidebar-link">
                            <PiOfficeChairThin
                              size={20}
                              className="mr-2 feather-icon"
                            />
                            <span className="hide-menu">Labs </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/radiology" className="sidebar-link">
                            <GiRadioactive
                              size={20}
                              className="mr-2 feather-icon"
                            />
                            <span className="hide-menu"> Radiology </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/consultation" className="sidebar-link">
                            <FaPeopleRobbery
                              size={20}
                              className="mr-2 feather-icon"
                            />
                            <span className="hide-menu"> Consult & Admin </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/dental" className="sidebar-link">
                            <FaTeeth size={20} className="mr-2 feather-icon" />
                            <span className="hide-menu"> Dental </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/optical" className="sidebar-link">
                            <FaRegEye size={20} className="mr-2 feather-icon" />
                            <span className="hide-menu"> Optical </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                  {permissions.system_admin.system_admin_manage_scheme ===
                    true && (
                    <li className="sidebar-item">
                      <a
                        className={`sidebar-link has-arrow`}
                        href="#"
                        aria-expanded="false"
                        onClick={() => handleDropdown("my_side_subview_6")}
                      >
                        <AiOutlineControl size={20} className="mr-2" />
                        <span className="hide-menu">Manage scheme</span>
                      </a>
                      <ul
                        className="my_side_subview_6 in "
                        id="my_side_subview"
                      >
                        <li className="sidebar-item">
                          <a href="/manage-scheme" className="sidebar-link">
                            <span className="hide-menu">
                              <AiOutlineControl
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Schemes
                            </span>
                          </a>
                        </li>
                        <li className="sidebar-item">
                          <a href="/cashflow-setup" className="sidebar-link">
                            <span className="hide-menu">
                              <GiTakeMyMoney
                                size={20}
                                className="mr-2 feather-icon"
                              />
                              Cashflow Setup
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                  {permissions.system_admin.system_admin_user_setup ===
                    true && (
                    <li className="sidebar-item">
                      <a
                        href="/user-setup"
                        className={`sidebar-link sidebar-link in active`}
                      >
                        <span className="hide-menu">
                          <i className="icon-people" /> User Setup
                        </span>
                      </a>
                    </li>
                  )}

                  {/* {permissions.system_admin.diagnosis_setup ===
                    true && ( */}
                  <li className="sidebar-item">
                    <a
                      href="/diagnosis"
                      className={`sidebar-link sidebar-link in active`}
                    >
                      <span className="hide-menu">
                        <i className="icon-people" /> Diagnosis
                      </span>
                    </a>
                  </li>
                  {/* )} */}

                  {permissions.system_admin.system_admin_hsp_classification ===
                    true && (
                    <li className="sidebar-item">
                      <a
                        href="/company-setup"
                        className={`sidebar-link sidebar-link ${
                          currentcolor("/company-setup") ||
                          currentcolor("/view-company-setup")
                        }`}
                      >
                        <span className="hide-menu">
                          <BsBuildingCheck
                            size={20}
                            className="mr-2 feather-icon"
                          />
                          Company Setup
                        </span>
                      </a>
                    </li>
                  )}

                  <li className="sidebar-item">
                    {" "}
                    <a
                      className="sidebar-link sidebar-link"
                      href="/"
                      onClick={handleLogout}
                      aria-expanded="false"
                    >
                      <MdLogin size={20} className="mr-2" />
                      <span className="hide-menu">Logout</span>
                    </a>
                  </li>
                </>
              </ul>
            </nav>
          )}
          {/* End Sidebar navigation */}
        </div>
        {/* End Sidebar scroll*/}
      </aside>
    </>
  );
};

export default Sidebar;
