import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const createDental = async (
  token: string,
  dentalData: {
    dental_name: string;
    dental_category: string;
    dental_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/dental/`;
  try {
    const response = await axios.post(url, dentalData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllDentalCategory = async (token: string) => {
  let url = `${BASE_URL}/setup-services/dental/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// {{base_url}}/dental/get_all_dentals
const getAllDental = async (token: string) => {
  let url = `${BASE_URL}/setup-services/dental/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const bulkDentalUpload = async (token: string, dentalFile: FormData) => {
  let url = `${BASE_URL}/setup-services/dental/bulk_upload`;
  try {
    const response = await axios.post(url, dentalFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  createDental,
  getAllDentalCategory,
  getAllDental,
  bulkDentalUpload,
};
