import { useState } from "react";
import axios from "axios";

const ResetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleForm = (e: any) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_Base_url}/staff/login`, {
        email,
        password,
      })
      .then((res) => {
        alert(res.data.message);
        sessionStorage.setItem("token", res.data.token);
        if (
          res.data.user_details.isVerified === true &&
          res.data.status === 200
        ) {
          window.location.href = "/dashboard";
        } else if (
          res.data.user_details.isVerified === false &&
          res.data.status === 200
        ) {
          window.location.href = "/staff";
        }
      })
      .catch((err) => {
        alert(err.data.message);
      });
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative">
          <div className="auth-box row">
            <div
              className="col-lg-7 col-md-5 modal-bg-img bg-white border-r-4"
              style={{
                backgroundImage: "url(assets/images/logo.png)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}></div>

            <div className="col-lg-5 col-md-7 bg-white">
              <div className="p-3">
                <div className="text-center">
                  <div
                    className="icon-lock text-primary"
                    style={{ fontSize: "50px" }}
                  />
                </div>
                <h2 className="mt-3 text-center">Sign In</h2>
                <form className="mt-4" onSubmit={handleForm}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          placeholder="enter your email address"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="pwd">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="pwd"
                          type="password"
                          placeholder="enter your password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-rounded btn-block btn-primary bg-[#5F76E8]">
                        <i className="icon-login mr-4" /> Sign In
                      </button>
                    </div>
                    <div className="col-lg-12 text-center mt-5">
                      forgot password?{" "}
                      <a href="/" className="text-danger">
                        reset here
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
