import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { StyledSearch } from "../../../components/StyledSearch";

import AddNewRefundModal from "../../../components/claims/refund/AddNewRefundModal";
import ConfirmNewRefundModal from "../../../components/claims/refund/ConfirmNewRefundModal";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import EditNewRefundModal from "../../../components/claims/refund/EditNewRefundModal";
import MasterTableComponent from "../../../components/ui/MasterTableComponent";
import { CreateRefundBodyType } from "../../../types/data";
import { Toast } from "primereact/toast";
import formatMoney from "../../../utils/formatMoney";
import refundApi from "../../../api/refundApi";

const ViewClaimsNewRefund = () => {
  const toast = useRef<Toast>(null);
  const [fetchedRefunds, setFetchedRefunds] = useState<any[]>([]);
  const [selectedRefund, setSelectedRefund] = useState<any>({});
  const [createNewRefundBody, setCreateNewRefundBody] =
    useState<CreateRefundBodyType>({
      refund_claims_no: "",
      patient_id: "",
      patient_name: "",
      date_attended: "",
      total_amount: 0,
      confirm_refund_status: false,
    });
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleCreateNewRefund = () => {};
  const handleUpdateRefund = () => {};
  const handleConfirmRefund = () => {};
  const handleFetchAllRefunds = () => {
    setIsLoading(true);
    refundApi
      .getAllRefund(sessionStorage.getItem("token") || "")
      .then((res) => {
        if (res?.status === 200) {
          setFetchedRefunds(res.info);
        } else {
          show("error", res?.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        show("error", "Error fetching claims");
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = fetchedRefunds.filter((data: any) => {
      return (
        data?.refund_claims_no.toLowerCase().includes(query.toLowerCase()) ||
        data?.patient_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.date_attended.toLowerCase().includes(query.toLowerCase()) ||
        data?.patient_id.toLowerCase().includes(query.toLowerCase()) ||
        data?.total_amount.toLowerCase().includes(query.toLowerCase()) ||
        data?.status.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedRefunds;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedRefunds]);

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* breadcrumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claims",
                  url: "/view-claims-new-refund",
                },
                {
                  title: "Refund Claims",
                  url: "/view-claims-new-refund",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="justify-end w-full flex mb-4 gap-4">
                      <StyledSearch
                        onChange={handleSearchChange}
                        value={searchQuery}
                        placeholder="🔍 by Patient Name, ID, Date Attended, Status"
                      />
                      <button
                        className="btn btn-primary bg-[#5F76E8] hover:bg-[#5F76E8] rounded-pill"
                        data-toggle="modal"
                        data-target="#add-new-refund-modal"
                      >
                        <i className="fa fa-plus mr-1 " />
                        Add Refund
                      </button>
                    </div>
                    <div className="table-responsive" id="myTable">
                      <MasterTableComponent
                        headers={[
                          "S/N",
                          "Refund Claim No.",
                          "Patient Name",
                          "Patient ID",
                          "Date Attended",
                          "Total Amount",
                          "Status",
                          "Actions",
                        ]}
                        tableRows={
                          tableRows && tableRows.length < 1
                            ? []
                            : tableRows.map((refund: any, index: number) => {
                                return {
                                  "S/N": index + 1,
                                  "Refund Claim No.": refund?.refund_claims_no,
                                  "Patient Name": refund?.patient_name,
                                  "Patient ID": refund?.patient_id,
                                  "Date Attended": refund?.date_attended,
                                  "Total Amount": formatMoney(
                                    refund?.total_amount || 0
                                  ),
                                  Status: refund?.status,
                                  Actions: (
                                    <>
                                      <a href="/view-claims-new-refund-details">
                                        <i className="fas fa-eye text-info cursor-pointer ml-2" />
                                      </a>
                                      <i
                                        className="fas fa-edit text-info cursor-pointer ml-2"
                                        data-toggle="modal"
                                        data-target="#edit-new-refund-modal"
                                        onClick={() =>
                                          setSelectedRefund(refund)
                                        }
                                      />
                                      <i
                                        className="fas fa-check text-info cursor-pointer ml-2"
                                        data-toggle="modal"
                                        data-target="#confirm-new-refund-modal"
                                        onClick={() =>
                                          setSelectedRefund(refund)
                                        }
                                      />
                                    </>
                                  ),
                                };
                              })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="vet-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Confirm Claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <h4 className="text-center mb-4">
                      You are Approving{" "}
                      <span className="text-primary">10/10</span> claims from{" "}
                      <span className="font-20 text-primary">
                        Francis Hospital
                      </span>{" "}
                      with{" "}
                      <span className="font-20 text-primary">
                        Batch No. 001{" "}
                      </span>{" "}
                      for payment
                    </h4>
                    <div className="buttons float-right mt-3 flex gap-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                      >
                        <i className="fas fa-check mr-2" />
                        Proceed
                      </button>
                      <button
                        className="btn btn-danger btn-sm closs flex items-center justify-center"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times mr-2" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/* edit claims */}

          <AddNewRefundModal />

          <ConfirmNewRefundModal />

          <EditNewRefundModal />

          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewClaimsNewRefund;
