import React from "react";

const Breadcrumb = () => {
  //@ desc - get the name of the user from the session storage and slice it to get the first name
  const name = sessionStorage.getItem("name");
  const firstName: any = name?.slice(0, name.indexOf(" "));

  return (
    <>
      <div className="page-breadcrumb h-[80px]">
        <div className="row">
          <div className="col-7 align-self-center">
            {firstName && (
              <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                Good Morning {firstName} !
              </h3>
            )}
            <div className="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-5 align-self-center">
            <div className="customize-input float-right">
              <select className="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius">
                <option selected>This Week</option>
                <option value={1}>This Month</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
