import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { GiMoneyStack } from "react-icons/gi";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { FaStethoscope } from "react-icons/fa";
import NewClaimEmailModal from "../../../components/new-claim/NewClaimEmailModal";
import NewClaimSMSModal from "../../../components/new-claim/NewClaimSMSModal";
import NewClaimPDFModal from "../../../components/new-claim/NewClaimPDFModal";
import PaidClaimSMSModal from "../../../components/paid-claims/PaidClaimSMSModal";
import PaidClaimEmailModal from "../../../components/paid-claims/PaidClaimEmailModal";
import PaidClaimPDFModal from "../../../components/paid-claims/PaidClaimPDFModal";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";

const ViewPaidBatchDetails = () => {
  const headers = ["Qty", "Claim Item", "Unit Cost", "Total Cost", ""];
  const navigate = useNavigate();
  const rows = [
    {
      qty: "1",
      claim_item: "Medication LTFYKGHJB, VJSHD FJHKDSKVJDSGLKSD GRD",
      unit_cost: "200.00",
      total_cost: "600.00",
    },
    {
      qty: "4",
      claim_item: "Consultation",
      unit_cost: "200.00",
      total_cost: "800.00",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Paid Claims",
                  url: "/paid-claims",
                },
                {
                  title: "Batch Claims",
                  url: "/view-paid-batch-claims",
                },
                {
                  title: "Claim Details",
                  url: "/view-paid-batch-details",
                },
              ]}
            />
            <div className="row">
              <div className="col-11 mx-auto">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-end gap-4 mb-2">
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#sms-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-comment mr-2" />
                        SMS
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#email-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-envelope mr-2" />
                        Email
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#pdf-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-file mr-2" />
                        PDF
                      </button>
                    </div>
                    <ul className="nav nav-pills bg-nav-pills nav-justified">
                      <li className="nav-item">
                        <a
                          href="#claimdetails"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded active"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Claim Details
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#benefits"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Benefits</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#admin-review"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Admin Review
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#medical-review"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Medical Review
                          </span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          href="#transaction-details"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Transaction Details
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="policy-details">
                        <div className="card-header text center">
                          <a>
                            <img
                              className="avatar mx-auto d-block rounded-circle mb-2"
                              style={{ width: "50px" }}
                              src="assets/images/img1.jpg"
                              alt="PAPA"
                            />
                          </a>
                          <div className="text-center">
                            <h3 className="font-bold text-2xl">
                              Francis Amoah
                            </h3>
                            <p className="text-xl">Male| 64yrs | Father </p>
                          </div>
                        </div>
                      </div>
                      {/* pane */}
                      <div
                        className="tab-pane process_claim active"
                        id="claimdetails"
                      >
                        <form>
                          <fieldset>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                            />
                            <a href="#">
                              <input
                                type="hidden"
                                name="id"
                                defaultValue={796}
                              />
                            </a>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="icon-user mr-1" />
                                    Patient ID
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="staff_id"
                                      name="staff_id"
                                      className="form-control"
                                      placeholder="Staff/Dependants ID"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-address-card mr-1" />
                                    Ghana Card
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="ghana_card"
                                      name="ghana_card"
                                      className="form-control"
                                      placeholder="Ghana Card No."
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-calendar mr-1" />
                                    Date Visited
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      disabled={true}
                                      type="date"
                                      id="date_visited"
                                      name="date_visited"
                                      className="form-control"
                                    />
                                    <input
                                      disabled={true}
                                      type="date"
                                      id="date_visited"
                                      name="date_visited"
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div className="bg-[#F7F7F7] p-4 card-title text-xl">
                            <h2>
                              Clinic & Diagnosis{"("}ICD 10{")"}
                            </h2>
                          </div>
                          <fieldset>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td className="font-bold">General Clinic</td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        IPD
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        OPD
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Maternity {"("}ANC {")"}
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Maternity {"("}PNC {")"}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Special Clinic</td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Dental
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Obs {"&"} Gyne
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        ENT
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Optical{"/"}Ophthalmology
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">
                                    Initial Diagnosis
                                  </td>
                                  <td>
                                    <div className="my-2 flex gap-2">
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        Cholera
                                      </span>
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        Maleria
                                      </span>
                                      <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                        HIV
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">
                                    Final Diagnosis
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      disabled={true}
                                      id="final_diagnosis"
                                      name="final_diagnosis"
                                      placeholder="final diagnosis"
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                      {/* pane */}
                      <div className="tab-pane process_claim" id="benefits">
                        <div className="card-shadow shadow-sm pb-2 ">
                          <div className="bg-[#F7F7F7] p-2 card-title text-xl mt-4">
                            <h2>Treatment & Service Costing</h2>
                          </div>
                          <div className="px-2">
                            <h3 className="text-xl mb-4">Consultation</h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-[#0054] cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />
                                      <span className="ml-2">
                                        comment goes here
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h3 className="text-xl mb-4 mt-6">
                              Investigations
                            </h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        gap: "4px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-danger cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />{" "}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h3 className="text-xl mb-4 mt-6">
                              Surgery and Procedures
                            </h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-danger cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h3 className="text-xl mb-4 mt-6">Medications</h3>
                            <Table
                              className="table table-sm table-striped table-bordered table-hover display"
                              id="default_order"
                              aria-label="simple table"
                              sx={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <StyledTableCell>{header}</StyledTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((row, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      backgroundColor: "#43e932",
                                    }}
                                  >
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.claim_item}</TableCell>
                                    <TableCell>{row.unit_cost}</TableCell>
                                    <TableCell>{row.total_cost}</TableCell>
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                      />
                                      <i
                                        className="fas fa-flag text-danger cursor-pointer ml-2"
                                        data-toggle="modal"
                                        onClick={() => {}}
                                        data-target="#flag-claim-modal"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>

                            <div className="flex gap-4 justify-end mb-2 pr-24">
                              {/* add a decline and approve button */}
                              <h3 className="btn flex items-center justify-center">
                                Proposed Claim Amount:
                              </h3>
                              <h3 className="btn  btn-warning  flex items-center justify-center">
                                12,892.00
                              </h3>
                            </div>
                            <div className="flex gap-4 justify-end mb-2 pr-24">
                              {/* add a decline and approve button */}
                              <h3 className="btn flex items-center justify-center">
                                Accepted Total Amount:
                              </h3>
                              <h3 className="btn  btn-primary  flex items-center justify-center">
                                12,892.00
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane process_claim"
                        id="transaction-details"
                      >
                        <div className="card-body">
                          <div className="mx-auto shadow-md w-[90%] p-2">
                            <div className="w-full bg-[#F7F7F7] flex flex-wrap p-4">
                              <div className="col-md-6 border-b my-2">
                                <h2 className="my-2">Amount Paid</h2>
                                <h1 className="mb-2 text-xl text-primary">
                                  GHS 429.00
                                </h1>
                              </div>
                              <div className="col-md-6 border-b my-2">
                                <h2 className="my-2">Mode of Payment</h2>
                                <h1 className="mb-2 text-xl text-primary">
                                  MTN
                                </h1>
                              </div>
                              <div className="col-md-6 border-b my-2">
                                <h2 className="my-2">Transaction Date</h2>
                                <h1 className="mb-2 text-xl text-primary">
                                  30-SEPT-2023 10:45:04 AM
                                </h1>
                              </div>
                              <div className="col-md-6 border-b my-2">
                                <h2 className="my-2">Transaction ID</h2>
                                <h1 className="mb-2 text-xl text-primary">
                                  10243837
                                </h1>
                              </div>
                              <div className="col-md-6 my-2">
                                <h2 className="my-2">Paid By</h2>
                                <h1 className="mb-2 text-xl text-primary">
                                  DOREN NIMMA
                                </h1>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 w-[90%] mx-auto">
                            <div className="">
                              <label htmlFor="">Payment Advise</label> <br />
                              {/* file is passed here */}
                              <div
                                data-toggle="modal"
                                data-target="#pdf-modal"
                                className="flex justify-center items-center py-4 bg-[#F7F7F7] hover:shadow-md cursor-pointer "
                              >
                                Filename.pdf
                              </div>
                            </div>
                            <div className="mt-4 w-[100%] mx-auto ">
                              <label htmlFor="">Comment</label> <br />
                              <div className="bg-[#DFE4FA] p-4 w-[100%] mt-4">
                                <div className="flex justify-content-between text-lg">
                                  <h5>
                                    <span className="font-bold">By: </span>
                                    Hanson Bansah
                                  </h5>
                                  <h5>
                                    <span className="font-bold">Sent on: </span>
                                    30-AUG-2023
                                  </h5>
                                </div>
                                <h4 className="mt-2">
                                  Dear EMMANUEL ANDREWS, kindly send a copy of
                                  the Medical Cause of death to aid in your
                                  claim processing. Send documents to
                                  osa@valifeapps.com
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane process_claim" id="admin-review">
                        <div className="card-body">
                          <fieldset>
                            <div className="my-4">
                              <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                                Review
                              </p>
                              <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                                <div className="flex justify-content-between text-lg">
                                  <h5>
                                    <span className="font-bold">By: </span>
                                    Hanson Bansah
                                  </h5>
                                  <h5>
                                    <span className="font-bold">Sent on: </span>
                                    30-AUG-2023 09:42:00AM
                                  </h5>
                                </div>
                                <h4 className="mt-2">
                                  Dear EMMANUEL ANDREWS, kindly send a copy of
                                  the Medical Cause of death to aid in your
                                  claim processing. Send documents to
                                  osa@valifeapps.com
                                </h4>
                              </div>
                            </div>
                            <div className="my-4">
                              <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                                Email
                              </p>
                              <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                                <div className="flex justify-content-between text-lg">
                                  <div>
                                    <h5>
                                      <span className="font-bold">By: </span>
                                      Hanson Bansah
                                    </h5>
                                    <h5 className="my-2">
                                      <span className="font-bold">
                                        Recipient:{" "}
                                      </span>
                                      Hanson Bansah
                                    </h5>
                                  </div>
                                  <h5>
                                    <span className="font-bold">Sent on: </span>
                                    30-AUG-2023 09:42:00AM
                                  </h5>
                                </div>

                                <h5 className="my-4">
                                  <span className="font-bold ">Subject: </span>
                                  This email was sent
                                </h5>
                                <h4 className="mt-2">
                                  Dear EMMANUEL ANDREWS, kindly send a copy of
                                  the Medical Cause of death to aid in your
                                  claim processing. Send documents to
                                  osa@valifeapps.com
                                </h4>
                              </div>
                            </div>
                            <div className="my-4">
                              <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                                SMS
                              </p>
                              <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                                <div className="flex justify-content-between text-lg">
                                  <h5>
                                    <span className="font-bold">By: </span>
                                    Hanson Bansah
                                  </h5>
                                  <h5>
                                    <span className="font-bold">Sent on: </span>
                                    30-AUG-2023 09:42:00AM
                                  </h5>
                                </div>
                                <h4 className="mt-2">
                                  Dear EMMANUEL ANDREWS, kindly send a copy of
                                  the Medical Cause of death to aid in your
                                  claim processing. Send documents to
                                  osa@valifeapps.com
                                </h4>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div
                        className="tab-pane process_claim"
                        id="medical-review"
                      >
                        <div className="card-body">
                          <fieldset>
                            <h3 className="font-bold ml-2 text-xl w-[90%] mx-auto">
                              Status |{" "}
                              <span className="badge badge-success rounded-pill text-lg">
                                Passed
                              </span>
                            </h3>

                            <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                              Diagnosis vs Medication mismatch
                            </p>
                            <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                              <div className="flex justify-content-between text-lg">
                                <h5>
                                  <span className="font-bold">By: </span>
                                  Hanson Bansah
                                </h5>
                                <h5>
                                  <span className="font-bold">Sent on: </span>
                                  30-AUG-2023
                                </h5>
                              </div>
                              <h4 className="mt-2">
                                Dear EMMANUEL ANDREWS, kindly send a copy of the
                                Medical Cause of death to aid in your claim
                                processing. Send documents to osa@valifeapps.com
                              </h4>
                            </div>
                            <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                              Diagnosis vs Investigation mismatch
                            </p>
                            <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                              <div className="flex justify-content-between text-lg">
                                <h5>
                                  <span className="font-bold">By: </span>
                                  Hanson Bansah
                                </h5>
                                <h5>
                                  <span className="font-bold">Sent on: </span>
                                  Diagnosis Vs Medication-age mismatch
                                </h5>
                              </div>
                              <h4 className="mt-2">
                                Dear EMMANUEL ANDREWS, kindly send a copy of the
                                Medical Cause of death to aid in your claim
                                processing. Send documents to osa@valifeapps.com
                              </h4>
                            </div>
                            <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                              No Relatd Diagnosis
                            </p>

                            <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                              <div className="flex justify-content-between text-lg">
                                <h5>
                                  <span className="font-bold">By: </span>
                                  Hanson Bansah
                                </h5>
                                <h5>
                                  <span className="font-bold">Sent on: </span>
                                  30-AUG-2023
                                </h5>
                              </div>
                              <h4 className="mt-2">
                                Dear EMMANUEL ANDREWS, kindly send a copy of the
                                Medical Cause of death to aid in your claim
                                processing. Send documents to osa@valifeapps.com
                              </h4>
                            </div>
                            <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                              Others
                            </p>
                            <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                              <div className="flex justify-content-between text-lg">
                                <h5>
                                  <span className="font-bold">By: </span>
                                  Hanson Bansah
                                </h5>
                                <h5>
                                  <span className="font-bold">Sent on: </span>
                                  30-AUG-2023
                                </h5>
                              </div>
                              <h4 className="mt-2">
                                Dear EMMANUEL ANDREWS, kindly send a copy of the
                                Medical Cause of death to aid in your claim
                                processing. Send documents to osa@valifeapps.com
                              </h4>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  Modal for Flagging Claims */}
          <div
            className="modal fade"
            id="flag-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    Flag claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <form>
                      <div className="form-body">
                        <div className="col-12 mb-3">
                          <label>
                            You are flagging receipt of claims from{" "}
                            <span className="text-[#5F76E8]">
                              Francis Hospital
                            </span>{" "}
                            with Batch No.{" "}
                            <span className="text-[#5F76E8]">001</span>
                          </label>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-question mr-1" />
                              Please select a reason for flagging.
                            </label>
                            <select
                              className="form-control"
                              // placeholder=" select one"
                            >
                              <option value="">Select a comment</option>
                              <option
                                value={"diagnosis vs medication mismatch"}
                              >
                                diagnosis vs medication mismatch
                              </option>
                              <option
                                value={"diagnosis vs investigation mismatch"}
                              >
                                diagnosis vs investigation mismatch
                              </option>
                              <option
                                value={" diagnosis vs medication-age mismatch"}
                              >
                                diagnosis vs medication-age mismatch
                              </option>
                              <option value={"no related diagnosis"}>
                                no related diagnosis
                              </option>
                              <option value={"Others"}>Others</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        >
                          <i className="fas fa-check mr-2" />
                          Proceed
                        </button>
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/*  SMS Modal */}
          <PaidClaimSMSModal />

          {/* Email Modal */}
          <PaidClaimEmailModal />

          {/* Email Modal */}
          <PaidClaimPDFModal />

          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </TableContainer>
  );
};

export default ViewPaidBatchDetails;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
