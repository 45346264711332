import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import StaffCallRecords from "../../components/crm/StaffCallRecords";
import AuthorizationTable from "../../components/tables/AuthorizationTable";
import { Toast } from "primereact/toast";
import { CreateNewAuthorizationBodyType } from "../../types/data";
import { StyledSearch } from "../../components/StyledSearch";
import authorizationApi from "../../api/authorizationApi";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import formatMoney from "../../utils/formatMoney";

const Authorization = () => {
  const toast = useRef<Toast>(null);
  const [hspName, setHspName] = useState<string>("");
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedAuthorization, setSelectedAuthorization] = useState<any>({});
  const [fetchIsLoading, setFetchIsLoading] = useState<boolean>(false);
  const [fetchedAuthorizations, setFetchedAuthorizations] = useState<any[]>([]);
  const [isCreatingNewAuthorization, setIsCreatingNewAuthorization] =
    useState<boolean>(false);
  const [createNewAuthorizationBody, setCreateNewAuthorizationBody] =
    useState<CreateNewAuthorizationBodyType>({
      approval_status: false,
      authorized_amount: 0,
      batch_number: "",
      claim_no: "",
      consultation_date: "",
      requested_amount: 0,
      service_name: "",
      staff_id: "",
      staff_name: "",
    });
  const [currentStep, setCurrentStep] = useState(1);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handlePrevious = () => {
    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep + 1);
  };

  const handleFetchAllAuthorization = () => {
    setFetchIsLoading(true);
    authorizationApi
      .getAllAuthorization(sessionStorage.getItem("token") || "")
      .then((res) => {
        if (res?.status === 200) {
          setFetchedAuthorizations(res.info);
        }
        setFetchIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchIsLoading(false);
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = fetchedAuthorizations.filter((data: any) => {
      return (
        data?.staff_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.staff_id.toLowerCase().includes(query.toLowerCase()) ||
        data?.batch_number.toLowerCase().includes(query.toLowerCase()) ||
        data?.claim_no.toLowerCase().includes(query.toLowerCase()) ||
        data?.service_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.consultation_date.toLowerCase().includes(query.toLowerCase()) ||
        data?.requested_amount.toLowerCase().includes(query.toLowerCase()) ||
        data?.authorized_amount.toLowerCase().includes(query.toLowerCase()) ||
        data?.approval_status.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedAuthorizations;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedAuthorizations]);


  useEffect(() => {
    handleFetchAllAuthorization();
  }
  , []);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <h3 className="card-title mb-3">
                            Authorization List
                          </h3>
                        </div>
                        <div className="col-sm-6 col-md-6 text-right mb-3">
                          {/* <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8]"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-plus" /> Add Authorization
                          </button> */}
                          <StyledSearch
                            onChange={handleSearchChange}
                            value={searchQuery}
                            placeholder="🔍 by staff name, staff id, batch number, claim number, service name, consultation date, requested amount, authorized amount, approval status"
                          />
                        </div>
                      </div>

                      {/* Modal for Approval */}
                      <div
                        className="modal fade"
                        id="accept-authorization-modal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-md">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4
                                className="modal-title"
                                id="myLargeModalLabel"
                              >
                                {" "}
                                Approve Authorization
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                              >
                                ×
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <form id="signUpForm">
                                        <div className="form-body">
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <label>
                                                    <i className="fas fa-address-book" />{" "}
                                                    Reason
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="add reason"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* start previous / next buttons */}
                                          <div className="form-footer d-flex justify-end">
                                            <div>
                                              <button
                                                type="button"
                                                className="text-end text-white"
                                                id="nextBtn"
                                                onClick={handleNext}
                                              >
                                                Save
                                                <span>
                                                  <i className="fa fa-save ml-2" />
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                          {/* end previous / next buttons */}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                      </div>
                      {/* /.modal */}

                      {/* Modal for Reject */}
                      <div
                        className="modal fade"
                        id="reject-authorization-modal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-md">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4
                                className="modal-title"
                                id="myLargeModalLabel"
                              >
                                {" "}
                                Reject Authorization
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                              >
                                ×
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <form id="signUpForm">
                                        <div className="form-body">
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <label>
                                                    <i className="fas fa-address-book" />{" "}
                                                    Reason
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="add reason"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* start accept | deny */}
                                          <div className="form-footer d-flex justify-end">
                                            {/* <div>
                                              <button
                                                type="button"
                                                className="text-end text-white bg-red-500"
                                                id="nextBtn"
                                                onClick={()=>{

                                                }}
                                              >
                                                Cancel
                                                <span>
                                                  <i className="fa fa-circle-xmark ml-2" />
                                                </span>
                                              </button>
                                            </div> */}
                                            <div>
                                              <button
                                                type="button"
                                                className="text-end text-white"
                                                id="nextBtn"
                                                onClick={handleNext}
                                              >
                                                Save
                                                <span>
                                                  <i className="fa fa-save ml-2" />
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                          {/* end accept | deny */}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}

                      {/* Modal for Edit */}
                      <div
                        className="modal fade"
                        id="edit-authorization-modal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-md">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4
                                className="modal-title"
                                id="myLargeModalLabel"
                              >
                                {" "}
                                Edit Authorization
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                              >
                                ×
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <form id="signUpForm">
                                        <div className="form-body">
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <label>
                                                    <i className="fas fa-calculator" />{" "}
                                                    Authorized Amount
                                                  </label>
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="0.00"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <label>
                                                    <i className="fas fa-address-book" />{" "}
                                                    Reason
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="add reason"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* start accept | deny */}
                                          <div className="form-footer d-flex justify-end">
                                            {/* <div>
                                              <button
                                                type="button"
                                                className="text-end text-white bg-red-500"
                                                id="nextBtn"
                                                onClick={()=>{

                                                }}
                                              >
                                                Cancel
                                                <span>
                                                  <i className="fa fa-circle-xmark ml-2" />
                                                </span>
                                              </button>
                                            </div> */}
                                            <div>
                                              <button
                                                type="button"
                                                className="text-end text-white"
                                                id="nextBtn"
                                                onClick={handleNext}
                                              >
                                                Save
                                                <span>
                                                  <i className="fa fa-save ml-2" />
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                          {/* end accept | deny */}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}

                      {/* table */}
                      <MasterTableComponent
                        headers={[
                          "S/N",
                          "Staff Name/ID",
                          "Service",
                          "Consultation Date",
                          "Claim No.",
                          "Requested Amount",
                          "Authorized Amount",
                          "Actions",
                        ]}
                        emptyTableMessage="No authorization found"
                        tableRows={
                          tableRows && tableRows.length < 1
                            ? []
                            : tableRows.map(
                                (authorization: any, idx: number) => {
                                  return {
                                    "S/N": idx + 1,
                                    "Staff Name/ID": (
                                      <div>
                                        <p>{authorization.staff_name}</p>
                                        <span>{authorization.staff_id}</span>
                                      </div>
                                    ),
                                    Service: authorization.service_name,
                                    "Consultation Date":
                                      authorization.consultation_date,
                                    "Claim No.": authorization.claim_no,
                                    "Requested Amount": formatMoney(
                                      authorization.requested_amount || 0
                                    ),
                                    "Authorized Amount": formatMoney(
                                      authorization.authorized_amount || 0
                                    ),
                                    Actions: (
                                      <div className="flex gap-2">
                                        <i
                                          className="fas fa-check text-success delplan mr-1"
                                          data-toggle="modal"
                                          data-target="#accept-authorization-modal"
                                        />
                                        <i
                                          className="fas fa-ban text-danger mr-1"
                                          data-toggle="modal"
                                          data-target="#reject-authorization-modal"
                                        />
                                        <i
                                          className="fas fa-edit text-warning delplan"
                                          data-toggle="modal"
                                          data-target="#edit-authorization-modal"
                                        />
                                      </div>
                                    ),
                                  };
                                }
                              )
                        }
                      />
                      {/* /. table */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Authorization;
