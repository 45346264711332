const formatMoney = (amount: number | string): string => {
  const formatter = new Intl.NumberFormat("en-GH", {
    style: "currency",
    currency: "GHS",
  });

  let formatedMoney = formatter.format(Number(amount));
  return formatedMoney.replace("GH₵", "");
};

export default formatMoney;
