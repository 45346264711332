import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import VettedClaimsTable from "../../../components/tables/VettedClaimsTable";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import MasterTableComponent from "../../../components/ui/MasterTableComponent";
import { StyledSearch } from "../../../components/StyledSearch";
import ClaimsApi from "../../../api/ClaimsApi";
import { Toast } from "primereact/toast";
import closeAnyModal from "../../../utils/closeAnyModal";

const VettedClaims = () => {
  const toast = useRef<Toast>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [fetchedVettedClaims, setFetchedVettedClaims] = useState<any[]>([]);
  const [filteredServiceData, setFilteredServiceData] = useState<any[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<any>({});
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = fetchedVettedClaims?.filter((data: any) => {
      return (
        data?.hsp_name?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.batch_number?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.total_amount?.toLowerCase()?.includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  useEffect(() => {
    const dataToRender = searchQuery
      ? filteredServiceData
      : fetchedVettedClaims;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedVettedClaims]);

  const handleApproveClaims = () => {
    setIsApproving(true);
    ClaimsApi.confirmClaimReceipt(
      sessionStorage.getItem("token") || "",
      selectedClaim?.claim_id
    )
      .then((res) => {
        setIsApproving(false);
        if (res?.status === 200) {
          show("success", res?.message);
          handleFetchAllVettedClaims();
          closeAnyModal("vet-claim-modal");
        } else {
          show("error", res?.message);
          setIsApproving(false);
        }
      })
      .catch((error) => {
        show("error", "Failed to approve claim");
        setIsApproving(false);
      });
  };

  const handleFetchAllVettedClaims = () => {
    setIsFetching(true);
    ClaimsApi.getAllVettedClaims(sessionStorage.getItem("token") || "")
      .then((res) => {
        setIsFetching(false);
        if (res.status === 200) {
          setFetchedVettedClaims(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        show("error", err.message);
      });
  };

  useEffect(() => {
    handleFetchAllVettedClaims();
  }, []);
  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* bread crumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claims",
                  url: "/vetted-claims",
                },
                {
                  title: "Vetted Claims",
                  url: "/vetted-claims",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="flex justify-between items-center col-md-12 mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Vetted Claims
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by HSP Name, batch number"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "HSP Name",
                          "Batch No.",
                          "No.Claims",
                          "Progress",
                          "Total Amount",
                          "Actions",
                        ]}
                        loading={isFetching}
                        emptyTableMessage="No Vetted Claims available"
                        tableRows={
                          tableRows && tableRows.length > 0
                            ? tableRows?.map(
                                (vettedClaims: any, idx: number) => {
                                  return {
                                    SN: idx + 1,
                                    "HSP Name": vettedClaims?.hsp_name,
                                    "No. Claims": vettedClaims?.batch_number,
                                    "Batch Number": vettedClaims?.batch_number,
                                    Progress: vettedClaims?.progress,
                                    "Total Amount": vettedClaims?.total_amount,
                                    Actions: (
                                      <div className="flex items-center gap-2">
                                        <i
                                          className="fas fa-tasks text-success delplan mr-2 cursor-pointer"
                                          data-toggle="modal"
                                          data-target="#vet-claim-modal"
                                          onClick={() => {
                                            setSelectedClaim(vettedClaims);
                                          }}
                                        />
                                        <a href="/vetted-claims-batch-view">
                                          <i className="fas fa-eye text-info cursor-pointer" />
                                        </a>
                                      </div>
                                    ),
                                  };
                                }
                              )
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="vet-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Claim Approval
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <h4 className="text-center mb-4">
                      You are Approving{" "}
                      <span className="text-primary">10/10</span> claims from{" "}
                      <span className="font-20 text-primary">
                        {selectedClaim?.hsp_name}
                      </span>{" "}
                      with{" "}
                      <span className="font-20 text-primary">
                        Batch No: {selectedClaim?.batch_number}
                      </span>{" "}
                      for payment
                    </h4>
                    <div className="buttons float-right mt-3 flex gap-4">
                      <button
                        disabled={isApproving}
                        type="submit"
                        className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        onClick={handleApproveClaims}
                      >
                        <i className="fas fa-check mr-2" />
                        {isApproving ? "Approving..." : "Approve"}
                      </button>
                      <button
                        className="btn btn-danger btn-sm closs flex items-center justify-center"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times mr-2" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default VettedClaims;
