import { MedicationType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";
import { StyledSearch } from "../../components/StyledSearch";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import medicationApi from "../../api/medicationApi";
import formatDate from "../../utils/formatDate";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import BulkUploadModal from "../../components/BulkUploadModal";
import closeAnyModal from "../../utils/closeAnyModal";

const Medication = () => {
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);

  const [category, setCategory] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [categoryData, setCategoryData] = useState<any>([]);
  const [selectedMedication, setSelectedMedication] = useState<any>({});
  const [medication_name, setMedicationName] = useState<string>("");
  const [medication_code, setMedicationCode] = useState<string>("");
  const [medication_category, setMedicationCategory] = useState<string>("");
  const [medicationData, setMedicationData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMedicationData, setFilteredMedicationData] = useState([]);
  const [tableRows, setTableRows] = useState<MedicationType[]>([]);
  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any>({});
  const [selectedCategoryDateAndStatus, setSelectedCategoryDateAndStatus] =
    useState({
      date: "",
      status: "",
    });
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const handleCategory = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/category/`,
        {
          medication_category: category,
          medication_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        // alert(res.data.message);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setCategory("");
          setInitials("");
          handleFetchCategory();
          closeAnyModal("bs-example-modal-lg");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select a file to upload",
        life: 3000,
      });
      return;
    }
    setBulkUploading(true);
    const formData = new FormData();
    formData.append("excel_file", bulkUploadBody as File);
    medicationApi
      .bulkMedicationUpload(sessionStorage.getItem("token") as string, formData)
      .then((res) => {
        setBulkUploading(false);
        if (res?.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.message,
            life: 3000,
          });
          handleFetchMedication();
          closeAnyModal("medicationBulkUploadModal");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        setBulkUploading(false);
        console.log(err);
      });
  };

  const handleMedication = (e: any) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/`,
        {
          medication_name: medication_name,
          medication_category: medication_category,
          medication_code: medication_code,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setMedicationName("");
          setMedicationCategory("");
          handleFetchMedication();
          closeAnyModal("bs-example-modal-lg");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleFetchCategory = async () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/category/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleFetchMedication = async () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup-services/medication/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        if (res.data.status === 200) {
          setMedicationData(res.data.info);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDeactivateMedication = async (id: string) => {
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    const response = await medicationApi.deactivateMedication(tk, id);
    if (response.status === 200) {
      toast.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: response.message,
        life: 3000,
      });
      await handleFetchMedication();
      closeAnyModal("deleteMedicationModal");
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: response.message,
        life: 3000,
      });
    }
  };
  const handleActivateMedication = async (id: string) => {
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    console.log(tk);
    const response = await medicationApi.activateMedication(tk, id);
    if (response.status === 200) {
      toast.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: response.message,
        life: 3000,
      });
      await handleFetchMedication();
      closeAnyModal("deleteMedicationModal");
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: response.message,
        life: 3000,
      });
    }
  };

  const FilterCategoryFromCategories = (categoryId: string) => {
    let temp = categoryData.filter(
      (category: any) => category.id != categoryId
    );
    setCategoryData(temp);
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/category/delete`,
        {
          medication_category_id: categoryToDelete.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          FilterCategoryFromCategories(categoryToDelete.id);
          setIsDeleteCategory(false);
          setCategoryToDelete({});
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };
  const handleUpdateMedicationCategory = (categoryId: string) => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/category/edit`,
        {
          medication_category_id: categoryId,
          medication_category: category,
          medication_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setIsEditable(false);
          setCategory("");
          setInitials("");
          handleFetchCategory();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  const handleDeleteMedication = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/delete`,
        {
          medication_id: selectedMedication.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          // handleFetchMedication();
          let temp = medicationData.filter(
            (medication: any) => medication.id != selectedMedication.id
          );
          setMedicationData(temp);
          let tempData = tableRows.filter(
            (medication: any) => medication.id != selectedMedication.id
          );
          setTableRows(tempData);
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });

          // close the modal
          let modal = document.getElementById("deleteMedicationModal");
          modal?.classList.remove("show");
          // remove the backdrop
          let backdrop = document.getElementsByClassName("modal-backdrop")[0];
          backdrop?.remove();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = medicationData.filter((medication: any) => {
      return (
        medication?.medication_name
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        medication?.medication_code
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        medication?.medication_category
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    });

    setFilteredMedicationData(filteredData);
  };

  useEffect(() => {
    handleFetchCategory();

    handleFetchMedication();
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredMedicationData : medicationData;
    setTableRows(dataToRender);
  }, [searchQuery, medicationData]);
  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Services",
                  url: "/medication",
                },
                {
                  title: "Medication",
                  url: "/medication",
                },
              ]}
            />
            <div className="row mt-3">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="col-md-12 flex items-center justify-between mb-2">
                        <h3 className="page-title text-truncate text-dark font-weight-medium">
                          Medication Setup
                        </h3>
                        <div className="flex jutify-end items-center gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by Name, Category, Code"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill mx-1"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-tasks" /> Add Medication
                          </button>
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example"
                          >
                            <i className="fas fa-tasks" /> Set Category
                          </button>
                          <button
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#medicationBulkUploadModal"
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Code",
                          "Name",
                          "Category",
                          "Date Added",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          tableRows &&
                          tableRows.map(
                            (medication: MedicationType, index: number) => ({
                              SN: index + 1,
                              Code: medication.medication_code,
                              Name: medication.medication_name,
                              Category: medication.medication_category,
                              "Date Added": Number(medication.created_at)
                                ? convertTimestampToDate(
                                    Number(medication.created_at)
                                  )
                                : formatDate(medication.created_at),
                              Status: medication.status,
                              Actions: (
                                <div className="flex">
                                  <a
                                    className="mx-1"
                                    href={`/view-medication?medication_id=${medication.id}`}
                                  >
                                    <i className="fas fa-eye text-primary delplan"></i>
                                  </a>

                                  <a
                                    className="mx-1"
                                    href={`/view-medication?medication_id=${medication.id}`}
                                  >
                                    <i className="fas fa-edit text-primary" />
                                  </a>
                                  {medication.status != "active" ? (
                                    <span
                                      onClick={() => {
                                        handleActivateMedication(medication.id);
                                      }}
                                    >
                                      <i className="fas fa-power-off text-primary delplan"></i>
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        handleDeactivateMedication(
                                          medication.id
                                        );
                                      }}
                                    >
                                      <i className="fas fa-times text-primary delplan"></i>
                                    </span>
                                  )}

                                  <button
                                    type="button"
                                    data-target="#deleteMedicationModal"
                                    data-toggle="modal"
                                    className="mx-1"
                                    onClick={() => {
                                      setSelectedMedication(medication);
                                      console.log(medication);
                                    }}
                                  >
                                    <i className="fas fa-trash text-primary delplan" />
                                  </button>
                                </div>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="bs-example"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Set Category
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleCategory}>
                            <div className="form-body">
                              {/* step one */}
                              {!isDeleteCategory && (
                                <>
                                  <div className="step">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="category">
                                            Category
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="category"
                                            placeholder="Hepatitis B"
                                            onChange={(e) =>
                                              setCategory(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="initials">
                                            Initials{" "}
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="initials"
                                            placeholder="HBB"
                                            onChange={(e) =>
                                              setInitials(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm float-right mb-4 bg-[#5F76E8] rounded-pill"
                                    >
                                      <i className="fas fa-paper-plane" />
                                      {
                                        <span className="mx-2">
                                          {loading ? "loading..." : "Save"}
                                        </span>
                                      }
                                    </button>
                                  </div>
                                </>
                              )}
                              {isDeleteCategory && (
                                <div className="border border-danger mb-5 p-2">
                                  <p className="text-[16px] font-normal my-2">
                                    Confirm Deletion of <br />
                                    <span className="text-danger">
                                      {categoryToDelete.medication_category}
                                    </span>
                                    <br />
                                    This action cannot be undone.
                                  </p>
                                  <div className="flex justify-end gap-4">
                                    <div
                                      onClick={() => {
                                        setIsDeleteCategory(false);
                                        setCategoryToDelete({});
                                      }}
                                      className="btn btn-danger rounded-pill bg-danger"
                                    >
                                      <i className="fa fa-times mx-1"></i>
                                      Cancel
                                    </div>
                                    <div
                                      className="btn btn-danger rounded-pill bg-danger"
                                      onClick={handleDelete}
                                    >
                                      <i className="fa fa-trash mx-1"></i>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="table-responsive">
                                <div className="col-md-12"></div>
                                <table
                                  id="default_order"
                                  className="table datatable table-striped table-hover table-sm"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Intials</th>
                                      <th>Category</th>
                                      <th>Date Added</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  {categoryData && (
                                    <tbody>
                                      {isEditable && (
                                        <tr className="border-2 border-primary">
                                          <td>
                                            <input
                                              defaultValue={initials}
                                              onChange={(e) => {
                                                setInitials(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              defaultValue={category}
                                              onChange={(e) => {
                                                setCategory(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            {Number(
                                              selectedCategoryDateAndStatus.date
                                            )
                                              ? convertTimestampToDate(
                                                  Number(
                                                    selectedCategoryDateAndStatus.date
                                                  )
                                                )
                                              : formatDate(
                                                  selectedCategoryDateAndStatus.date
                                                )}
                                          </td>
                                          <td>
                                            {
                                              selectedCategoryDateAndStatus.status
                                            }
                                          </td>
                                          <td className="align-center">
                                            <span>
                                              {!isLoading ? (
                                                <>
                                                  <i
                                                    onClick={() => {
                                                      handleUpdateMedicationCategory(
                                                        selectedCategoryId
                                                      );
                                                    }}
                                                    className="fa fa-save text-primary mx-1"
                                                  ></i>
                                                  <i
                                                    onClick={() => {
                                                      setIsEditable(false);
                                                    }}
                                                    className="fa fa-times text-warning"
                                                  ></i>
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fa fa-spinner fa-spin text-primary"></i>
                                                </>
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      )}
                                      {categoryData.map(
                                        (
                                          category: MedicationType,
                                          index: number
                                        ) => (
                                          <tr
                                            key={category.id}
                                            className="clickable-row"
                                          >
                                            <td>
                                              {category.medication_initials}
                                            </td>
                                            <td>
                                              {" "}
                                              {category.medication_category}
                                            </td>
                                            <td>
                                              {Number(category.created_at)
                                                ? convertTimestampToDate(
                                                    Number(category.created_at)
                                                  )
                                                : formatDate(
                                                    category.created_at
                                                  )}
                                            </td>
                                            <td>{category.status}</td>
                                            <td>
                                              <i
                                                className="fas fa-edit text-primary"
                                                onClick={() => {
                                                  setInitials(
                                                    category.medication_initials ||
                                                      ""
                                                  );
                                                  setCategory(
                                                    category.medication_category
                                                  );
                                                  setSelectedCategoryId(
                                                    category.id
                                                  );
                                                  setIsEditable(true);
                                                  setSelectedCategoryDateAndStatus(
                                                    {
                                                      date: category.created_at,
                                                      status: category.status,
                                                    }
                                                  );
                                                }}
                                              />

                                              <button
                                                type="button"
                                                className="mx-1"
                                              >
                                                <i
                                                  onClick={() => {
                                                    setIsDeleteCategory(true);
                                                    setCategoryToDelete(
                                                      category
                                                    );
                                                  }}
                                                  className="fas fa-trash text-primary delplan"
                                                />
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="bs-example-modal-lg"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Add Medication
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleMedication}>
                            <div className="form-body">
                              {/* step one */}
                              <div className="step">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="plan_type">
                                        Category
                                      </label>
                                      <br />
                                      <select
                                        className="form-control plantype"
                                        name="plan_type"
                                        id="plan_type"
                                        onChange={(e) =>
                                          setMedicationCategory(e.target.value)
                                        }
                                        // placeholder="Select Type"
                                      >
                                        <option disabled selected>
                                          Select
                                        </option>
                                        {categoryData.map(
                                          (category_data: any) => (
                                            <option
                                              key={category_data.id}
                                              value={
                                                category_data.medication_initials
                                              }
                                              className="generic"
                                            >
                                              {
                                                category_data.medication_category
                                              }
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="medication_name">
                                        Name
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="medication_name"
                                        placeholder="Generic"
                                        onChange={(e) =>
                                          setMedicationName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="medication_code">
                                        Code
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="medication_code"
                                        placeholder="HBA01"
                                        value={medication_code}
                                        onChange={(e) =>
                                          setMedicationCode(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="submit-btn">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm float-right bg-[#5F76E8]"
                                >
                                  <i className="fas fa-paper-plane" />{" "}
                                  {
                                    <span className="mx-2">
                                      {loading ? "loading..." : "Save"}
                                    </span>
                                  }
                                </button>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          {/*  Modal for Delete Medication */}
          <div
            className="modal fade"
            id="deleteMedicationModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Delete Medication
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          {/* Once you deactivate this medication, it cannot be
                          recovered. Are you sure you want to deactivate this
                          medication with name{" "}
                          <span className="text-red-500">
                            {selectedMedication?.medication_name}
                          </span>
                          ? */}
                          <p className="text-center text-sm">
                            Are you sure you want to delete. <br />
                            Deleting radiology medication:{" "}
                            <span className="text-red-500">
                              {selectedMedication?.medication_name} <br />
                              cannot be reversed.
                            </span>
                          </p>
                          <div className="flex justify-end gap-4 mt-4">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                            >
                              <i className="fas fa-times" />{" "}
                              {<span className="mx-2">Cancel</span>}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                              onClick={handleDeleteMedication}
                            >
                              <i className="fas fa-trash" />{" "}
                              {<span className="mx-2">Delete </span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          {/* -------------- BULK UPLOAD MODAL */}
          <BulkUploadModal
            title="Medication"
            selector="medicationBulkUploadModal"
            setBulkUploadBody={setBulkUploadBody}
            handleBulkUpload={handleBulkUpload}
            loading={bulkUploading}
          />

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Medication;
