import React from "react";

function PassPaidClaimsModal() {
  return (
    <div
      className="modal fade"
      id="pass-claim-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Confirm Claim receipt
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <h4 className="text-center mb-4">
                You are confirming receipt of claims from{" "}
                <span className="font-20 text-primary">Francis Hospital</span>{" "}
                with <span className="font-20 text-primary">Batch No. 001</span>{" "}
              </h4>
              <div className="row">
                <div className="col-12">
                  <div className="buttons float-right mt-3 flex gap-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                    >
                      <i className="fas fa-check mr-2" />
                      Proceed
                    </button>
                    <button
                      className="btn btn-danger btn-sm closs flex items-center justify-center"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-times mr-2" />
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default PassPaidClaimsModal;
