import React from "react";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { StyledSearch } from "../StyledSearch";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function FinancialsPaidClaimsTable() {
  const navigate = useNavigate();

  const headers = [
    "SN",
    "HSP Name",
    "Batch No.",
    "Claim No.",
    "Patient Name/ID",
    "Total Amount",
    "Actions",
  ];
  const rows = [
    {
      sn: "1",
      hsp_name: "Francis Hospital",
      batch_number: "004",
      claim_number: 43,
      patient_name: "Kofi",
      patient_id: "434",
      // number_of_claims: 10,
      total_amount: "2050.00",
    },
    {
      sn: "1",
      hsp_name: "Francis Hospital",
      batch_number: "004",
      claim_number: 43,
      patient_name: "Kofi",
      patient_id: "434",
      // number_of_claims: 10,
      total_amount: "2050.00",
    },
  ];
  return (
    <>
      <TableContainer component={Paper}>
        <div className="justify-end w-full flex mb-4">
          <StyledSearch />
        </div>
        <Table
          className="table table-sm table-striped table-bordered table-hover display"
          id="default_order"
          aria-label="simple table"
          sx={{ width: "100%" }}
        >
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((staff) => (
              <TableRow key={staff.sn}>
                <TableCell>{staff.sn}</TableCell>
                <TableCell>{staff.hsp_name}</TableCell>
                <TableCell>{staff.batch_number}</TableCell>
                <TableCell>{staff.claim_number}</TableCell>
                <TableCell>
                  <div>
                    <span className="font-bold">
                      {staff.patient_name}
                      <br />
                      {staff.patient_id}
                    </span>
                  </div>
                </TableCell>
                <TableCell>{staff.total_amount}</TableCell>
                <TableCell>
                  <a href="/fn-paid-claims-details">
                    <i className="fas fa-eye text-primary mr-2" />
                  </a>
                  <i
                    className="fas fa-file text-primary mr-2 cursor-pointer"
                    data-toggle="modal"
                    data-target="#pdf-modal"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex justify-end">
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={5}
            page={0}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default FinancialsPaidClaimsTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5F76E8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
