import React, { useEffect, useState } from "react";

interface MasterTableComponentProps {
  tableStyles?: string;
  headers?: string[];
  headerStyles?: string;
  tableRows?: { [key: string]: any }[];
  tableRowsStyles?: string;
  emptyTableMessage?: string;
  loading?: boolean;
}

type TableRowsType = {
  [key: string]: any;
};
function MasterTableComponent({
  tableStyles,
  headers,
  headerStyles,
  tableRows,
  tableRowsStyles,
  emptyTableMessage,
  loading = false,
}: MasterTableComponentProps) {
  const [currentTableRows, setCurrentTableRows] = useState<TableRowsType[]>([]);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 10,
    currentPage: 1,
    totalNumberOfPages: 1,
    totalNumberOfRows: tableRows ? tableRows.length : 0,
  });

  useEffect(() => {
    if (tableRows) {
      const start = (tableProps.currentPage - 1) * tableProps.rowsPerPage;
      const end = start + tableProps.rowsPerPage;
      setCurrentTableRows(tableRows.slice(start, end));
      setTableProps((props) => ({
        ...props,
        totalNumberOfPages: Math.ceil(
          tableRows.length / tableProps.rowsPerPage
        ),
        totalNumberOfRows: tableRows.length,
      }));
    }
  }, [tableRows, tableProps.currentPage, tableProps.rowsPerPage]);

  const handlePageChange = (direction: string) => {
    setTableProps((props) => ({
      ...props,
      currentPage:
        direction === "next"
          ? Math.min(props.currentPage + 1, props.totalNumberOfPages)
          : Math.max(props.currentPage - 1, 1),
    }));
  };

  const tableHeaders =
    headers &&
    headers.length > 0 &&
    headers.map((header) => (
      <th
        className={`${headerStyles ? headerStyles : ""}`}
        scope="col"
        key={header}
      >
        {header}
      </th>
    ));

  const tableRowsComponent =
    currentTableRows &&
    currentTableRows.length > 0 &&
    currentTableRows.map((row: any, idx: number) => (
      <tr key={idx}>
        {Object.keys(row).map((key) => (
          <td key={key}>{row[key]}</td>
        ))}
      </tr>
    ));

  const tablePagination = tableRows && tableRows.length > 0 && (
    <div className="flex justify-end items-center gap-4">
      <div className="flex gap-4 items-center">
        <div className="flex items-center">
          <span>Rows per Page:</span>
          <select
            name=""
            id=""
            className="hover:bg-gray-100 p-2"
            value={tableProps.rowsPerPage}
            onChange={(e: any) =>
              setTableProps({
                ...tableProps,
                rowsPerPage: Number(e.target.value),
                currentPage: 1, // Reset to the first page when changing rows per page
              })
            }
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <span>
          {`${Math.min(
            (tableProps.currentPage - 1) * tableProps.rowsPerPage + 1,
            tableProps.totalNumberOfRows
          )} - ${Math.min(
            tableProps.currentPage * tableProps.rowsPerPage,
            tableProps.totalNumberOfRows
          )} of ${tableProps.totalNumberOfRows}`}
        </span>
      </div>
      <div className="flex items-center gap-3">
        <i
          onClick={() => handlePageChange("prev")}
          className={`fa fa-arrow-left hover:text-primary ${
            tableProps.currentPage === 1
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
        />
        <i
          onClick={() => handlePageChange("next")}
          //   if it's the last page cursor-not-allowed otherwise cursor-pointer
          className={`fa fa-arrow-right hover:text-primary ${
            tableProps.currentPage === tableProps.totalNumberOfPages
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
        />
      </div>
    </div>
  );

  return (
    <div>
      <table
        id="default_order"
        className={`${
          tableStyles
            ? tableStyles
            : "table table-sm table-striped table-bordered table-hover display no-wrap"
        }`}
        style={{ width: "100%" }}
      >
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>

        <tbody>{!loading && tableRowsComponent}</tbody>
      </table>
      {loading && (
        <div className="flex justify-center items-center h-32">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      {!loading && tableRows && tableRows.length === 0 && (
        <div className="flex justify-center items-center h-32">
          <span className="text-gray-400 text-lg">{emptyTableMessage}</span>
        </div>
      )}
      {!loading && tablePagination}
    </div>
  );
}

export default MasterTableComponent;
