import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import axios from "axios";
import setupUserApi from "../api/setupUserApi";

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const toast = useRef<Toast>(null);

  // todo: handle form submission
  const handleForm = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setupUserApi
      .loginUser({ email, password })
      .then((res) => {
        //todo:@ call toast message
        show("info", res.message);
        setLoading(false);
        sessionStorage.setItem("token", res.token);
        localStorage.setItem("token", res.token);
        sessionStorage.setItem("name", res.info.full_name);
        sessionStorage.setItem("auth_user", res.info.id);
        sessionStorage.setItem("isverified", res.info.isverified);
        sessionStorage.setItem("userDetails", JSON.stringify(res.info));
        if (res.info.isverified === true && res.status === 200) {
          window.location.href = "/dashboard";
        } else if (res.info.isverified === false && res.status === 200) {
          window.location.href = "/change-password";
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // todo: add toast message
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative">
          <div className="auth-box row">
            <div
              className="col-lg-7 col-md-5 modal-bg-img bg-white border-r-4"
              style={{
                backgroundImage: "url(assets/images/bhasys_logo_v1.jpeg)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>

            <div className="col-lg-5 col-md-7 bg-white">
              <div className="p-3">
                <div className="text-center">
                  <div
                    className="icon-lock text-primary"
                    style={{ fontSize: "50px" }}
                  />
                </div>
                <h2 className="mt-3 text-center">Sign In</h2>
                <form className="mt-4" onSubmit={handleForm}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          placeholder="enter your email address"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="pwd">
                          Password
                        </label>
                        <div className="flex items-center form-control">
                          <input
                            className="w-[95%] bg-transparent border-none outline-none"
                            id="pwd"
                            type={isPasswordVisible ? "text" : "password"}
                            required
                            placeholder="enter your password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {/* <i class="fa-regular fa-eye-slash"></i> */}
                          <i
                            className={`${
                              isPasswordVisible
                                ? "fas fa-eye-slash"
                                : "icon-eye text-dark"
                            }`}
                            onClick={() =>
                              setIsPasswordVisible(!isPasswordVisible)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-rounded btn-block btn-primary bg-[#5F76E8]"
                      >
                        {loading ? (
                          <p>loading..</p>
                        ) : (
                          <>
                            <i className="icon-login mr-4" /> Sign In
                          </>
                        )}
                      </button>
                    </div>
                    <div className="col-lg-12 text-center mt-5">
                      forgot password?{" "}
                      <a href="/reset-password" className="text-danger">
                        reset here
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
