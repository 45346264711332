import * as React from "react";
import { styled } from "@mui/material/styles";
// import
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ViewDependantData } from "../types/data";
import capitalizeText from "../utils/capitalizeText";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={< sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface ViewStaffAccordionProps {
  DATA?: ViewDependantData[];
}

export default function ViewStaffAccordion({ DATA }: ViewStaffAccordionProps) {
  // const DATA = [
  //   {
  //     id: "panel1",
  //     first_name: "Jack",
  //     last_name: "Owusu",
  //     relationship: "Father",
  //     email: "jowusu@gmail.com",
  //     ghana_card: "GHA-XXXXXXX-XX",
  //   },
  //   {
  //     id: "panel2",
  //     first_name: "Jack",
  //     last_name: "Owusu",
  //     relationship: "Mother",
  //     email: "jowusu@gmail.com",
  //     ghana_card: "GHA-XXXXXXX-XX",
  //   },
  //   {
  //     id: "panel3",
  //     first_name: "Jack",
  //     last_name: "Owusu",
  //     relationship: "Daughter",
  //     email: "jowusu@gmail.com",
  //     ghana_card: "GHA-XXXXXXX-XX",
  //   },
  //   {
  //     id: "panel4",
  //     first_name: "Jack",
  //     last_name: "Owusu",
  //     relationship: "Son",
  //     email: "jowusu@gmail.com",
  //     ghana_card: "GHA-XXXXXXX-XX",
  //   },
  //   {
  //     id: "panel5",
  //     first_name: "Jack",
  //     last_name: "Owusu",
  //     relationship: "Wife",
  //     email: "jowusu@gmail.com",
  //     ghana_card: "GHA-XXXXXXX-XX",
  //   },
  //   {
  //     id: "panel6",
  //     first_name: "Jack",
  //     last_name: "Owusu",
  //     relationship: "Child",
  //     email: "jowusu@gmail.com",
  //     ghana_card: "GHA-XXXXXXX-XX",
  //   },
  // ];
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      {DATA &&
        DATA.map((dependant: ViewDependantData) => {
          return (
            <Accordion
              key={dependant.id}
              expanded={expanded === dependant.id}
              onChange={handleChange(dependant?.id)}
            >
              <AccordionSummary
                aria-controls={`${dependant.id}-content`}
                id={`${dependant.id}-header`}
              >
                <div className="flex gap-4">
                  <img
                    className="avatar mx-auto d-block rounded-circle mb-2"
                    style={{ width: "60px" }}
                    src="assets/images/img1.jpg"
                    alt="PAPA"
                  />
                  <h4 className="card-title py-3 text-sm">
                    {dependant?.first_name} {dependant?.last_name} |{" "}
                    {dependant?.relationship}
                  </h4>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  <fieldset>
                    <table className="table" id="dataTable">
                      <tbody>
                        <tr>
                          <td>
                            <i className="icon-user" /> First Name
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={capitalizeText(
                                dependant?.first_name || ""
                              )}
                              id="first_name"
                              name="first_name"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="icon-user" /> Last Name
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={capitalizeText(
                                dependant?.last_name || ""
                              )}
                              id="last_name"
                              name="last_name"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="icon-calender" /> Email
                          </td>
                          <td>
                            <input
                              type="email"
                              defaultValue={dependant?.email}
                              id="email"
                              name="email"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fas fa-id-card" /> Ghana Card
                          </td>
                          <td>
                            <input
                              type="tel"
                              defaultValue={dependant?.ghana_card}
                              id="phone"
                              name="phone"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="icon-phone" /> Contact
                          </td>
                          <td>
                            <input
                              type="tel"
                              defaultValue={dependant?.contact}
                              id="phone"
                              name="phone"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="icon-user" /> Gender
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={capitalizeText(
                                dependant?.gender || ""
                              )}
                              id="gender"
                              name="gender"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="icon-user" /> Relationship
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={capitalizeText(
                                dependant?.relationship || ""
                              )}
                              id="relationship"
                              name="relationship"
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </form>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}
