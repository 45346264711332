import React from "react";
import { CashflowSetupType } from "../../../types/data";

interface DisableCashflowModalProps {
  handleSubmit: (e: any) => void;
  selectedCashflow: CashflowSetupType | undefined;
  isLoading: boolean;
}

const DisableCashflowModal: React.FC<DisableCashflowModalProps> = ({
  handleSubmit,
  selectedCashflow,
  isLoading,
}) => {
  return (
    <div
      className="modal fade"
      id="disableCashflowModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Disable Cashflow Type
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <h3 className="car-title text-center">
                        Are you sure you want to enable cashflow with applied to{" "}
                        <span className="text-primary">
                          {selectedCashflow?.applied_to}
                        </span>{" "}
                        and percentage of{" "}
                        <span className="text-primary">
                          {selectedCashflow?.percentage}
                          {"%"}
                        </span>{" "}
                      </h3>
                      <div className="flex items-center justify-end gap-4 text-right mt-3">
                        <button
                          type="submit"
                          disabled={isLoading}
                          onClick={handleSubmit}
                          className="btn bg-primary text-white btn-sm rounded-pill"
                        >
                          {isLoading ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <>Disable</>
                          )}
                        </button>
                        <button
                          data-dismiss="modal"
                          className="btn btn-danger btn-sm rounded-pill"
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default DisableCashflowModal;
