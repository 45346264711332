import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import VetReceiptModal from "../../components/medical-vetting/VetReceiptModal";
import { StyledSearch } from "../../components/StyledSearch";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const MedicalVetting = () => {
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* breadcrumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Medical Vetting",
                  url: "/medical-vetting",
                },
              
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-between mb-4">
                      <h3 className="card-title mb-3">Medical Vetting</h3>
                      <StyledSearch />
                    </div>
                    <div className="table-responsive" id="myTable">
                      {/* <MedicalVetting /> */}
                      <table
                        id="default_order"
                        className="table table-sm table-striped table-bordered table-hover display"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">SN</th>
                            <th scope="col">HSP Name</th>
                            <th scope="col">Batch Number</th>
                            <th scope="col">Number of Claims</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <a href="viewmedvett.html"> Francis Hospital</a>
                            </td>
                            <td>001</td>
                            <td>10</td>
                            <td>
                              <span>2500</span>
                            </td>
                            <td className="flex gap-3">
                              <a href="#" type="button">
                                <i
                                  className="fas fa-check text-primary"
                                  data-toggle="modal"
                                  data-target="#bs-example-modal-lg"
                                  title="confirm receipt message"
                                >
                                  {" "}
                                </i>
                              </a>
                              <a href="/view-med-vetting" type="button">
                                <i className="fas fa-eye text-primary" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <VetReceiptModal />
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default MedicalVetting;
