import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;
import { CreateNewDiagnosisBodyType } from "../types/data";

const createNewDiagnosis = async (
  token: string | null,
  diagnosisData: CreateNewDiagnosisBodyType
) => {
  let url = `${BASE_URL}/diagnosis/`;
  try {
    const response = await axios.post(
      url,
      {
        Diagnosis: [diagnosisData],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createNewDiagnosisBulkUpload = async (
  token: string | null,
  diagnosisFile: FormData
) => {
  let url = `${BASE_URL}/diagnosis/bulk-upload`;
  try {
    const response = await axios.post(url, diagnosisFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllDiagnosis = async (token: string | null) => {
  let url = `${BASE_URL}/diagnosis/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editDiagnosis = async (
  token: string | null,
  diagnosisData: CreateNewDiagnosisBodyType
) => {
  let url = `${BASE_URL}/diagnosis/edit`;
  try {
    const response = await axios.post(url, diagnosisData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteDiagnosis = async (
  token: string | null,
  deleteDiagnosisData: {
    diagnosis_id: string;
  }
) => {
  let url = `${BASE_URL}/diagnosis/delete`;
  try {
    const response = await axios.post(url, deleteDiagnosisData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const bulkDiagnosisUpload = async (
  token: string | null,
  diagnosisFile: FormData
) => {
  let url = `${BASE_URL}/diagnosis/bulk-upload`;
  try {
    const response = await axios.post(url, diagnosisFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  createNewDiagnosis,
  getAllDiagnosis,
  createNewDiagnosisBulkUpload,
  editDiagnosis,
  deleteDiagnosis,
  bulkDiagnosisUpload,
};
