import React from "react";

interface BulkUploadModalProps {
  setBulkUploadBody: (file: File) => void;
  handleBulkUpload: () => void;
  loading: boolean;
  title: string;
  selector: string;
}

const BulkUploadModal: React.FC<BulkUploadModalProps> = ({
  setBulkUploadBody,
  handleBulkUpload,
  loading,
  title,
  selector,
}) => {
  const handleDownloadTemplate = () => {
    if (loading) {
      return;
    }
    if (title === "Medication") {
      window.open("/templates/medication_bulk_upload.xlsx", "_blank");
    } else if (title === "Consultation") {
      window.open("/templates/consultation_bulk_upload.xlsx", "_blank");
    } else if (title === "Surgery") {
      window.open("/templates/surgery_bulk_upload.xlsx", "_blank");
    } else if (title === "Labs") {
      window.open("/templates/labs_bulk_upload.xlsx", "_blank");
    } else if (title === "Radiology") {
      window.open("/templates/radiology_bulk_upload.xlsx", "_blank");
    } else if (title === "Dentals") {
      window.open("/templates/dentals_bulk_upload.xlsx", "_blank");
    } else if (title === "Diagnosis") {
      window.open("/templates/diagnosis_bulk_upload.xlsx", "_blank");
    } else if (title === "Staff") {
      window.open("/templates/staff_bulk_upload.xlsx", "_blank");
    }
  };

  return (
    <div
      className="modal fade"
      id={selector}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title} Bulk Upload</h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="bulkUploadFile">Select File</label>
                          <input
                            type="file"
                            accept=".xlsx"
                            className="form-control"
                            id="bulkUploadFile"
                            placeholder="Upload File"
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                setBulkUploadBody(file);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-[80%] mx-auto mt-2 mb-8 py-2 px-2 text-center bg-green-200 border-2 border-green-300 text-green-800 rounded-2xl">
                    Please download the template and confirm the format and
                    structure before uploading the file.
                  </div>

                  <div className="flex justify-end gap-4">
                    <button
                      type="button"
                      className="
                        btn btn-primary bg-primary
                        rounded-pill flex gap-2 items-center
                        "
                      onClick={handleDownloadTemplate}
                      disabled={loading}
                    >
                      <i className="fas fa-download" />
                      Download Template
                    </button>
                    <button
                      type="button"
                      className="
                        btn btn-primary bg-primary
                        rounded-pill flex gap-2 items-center
                        "
                      onClick={handleBulkUpload}
                      disabled={loading}
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <>
                          <i className="fas fa-upload" />
                          Upload
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BulkUploadModal;
