import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllManageScheme = async (token: string | null) => {
  let url = `${BASE_URL}/manage_scheme/`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createScheme = async (
  token: string,
  schemeData: {
    scheme_name: string;
    scheme_type: string;
    total_number_of_children: number;
    other_dependents: number;
    total_number_of_dependents: number;
    staff_category_junior_staff: boolean;
    staff_category_senior_staff: boolean;
    staff_category_executive: boolean;
    staff_category_retirees: boolean;
    staff_category_others: boolean;
    opd_principal: number;
    opd_spouse: number;
    opd_child_one: number;
    opd_child_two: number;
    opd_child_three: number;
    opd_child_four: number;
    opd_other_dependents: number;
    sum_of_all_opd_benefit: number;
    ipd_principal: number;
    ipd_spouse: number;
    ipd_child_one: number;
    ipd_child_two: number;
    ipd_child_three: number;
    ipd_child_four: number;
    ipd_other_dependents: number;
    sum_of_all_ipd_benefit: number;
    dental_principal: number;
    dental_spouse: number;
    dental_child_one: number;
    dental_child_two: number;
    dental_child_three: number;
    dental_child_four: number;
    dental_other_dependents: number;
    sum_of_all_dental_benefit: number;
    optical_principal: number;
    optical_spouse: number;
    optical_child_one: number;
    optical_child_two: number;
    optical_child_three: number;
    optical_child_four: number;
    optical_other_dependents: number;
    sum_of_all_optical_benefit: number;
    sum_of_principal_for_opd_ipd_dental_optical: number;
    sum_of_spouse_for_opd_ipd_dental_optical: number;
    sum_of_child_one_for_opd_ipd_dental_optical: number;
    sum_of_child_two_for_opd_ipd_dental_optical: number;
    sum_of_child_three_for_opd_ipd_dental_optical: number;
    sum_of_child_four_for_opd_ipd_dental_optical: number;
    sum_of_other_dependents_for_opd_ipd_dental_optical: number;
    sum_of_everything: number;
  }
) => {
  let url = `${BASE_URL}/manage_scheme/`;
  try {
    const response = await axios.post(url, schemeData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editScheme = async (
  token: string,
  schemeData: {
    id: string;
    scheme_name: string;
    scheme_type: string;
    total_number_of_children: number;
    other_dependents: number;
    total_number_of_dependents: number;
    staff_category_junior_staff: boolean;
    staff_category_senior_staff: boolean;
    staff_category_executive: boolean;
    staff_category_retirees: boolean;
    staff_category_others: boolean;
    opd_principal: number;
    opd_spouse: number;
    opd_child_one: number;
    opd_child_two: number;
    opd_child_three: number;
    opd_child_four: number;
    opd_other_dependents: number;
    sum_of_all_opd_benefit: number;
    ipd_principal: number;
    ipd_spouse: number;
    ipd_child_one: number;
    ipd_child_two: number;
    ipd_child_three: number;
    ipd_child_four: number;
    ipd_other_dependents: number;
    sum_of_all_ipd_benefit: number;
    dental_principal: number;
    dental_spouse: number;
    dental_child_one: number;
    dental_child_two: number;
    dental_child_three: number;
    dental_child_four: number;
    dental_other_dependents: number;
    sum_of_all_dental_benefit: number;
    optical_principal: number;
    optical_spouse: number;
    optical_child_one: number;
    optical_child_two: number;
    optical_child_three: number;
    optical_child_four: number;
    optical_other_dependents: number;
    sum_of_all_optical_benefit: number;
    sum_of_principal_for_opd_ipd_dental_optical: number;
    sum_of_spouse_for_opd_ipd_dental_optical: number;
    sum_of_child_one_for_opd_ipd_dental_optical: number;
    sum_of_child_two_for_opd_ipd_dental_optical: number;
    sum_of_child_three_for_opd_ipd_dental_optical: number;
    sum_of_child_four_for_opd_ipd_dental_optical: number;
    sum_of_other_dependents_for_opd_ipd_dental_optical: number;
    sum_of_everything: number;
  }
) => {
  let url = `${BASE_URL}/manage_scheme/edit`;
  try {
    const response = await axios.post(url, schemeData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeManageSchemeStatus = async (
  token: string,
  schemeId: string,
  flag: string
) => {
  let url = `${BASE_URL}/manage_scheme/change-status`;
  try {
    const response = await axios.post(
      url,
      { manage_scheme_id: schemeId, status_flag: flag },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getSchemeDetails = async (
  token: string | null,
  schemeId: string | null
) => {
  let url = `${BASE_URL}/manage_scheme/details`;
  try {
    const response = await axios.post(
      url,
      { manage_scheme_id: schemeId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const deleteScheme = async (token: string | null, schemeId: string | null) => {
  let url = `${BASE_URL}/manage_scheme/delete`;
  try {
    const response = await axios.post(
      url,
      {
        manage_scheme_id: schemeId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default {
  getAllManageScheme,
  createScheme,
  editScheme,
  changeManageSchemeStatus,
  getSchemeDetails,
  deleteScheme,
};
