import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer text-center text-muted">
        All Rights Reserved by Bhasys App. Designed and Developed by{" "}
        <a href="https://www.linkedin.com/company/perigee-insights-limited/about/">
          Perigee Insights
        </a>
        .
      </footer>
    </>
  );
};

export default Footer;
