import React from "react";
import { StaffDataType, StaffDependantData } from "../../../types/data";

interface AddNewStaffModalProps {
  handleNext: () => void;
  handlePrevious: () => void;
  handleSubmit: (e: any) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  createStaffDependantData: StaffDependantData;
  setCreateStaffDependantData: (data: StaffDependantData) => void;
  createStaffData: StaffDataType;
  setCreateStaffData: (data: StaffDataType) => void;
  createStaffDependantSixDataImage: null | File;
  setCreateStaffDependantSixDataImage: (image: null | File) => void;
  createStaffDependantFiveDataImage: null | File;
  setCreateStaffDependantFiveDataImage: (image: null | File) => void;
  createStaffDependantFourDataImage: null | File;
  setCreateStaffDependantFourDataImage: (image: null | File) => void;
  createStaffDependantThreeDataImage: null | File;
  setCreateStaffDependantThreeDataImage: (image: null | File) => void;
  createStaffDependantTwoDataImage: null | File;
  setCreateStaffDependantTwoDataImage: (image: null | File) => void;
  createStaffDependantOneDataImage: null | File;
  setCreateStaffDependantOneDataImage: (image: null | File) => void;
  createStaffDataImage: null | File;
  setCreateStaffDataImage: (image: null | File) => void;
}

function AddNewStaffModal({
  handleNext,
  handlePrevious,
  handleSubmit,
  currentStep,
  setCurrentStep,
  loading,
  setLoading,
  createStaffDependantData,
  setCreateStaffDependantData,
  createStaffData,
  setCreateStaffData,
  createStaffDependantSixDataImage,
  setCreateStaffDependantSixDataImage,
  createStaffDependantFiveDataImage,
  setCreateStaffDependantFiveDataImage,
  createStaffDependantFourDataImage,
  setCreateStaffDependantFourDataImage,
  createStaffDependantThreeDataImage,
  setCreateStaffDependantThreeDataImage,
  createStaffDependantTwoDataImage,
  setCreateStaffDependantTwoDataImage,
  createStaffDependantOneDataImage,
  setCreateStaffDependantOneDataImage,
  createStaffDataImage,
  setCreateStaffDataImage,
}: AddNewStaffModalProps) {
  return (
    <div
      className="modal fade"
      id="addNewStaffModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Add Staff
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form className="form-body">
                      {/* step one */}
                      {currentStep === 1 && (
                        <div className="">
                          <h4 className="card-title ">
                            Personal Details
                            {/* <i
                              className="fas fa-file"
                              id="benefit"
                            /> */}
                          </h4>
                          <hr className="bg-dark" />
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-image" />
                                  Picture
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={createStaffData.first_name}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      first_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={createStaffData.last_name}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="select DoB"
                                  value={createStaffData.date_of_birth}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      date_of_birth: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={createStaffData.email}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      email: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  placeholder="0240000000"
                                  value={createStaffData.contact}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      contact: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="Company Name"
                                  value={createStaffData.gender}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      gender: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-history" /> Staff ID
                                </label>
                                <input
                                  type="string"
                                  className="form-control"
                                  placeholder="Staff ID"
                                  value={createStaffData.id}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      id: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label htmlFor="exampleFormControlSelect1">
                                  <i className="fas fa-tasks" /> Staff Category
                                </label>
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  value={createStaffData.category}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      category: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Select Category</option>
                                  <option value="Junior Staff">
                                    Junior Staff
                                  </option>
                                  <option value="Senior Staff">
                                    Senior Staff
                                  </option>
                                  <option value="Executive Staff">
                                    Executive Staff
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-map-pin" /> Branch
                                  Office/Location
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Branch Office/Location"
                                  value={createStaffData.location}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      location: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={createStaffData.ghana_card}
                                  onChange={(e) => {
                                    setCreateStaffData({
                                      ...createStaffData,
                                      ghana_card: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* step two */}
                      {currentStep === 2 && (
                        <div className="">
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <h4 className="card-title">Dependants</h4>
                              <hr className="bg-dark" />
                            </div>
                            {/* dep 1 */}
                            <div className="col-md-12 col-sm-12">
                              <h5 className="sidebar-heading d-flex justify-content-between align-items-center mb-1 text-danger">
                                <span>Dependant One</span>
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-image" />
                                  Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={createStaffDependantOneDataImage}
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDependantOneDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.first_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        first_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.last_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        last_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DoB"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.date_of_birth
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        date_of_birth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.email
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0500000000"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.contact
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        contact: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="select one"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.gender
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        gender: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Relationship
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder=" select one"
                                  value={
                                    createStaffDependantData?.dependantOne
                                      ?.relationship
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        relationship: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select a relationship
                                  </option>
                                  <option value={"Parent"}>Parent</option>
                                  <option value={"Spouse"}>Spouse</option>
                                  <option value={"Sibling"}>Sibling</option>
                                  <option value={"Ward"}>Ward</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={
                                    createStaffDependantData.dependantOne
                                      .ghana_card
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantOne: {
                                        ...createStaffDependantData.dependantOne,
                                        ghana_card: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {/* dep 2 */}
                            <div className="col-md-12 col-sm-12">
                              <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                <span>Dependant Two</span>
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-image" />
                                  Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={createStaffDependantTwoDataImage}
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDependantTwoDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={
                                    createStaffDependantData.dependantTwo
                                      .first_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        first_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={
                                    createStaffDependantData.dependantTwo
                                      .last_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        last_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DoB"
                                  value={
                                    createStaffDependantData.dependantTwo
                                      .date_of_birth
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        date_of_birth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={
                                    createStaffDependantData.dependantTwo.email
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0500000000"
                                  value={
                                    createStaffDependantData.dependantTwo
                                      .contact
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        contact: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="Gender"
                                  value={
                                    createStaffDependantData?.dependantTwo
                                      ?.gender
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        gender: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Relationship
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder=" select one"
                                  value={
                                    createStaffDependantData?.dependantTwo
                                      ?.relationship
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        relationship: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select a relationship
                                  </option>
                                  <option value={"Parent"}>Parent</option>
                                  <option value={"Spouse"}>Spouse</option>
                                  <option value={"Sibling"}>Sibling</option>
                                  <option value={"Ward"}>Ward</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={
                                    createStaffDependantData.dependantTwo
                                      .ghana_card
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantTwo: {
                                        ...createStaffDependantData.dependantTwo,
                                        ghana_card: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {/* dep 3 */}
                            <div className="col-md-12 col-sm-12">
                              <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                <span>Dependant Three</span>
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-image" />
                                  Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={createStaffDependantThreeDataImage}
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDependantThreeDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={
                                    createStaffDependantData.dependantThree
                                      .first_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        first_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={
                                    createStaffDependantData.dependantThree
                                      .last_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        last_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DoB"
                                  value={
                                    createStaffDependantData.dependantThree
                                      .date_of_birth
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        date_of_birth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={
                                    createStaffDependantData.dependantThree
                                      .email
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0500000000"
                                  value={
                                    createStaffDependantData.dependantThree
                                      .contact
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        contact: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="Gender"
                                  value={
                                    createStaffDependantData?.dependantThree
                                      ?.gender
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        gender: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Relationship
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder=" select one"
                                  value={
                                    createStaffDependantData?.dependantThree
                                      ?.relationship
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        relationship: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select a relationship
                                  </option>
                                  <option value={"Parent"}>Parent</option>
                                  <option value={"Spouse"}>Spouse</option>
                                  <option value={"Sibling"}>Sibling</option>
                                  <option value={"Ward"}>Ward</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={
                                    createStaffDependantData.dependantThree
                                      .ghana_card
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantThree: {
                                        ...createStaffDependantData.dependantThree,
                                        ghana_card: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* step three */}
                      {currentStep === 3 && (
                        <div className="">
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <h4 className="card-title">Dependants</h4>
                              <hr className="bg-dark" />
                            </div>
                            {/* dep 4 */}
                            <div className="col-md-12 col-sm-12">
                              <h5 className="sidebar-heading d-flex justify-content-between align-items-center mb-1 text-danger">
                                <span>Dependant Four</span>
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-image" />
                                  Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={createStaffDependantFourDataImage}
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDependantFourDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={
                                    createStaffDependantData.dependantFour
                                      .first_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        first_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={
                                    createStaffDependantData.dependantFour
                                      .last_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        last_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DoB"
                                  value={
                                    createStaffDependantData.dependantFour
                                      .date_of_birth
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        date_of_birth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={
                                    createStaffDependantData.dependantFour.email
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0500000000"
                                  value={
                                    createStaffDependantData.dependantFour
                                      .contact
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        contact: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="Gender"
                                  value={
                                    createStaffDependantData?.dependantFour
                                      ?.gender
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        gender: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Relationship
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder=" select one"
                                  value={
                                    createStaffDependantData?.dependantFour
                                      ?.relationship
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        relationship: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select a relationship
                                  </option>
                                  <option value={"Parent"}>Parent</option>
                                  <option value={"Spouse"}>Spouse</option>
                                  <option value={"Sibling"}>Sibling</option>
                                  <option value={"Ward"}>Ward</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={
                                    createStaffDependantData.dependantFour
                                      .ghana_card
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFour: {
                                        ...createStaffDependantData.dependantFour,
                                        ghana_card: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {/* dep 5 */}
                            <div className="col-md-12 col-sm-12">
                              <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                <span>Dependant Five</span>
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={createStaffDependantFiveDataImage}
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDependantFiveDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={
                                    createStaffDependantData.dependantFive
                                      .first_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        first_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={
                                    createStaffDependantData.dependantFive
                                      .last_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        last_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DoB"
                                  value={
                                    createStaffDependantData.dependantFive
                                      .date_of_birth
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        date_of_birth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={
                                    createStaffDependantData.dependantFive.email
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0500000000"
                                  value={
                                    createStaffDependantData.dependantFive
                                      .contact
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        contact: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="Gender"
                                  value={
                                    createStaffDependantData?.dependantFive
                                      ?.gender
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        gender: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Relationship
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder=" select one"
                                  value={
                                    createStaffDependantData?.dependantFive
                                      ?.relationship
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        relationship: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select a relationship
                                  </option>
                                  <option value={"Parent"}>Parent</option>
                                  <option value={"Spouse"}>Spouse</option>
                                  <option value={"Sibling"}>Sibling</option>
                                  <option value={"Ward"}>Ward</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={
                                    createStaffDependantData.dependantFive
                                      .ghana_card
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantFive: {
                                        ...createStaffDependantData.dependantFive,
                                        ghana_card: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {/* dep 6*/}
                            <div className="col-md-12 col-sm-12">
                              <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                <span>Dependant Six</span>
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-image" />
                                  Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={createStaffDependantSixDataImage}
                                  onChange={(e) => {
                                    if (e.target.files != null) {
                                      setCreateStaffDependantSixDataImage(
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> First
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={
                                    createStaffDependantData.dependantSix
                                      .first_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        first_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" /> Last
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={
                                    createStaffDependantData.dependantSix
                                      .last_name
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        last_name: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-calendar-alt" /> Date of
                                  Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Company Name"
                                  value={
                                    createStaffDependantData.dependantSix
                                      .date_of_birth
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        date_of_birth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-envelope" /> E-mail
                                  Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="me@gmail.com"
                                  value={
                                    createStaffDependantData.dependantSix.email
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-phone" /> Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0500000000"
                                  value={
                                    createStaffDependantData.dependantSix
                                      .contact
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        contact: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user-circle" />
                                  Gender
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder="Gender"
                                  value={
                                    createStaffDependantData?.dependantSix
                                      ?.gender
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        gender: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">Select a gender</option>
                                  <option value={"Male"}>Male</option>
                                  <option value={"Female"}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-address-book" />
                                  Relationship
                                </label>
                                <select
                                  className="form-control"
                                  // placeholder=" select one"
                                  value={
                                    createStaffDependantData?.dependantSix
                                      ?.relationship
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        relationship: e.target.value,
                                      },
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select a relationship
                                  </option>
                                  <option value={"Parent"}>Parent</option>
                                  <option value={"Spouse"}>Spouse</option>
                                  <option value={"Sibling"}>Sibling</option>
                                  <option value={"Ward"}>Ward</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-user" /> Ghana Card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GHA-XXXXXXXXX-XX"
                                  value={
                                    createStaffDependantData.dependantSix
                                      .ghana_card
                                  }
                                  onChange={(e) => {
                                    setCreateStaffDependantData({
                                      ...createStaffDependantData,
                                      dependantSix: {
                                        ...createStaffDependantData.dependantSix,
                                        ghana_card: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex justify-between px-2">
                        {currentStep === 1 ? (
                          <div></div>
                        ) : (
                          <div
                            onClick={handlePrevious}
                            className="btn btn-primary rounded-pill"
                          >
                            <span>
                              <i className="fa fa-arrow-left mr-2" />
                            </span>{" "}
                            Previous
                          </div>
                        )}
                        {currentStep === 3 ? (
                          <div
                            onClick={handleSubmit}
                            className="btn btn-primary rounded-pill "
                          >
                            {loading ? "Saving..." : "Save"}
                            <span>
                              <i className="fa fa-save ml-2" />
                            </span>{" "}
                          </div>
                        ) : (
                          <div
                            onClick={handleNext}
                            className="btn btn-primary rounded-pill "
                          >
                            Next
                            <span>
                              <i className="fa fa-arrow-right ml-2" />
                            </span>{" "}
                          </div>
                        )}
                      </div>
              
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default AddNewStaffModal;
