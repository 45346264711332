import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { OptionsType } from "../../types/data";

interface CustomSelectorProps {
  options: OptionsType[];
  selected: OptionsType | null;
  setSelected: React.Dispatch<React.SetStateAction<OptionsType | null>>;
  handleSetSelected?: (value: any) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange?: (e: any) => void;
  name?: string;
  id?: string;
  required?: boolean;
  error?: string;
  selecterFor?: string;
  setAccreditedHspId?: React.Dispatch<React.SetStateAction<string | null>>;
}
const CustomSelector: React.FC<CustomSelectorProps> = ({
  options,
  selected,
  setSelected,
  placeholder,
  searchPlaceholder,
  className,
  style,
  disabled,
  onChange,
  name,
  id,
  required,
  error,
  selecterFor,
  setAccreditedHspId,
  handleSetSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  return (
    <div
      className={`relative ${className}`}
      style={{ ...style, minWidth: "10rem" }}
    >
      <div
        onClick={() => setOpen(!open)}
        className={`bg-neutral-100 w-full p-2  flex items-center justify-between rounded ${
          !selected && "text-gray-700"
        }`}
      >
        {/* check if selecterFor is med-dev-staff if so then use selected?.id */}
        {selected && selecterFor === "med-dev-staff"
          ? selected?.id?.length > 25
            ? selected?.id?.substring(0, 25) + "..."
            : selected?.id
          : selected?.label && selected?.label?.length > 25
          ? selected?.label?.substring(0, 25) + "..."
          : selected?.label}
        {!selected && placeholder}
        <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
      </div>
      <ul
        className={`absolute z-10 left-0 bg-neutral-200 w-full mt-2 overflow-y-scroll ${
          open ? "max-h-60" : "max-h-0"
        } `}
      >
        <div className="relative">
          <div className="flex items-center px-2 w-full bg-white sticky top-0 border-[1px] rounded-sm">
            <AiOutlineSearch size={18} className="text-gray-700" />
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.toLowerCase())}
              placeholder={searchPlaceholder}
              className="placeholder:text-gray-700 p-2 outline-none w-[100%]"
            />
          </div>
        </div>
        {options?.map((option: OptionsType) => (
          <li
            key={option.id}
            className={`p-2 text-sm hover:bg-sky-600 hover:text-white
                ${
                  option?.label?.toLowerCase() ===
                    selected?.label?.toLowerCase() && "bg-sky-600 text-white"
                }
                ${
                  option?.label?.toLowerCase().includes(inputValue)
                    ? "block"
                    : "hidden"
                }`}
            onClick={() => {
              if (
                option?.label?.toLowerCase() !== selected?.label?.toLowerCase()
              ) {
                if (selecterFor === "hsp") {
                  setAccreditedHspId && setAccreditedHspId(option.id);
                }
                if (selecterFor === "med-dev-staff") {
                  handleSetSelected && handleSetSelected(option.id);
                }
                setSelected(option);
                setOpen(false);
                setInputValue("");
              }
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomSelector;
