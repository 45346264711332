import React, { useState } from "react";
import { AiOutlineBank } from "react-icons/ai";
import {
  BsFileEarmarkPdf,
  BsFilePerson,
  BsFillCreditCard2FrontFill,
  BsReceiptCutoff,
} from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";
import { LuReceipt } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";

function ApprovedRefundPaymentsModal() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [bankPaymentOptions, setBankPaymentOptions] = useState("");
  return (
    <div
      className="modal fade"
      id="payment-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              Payment Form
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Select Network</label>
                    <select
                      name="myselection"
                      id="myselection"
                      className="form-control"
                      onChange={(e) => {
                        setSelectedPaymentMethod(e.target.value);
                      }}
                    >
                      <option value="">Provider</option>
                      <option value="MTN">MTN</option>
                      <option value="Vodafone">Vodafone</option>
                      <option value="AT">AT</option>
                    </select>
                  </div>
                </div>

                <div className="row myDiv" id="showone">
                  <div className="col-12 mt-2">
                    <label className="flex items-center">
                      <i className="fa fa-phone mr-1" />
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="phone number"
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label className="flex items-center">
                      <i className="fa fa-phone mr-1" />
                      Confirm Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="phone number"
                    />
                  </div>
                </div>

                <div className="buttons  mt-3 w-full flex justify-between">
                  <button
                    className="btn btn-primary btn-sm closs bg-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times mr-1" />
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm bg-primary rounded-pill flex gap-1 items-center"
                  >
                    <MdOutlinePayments className="mr-1" />
                    Pay
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ApprovedRefundPaymentsModal;
