import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BreadCrumbsComponent({
  breadcrumbs = [
    {
      title: "Home",
      url: "/dashboard",
    },
  ],
}) {
  return (
    <Stack spacing={1}>
      <Breadcrumbs
        sx={{
          marginBottom: "20px",
        }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map(
          (breadcrumb, index) => {
            if (index === breadcrumbs.length - 1) {
              return (
                <Typography key={index} color="text.primary" fontSize={20}>
                  {breadcrumb.title}
                </Typography>
              );
            } else {
              return (
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href={breadcrumb?.url}
                  fontSize={20}
                >
                  {breadcrumb?.title}
                </Link>
              );
            }
          }
          // <>
          //   {index === breadcrumbs.length - 1 ? (
          //     <Typography key={index} color="text.primary" fontSize={20}>
          //       {breadcrumb.title}
          //     </Typography>
          //   ) : (
          //     <Link
          //       underline="hover"
          //       key="1"
          //       color="inherit"
          //       href={breadcrumb?.url}
          //       fontSize={20}
          //     >
          //       {breadcrumb?.title}
          //     </Link>
          //   )}
          // </>
        )}
      </Breadcrumbs>
    </Stack>
  );
}
