import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const createOptical = async (
  token: string,
  opticalData: {
    optical_name: string;
    optical_category: string;
    optical_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/optical/`;
  try {
    const response = await axios.post(url, opticalData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllOpticalCategory = async (token: string) => {
  let url = `${BASE_URL}/setup-services/optical/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllOptical = async (token: string) => {
  let url = `${BASE_URL}/setup-services/optical/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const bulkOpticalUpload = async (token: string, opticalFile: FormData) => {
  let url = `${BASE_URL}/setup-services/optical/bulk_upload`;
  try {
    const response = await axios.post(url, opticalFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getOpticalDetails = async (token: string, opticalId: string) => {
  let url = `${BASE_URL}/setup-services/optical/details`;
  try {
    const response = await axios.post(
      url,
      {
        optical_id: opticalId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteOpticalCategory = async (token: string, categoryId: string) => {
  let url = `${BASE_URL}/setup-services/optical/category/delete`;
  try {
    const response = await axios.post(
      url,
      {
        category_id: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editOpticalCategory = async (
  token: string,
  categoryId: string,
  categoryName: string
) => {
  let url = `${BASE_URL}/setup-services/optical/category/edit`;
  try {
    const response = await axios.post(
      url,
      {
        category_id: categoryId,
        category_name: categoryName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editOptical = async (
  token: string,
  opticalData: {
    optical_id: string;
    optical_name: string;
    optical_category: string;
    optical_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/optical/edit`;
  try {
    const response = await axios.post(url, opticalData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createOpticalCategory = async (token: string, categoryName: string) => {
  let url = `${BASE_URL}/setup-services/optical/category/`;
  try {
    const response = await axios.post(
      url,
      {
        category_name: categoryName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  createOptical,
  getAllOpticalCategory,
  getAllOptical,
  bulkOpticalUpload,
  getOpticalDetails,
  deleteOpticalCategory,
  editOpticalCategory,
  editOptical,
  createOpticalCategory,
};
