import React from "react";
import MasterTableComponent from "../../ui/MasterTableComponent";

function ClaimsHistoryTab() {
  return (
    <div className="tab-pane" id="claimHistoryTab">
      <div className="card shadow">
        <div className="card-body">
          <div className="table-responsive" id="myTable">
            <MasterTableComponent
              headers={[
                "S/N",
                "Claim No.",
                "Patient Name",
                "Patient ID",
                "Batch No.",
                "Date Attended",
                "Amount",
                "Actions",
              ]}
              headerStyles="bg-primary text-white"
              tableRows={[
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "5678",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "5678",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
                {
                  "S/N": 1,
                  "Claim NO.": "HTWRS",
                  "Patient Name": "Kofi Momoa",
                  "Patient ID": "435465765643",
                  "Batch No.": 345,
                  "Date Attended": "30-0CT-2022",
                  Amount: "65.34",
                  Actions: (
                    <div className="flex gap-2">
                      <i className="fas fa-edit text-primary" />
                      <i className="fas fa-times text-primary" />
                      <i className="fas fa-trash text-primary" />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimsHistoryTab;
