import React, { useEffect, useRef, useState } from "react";
import { HspInvestigationType } from "../../../types/data";
import hspInvestigationsApi from "../../../api/hspInvestigationsApi";
import radiologyApi from "../../../api/radiologyApi";
import labsApi from "../../../api/labsApi";
import { Toast } from "primereact/toast";
import { StyledSearch } from "../../StyledSearch";
import SaveAllServicesModal from "./SaveAllServicesModal";
import MasterTableComponent from "../../ui/MasterTableComponent";
import formatMoney from "../../../utils/formatMoney";
import EditHspInvestigationModal from "./EditHspInvestigationModal";
import closeAnyModal from "../../../utils/closeAnyModal";

interface InvestigationsAndPricingTabProps {
  fetchedCashflows: any[];
}

const InvestigationsAndPricingTab: React.FC<
  InvestigationsAndPricingTabProps
> = ({ fetchedCashflows }) => {
  const toast = useRef<Toast>(null);
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>({});

  const [createNewInvestigationsBody, setCreateNewInvestigationsBody] =
    useState<HspInvestigationType[]>([]);
  const [includedData, setIncludedData] = useState<HspInvestigationType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [fetchInvestigations, setFetchedInvestigations] = useState([{}]);
  const [
    fetchedAlreadyAddedInvestigations,
    setFetchedAlreadyAddedInvestigations,
  ] = useState<any[]>([]);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [createNewInvestigationIsLoading, setCreateNewInvestigationIsLoading] =
    useState(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [hspName, setHspName] = useState<string>("");
  const [token, setToken] = useState<string>("");
  // todo: add toast message
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const getAlreadyAddedInvestigations = async () => {
    const selectedHSP = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    const res = await hspInvestigationsApi.getAllHspInvestigations(
      sessionStorage.getItem("token") || "",
      selectedHSP?.id || ""
    );
    if (res.status === 200) {
      setFetchedAlreadyAddedInvestigations(res?.info);
    } else {
      show("error", res?.message);
    }
  };

  const handleAddNewInvestigationToHSPList = async (e: any) => {
    setFetchIsLoading(true);
    const [type, id] = e.target.value.split("=");
    let category: any = fetchInvestigations.find(
      (item: any) => item.id === e.target.value
    );
    setSelectedCategory({
      name: category?.investigation_category,
      id: id,
    });

    const res = await hspInvestigationsApi.getAllHspInvestigationsByCategory(
      sessionStorage.getItem("token") || "",
      id
    );

    if (res.status === 200) {
      const response = res?.info ? res?.info : res?.data;
      const data =
        response?.length > 0 &&
        response.map((item: any) => {
          const alreadyAdded = fetchedAlreadyAddedInvestigations.find(
            (investigation: any) =>
              investigation.service_name ===
              (type === "radiology" ? item?.radiology_name : item?.lab_name)
          );

          if (alreadyAdded) {
            return {
              investigation_code:
                type === "radiology" ? item?.radiology_code : item?.lab_code,
              service_name:
                type === "radiology" ? item?.radiology_name : item?.lab_name,
              investigation_included: true,
              standard_rate: alreadyAdded?.standard_rate,
              discount_rate: alreadyAdded?.discount_rate,
              net_rate: alreadyAdded?.net_rate,
              investigation_approval: alreadyAdded?.investigation_approval,
              investigation_revenue_type:
                alreadyAdded?.investigation_revenue_type,
              has_been_added: true,
              investigation_id: item?.id || "",
            };
          } else {
            return {
              investigation_code:
                type === "radiology" ? item?.radiology_code : item?.lab_code,
              service_name:
                type === "radiology" ? item?.radiology_name : item?.lab_name,
              investigation_included: false,
              standard_rate: 0.0,
              discount_rate: 0.0,
              net_rate: 0.0,
              investigation_approval: false,
              investigation_revenue_type: "",
              has_been_added: false,
            };
          }
        });
      setCreateNewInvestigationsBody(data);
    } else {
      show("error", res?.message);
      setCreateNewInvestigationsBody([]);
    }

    setFetchIsLoading(false);
  };

  const handleCreateHspInvestigation = async () => {
    setCreateNewInvestigationIsLoading(true);
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    const includedData = getIncludedData();
    let dataToSave: HspInvestigationType[] = includedData.map(
      (item: HspInvestigationType) => ({
        ...item,
        hsp_owner_id: hspId,
        hsp_category: selectedCategory.name,
      })
    );
    // delete has_been_added property
    dataToSave.forEach((item) => delete item.has_been_added);

    const response = await hspInvestigationsApi.createHsInvestigation(
      token,
      dataToSave
    );
    setCreateNewInvestigationIsLoading(false);
    if (response.status === 200) {
      show("success", response?.message);
      document
        .getElementById("saveAllServicesModal-investigation")
        ?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      await handleFetchHspInvestigations();
    } else {
      show("error", response?.message);
    }
  };

  const handleUpdateInvestigationService = async () => {
    setIsUpdateLoading(true);

    const response = await hspInvestigationsApi.editHspInvestigation(
      sessionStorage.getItem("token") || "",
      [
        {
          investigation_hsp_id: selectedItemToEdit?.investigation_id,
          service_name: selectedItemToEdit?.service_name,
          investigation_included: selectedItemToEdit?.investigation_included,
          standard_rate: selectedItemToEdit?.standard_rate,
          discount_rate: selectedItemToEdit?.discount_rate,
          net_rate: selectedItemToEdit?.net_rate,
          investigation_approval: selectedItemToEdit?.investigation_approval,
          investigation_revenue_type:
            selectedItemToEdit?.investigation_revenue_type,
        },
      ]
    );
    if (response.status === 200) {
      await handleFetchHspInvestigations();
      show("success", response?.message);
      setIsUpdateLoading(false);
      closeAnyModal("EditHspInvestigationModal");
    } else {
      show("error", response?.message);
      setIsUpdateLoading(false);
    }
  };

  const handleFetchHspInvestigations = async () => {
    const tk = sessionStorage.getItem("token");
    const radiolodyResponse = await radiologyApi.getAllRadiologyCategory(
      !tk ? "" : tk
    );
    const labsResponse = await labsApi.getAllLabCategories(!tk ? "" : tk);
    if (labsResponse.status === 200) {
      const radiologyRecords =
        labsResponse?.info?.length > 0
          ? labsResponse?.info.map((item: any) => {
              return {
                investigation_category: item.lab_category,
                investigation_initials: item.lab_initials,
                id: `lab=${item.id}`,
              };
            })
          : [];

      const labsRecords =
        radiolodyResponse?.info?.length > 0
          ? radiolodyResponse?.info.map((item: any) => {
              return {
                investigation_category: item.radiology_category,
                investigation_initials: item.radiology_initials,
                id: `radiology=${item.id}`,
              };
            })
          : [];
      console.log("labsRecords", labsRecords);
      const data = [...labsRecords, ...radiologyRecords];
      setFetchedInvestigations(data);
    } else {
      show("error", radiolodyResponse?.message);
    }
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setHspName(selectedHsp?.hsp_name);
    const fn = async () => {
      await handleFetchHspInvestigations();
      await getAlreadyAddedInvestigations();
    };
    fn();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = createNewInvestigationsBody.filter((data: any) => {
      return (
        data?.investigation_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.service_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  const getIncludedData = () => {
    const data = tableRows.filter(
      (investigation: HspInvestigationType) =>
        investigation.investigation_included === true &&
        investigation.has_been_added === false
    );
    setIncludedData(data);
    return data;
  };

  const toggleSaveAllModal = () => {
    const included_data = getIncludedData();
    if (included_data.length === 0) {
      show("error", "No services selected");
    }
  };

  useEffect(() => {
    const dataToRender = searchQuery
      ? filteredData
      : createNewInvestigationsBody;
    setTableRows(dataToRender);
  }, [searchQuery, createNewInvestigationsBody]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane" id="investigationsAndPricingTab">
        <div className="row">
          <div className="col-md-8 offset-md-2 ">
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  {/* <label htmlFor="Type consultation" className="col-md-12 ">
                Select a consultation type
              </label> <br /> */}
                  <select
                    name=""
                    id=""
                    className="col-md-12 border-2 py-2 rounded-pill"
                    onChange={handleAddNewInvestigationToHSPList}
                  >
                    <option value="">select a category</option>
                    {fetchInvestigations?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item?.id}>
                          {item?.investigation_initials} -{" "}
                          {item?.investigation_category}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive" id="myTable">
              <div className="flex justify-end gap-4">
                <StyledSearch
                  onChange={handleSearchChange}
                  value={searchQuery}
                  placeholder="Search by Investigation or Code"
                />
                <button
                  data-target={
                    includedData.length == 0
                      ? ""
                      : "#saveAllServicesModal-investigation"
                  }
                  data-toggle="modal"
                  className={`btn btn-primary rounded-pill mb-1 bg-primary ${
                    tableRows.length === 0 && "disabled"
                  }`}
                  onClick={toggleSaveAllModal}
                >
                  {createNewInvestigationIsLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm mr-2" />
                      Loading
                    </>
                  ) : (
                    <>
                      <i className="fas fa-save" /> Save All
                    </>
                  )}
                </button>
              </div>
              <MasterTableComponent
                headers={[
                  "Code",
                  "Service Name",
                  "Included",
                  "Std Tariff",
                  "Agreed Tariff",
                  "Net Tariff",
                  "Authorization",
                  "Cashflow Type",
                  "Action",
                ]}
                loading={fetchIsLoading}
                emptyTableMessage="No Investigations Available"
                tableRows={
                  tableRows &&
                  tableRows.map(
                    (investigation: HspInvestigationType, idx: number) => ({
                      Code: investigation.investigation_code,
                      "Service Name": investigation.service_name,
                      Included: (
                        <input
                          type="checkbox"
                          className=""
                          disabled={investigation?.has_been_added === true}
                          checked={investigation.investigation_included}
                          onChange={(e: any) => {
                            const newInvestigationBody = [...tableRows];
                            newInvestigationBody[idx].investigation_included =
                              e.target.checked;
                            setTableRows(newInvestigationBody);
                          }}
                        />
                      ),
                      "Std Tariff": (
                        <input
                          type="number"
                          min={"0"}
                          className="form-control"
                          disabled={investigation?.has_been_added === true}
                          value={investigation.standard_rate}
                          onChange={(e: any) => {
                            const newInvestigationBody = [...tableRows];
                            newInvestigationBody[idx].standard_rate =
                              e.target.value;
                            newInvestigationBody[idx].net_rate = (
                              parseFloat(e.target.value) -
                              parseFloat(
                                newInvestigationBody[idx].discount_rate
                              )
                            ).toFixed(2);
                            setTableRows(newInvestigationBody);
                          }}
                        />
                      ),
                      "Agreed Tariff": (
                        <input
                          type="number"
                          min={"0"}
                          className="form-control"
                          disabled={investigation?.has_been_added === true}
                          value={investigation.discount_rate}
                          onChange={(e: any) => {
                            const newInvestigationBody = [...tableRows];
                            newInvestigationBody[idx].discount_rate =
                              e.target.value;
                            newInvestigationBody[idx].net_rate = (
                              parseFloat(
                                newInvestigationBody[idx].standard_rate
                              ) - parseFloat(e.target.value)
                            ).toFixed(2);
                            setTableRows(newInvestigationBody);
                          }}
                        />
                      ),
                      "Net Tariff": (
                        <input
                          type="number"
                          min={"0"}
                          className="form-control"
                          disabled
                          value={investigation.net_rate}
                          onChange={(e: any) => {
                            const newInvestigationBody = [...tableRows];
                            newInvestigationBody[idx].net_rate = Number(
                              e.target.value
                            );
                            setTableRows(newInvestigationBody);
                          }}
                        />
                      ),
                      Authorization: (
                        <input
                          type="checkbox"
                          disabled={investigation?.has_been_added === true}
                          className=""
                          checked={investigation.investigation_approval}
                          onChange={(e: any) => {
                            const newInvestigationBody = [...tableRows];
                            newInvestigationBody[idx].investigation_approval =
                              e.target.checked;
                            setTableRows(newInvestigationBody);
                          }}
                        />
                      ),
                      "Cashflow Type": (
                        <div className="form-group mb-3">
                          <select
                            className="form-control plantype"
                            disabled={investigation?.has_been_added === true}
                            name="plan_type"
                            id="mySelection"
                            // placeholder="Select Type"
                            value={investigation.investigation_revenue_type}
                            onChange={(e: any) => {
                              const newInvestigationBody = [...tableRows];
                              newInvestigationBody[
                                idx
                              ].investigation_revenue_type = e.target.value;
                              setTableRows(newInvestigationBody);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {fetchedCashflows?.map(
                              (cashflow: any, idx: number) => (
                                <option
                                  key={idx}
                                  value={
                                    cashflow?.cash_flow_type +
                                    " - " +
                                    cashflow?.percentage +
                                    "%"
                                  }
                                >
                                  {cashflow?.cash_flow_type} -{" "}
                                  {cashflow?.percentage
                                    ? `${cashflow?.percentage}%`
                                    : ""}{" "}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ),
                      Action: (
                        <button
                          className={
                            investigation?.has_been_added === true
                              ? "btn text-primary btn-sm"
                              : "btn text-gray-500 btn-sm cursor-not-allowed"
                          }
                          disabled={investigation?.has_been_added != true}
                          data-toggle="modal"
                          data-target="#EditHspInvestigationModal"
                          onClick={() => {
                            setSelectedItemToEdit(investigation);
                          }}
                        >
                          <i className="fas fa-edit" />
                        </button>
                      ),
                    })
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* save all modal */}
      <SaveAllServicesModal
        selectedCategory={selectedCategory}
        hspName={hspName}
        type={"Investigation"}
        handleSaveAllServices={handleCreateHspInvestigation}
        itemsToSave={includedData?.map((item) => ({
          Code: item.investigation_code,
          "Service Name": item.service_name,
          Included: item.investigation_included ? "Yes" : "No",
          "Std Tariff": formatMoney(item.standard_rate),
          "Agreed Tariff": formatMoney(item.discount_rate),
          "Net Tariff": formatMoney(item.net_rate),
          Authorization: item.investigation_approval ? "Yes" : "No",
          "Cashflow Type": item.investigation_revenue_type,
        }))}
        selector={"saveAllServicesModal-investigation"}
      />
      {/* // edit modal */}
      <EditHspInvestigationModal
        serviceType="Investigation"
        itemToEdit={selectedItemToEdit}
        setItemToEdit={setSelectedItemToEdit}
        fetchedCashflows={fetchedCashflows}
        handleUpdate={handleUpdateInvestigationService}
        loading={isUpdateLoading}
      />
    </>
  );
};

export default InvestigationsAndPricingTab;
