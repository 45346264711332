import React from "react";

interface DeactivateHspModalProps {
  selectedHSP: any;
  handleDeactivateHSP: () => void;
}

function DeactivateHspModal({
  selectedHSP,
  handleDeactivateHSP,
}: DeactivateHspModalProps) {
  return (
    <div
      className="modal fade"
      id="deactivateHSPModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Deactivate HSP
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <p className="text-center text-sm">
                      Are you sure you want to deactivate. <br />
                      deactivating HSP:{" "}
                      <span className="text-red-500">
                        {selectedHSP?.hsp_name} <br />
                      </span>
                    </p>
                    <div className="flex justify-end gap-4 mt-4">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                      >
                        <i className="fas fa-times" />{" "}
                        {<span className="mx-2">Cancel</span>}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                        onClick={handleDeactivateHSP}
                      >
                        <i className="fas fa-check" />{" "}
                        {<span className="mx-2">Deactivate </span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default DeactivateHspModal;
