import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const registerStaff = async (
  token: string | null,
  registerStaffData: FormData
) => {
  let url = `${BASE_URL}/staff/register`;
  try {
    const response = await axios.post(url, registerStaffData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const loginStaff = async (
  token: string | null,
  loginStaffData: {
    email: string;
    password: string;
  }
) => {
  let url = `${BASE_URL}/staff/login`;
  try {
    const response = await axios.post(url, loginStaffData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getStaffDetails = async (token: string, staffId: string) => {
  let url = `${BASE_URL}/staff/details`;
  try {
    const response = await axios.post(
      url,
      { staff_id: staffId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeStaffPassword = async (
  token: string,
  staffData: {
    staff_id: string;
    old_password: string;
    new_password: string;
  }
) => {
  let url = `${BASE_URL}/staff/password/change`;
  try {
    const response = await axios.post(url, staffData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const updateStaffPassword = async (
  token: string,
  updateStaffPasswordData: {
    staff_id: string;
    new_password: string;
    old_password: string;
  }
) => {
  let url = `${BASE_URL}/staff/password/update`;
  try {
    const response = await axios.post(url, updateStaffPasswordData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const updateStaffDetails = async (
  token: string | null,
  updateStaffDetailsData: FormData
) => {
  let url = `${BASE_URL}/staff/edit`;
  try {
    const response = await axios.post(url, updateStaffDetailsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllStaff = async (token: string | null) => {
  let url = `${BASE_URL}/staff/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const getShortStaffList = async (token: string | null) => {
  let url = `${BASE_URL}/staff/short_details`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteStaff = async (token: string | null, staffId: string) => {
  let url = `${BASE_URL}/staff/delete`;
  try {
    const response = await axios.post(
      url,
      {
        staff_id: staffId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// {{base_url}}/staff/bulk_staff_upload
const bulkStaffUpload = async (token: string | null, staffData: FormData) => {
  let url = `${BASE_URL}/staff/bulk_upload`;
  try {
    const response = await axios.post(url, staffData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  registerStaff,
  loginStaff,
  getStaffDetails,
  changeStaffPassword,
  updateStaffPassword,
  updateStaffDetails,
  getAllStaff,
  deleteStaff,
  getShortStaffList,
  bulkStaffUpload,
};
