import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import axios from "axios";
import { Toast } from "primereact/toast";
import setupUserApi from "../../api/setupUserApi";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const AccountSettings = () => {
  //todo: @ desc - get staff_id from location search
  const { search } = useLocation();
  const { staff_id } = queryString.parse(search);
  const toast = useRef<Toast>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [user, setUser] = useState<any>([]);

  //todo: @ desc - this function calls the api to get the staff details
  const handleFetchUserDetails = () => {
    console.log(user);
    setupUserApi
      .getUserDetails(localStorage.getItem("token") || "", {
        user_id: staff_id as string,
      })
      .then((response) => {
        setUser({
          user_id: response?.info?.id || "",
          full_name: response?.info?.full_name || "",
          email: response?.info?.email || "",
          contact: response?.info?.contact || "",
          role: response?.info?.role || "",
          policy_admin: {
            policy_admin_dashboard:
              response?.info?.policy_admin?.policy_admin_dashboard || false,
            policy_admin_staff:
              response?.info?.policy_admin?.policy_admin_staff || false,
            policy_admin_reminders:
              response?.info?.policy_admin?.policy_admin_reminders || false,
            policy_admin_complaints:
              response?.info?.policy_admin?.policy_admin_complaints || false,
            policy_admin_reports:
              response?.info?.policy_admin?.policy_admin_reports || false,
          },
          claims_section: {
            claims_dashboard:
              response?.info?.claims_section?.claims_dashboard || false,
            claims_claim_list:
              response?.info?.claims_section?.claims_claim_list || false,
            claims_med_vetting:
              response?.info?.claims_section?.claims_med_vetting || false,
            claims_refund:
              response?.info?.claims_section?.claims_refund || false,
            claims_reports:
              response?.info?.claims_section?.claims_reports || false,
          },
          manage_hsp: {
            manage_hsp_accredited_hsp:
              response?.info?.manage_hsp?.manage_hsp_accredited_hsp || false,
            manage_hsp_qos_survey:
              response?.info?.manage_hsp?.manage_hsp_qos_survey || false,
            manage_hsp_reports:
              response?.info?.manage_hsp?.manage_hsp_reports || false,
          },
          finance_section: {
            finance_dashboard:
              response?.info?.finance_section?.finance_dashboard || false,
            finance_approved_claims:
              response?.info?.finance_section?.finance_approved_claims || false,
            finance_paid_claims:
              response?.info?.finance_section?.finance_paid_claims || false,
            finance_refund:
              response?.info?.finance_section?.finance_refund || false,
            finance_reports:
              response?.info?.finance_section?.finance_reports || false,
          },
          system_admin: {
            system_admin_manage_scheme:
              response?.info?.system_admin?.system_admin_manage_scheme || false,
            system_admin_setup_services:
              response?.info?.system_admin?.system_admin_setup_services ||
              false,
            system_admin_user_setup:
              response?.info?.system_admin?.system_admin_user_setup || false,
            system_admin_hsp_classification:
              response?.info?.system_admin?.system_admin_hsp_classification ||
              false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        show("error", "Error fetching user details");
      });
  };
  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      handleFetchUserDetails();
    }

    return () => {
      isMounted = true;
    };
  }, [staff_id]);

  // todo: @ desc - this function calls the api to update the staff details
  const handleSubmit = (e: any) => {
    setIsUpdating(true);
    e.preventDefault();
    if (!user) {
      show("error", "No user details found");
      return;
    }

    setupUserApi
      .updateUserDetails(localStorage.getItem("token") || "", {
        user_id: staff_id as string,
        full_name: user.full_name,
        email: user.email,
        contact: user.contact,
        role: user.role,
        policy_admin: {
          policy_admin_dashboard: user?.policy_admin?.policy_admin_dashboard,
          policy_admin_staff: user?.policy_admin?.policy_admin_staff,
          // policy_admin_reminders: user?.policy_admin?.policy_admin_reminders,
          // policy_admin_complaints: user?.policy_admin?.policy_admin_complaints,
          policy_admin_reports: user?.policy_admin?.policy_admin_reports,
        },
        claims_section: {
          claims_dashboard: user?.claims_section?.claims_dashboard,
          claims_claim_list: user?.claims_section?.claims_claim_list,
          claims_med_vetting: user?.claims_section?.claims_med_vetting,
          claims_refund: user?.claims_section?.claims_refund,
          claims_reports: user?.claims_section?.claims_reports,
        },
        manage_hsp: {
          manage_hsp_accredited_hsp:
            user?.manage_hsp?.manage_hsp_accredited_hsp,
          manage_hsp_qos_survey: user?.manage_hsp?.manage_hsp_qos_survey,
          manage_hsp_reports: user?.manage_hsp?.manage_hsp_reports,
        },
        finance_section: {
          finance_dashboard: user?.finance_section?.finance_dashboard,
          finance_approved_claims:
            user?.finance_section?.finance_approved_claims,
          finance_paid_claims: user?.finance_section?.finance_paid_claims,
          finance_refund: user?.finance_section?.finance_refund,
          finance_reports: user?.finance_section?.finance_reports,
        },
        system_admin: {
          system_admin_manage_scheme:
            user?.system_admin?.system_admin_manage_scheme,
          system_admin_setup_services:
            user?.system_admin?.system_admin_setup_services,
          system_admin_user_setup: user?.system_admin?.system_admin_user_setup,
          system_admin_hsp_classification:
            user?.system_admin?.system_admin_hsp_classification,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          show("success", response.message);
          handleFetchUserDetails();
        }
      })
      .catch((err) => {
        console.log(err);
        show("error", "Error updating user");
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  // todo: add toast message
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />

        <Sidebar />

        {user && (
          <div className="page-wrapper">
            <div className="container-fluid">
              <BreadCrumbsComponent
                breadcrumbs={[
                  {
                    title: "Setup",
                    url: "/setup",
                  },
                  {
                    title: "Account Settings",
                    url: "/account-settings",
                  },
                ]}
              />
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="form-body">
                          {/* step one */}
                          <h4 className="text-primary">Profile</h4>
                          <hr />
                          <div className="row my-2">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="name">Name</label>
                                <br />
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="name"
                                  defaultValue={user?.full_name}
                                  onChange={(e) =>
                                    setUser((user: any) => {
                                      return {
                                        ...user,
                                        full_name: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="email">Email</label>
                                <br />
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="email"
                                  defaultValue={user?.email}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="contact"> Contact</label>
                                <br />
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="contact"
                                  name="contact"
                                  id="contact"
                                  defaultValue={user?.contact}
                                  onChange={(e) =>
                                    setUser((user: any) => {
                                      return {
                                        ...user,
                                        contact: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          {user?.role?.toLowerCase() === "super-admin" && (
                            <>
                              <fieldset>
                                <h4 className="text-primary">Authorization</h4>

                                <table className="table " id="dataTable">
                                  <tbody>
                                    <tr>
                                      <td>Policy Admin</td>
                                      <td></td>
                                      <td className="text-center font-8">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Dashboard
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.policy_admin
                                              ?.policy_admin_dashboard
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                policy_admin: {
                                                  ...user?.policy_admin,
                                                  policy_admin_dashboard:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center font-8">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Staff
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.policy_admin
                                              ?.policy_admin_staff
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                policy_admin: {
                                                  ...user?.policy_admin,
                                                  policy_admin_staff:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center font-8">
                                        <h5 style={{ fontSize: "12px" }}>
                                          CRM
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.policy_admin?.policy_admin_crm
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                policy_admin: {
                                                  ...user?.policy_admin,
                                                  policy_admin_crm:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center font-8">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Med Delivery
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.policy_admin
                                              ?.policy_admin_med_delivery
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                policy_admin: {
                                                  ...user?.policy_admin,
                                                  policy_admin_med_delivery:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center font-8">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Authorization
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.policy_admin
                                              ?.policy_admin_authorization
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                policy_admin: {
                                                  ...user?.policy_admin,
                                                  policy_admin_authorization:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center font-8">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Report
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.policy_admin
                                              ?.policy_admin_reports
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                policy_admin: {
                                                  ...user?.policy_admin,
                                                  policy_admin_reports:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>

                                    {/* claims */}
                                    <tr>
                                      <td> Claims</td>
                                      <td></td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Dashboard
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.claims_section
                                              ?.claims_dashboard
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                claims_section: {
                                                  ...user?.claims_section,
                                                  claims_dashboard:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Claim List
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.claims_section
                                              ?.claims_claim_list
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                claims_section: {
                                                  ...user?.claims_section,
                                                  claims_claim_list:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Manual Claims
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.claims_section?.claims_manual
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                claims_section: {
                                                  ...user?.claims_section,
                                                  claims_manual:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Med Vetting
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.claims_section
                                              ?.claims_med_vetting
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                claims_section: {
                                                  ...user?.claims_section,
                                                  claims_med_vetting:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Refund
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.claims_section?.claims_refund
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                claims_section: {
                                                  ...user?.claims_section,
                                                  claims_refund:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Report
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.claims_section?.claims_reports
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                claims_section: {
                                                  ...user?.claims_section,
                                                  claims_reports:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>

                                    {/* manage hsp */}
                                    <tr>
                                      <td> Manage HSP's</td>
                                      <td></td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Accredited HSP
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.manage_hsp
                                              ?.manage_hsp_accredited_hsp
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                manage_hsp: {
                                                  ...user?.manage_hsp,
                                                  manage_hsp_accredited_hsp:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          QOS Survey
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.manage_hsp
                                              ?.manage_hsp_qos_survey
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                manage_hsp: {
                                                  ...user?.manage_hsp,
                                                  manage_hsp_qos_survey:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Report
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.manage_hsp?.manage_hsp_reports
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                manage_hsp: {
                                                  ...user?.manage_hsp,
                                                  manage_hsp_reports:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>

                                    {/* // finance */}
                                    <tr>
                                      <td> Finance</td>
                                      <td></td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Dashboard
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.finance_section
                                              ?.finance_dashboard
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                finance_section: {
                                                  ...user?.finance_section,
                                                  finance_dashboard:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Approved Claims
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.finance_section
                                              ?.finance_approved_claims
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                finance_section: {
                                                  ...user?.finance_section,
                                                  finance_approved_claims:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Paid Claims
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.finance_section
                                              ?.finance_paid_claims
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                finance_section: {
                                                  ...user?.finance_section,
                                                  finance_paid_claims:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Refund
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.finance_section
                                              ?.finance_refund
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                finance_section: {
                                                  ...user?.finance_section,
                                                  finance_refund:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Report
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.finance_section
                                              ?.finance_reports
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                finance_section: {
                                                  ...user?.finance_section,
                                                  finance_reports:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>

                                    {/* system admin */}
                                    <tr>
                                      <td> System Admin</td>
                                      <td></td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Manage Scheme
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.system_admin
                                              ?.system_admin_manage_scheme
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                system_admin: {
                                                  ...user?.system_admin,
                                                  system_admin_manage_scheme:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          Setup Services
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.system_admin
                                              ?.system_admin_setup_services
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                system_admin: {
                                                  ...user?.system_admin,
                                                  system_admin_setup_services:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          User Setup
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.system_admin
                                              ?.system_admin_user_setup
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                system_admin: {
                                                  ...user?.system_admin,
                                                  system_admin_user_setup:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                      <td className="text-center">
                                        <h5 style={{ fontSize: "12px" }}>
                                          HSP Classification
                                        </h5>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            user?.system_admin
                                              ?.system_admin_hsp_classification
                                          }
                                          onChange={(e) =>
                                            setUser((user: any) => {
                                              return {
                                                ...user,
                                                system_admin: {
                                                  ...user?.system_admin,
                                                  system_admin_hsp_classification:
                                                    e.target.checked,
                                                },
                                              };
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </fieldset>
                              <hr />
                              <div className="submit-btn my-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm float-right bg-[#5F76E8]"
                                  onClick={handleSubmit}
                                  disabled={isUpdating}
                                >
                                  {isUpdating ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <i className="fas fa-paper-plane" />{" "}
                                      update
                                    </>
                                  )}
                                </button>
                              </div>
                            </>
                          )}

                          {/* end previous / next buttons */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default AccountSettings;
