// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import axios from "axios";
import { Toast } from "primereact/toast";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const ViewRadiology = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const toast = useRef<Toast>(null);
  const { radiology_id, action_type } = queryString.parse(search);
  const [medication, setMedication] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/details`,
        {
          radiology_id: radiology_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setMedication(res.data.info[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (action_type === "edit") {
      handleFetchRadiologyCategory();
    }
  }, [radiology_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/edit`,
        {
          medication_id: radiology_id,
          medication_code: medication.medication_code,
          medication_name: medication.medication_name,
          medication_category: medication.medication_category,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchRadiologyCategory = () => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/category/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Service",
                  url: "/radiology",
                },
                {
                  title: "Radiology",
                  url: "/radiology",
                },
                {
                  title: `${medication.radiology_name || "Radiology Details"} `,
                  url: "/radiology",
                },
              ]}
            />
            <div className="col-md-8 col-sm-12 offset-md-2 mt-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">Radiology Details</h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Radiology category</td>
                              <td>
                                {/* <input
                                  type="text"
                                  disabled={action_type === "edit" ? false : true}
                                  defaultValue={medication.radiology_category}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      radiology_category: e.target.value,
                                    });
                                  }}
                                /> */}
                                <select
                                  disabled={
                                    action_type === "edit" ? false : true
                                  }
                                  onChange={(e) => {
                                    if (e.target.value === "") {
                                      return;
                                    }
                                    setMedication({
                                      ...medication,
                                      radiology_category: e.target.value,
                                    });
                                  }}
                                  className="form-control"
                                  name=""
                                  id=""
                                >
                                  <option value="">
                                    {medication.radiology_category}
                                  </option>

                                  {categoryData.map((item: any) => (
                                    <option value={item.radiology_category}>
                                      {item.radiology_category}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td>Radiology code</td>
                              <td>
                                <input
                                  type="text"
                                  disabled={
                                    action_type === "edit" ? false : true
                                  }
                                  defaultValue={medication.radiology_code}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Radiology Name</td>
                              <td>
                                <input
                                  type="text"
                                  disabled={
                                    action_type === "edit" ? false : true
                                  }
                                  defaultValue={medication.radiology_name}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      radiology_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>status</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.status}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At</td>
                              <td>
                                <input
                                  type="email"
                                  defaultValue={medication.updated_at}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      {action_type === "edit" ? (
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded-pill bg-[#5F76E8]"
                          >
                            <i className="fa fa-save mr-2"></i>
                            Update
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewRadiology;
