import { useState, Fragment } from "react";
import { Combobox } from "@headlessui/react";
// import { CheckIcon } from '@heroicons/react/20/solid'

type Person = {
  id: number;
  name: string;
};

const people: Person[] = [
  { id: 1, name: "Durward Reynolds" },
  { id: 2, name: "Kenton Towne" },
  { id: 3, name: "Therese Wunsch" },
  { id: 4, name: "Benedict Kessler" },
  { id: 5, name: "Katelyn Rohan" },
];

function CustomComboBox({ placeholder = "Search & Select", disabled = false }) {
  const [selectedPerson, setSelectedPerson] = useState<Person | unknown>(
    undefined
  );
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      value={selectedPerson}
      disabled={disabled}
      onChange={setSelectedPerson}
    >
      <Combobox.Input
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(person: Person) => person.name}
        style={{
          width: "100%",
        }}
        className="form-control"
        placeholder={placeholder}
      />
      <Combobox.Options className={"bg-white z-50 shadow-lg ring-1 p-2"}>
        {filteredPeople.map((person) => (
          /* Use the `active` state to conditionally style the active option. */
          /* Use the `selected` state to conditionally style the selected option. */
          <Combobox.Option key={person.id} value={person} as={Fragment}>
            {({ active, selected }) => (
              <li
                className={`${
                  active ? "bg-blue-500 text-white" : "bg-white text-black"
                }`}
              >
                {selected && <i className="fas fa-check mr-2" />}
                {person.name}
              </li>
            )}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
}

export default CustomComboBox;
