import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import formatMoney from "../../utils/formatMoney";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { StyledSearch } from "../../components/StyledSearch";
import diagnosisApi from "../../api/diagnosisApi";
import closeAnyModal from "../../utils/closeAnyModal";
import BulkUploadModal from "../../components/BulkUploadModal";

const DiagnosisPage = () => {
  const [fetchedDiagnosis, setFetchedDiagnosis] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<any>({});
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [hspId, setHspId] = useState("");
  const [createNewDiagnosisIsLoading, setCreateNewDiagnosisIsLoading] =
    useState(false);
  const [createNewDiagnosisBody, setCreateNewDiagnosisBody] = useState({
    diagnosis_code: "",
    diagnosis_name: "",
    diagnosis_description: "",
  });
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);

  const toast = useRef<Toast>(null);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = fetchedDiagnosis.filter((data: any) => {
      return (
        data?.diagnosis_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.diagnosis_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filteredData);
  };

  const handleFetchDiagnosis = () => {
    setFetchIsLoading(true);
    diagnosisApi
      .getAllDiagnosis(sessionStorage.getItem("token") || "")
      .then((res) => {
        setFetchIsLoading(false);
        if (res.status === 200) {
          setFetchedDiagnosis(res.info);
        } else {
          show("error", res.message);
        }
      });
  };

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      show("error", "Please select a file");
      return;
    }
    const token = sessionStorage.getItem("token");
    const formData = new FormData();

    setBulkUploading(true);
    formData.append("excel_file", bulkUploadBody);
    diagnosisApi
      .bulkDiagnosisUpload(sessionStorage.getItem("token") || "", formData)
      .then((res) => {
        if (res.status === 200) {
          show("success", res.message);
          setBulkUploading(false);
          closeAnyModal("diagnosisBulkUploadModal");
          setBulkUploadBody(null);
          handleFetchDiagnosis();
        } else {
          show("error", res.message);
          setBulkUploading(false);
        }
      })
      .catch((error) => {
        show("error", error.message);
      });
  };

  const handleCreateNewDiagnosis = (e: any) => {
    e.preventDefault();
    if (!createNewDiagnosisBody.diagnosis_code) {
      show("error", "Please enter diagnosis code");
      return;
    }
    if (!createNewDiagnosisBody.diagnosis_name) {
      show("error", "Please enter diagnosis name");
      return;
    }
    if (!createNewDiagnosisBody.diagnosis_description) {
      show("error", "Please enter diagnosis description");
      return;
    }
    setCreateNewDiagnosisIsLoading(true);
    diagnosisApi
      .createNewDiagnosis(
        sessionStorage.getItem("token") || "",
        createNewDiagnosisBody
      )
      .then((res) => {
        setCreateNewDiagnosisIsLoading(false);
        if (res.status === 200) {
          show("success", res.message);
          handleFetchDiagnosis();
          closeAnyModal("addDiagnosisModal");
          setCreateNewDiagnosisBody({
            diagnosis_code: "",
            diagnosis_name: "",
            diagnosis_description: "",
          });
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        setCreateNewDiagnosisIsLoading(false);
        show("error", error.message);
      });
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedDiagnosis;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedDiagnosis]);

  useEffect(() => {
    handleFetchDiagnosis();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "System Admin",
                  url: "/system-admin",
                },
                {
                  title: "Diagnosis",
                  url: "/diagnosis",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="flex justify-between col-md-12 mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Diagnosis
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by ICD code & Diagnosis name"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                          <button
                            className="btn btn-primary bg-primary rounded-pill"
                            data-toggle="modal"
                            data-target="#addDiagnosisModal"
                          >
                            Add Diagnosis
                          </button>
                          <button
                            className="btn btn-primary bg-primary rounded-pill"
                            data-toggle="modal"
                            data-target="#diagnosisBulkUploadModal"
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "S/N",
                          "ICD Code",
                          "Diagnosis Name",
                          "Actions",
                        ]}
                        emptyTableMessage="No Diagnosis found"
                        loading={fetchIsLoading}
                        tableRows={
                          tableRows.length > 0
                            ? tableRows.map((row: any, index: number) => ({
                                "S/N": index + 1,
                                "ICD Code": row.diagnosis_code,
                                "Diagnosis Name": row.diagnosis_name,
                                Actions: (
                                  <div className="flex gap-2">
                                    {/* <i className="fas fa-edit text-primary" /> */}
                                    {/* <i className="fas fa-trash text-primary" /> */}
                                  </div>
                                ),
                              }))
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------ADD DIAGNOSIS MODAL-------- */}
      <div
        className="modal fade"
        id="addDiagnosisModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Diagnosis
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="card shadow">
                    <div className="card-body">
                      <form onSubmit={handleCreateNewDiagnosis}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="icdCode">ICD Code</label>
                              <input
                                type="text"
                                className="form-control"
                                id="icdCode"
                                placeholder="Enter ICD Code"
                                value={createNewDiagnosisBody.diagnosis_code}
                                onChange={(e) =>
                                  setCreateNewDiagnosisBody({
                                    ...createNewDiagnosisBody,
                                    diagnosis_code: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="diagnosisName">
                                Diagnosis Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="diagnosisName"
                                placeholder="Enter Diagnosis Name"
                                value={createNewDiagnosisBody.diagnosis_name}
                                onChange={(e) =>
                                  setCreateNewDiagnosisBody({
                                    ...createNewDiagnosisBody,
                                    diagnosis_name: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="diagnosisDescription">
                                Diagnosis Description
                              </label>
                              <textarea
                                className="form-control"
                                id="diagnosisDescription"
                                placeholder="Enter Diagnosis Description"
                                value={
                                  createNewDiagnosisBody.diagnosis_description
                                }
                                onChange={(e) =>
                                  setCreateNewDiagnosisBody({
                                    ...createNewDiagnosisBody,
                                    diagnosis_description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer">
                      <input
                        disabled={createNewDiagnosisIsLoading}
                        type="submit"
                        className="
                          btn btn-primary bg-primary
                          rounded-pill
                          float-end
                        "
                        onClick={
                          createNewDiagnosisIsLoading
                            ? () => {}
                            : handleCreateNewDiagnosis
                        }
                        value={
                          createNewDiagnosisIsLoading
                            ? "Please wait..."
                            : "Add Diagnosis"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------EDIT DIAGNOSIS MODAL-------- */}
      </div>

      {/* -------------- BULK UPLOAD MODAL */}
      <BulkUploadModal
        title="Diagnosis"
        selector="diagnosisBulkUploadModal"
        setBulkUploadBody={setBulkUploadBody}
        handleBulkUpload={handleBulkUpload}
        loading={bulkUploading}
      />
    </>
  );
};

export default DiagnosisPage;
