import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import { Toast } from "primereact/toast";
import companySetupApi from "../../api/companySetupApi";
import closeAnyModal from "../../utils/closeAnyModal";

const CompanySetup = () => {
  const toast = useRef<Toast>(null);

  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [fetchedListOfCompanies, setFetchedListOfCompanies] = useState<any[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<File | null>(null);
  const [createNewCompanyBody, setCreateNewCompanyBody] = useState<any>({
    companyName: "",
    contactPersonName: "",
    companyEmail: "",
    companyPhone: "",
    companyWebsite: "",
    contractValidityStartDate: "",
    contractValidityEndDate: "",
  });

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleSubmit = (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (
      !createNewCompanyBody.companyName ||
      !createNewCompanyBody.contactPersonName ||
      !createNewCompanyBody.companyEmail ||
      !createNewCompanyBody.companyPhone ||
      !createNewCompanyBody.companyWebsite ||
      !createNewCompanyBody.contractValidityStartDate ||
      !createNewCompanyBody.contractValidityEndDate
    ) {
      show("error", "Please fill all fields");
      return;
    }
    const formData = new FormData();
    formData.append("company_logo", companyLogo as File);
    formData.append("company_name", createNewCompanyBody.companyName);
    formData.append(
      "contact_person_name",
      createNewCompanyBody.contactPersonName
    );
    formData.append("company_email", createNewCompanyBody.companyEmail);
    formData.append("company_telephone", createNewCompanyBody.companyPhone);
    formData.append("company_website", createNewCompanyBody.companyWebsite);
    formData.append(
      "contract_validity_start_date",
      createNewCompanyBody.contractValidityStartDate
    );

    formData.append(
      "contract_validity_end_date_date",
      createNewCompanyBody.contractValidityEndDate
    );
    companySetupApi
      .createCompanyProfile(sessionStorage.getItem("token") || "", formData)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          show("success", response.message);
          closeAnyModal("addNewCompanyModal");
          handleFetchCompanies();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleUpdateCompanyDetails = (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (
      !selectedCompany.company_name ||
      !selectedCompany.contact_person_name ||
      !selectedCompany.company_email ||
      !selectedCompany.company_telephone ||
      !selectedCompany.company_website ||
      !selectedCompany.contract_validity_start_date ||
      !selectedCompany.contract_validity_end_date_date
    ) {
      show("error", "Please fill all fields");
      return;
    }
    const formData = new FormData();
    formData.append("company_logo", companyLogo as File);
    formData.append("company_name", selectedCompany.company_name);
    formData.append("contact_person_name", selectedCompany.contact_person_name);
    formData.append("company_email", selectedCompany.company_email);
    formData.append("company_telephone", selectedCompany.company_telephone);
    formData.append("company_website", selectedCompany.company_website);
    formData.append(
      "contract_validity_start_date",
      selectedCompany.contract_validity_start_date
    );

    formData.append(
      "contract_validity_end_date_date",
      selectedCompany.contract_validity_end_date_date
    );
    companySetupApi
      .createCompanyProfile(sessionStorage.getItem("token") || "", formData)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          show("success", response.message);
          closeAnyModal("editCompanyDetailsModal");
          handleFetchCompanies();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // @ desc - delete user
  const handleDelete = (id: string) => {};

  // @ desc - deactivate user
  const handleDeactivate = (id: string) => {};

  const handleFetchCompanies = () => {
    companySetupApi
      .getAllCompanyProfiles(sessionStorage.getItem("token") || "")
      .then((response) => {
        console.log(response);
        setFetchedListOfCompanies(response.info);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFetchCompanies();
  }, []);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            {/* breadcrumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "System Admin",
                  url: "/company-setup",
                },
                {
                  title: "Company Setup",
                  url: "/company-setup",
                },
              ]}
            />
            <div className="page-breadcrumb">
              {/*  Modal content for the above example */}
              <div
                className="modal fade"
                id="addNewCompanyModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myLargeModalLabel">
                        {" "}
                        Add Company
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        ×
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body">
                              <form onSubmit={handleSubmit}>
                                <div className="form-body">
                                  {/* step one */}
                                  <div className="step">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="plan_type">
                                            Logo{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <br />
                                          <input
                                            className="form-control"
                                            type="file"
                                            placeholder="Logo"
                                            name="logo"
                                            id="logo"
                                            accept="image/*"
                                            onChange={(e) => {
                                              if (e.target.files) {
                                                setCompanyLogo(
                                                  e.target.files[0]
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Company Name{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Amount (to the nearest Ghana Cedi)"
                                            name="company-name"
                                            max={100}
                                            min={0}
                                            id="company-name"
                                            value={
                                              createNewCompanyBody.companyName
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                companyName: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Contact Person Name{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <br />
                                          <input
                                            placeholder="Enter contact name"
                                            type="text"
                                            className="form-control"
                                            aria-label="Amount (to the nearest Ghana Cedi)"
                                            name="contact-person-name"
                                            max={100}
                                            min={0}
                                            id="contact-person-name"
                                            value={
                                              createNewCompanyBody.contactPersonName
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                contactPersonName:
                                                  e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Email{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <br />
                                          <input
                                            type="email"
                                            className="form-control"
                                            aria-label="Amount (to the nearest Ghana Cedi)"
                                            name="email"
                                            max={100}
                                            min={0}
                                            id="email"
                                            value={
                                              createNewCompanyBody.companyEmail
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                companyEmail: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Phone
                                          </label>
                                          <br />
                                          <input
                                            type="tel"
                                            className="form-control"
                                            aria-label="0200000000"
                                            name="phonenumber"
                                            max={100}
                                            min={0}
                                            id="phonenumber"
                                            value={
                                              createNewCompanyBody.companyPhone
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                companyPhone: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Website
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="https://example.com"
                                            name="website"
                                            id="website"
                                            value={
                                              createNewCompanyBody.companyWebsite
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                companyWebsite: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Contract Validity (Date From)
                                          </label>
                                          <br />
                                          <input
                                            type="date"
                                            className="form-control"
                                            placeholder="select date"
                                            value={
                                              createNewCompanyBody.contractValidityStartDate
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                contractValidityStartDate:
                                                  e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            {" "}
                                            Contract Validity (Date To)
                                          </label>
                                          <br />
                                          <input
                                            type="date"
                                            className="form-control"
                                            placeholder="select date"
                                            value={
                                              createNewCompanyBody.contractValidityEndDate
                                            }
                                            onChange={(e) => {
                                              setCreateNewCompanyBody({
                                                ...createNewCompanyBody,
                                                contractValidityEndDate:
                                                  e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submit-btn my-2">
                                    <button
                                      type="submit"
                                      onClick={handleSubmit}
                                      className="mt-2 btn btn-primary btn-sm float-right rounded-pill bg-[#5F76E8]"
                                    >
                                      <i className="fas fa-paper-plane" />
                                      {isLoading ? (
                                        <span className="px-2">Loading...</span>
                                      ) : (
                                        <span className="px-2">Add</span>
                                      )}
                                    </button>
                                  </div>
                                  {/* end previous / next buttons */}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7 align-self-center mb-2">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Company Setup
                        </h3>
                      </div>
                      <div className="col-5 align-self-center mb-3">
                        <div className="customize-input float-right">
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#addNewCompanyModal"
                          >
                            <i className="fas fa-plus" /> Add Company
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Company Name",
                          "Contact Person",
                          "Email",
                          "Phone",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          fetchedListOfCompanies &&
                          fetchedListOfCompanies.map(
                            (item: any, index: number) => ({
                              SN: index + 1,
                              "Company Name": item.company_name,
                              contact_person: item.contact_person_name,
                              email: item.company_email,
                              phone: item.company_telephone,
                              status: item.status,
                              action: (
                                <>
                                  <a
                                    className="mx-1"
                                    href={`/view-company-setup?company_id=${item.id}`}
                                  >
                                    <i className="fas fa-eye text-primary" />
                                  </a>
                                  {/* <a
                                    className="mx-1"
                                    href={`/edit-company-setup?company_id=${item.id}`}
                                  >
                                </a> */}
                                  <i
                                    data-toggle="modal"
                                    data-target="#editCompanyDetailsModal"
                                    onClick={() => {
                                      setSelectedCompany(item);
                                    }}
                                    className="fas fa-edit text-primary"
                                  />

                                  {/* <a
                                    className="mx-1"
                                    href="#/"
                                    onClick={() => handleDeactivate(item.id)}
                                  >
                                    <i
                                      className="fas fa-times mr-2 text-primary delplan"
                                      title="Deactivate"
                                    />
                                  </a> */}

                                  {/* <button
                                    type="button"
                                    onClick={() => handleDelete(item.id)}
                                    className="mx-1"
                                  >
                                    <i className="fas fa-trash text-primary delplan" />
                                  </button> */}
                                </>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* EDIT MODAL */}
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="editCompanyDetailsModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Edit Company
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleUpdateCompanyDetails}>
                            <div className="form-body">
                              {/* step one */}
                              <div className="step">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="plan_type">
                                        Logo{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        className="form-control"
                                        type="file"
                                        placeholder="Logo"
                                        name="logo"
                                        id="logo"
                                        accept="image/*"
                                        onChange={(e) => {
                                          if (e.target.files) {
                                            setCompanyLogo(e.target.files[0]);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">
                                        {" "}
                                        Company Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="Amount (to the nearest Ghana Cedi)"
                                        name="company-name"
                                        max={100}
                                        min={0}
                                        id="company-name"
                                        value={selectedCompany.company_name}
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            company_name: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">
                                        {" "}
                                        Contact Person Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        placeholder="Enter contact name"
                                        type="text"
                                        className="form-control"
                                        aria-label="Amount (to the nearest Ghana Cedi)"
                                        name="contact-person-name"
                                        max={100}
                                        min={0}
                                        id="contact-person-name"
                                        value={
                                          selectedCompany.contact_person_name
                                        }
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            contact_person_name: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">
                                        {" "}
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <br />
                                      <input
                                        type="email"
                                        className="form-control"
                                        aria-label="Amount (to the nearest Ghana Cedi)"
                                        name="email"
                                        max={100}
                                        min={0}
                                        id="email"
                                        value={selectedCompany.company_email}
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            company_email: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit"> Phone</label>
                                      <br />
                                      <input
                                        type="tel"
                                        className="form-control"
                                        aria-label="0200000000"
                                        name="phonenumber"
                                        max={100}
                                        min={0}
                                        id="phonenumber"
                                        value={
                                          selectedCompany.company_telephone
                                        }
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            company_telephone: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit"> Website</label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="https://example.com"
                                        name="website"
                                        id="website"
                                        value={selectedCompany.company_website}
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            company_website: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">
                                        {" "}
                                        Contract Validity (Date From)
                                      </label>
                                      <br />
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="select date"
                                        value={
                                          selectedCompany.contract_validity_start_date
                                        }
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            contract_validity_start_date:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">
                                        {" "}
                                        Contract Validity (Date To)
                                      </label>
                                      <br />
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="select date"
                                        value={
                                          selectedCompany.contract_validity_end_date_date
                                        }
                                        onChange={(e) => {
                                          setSelectedCompany({
                                            ...selectedCompany,
                                            contract_validity_end_date_date:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="submit-btn my-2">
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="mt-2 btn btn-primary btn-sm float-right rounded-pill bg-[#5F76E8]"
                                >
                                  <i className="fas fa-paper-plane" />
                                  {isLoading ? (
                                    <span className="px-2">Loading...</span>
                                  ) : (
                                    <span className="px-2">Update</span>
                                  )}
                                </button>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* Dis MODAL */}
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="deactivate-example-modal-lg"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Disable Revenue Type
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form>
                            <h3 className="car-title text-center">
                              Are you sure you want to dis able this revenue
                              type
                            </h3>
                            <div className="btns text-right mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm"
                              >
                                {" "}
                                Proceed
                              </button>
                              <button className="btn btn-danger btn-sm">
                                {" "}
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default CompanySetup;
