import React from "react";
import { CashflowSetupType } from "../../../types/data";
interface EditCashflowModalProps {
  handleSubmit: (e: any) => void;
  selectedCashflow: any | undefined;
  modifiedCashflow: any | undefined;
  setModifiedCashflow: React.Dispatch<
    React.SetStateAction<CashflowSetupType | undefined>
  >;
  isLoading: boolean;
}

function EditCashflowModal({
  handleSubmit,
  selectedCashflow,
  modifiedCashflow,
  setModifiedCashflow,
  isLoading,
}: EditCashflowModalProps) {
  return (
    <div
      className="modal fade"
      id="editCashflowModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Modify Cashflow Type
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-body">
                        {/* step one */}
                        <div className="step">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="plan_type">Cashflow Type</label>
                                <br />
                                <select
                                  className="form-control plantype"
                                  name="plan_type"
                                  id="mySelection"
                                  // placeholder="Select Type"
                                  defaultValue={
                                    selectedCashflow?.revenue_type ||
                                    selectedCashflow?.cashflow_type
                                  }
                                  onChange={(e) => {
                                    if (modifiedCashflow) {
                                      setModifiedCashflow({
                                        ...modifiedCashflow,
                                        revenue_type: e.target.value,
                                        cashflow_type: e.target.value,
                                      });
                                    }
                                  }}
                                >
                                  <option disabled selected>
                                    Select
                                  </option>
                                  <option value="Sponsored" className="generic">
                                    Sponsored
                                  </option>
                                  <option value="Co-payment" className="custom">
                                    Co-payment
                                  </option>
                                  <option
                                    value="Out Of Pocket"
                                    className="custom"
                                  >
                                    Out Of Pocket
                                  </option>
                                  <option
                                    value="Tax Deductions (Withholding)"
                                    className="custom"
                                  >
                                    Tax Deductions (Withholding)
                                  </option>
                                  <option
                                    value="Tax Deductions (Exemptions)"
                                    className="custom"
                                  >
                                    Tax Deductions (Exemptions)
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="benefit"> Applied To</label>
                                <br />
                                <input
                                  type="text"
                                  defaultValue={selectedCashflow?.applied_to}
                                  className="form-control"
                                  name="applied_to"
                                  id="applied_to"
                                  placeholder="Applied To"
                                  onChange={(e) => {
                                    if (modifiedCashflow) {
                                      setModifiedCashflow({
                                        ...modifiedCashflow,
                                        applied_to: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="plan_type">
                                  Percentage {"(%)"}
                                </label>
                                <br />
                                <input
                                  type="number"
                                  defaultValue={selectedCashflow?.percentage}
                                  value={modifiedCashflow?.percentage}
                                  className="form-control"
                                  name="percentage"
                                  id="percentage"
                                  placeholder="Percentage"
                                  onChange={(e) => {
                                    if (modifiedCashflow) {
                                      setModifiedCashflow({
                                        ...modifiedCashflow,
                                        percentage: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end gap-4">
                          <button
                            disabled={isLoading}
                            type="submit"
                            onClick={handleSubmit}
                            className="mt-2 btn bg-primary text-white btn-sm rounded-pill "
                          >
                            {!isLoading ? (
                              <div className="flex gap-2 items-center">
                                <i className="fas fa-paper-plane" /> Update
                              </div>
                            ) : (
                              <div className="flex gap-2  items-center">
                                <i className="fas fa-spinner fa-spin" />
                                Update...
                              </div>
                            )}
                          </button>
                        </div>
                        {/* end previous / next buttons */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default EditCashflowModal;
