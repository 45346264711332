import React from "react";
interface EditMedDeliveryModalProps {
  editClaimCurrentStep: number;
  handleEditClaimPrevious: () => void;
  handleEditClaimNext: () => void;
}
const EditMedDeliveryModal: React.FC<EditMedDeliveryModalProps> = ({
  editClaimCurrentStep,
  handleEditClaimPrevious,
  handleEditClaimNext,
}) => {
  return (
    <div
      className="modal fade"
      id="editClaimModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Edit Delivery Claim
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form id="signUpForm">
                      <div className="form-body">
                        {/* step one */}
                        {editClaimCurrentStep === 1 && (
                          <div className="">
                            <h4 className="card-title py-1">
                              Delivery Details
                            </h4>
                            <hr className="bg-dark" />
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Staff
                                    ID
                                  </label>
                                  <input
                                    disabled={true}
                                    type="text"
                                    className="form-control"
                                    placeholder="Staff ID"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Staff
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    placeholder="Staff Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-map-pin" /> Current
                                    Location
                                  </label>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="Current Location"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-hospital" /> HSP Source
                                  </label>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="HSP Source"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />{" "}
                                    Receiver's Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Receiver's Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />{" "}
                                    Receiver's Contact
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="Receiver's contact"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-clock" /> Estimated
                                    Delivery Time
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Estimated Delivery Time"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-clock" /> Actual
                                    Delivery Time
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Actual Delivery Time"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-file" /> Attach HSP
                                    Request Form
                                  </label>
                                  <input type="file" className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="fa-solid fa-notes-medical" />{" "}
                                    Client's Remarks
                                  </label>
                                  <textarea
                                    className="form-control"
                                    placeholder="Client's Remarks"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* step two */}
                        {editClaimCurrentStep === 2 && (
                          <div>
                            <div className="col-12">
                              <label> Diagnosis</label>

                              <div className="col-12 flex items-end">
                                <div className="col-11">
                                  {[1].map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <select
                                          id=""
                                          name=""
                                          className="form-control col-12 mb-3"
                                          // value={data.diagnosis}
                                          // onChange={handleChange}
                                        >
                                          <option value="Diagnosis 1">
                                            Diagnosis 1
                                          </option>
                                          <option value="Diagnosis 2">
                                            Diagnosis 2
                                          </option>
                                          <option value="Diagnosis 3">
                                            Diagnosis 3
                                          </option>
                                          <option value="Diagnosis 4">
                                            Diagnosis 4
                                          </option>
                                        </select>
                                      </div>
                                    );
                                  })}
                                </div>
                                <button
                                  // onClick={addNewDiagnosis}
                                  type="button"
                                  className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="row col-12 my-4">
                              <h4 className="card-title py-1">
                                Medication Details
                              </h4>
                              <div className="flex col-12">
                                <div className="col-2">
                                  <label>QTY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-5">
                                  <label>Service</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Total Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                  />
                                </div>
                                <div className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center">
                                  +
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* start send buttons */}
                        <div className="form-footer d-flex justify-between">
                          {editClaimCurrentStep != 1 && (
                            <div>
                              <button
                                type="button"
                                className="text-end text-white"
                                id="nextBtn"
                                onClick={handleEditClaimPrevious}
                              >
                                <span>
                                  <i className="fa fa-arrow-left ml-2" />
                                  Previous
                                </span>
                              </button>
                            </div>
                          )}
                          {editClaimCurrentStep === 2 && (
                            <div>
                              <button
                                type="button"
                                className="text-end text-white"
                                id="nextBtn"
                                // onClick={handleNext}
                              >
                                <span>
                                  Save
                                  <i className="fa fa-save ml-2" />
                                </span>
                              </button>
                            </div>
                          )}

                          {editClaimCurrentStep === 1 && (
                            <>
                              <div></div>
                              <div>
                                <button
                                  type="button"
                                  className="text-end text-white"
                                  id="nextBtn"
                                  onClick={handleEditClaimNext}
                                >
                                  <span>
                                    Next
                                    <i className="fa fa-arrow-right ml-2" />
                                  </span>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        {/* end send buttons */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default EditMedDeliveryModal;
