import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspContracts = async (token: string, hspOwnerId: string) => {
  let url = `${BASE_URL}/hsp/contracts/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_contract_owner_id: hspOwnerId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHspContract = async (token: string, contractData: FormData) => {
  let url = `${BASE_URL}/hsp/contracts/`;
  try {
    const response = await axios.post(url, contractData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspContractDetails = async (token: string, contractId: string) => {
  let url = `${BASE_URL}/hsp/contracts/details`;
  try {
    const response = await axios.post(
      url,
      { hsp_contract_id: contractId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspContract = async (token: string, contractId: string) => {
  let url = `${BASE_URL}/hsp/contracts/status-change`;
  try {
    const response = await axios.post(
      url,
      { hsp_contract_id: contractId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspContract = async (token: string, contractId: string) => {
  let url = `${BASE_URL}/hsp/contracts/status-change`;
  try {
    const response = await axios.post(
      url,
      { hsp_contract_id: contractId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const deleteHspContract = async (token: string, contractId: string) => {
  let url = `${BASE_URL}/hsp/contracts/delete`;
  try {
    const response = await axios.post(
      url,
      { hsp_contract_id: contractId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editHspContract = async (token: string, contractData: FormData) => {
  let url = `${BASE_URL}/hsp/contracts/edit`;
  try {
    const response = await axios.post(url, contractData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspContracts,
  createHspContract,
  getHspContractDetails,
  deactivateHspContract,
  activateHspContract,
  editHspContract,
  deleteHspContract,
};
