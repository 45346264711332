import React from "react";
import MasterTableComponent from "../../ui/MasterTableComponent";

interface SaveAllServicesModalProps {
  handleSaveAllServices: () => void;
  type: string;
  hspName: string;
  itemsToSave?: any[];
  selector: string;
  selectedCategory?: any;
}
function SaveAllServicesModal({
  handleSaveAllServices,
  type,
  hspName,
  itemsToSave,
  selector,
  selectedCategory,
}: SaveAllServicesModalProps) {
  return (
    <div
      className="modal fade"
      id={selector}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Save All {type} Services
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <p className="text-center text-lg mb-2">
                      Please confirm saving and inclusion of the following
                      services to <span className="text-red-500">{type} </span>
                      with category:{" "}
                      <span className="text-red-500">
                        {selectedCategory && selectedCategory?.name}{" "}
                      </span>
                      for <span className="text-red-500">{hspName}</span>
                    </p>
                    <MasterTableComponent
                      headers={[
                        "Code",
                        "Service Name",
                        "Included",
                        "Std Tariff	",
                        "Agreed Tariff",
                        "Net Tariff",
                        "Authorization",
                        "Cashflow Type",
                      ]}
                      tableRows={itemsToSave && itemsToSave}
                    />
                    <div className="flex justify-end gap-4 mt-4">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                      >
                        <i className="fas fa-times" />{" "}
                        {<span className="mx-2">Cancel</span>}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                        onClick={handleSaveAllServices}
                      >
                        <i className="fas fa-save" />{" "}
                        {<span className="mx-2">Save </span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default SaveAllServicesModal;
