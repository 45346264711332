import React from "react";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { StyledSearch } from "../StyledSearch";
import { styled } from "@mui/material/styles";

function DeliveryHistoryTable() {
  const headers = [
    "SN",
    <h2>Staff Name{"/"}ID</h2>,
    "Location",
    "HSP Name",
    "Claim No.",
    "Receiver's Name",
    "Delivery Duration",
    "Client's Remarks",
    "Action"
  ];
  const rows = [
    {
      sn: "1",
      staff_name: "Francis Anane",
      staff_id: "GPI011521001",
      location:"Accra",
      hsp_name: "fbghgbfv",
      claim_number: "43girnk",
      receiver_name:"djifkj fdjk",
      delivery_duration: "23:00",
      client_remarks: "sdavbdsfzxs v",
    },
    {
      sn: "2",
      staff_name: "Francis Anane",
      staff_id: "GPI011521001",
      location:"Accra",
      hsp_name: "fbghgbfv",
      claim_number: "43girnk",
      receiver_name:"djifkj fdjk",
      delivery_duration: "23:00",
      client_remarks: "sdavbdsfzxs v",
    },
  ];
  return (
    <>
      <TableContainer component={Paper}>
        <div className="justify-end w-full flex mb-2">
          <StyledSearch />
        </div>
        <Table
          className="table table-sm table-striped table-bordered table-hover display"
          id="default_order"
          aria-label="simple table"
          sx={{ width: "100%" }}
        >
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((staff) => (
              <TableRow key={staff.sn}>
                <TableCell>{staff.sn}</TableCell>
                <TableCell>
                  {staff.staff_name}
                  <br />
                  <span className="text-sm">{"("}{staff.staff_id}{")"}</span>
                </TableCell>
                <TableCell>{staff.location}</TableCell>
                <TableCell>{staff.hsp_name}</TableCell>
                <TableCell>{staff.claim_number}</TableCell>
                <TableCell>{staff.receiver_name}</TableCell>
                <TableCell>{staff.delivery_duration}</TableCell>
                <TableCell>{staff.client_remarks}</TableCell>
                <TableCell>
                  <i
                    className="fas fa-eye text-success delplan mr-2"
                    data-toggle="modal"
                    data-target="#accept-authorization-modal"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex justify-end">
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={5}
            page={0}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default DeliveryHistoryTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5F76E8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
