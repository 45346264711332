import React, { useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import ViewStaffAccordion from "../../components/ViewStaffAccordion";
import { GiMoneyStack } from "react-icons/gi";
import ClaimHistoryModal from "../../components/policy-admin/staff/ClaimHistoryModal";
import StaffApi from "../../api/StaffApi";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import capitalizeText from "../../utils/capitalizeText";
import { ViewDependantData } from "../../types/data";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import formatMoney from "../../utils/formatMoney";

const ViewStaff = () => {
  const [staffDetails, setStaffDetails] = React.useState<any>({});
  const [claimHistory, setClaimHistory] = React.useState<any[]>([
    {
      id: "P1000",
      patient_name: "Francis Anane",
      date_from: "30-JAN-2023",
      date_to: "30-JAN-2023",
      total_cost: "200.00",
      hsp_name: "Bingo Hospital",
    },
  ]);
  const [staffDependants, setStaffDependants] = React.useState<
    ViewDependantData[]
  >([]);
  const fetchStaffDetails = async () => {
    // get staff id from url
    let selectedStaff: any;
    const token = sessionStorage.getItem("token");
    const url = window.location.href;
    const staffId = url.split("=").pop();
    const selectedStaffReq = await StaffApi.getStaffDetails(
      token as string,
      staffId as string
    );
    if (selectedStaffReq?.status === 200) {
      setStaffDetails(selectedStaffReq?.info);
      selectedStaff = selectedStaffReq?.info;
    } else {
      setStaffDetails({});
    }

    let dependant_one = {
      id: selectedStaff?.staff_dependents?.staff_dependents_one_id || "",
      first_name:
        selectedStaff?.staff_dependents?.staff_dependents_one_first_name || "",
      last_name:
        selectedStaff?.staff_dependents?.staff_dependents_one_last_name || "",
      email: selectedStaff?.staff_dependents?.staff_dependents_one_email || "",
      date_of_birth:
        selectedStaff?.staff_dependents?.staff_dependents_one_date_of_birth ||
        "",
      contact:
        selectedStaff?.staff_dependents?.staff_dependents_one_contact || "",
      gender:
        selectedStaff?.staff_dependents?.staff_dependents_one_gender || "",
      relationship:
        selectedStaff?.staff_dependents?.staff_dependents_one_relationship ||
        "",
      ghana_card:
        selectedStaff?.staff_dependents?.staff_dependents_one_ghana_card || "",
    };
    let dependant_two = {
      id: selectedStaff?.staff_dependents?.staff_dependents_two_id || "",
      first_name:
        selectedStaff?.staff_dependents?.staff_dependents_two_first_name || "",
      last_name:
        selectedStaff?.staff_dependents?.staff_dependents_two_last_name || "",
      email: selectedStaff?.staff_dependents?.staff_dependents_two_email || "",
      date_of_birth:
        selectedStaff?.staff_dependents?.staff_dependents_two_date_of_birth ||
        "",
      contact:
        selectedStaff?.staff_dependents?.staff_dependents_two_contact || "",
      gender:
        selectedStaff?.staff_dependents?.staff_dependents_two_gender || "",
      relationship:
        selectedStaff?.staff_dependents?.staff_dependents_two_relationship ||
        "",
      ghana_card:
        selectedStaff?.staff_dependents?.staff_dependents_two_ghana_card || "",
    };

    let dependant_three = {
      id: selectedStaff?.staff_dependents?.staff_dependents_three_id || "",
      first_name:
        selectedStaff?.staff_dependents?.staff_dependents_three_first_name ||
        "",
      last_name:
        selectedStaff?.staff_dependents?.staff_dependents_three_last_name || "",
      email:
        selectedStaff?.staff_dependents?.staff_dependents_three_email || "",
      date_of_birth:
        selectedStaff?.staff_dependents?.staff_dependents_three_date_of_birth ||
        "",
      contact:
        selectedStaff?.staff_dependents?.staff_dependents_three_contact || "",
      gender:
        selectedStaff?.staff_dependents?.staff_dependents_three_gender || "",
      relationship:
        selectedStaff?.staff_dependents?.staff_dependents_three_relationship ||
        "",
      ghana_card:
        selectedStaff?.staff_dependents?.staff_dependents_three_ghana_card ||
        "",
    };
    let dependant_four = {
      id: selectedStaff?.staff_dependents?.staff_dependents_four_id || "",
      first_name:
        selectedStaff?.staff_dependents?.staff_dependents_four_first_name || "",
      last_name:
        selectedStaff?.staff_dependents?.staff_dependents_four_last_name || "",
      email: selectedStaff?.staff_dependents?.staff_dependents_four_email || "",
      date_of_birth:
        selectedStaff?.staff_dependents?.staff_dependents_four_date_of_birth ||
        "",
      contact:
        selectedStaff?.staff_dependents?.staff_dependents_four_contact || "",
      gender:
        selectedStaff?.staff_dependents?.staff_dependents_four_gender || "",
      relationship:
        selectedStaff?.staff_dependents?.staff_dependents_four_relationship ||
        "",
      ghana_card:
        selectedStaff?.staff_dependents?.staff_dependents_four_ghana_card || "",
    };
    let dependant_five = {
      id: selectedStaff?.staff_dependents?.staff_dependents_five_id || "",
      first_name:
        selectedStaff?.staff_dependents?.staff_dependents_five_first_name || "",
      last_name:
        selectedStaff?.staff_dependents?.staff_dependents_five_last_name || "",
      email: selectedStaff?.staff_dependents?.staff_dependents_five_email || "",
      date_of_birth:
        selectedStaff?.staff_dependents?.staff_dependents_five_date_of_birth ||
        "",
      contact:
        selectedStaff?.staff_dependents?.staff_dependents_five_contact || "",
      gender:
        selectedStaff?.staff_dependents?.staff_dependents_five_gender || "",
      relationship:
        selectedStaff?.staff_dependents?.staff_dependents_five_relationship ||
        "",
      ghana_card:
        selectedStaff?.staff_dependents?.staff_dependents_five_ghana_card || "",
    };
    let dependant_six = {
      id: selectedStaff?.staff_dependents?.staff_dependents_six_id || "",
      first_name:
        selectedStaff?.staff_dependents?.staff_dependents_six_first_name || "",
      last_name:
        selectedStaff?.staff_dependents?.staff_dependents_six_last_name || "",
      email: selectedStaff?.staff_dependents?.staff_dependents_six_email || "",
      date_of_birth:
        selectedStaff?.staff_dependents?.staff_dependents_six_date_of_birth ||
        "",
      contact:
        selectedStaff?.staff_dependents?.staff_dependents_six_contact || "",
      gender:
        selectedStaff?.staff_dependents?.staff_dependents_six_gender || "",
      relationship:
        selectedStaff?.staff_dependents?.staff_dependents_six_relationship ||
        "",
      ghana_card:
        selectedStaff?.staff_dependents?.staff_dependents_six_ghana_card || "",
    };
    let dependants = [];
    if (Object.values(dependant_one).some((x) => x !== "")) {
      dependants.push(dependant_one);
    }
    if (Object.values(dependant_two).some((x) => x !== "")) {
      dependants.push(dependant_two);
    }
    if (Object.values(dependant_three).some((x) => x !== "")) {
      dependants.push(dependant_three);
    }
    if (Object.values(dependant_four).some((x) => x !== "")) {
      dependants.push(dependant_four);
    }
    if (Object.values(dependant_five).some((x) => x !== "")) {
      dependants.push(dependant_five);
    }
    if (Object.values(dependant_six).some((x) => x !== "")) {
      dependants.push(dependant_six);
    }
    setStaffDependants(dependants);
  };

  useEffect(() => {
    const fn = async () => {
      await fetchStaffDetails();
    };
    fn();
  }, []);

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Policy Admin",
                  url: "/staff",
                },
                {
                  title: "Staff",
                  url: "/staff",
                },
                {
                  title: `${
                    capitalizeText(staffDetails?.staff_first_name || "") ||
                    "Staff"
                  } ${capitalizeText(
                    staffDetails?.staff_last_name || ""
                  )} Details`,
                  url: "/view-staff",
                },
              ]}
            />
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="card-header text-center mb-2 rounded-pill">
                      <h4 className="text-center">
                        {capitalizeText(staffDetails?.staff_first_name || "") ||
                          "Staff"}{" "}
                        {capitalizeText(staffDetails?.staff_last_name || "")}{" "}
                        Details
                      </h4>
                    </div>
                    <ul className="nav nav-pills bg-nav-pills nav-justified">
                      <li className="nav-item">
                        <a
                          href="#staffbio"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded active"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block"> Staff Bio</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#dependants"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Dependants</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#history"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Claim History{" "}
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#utilization"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Utilization</span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      {/* pane */}
                      <div
                        className="tab-pane process_claim active"
                        id="staffbio"
                      >
                        <form>
                          <fieldset>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                            />
                            <a href="#">
                              <input
                                type="hidden"
                                name="id"
                                defaultValue={796}
                              />
                            </a>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="icon-user" /> First Name
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${capitalizeText(
                                        staffDetails?.staff_first_name || ""
                                      )}`}
                                      id="first_name"
                                      name="first_name"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-user" /> Last Name
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${capitalizeText(
                                        staffDetails?.staff_last_name || ""
                                      )}`}
                                      id="last_name"
                                      name="last_name"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-calendar" /> Date of
                                    Birth
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${
                                        staffDetails?.staff_dob || ""
                                      }`}
                                      id="dob"
                                      name="dob"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-envelope" /> Email
                                  </td>
                                  <td>
                                    <input
                                      type="email"
                                      defaultValue={`${
                                        staffDetails?.staff_email || ""
                                      }`}
                                      id="email"
                                      name="email"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fas fa-id-card" /> Ghana Card
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      id="ghana_card"
                                      name="ghana_card"
                                      className="form-control"
                                      disabled
                                      defaultValue={`${staffDetails?.staff_ghana_card ||''}`}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-phone" /> Contact
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${
                                        staffDetails?.staff_contact || ""
                                      }`}
                                      id="staff_contact"
                                      name="staff_contact"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-user" /> Gender
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${
                                        staffDetails?.staff_gender || ""
                                      }`}
                                      id="gender"
                                      name="gender"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fas fa-id-card" /> Staff ID
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${
                                        staffDetails?.staff_id || ""
                                      }`}
                                      id="staff_id"
                                      name="staff_id"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-map" /> Staff Location
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${
                                        staffDetails?.staff_location || ""
                                      }`}
                                      id="staff_location"
                                      name="staff_location"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="icon-user" /> Staff Category
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue={`${
                                        staffDetails?.staff_category || ""
                                      }`}
                                      id="staff_category"
                                      name="staff_category"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                      {/* pane */}
                      <div className="tab-pane process_claim" id="dependants">
                        {staffDependants?.length === 0 ? (
                          <div className="text-center my-5">
                            No Dependants Available
                          </div>
                        ) : (
                          <ViewStaffAccordion DATA={staffDependants} />
                        )}
                      </div>
                      <div className="tab-pane process_claim" id="history">
                        <div className="card-body">
                          <div className="table-responsive" id="myTable">
                            <div className="flex justify-end gap-4 mb-4">
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="search..."
                                className="border-[2px] rounded-pill p-1"
                              />

                              <select
                                name=""
                                className="border-[2px] rounded-pill p-1"
                                id=""
                              >
                                <option value="">All</option>
                                <option value="">Principal</option>
                                <option value="">Spouse</option>
                                <option value="">Child 1</option>
                                <option value="">Child 2</option>
                                <option value="">Child 3</option>
                                <option value="">Child 4</option>
                                <option value="">Other Dependants</option>
                              </select>
                            </div>
                            <MasterTableComponent
                              headers={[
                                "SN",
                                "Patient Name/ID",
                                "Date From",
                                "Date To",
                                "Total Cost(GHS)",
                                "HSP Name",
                                "Actions",
                              ]}
                              tableRows={claimHistory.map((history, index) => ({
                                sn: index + 1,
                                patient_name_id: (
                                  <a href="viewstafhistory.html">
                                    {history?.patient_name}
                                    <br />
                                    <span className="text-sm">
                                      {"("}
                                      {history?.id}
                                      {")"}
                                    </span>
                                  </a>
                                ),
                                date_from: history?.date_from,
                                date_to: history?.date_to,
                                total_cost: formatMoney(
                                  history?.total_cost || "0"
                                ),
                                hsp_name: history?.hsp_name,
                                actions: (
                                  <button
                                    data-toggle="modal"
                                    data-target="#view-claim-history-modal"
                                    type="button"
                                  >
                                    <i className="fas fa-eye text-primary" />
                                  </button>
                                ),
                              }))}
                            />
                          </div>

                          {/* PDF Modal */}
                          <ClaimHistoryModal />
                        </div>
                      </div>
                      <div className="tab-pane process_claim" id="utilization">
                        <div className="card-body">
                          <div className="flex justify-end gap-4 mb-4">
                            <select
                              name=""
                              className="border-[2px] rounded-pill p-1"
                              id=""
                            >
                              <option value="">All</option>
                              <option value="">Principal</option>
                              <option value="">Spouse</option>
                              <option value="">Child 1</option>
                              <option value="">Child 2</option>
                              <option value="">Child 3</option>
                              <option value="">Child 4</option>
                              <option value="">Other Dependants</option>
                            </select>
                          </div>
                          <div className="card-group gap-2">
                            <div className="card border-right shadow">
                              <div className="card-body">
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                  <div>
                                    <div className="d-inline-flex align-items-center">
                                      <h2 className="text-dark mb-1 font-weight-medium">
                                        GHS 236.00
                                      </h2>
                                      <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none">
                                        +18.33%
                                      </span>
                                    </div>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                      Total Utilization
                                    </h6>
                                  </div>
                                  <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted">
                                      <GiMoneyStack className="text-2xl" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card border-right shadow">
                              <div className="card-body">
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                  <div>
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                      GHS 18,306.00
                                    </h2>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                      OPD Utilization
                                    </h6>
                                  </div>
                                  <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted">
                                      <GiMoneyStack className="text-2xl" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card shadow">
                              <div className="card-body">
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                  <div>
                                    <h2 className="text-dark mb-1 font-weight-medium">
                                      GHS 864.00
                                    </h2>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                      IPD Utilization
                                    </h6>
                                  </div>
                                  <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted">
                                      <GiMoneyStack className="text-2xl" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-group gap-2">
                            <div className="card border-right shadow">
                              <div className="card-body">
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                  <div>
                                    <div className="d-inline-flex align-items-center">
                                      <h2 className="text-dark mb-1 font-weight-medium">
                                        GHS 236.04
                                      </h2>
                                      <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none">
                                        +18.33%
                                      </span>
                                    </div>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                      Total Outstanding
                                    </h6>
                                  </div>
                                  <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted">
                                      <GiMoneyStack className="text-2xl" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card border-right shadow">
                              <div className="card-body">
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                  <div>
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                      <span className="">GHS</span>18,306
                                    </h2>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                      OPD Outstanding
                                    </h6>
                                  </div>
                                  <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted">
                                      <GiMoneyStack className="text-2xl" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card shadow">
                              <div className="card-body">
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                  <div>
                                    <h2 className="text-dark mb-1 font-weight-medium">
                                      GHS 864.00
                                    </h2>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                      IPD Outstanding
                                    </h6>
                                  </div>
                                  <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted">
                                      <GiMoneyStack className="text-2xl" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive" id="myTable">
                            <table
                              id="default_order"
                              className="table table-sm table-striped table-bordered table-hover display"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col">SN</th>
                                  <th scope="col">Patient Name{"/"}ID</th>
                                  <th scope="col">HSP</th>
                                  <th scope="col">Date Attended</th>
                                  <th scope="col">Claim Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <a href="viewstaf.html">
                                      {" "}
                                      Francis Anane
                                      <br />
                                      <span className="text-sm">
                                        {"("}GPI011521001{")"}
                                      </span>
                                    </a>
                                  </td>
                                  <td>Bingo Hospital</td>
                                  <td>19-09-2023</td>
                                  <td>
                                    <span className="badge badge-pill badge-success" />
                                    200
                                  </td>
                                  {/* <td className="justify-content-around d-flex">
                                    <a href="viewstaf.html" type="button">
                                      <i className="fas fa-eye text-primary" />
                                    </a>
                                  </td> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewStaff;
