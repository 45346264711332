import axios from "axios";
import { HspOpticalsType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspOpticals = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/optical/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_owner_id: hspId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const getAllHspOpticalsByCategory = async (
  token: string,
  categoryId: string
) => {
  let url = `${BASE_URL}/hsp/optical/category/`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_optical_category: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHspOptical = async (token: string, opticals: HspOpticalsType[]) => {
  let url = `${BASE_URL}/hsp/optical/`;
  try {
    // remove optical_code from every item in the array
    opticals.forEach((item) => {
      delete item.optical_code;
    });
    const response = await axios.post(
      url,
      { Opticals: opticals },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspOpticalDetails = async (token: string, OpticalId: string) => {
  let url = `${BASE_URL}/hsp/optical/details`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: OpticalId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspOptical = async (token: string, OpticalId: string) => {
  let url = `${BASE_URL}/hsp/optical/status-change`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: OpticalId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspOptical = async (token: string, OpticalId: string) => {
  let url = `${BASE_URL}/hsp/optical/status-change`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: OpticalId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteHspOptical = async (token: string, OpticalId: string) => {
  let url = `${BASE_URL}/hsp/optical/delete`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: OpticalId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// const editHspDentals = async (
//   token: string,
//   OpticalId: string,
//   data: HspOpticalsType
// ) => {
//   try {
//     let url = `${BASE_URL}/hsp_optical/edit_hsp_optical`;
//     const response = await axios.post(
//       url,
//       {
//         ...data,
//         hsp_optical_id: OpticalId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     return error;
//   }
// };

const editHspOptical = async (token: string, data: HspOpticalsType[]) => {
  try {
    let url = `${BASE_URL}/hsp/optical/edit`;
    const response = await axios.post(
      url,
      {
        Opticals: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspOpticals,
  createHspOptical,
  getHspOpticalDetails,
  deactivateHspOptical,
  activateHspOptical,
  deleteHspOptical,
  // editHspDentals,
  getAllHspOpticalsByCategory,
  editHspOptical,
};
