import React, { useState } from "react";
import {
  MedDeliveryType,
  medDevMedicationDetailsType,
} from "../../../types/data";
import CustomSelector from "../../ui/CustomSelector";
import CustomServiceSelector from "../../ui/CustomServiceSelector";
import capitalizeText from "../../../utils/capitalizeText";

interface AddNewMedDeliveryModalProps {
  currentStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
  fetchedDiagnosis: any[];
  createNewDeliveryBody: MedDeliveryType;
  setCreateNewDeliveryBody: React.Dispatch<
    React.SetStateAction<MedDeliveryType>
  >;
  show: (code: string, message: string) => void;
  handleSaveNewMedDelivery: (e: any) => void;
  listOfMedicationServices: any[];
  listOfStaff: any[];
}

const AddNewMedDeliveryModal: React.FC<AddNewMedDeliveryModalProps> = ({
  currentStep,
  handleNext,
  handlePrevious,
  fetchedDiagnosis,
  createNewDeliveryBody,
  setCreateNewDeliveryBody,
  show,
  handleSaveNewMedDelivery,
  listOfMedicationServices,
  listOfStaff,
}) => {
  const [medItems, setMedItems] = useState<medDevMedicationDetailsType[]>([
    {
      medication_quantity: 0,
      medication_service: "",
      medication_unit_cost: 0,
      medication_total_cost: 0,
    },
  ]);
  const [selectedStaff, setSelectedStaff] = useState<any>({});

  const handleAddNewMedDetailItem = () => {
    // before adding new item, check if the last item is empty
    if (
      medItems[medItems.length - 1].medication_service === "" ||
      medItems[medItems.length - 1].medication_quantity === 0
    ) {
      show("info", "Please fill all fields");
      return;
    }
    setMedItems([
      ...medItems,
      {
        medication_quantity: 0,
        medication_service: "",
        medication_unit_cost: 0,
        medication_total_cost: 0,
      },
    ]);
  };

  const [diagnosisItems, setDiagnosisItems] = useState<string[]>([""]);

  const handleAddNewDiagnosisItem = () => {
    // before adding new item, check if the last item is empty
    if (diagnosisItems[diagnosisItems.length - 1] === "") {
      show("info", "Please select a diagnosis");
      return;
    }
    setDiagnosisItems([...diagnosisItems, ""]);
  };

  const handleRemoveNewMedDetailItem = (index: number) => {
    const newMedItems = [...medItems];
    newMedItems.splice(index, 1);
    setMedItems(newMedItems);
    setCreateNewDeliveryBody({
      ...createNewDeliveryBody,
      medication_details: medItems,
    });
  };

  return (
    <div
      className="modal fade"
      id="bs-example-modal-lg"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Add New Delivery
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form id="signUpForm" onSubmit={handleSaveNewMedDelivery}>
                      <div className="form-body">
                        {/* step one */}
                        {currentStep === 1 && (
                          <div className="">
                            <h4 className="card-title py-1">
                              Delivery Details
                              {/* <i
                              className="fas fa-file"
                              id="benefit"
                            /> */}
                            </h4>
                            <hr className="bg-dark" />
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Staff
                                    ID
                                  </label>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Staff ID"
                                    value={createNewDeliveryBody.staff_id}
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        staff_id: e.target.value,
                                      })
                                    }
                                  /> */}
                                  <CustomSelector
                                    options={
                                      listOfStaff?.length <= 0
                                        ? []
                                        : listOfStaff?.map(
                                            (staff: any, idx: number) => {
                                              const full_name =
                                                capitalizeText(
                                                  staff?.staff_first_name || ""
                                                ) +
                                                " " +
                                                capitalizeText(
                                                  staff?.staff_last_name || ""
                                                );
                                              return {
                                                id: staff?.staff_id,
                                                label: full_name,
                                                value: full_name,
                                              };
                                            }
                                          )
                                    }
                                    className="w-full"
                                    placeholder="Dropdown to select staff"
                                    searchPlaceholder="Search staff"
                                    selected={selectedStaff}
                                    setSelected={setSelectedStaff}
                                    selecterFor="med-dev-staff"
                                    handleSetSelected={(
                                      selectedStaffId: string
                                    ) => {
                                      const selectedStaff = listOfStaff.find(
                                        (staff) =>
                                          staff.staff_id === selectedStaffId
                                      );

                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        staff_id: selectedStaffId,
                                        staff_name:
                                          capitalizeText(
                                            selectedStaff?.staff_first_name ||
                                              ""
                                          ) +
                                          " " +
                                          capitalizeText(
                                            selectedStaff?.staff_last_name || ""
                                          ),
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Staff
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    placeholder="Staff Name"
                                    value={createNewDeliveryBody.staff_name}
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        staff_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-map-pin" /> Current
                                    Location
                                  </label>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="Current Location"
                                    id="location"
                                    name="location"
                                    value={createNewDeliveryBody.location}
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        location: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-hospital" /> HSP Source
                                  </label>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="HSP Source"
                                    id="hsp_source"
                                    name="hsp_source"
                                    value={createNewDeliveryBody.hsp_source}
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        hsp_source: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />{" "}
                                    Receiver's Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Receiver's Name"
                                    id="receiver_name"
                                    name="receiver_name"
                                    value={createNewDeliveryBody.receiver_name}
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        receiver_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />{" "}
                                    Receiver's Contact
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="Receiver's contact"
                                    id="receiver_contact"
                                    name="receiver_contact"
                                    value={
                                      createNewDeliveryBody.receiver_contact
                                    }
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        receiver_contact: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-clock" /> Estimated
                                    Delivery Time
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="estimated_delivery_time"
                                    name="estimated_delivery_time"
                                    placeholder="Estimated Delivery Time"
                                    value={
                                      createNewDeliveryBody.estimated_delivery_time
                                    }
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        estimated_delivery_time: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-clock" /> Actual
                                    Delivery Time
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Actual Delivery Time"
                                    id="actual_delivery_time"
                                    name="actual_delivery_time"
                                    value={
                                      createNewDeliveryBody.actual_delivery_time
                                    }
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        actual_delivery_time: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-file" /> Attach HSP
                                    Request Form
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="hsp_request_form"
                                    onChange={(e) => {
                                      if (!e?.target?.files) {
                                        return;
                                      }
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        hsp_request_form: e.target
                                          .files[0] as File,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    <i className="fa-solid fa-notes-medical" />{" "}
                                    Client's Remarks
                                  </label>
                                  <textarea
                                    className="form-control"
                                    placeholder="Client's Remarks"
                                    id="client_remarks_message"
                                    name="client_remarks_message"
                                    value={
                                      createNewDeliveryBody.client_remarks_message
                                    }
                                    onChange={(e) =>
                                      setCreateNewDeliveryBody({
                                        ...createNewDeliveryBody,
                                        client_remarks_message: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* step two */}
                        {currentStep === 2 && (
                          <div>
                            <div className="col-12">
                              <label> Diagnosis</label>

                              <div className="col-12 flex items-end">
                                <div className="col-11">
                                  {diagnosisItems.map((_, index) => {
                                    return (
                                      <div key={index}>
                                        <select
                                          id=""
                                          name=""
                                          className="form-control col-12 mb-3"
                                          value={
                                            createNewDeliveryBody.diagnosis[
                                              index
                                            ]
                                          }
                                          onChange={(e) => {
                                            const newDiagnosis = [
                                              ...createNewDeliveryBody.diagnosis,
                                            ];
                                            newDiagnosis[index] =
                                              e.target.value;
                                            setCreateNewDeliveryBody({
                                              ...createNewDeliveryBody,
                                              diagnosis: newDiagnosis,
                                            });
                                            setDiagnosisItems(newDiagnosis);
                                          }}
                                        >
                                          <option value="Select Diagnosis">
                                            Select Diagnosis
                                          </option>
                                          {fetchedDiagnosis?.length > 0 &&
                                            fetchedDiagnosis.map(
                                              (item, index) => (
                                                <option
                                                  key={index}
                                                  value={
                                                    item.diagnosis_name +
                                                    " - " +
                                                    item.diagnosis_code
                                                  }
                                                >
                                                  {item.diagnosis_name} -{" "}
                                                  {item.diagnosis_code}
                                                </option>
                                              )
                                            )}
                                        </select>
                                      </div>
                                    );
                                  })}
                                </div>
                                <button
                                  onClick={handleAddNewDiagnosisItem}
                                  type="button"
                                  className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="row col-12 my-4">
                              <h4 className="card-title py-1">
                                Medication Details
                              </h4>
                              <div className="">
                                {medItems.map((_, index) => (
                                  <div className="flex ">
                                    <div
                                      className="flex col-12 mb-4"
                                      key={index}
                                    >
                                      <div className="col-2">
                                        <label>
                                          QTY{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          min={0}
                                          className="form-control"
                                          id="medication_quantity"
                                          name="medication_quantity"
                                          placeholder="0"
                                          value={
                                            medItems[index].medication_quantity
                                          }
                                          onChange={(e) => {
                                            const newMedItems = [...medItems];
                                            newMedItems[
                                              index
                                            ].medication_quantity = Number(
                                              e.target.value
                                            );
                                            setMedItems(newMedItems);
                                            setCreateNewDeliveryBody({
                                              ...createNewDeliveryBody,
                                              medication_details: medItems,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-6">
                                        <label>
                                          Service
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <CustomServiceSelector
                                          options={
                                            listOfMedicationServices?.length <=
                                            0
                                              ? []
                                              : listOfMedicationServices?.map(
                                                  (
                                                    medication: any,
                                                    idx: number
                                                  ) => ({
                                                    id: medication?.id,
                                                    label:
                                                      medication?.medication_name +
                                                      " - " +
                                                      medication?.medication_code,
                                                    value:
                                                      medication?.medication_name +
                                                      " - " +
                                                      medication?.medication_code,
                                                  })
                                                )
                                          }
                                          className="w-full"
                                          placeholder="Dropdown to select service"
                                          searchPlaceholder="Search service"
                                          selected={
                                            medItems[index].medication_service
                                          }
                                          handleSetSelected={(
                                            value: string
                                          ) => {
                                            const newMedItems = [...medItems];
                                            newMedItems[
                                              index
                                            ].medication_service = value;
                                            setMedItems(newMedItems);
                                            setCreateNewDeliveryBody({
                                              ...createNewDeliveryBody,
                                              medication_details: medItems,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-2">
                                        <label>Unit Cost</label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="medication_unit_cost"
                                          name="medication_unit_cost"
                                          placeholder="0"
                                          value={
                                            medItems[index].medication_unit_cost
                                          }
                                          onChange={(e) => {
                                            const newMedItems = [...medItems];
                                            newMedItems[
                                              index
                                            ].medication_unit_cost = Number(
                                              e.target.value
                                            );
                                            newMedItems[
                                              index
                                            ].medication_total_cost =
                                              Number(e.target.value) *
                                              Number(
                                                medItems[index]
                                                  .medication_quantity
                                              );
                                            setMedItems(newMedItems);
                                            setCreateNewDeliveryBody({
                                              ...createNewDeliveryBody,
                                              medication_details: medItems,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-2">
                                        <label>Total Cost</label>
                                        <input
                                          type="number"
                                          disabled={true}
                                          id="medication_total_cost"
                                          name="medication_total_cost"
                                          placeholder="0"
                                          className="form-control"
                                          value={
                                            medItems[index]
                                              .medication_total_cost
                                          }
                                          onChange={(e) => {
                                            const newMedItems = [...medItems];
                                            newMedItems[
                                              index
                                            ].medication_total_cost = Number(
                                              e.target.value
                                            );
                                            setMedItems(newMedItems);
                                            setCreateNewDeliveryBody({
                                              ...createNewDeliveryBody,
                                              medication_details: medItems,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="pt-10">
                                      <div
                                        className={`btn btn-primary w-14 rounded-pill h-6 flex items-center justify-center
                                       ${
                                         medItems.length === 1
                                           ? "hidden"
                                           : "block"
                                       }
                                      `}
                                        onClick={() => {
                                          handleRemoveNewMedDetailItem(index);
                                        }}
                                      >
                                        -
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="flex justify-end">
                                  <div
                                    className="btn btn-primary w-14 rounded-pill h-6 flex items-center justify-center"
                                    onClick={handleAddNewMedDetailItem}
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* start send buttons */}
                        <div className="form-footer d-flex justify-between">
                          {currentStep != 1 && (
                            <div>
                              <div
                                className="btn bg-primary rounded-pill text-end text-white"
                                id="nextBtn"
                                onClick={handlePrevious}
                              >
                                <span>
                                  <i className="fa fa-arrow-left ml-2" />
                                  Previous
                                </span>
                              </div>
                            </div>
                          )}
                          {currentStep === 2 && (
                            <div>
                              <input
                                type="submit"
                                className="btn bg-primary rounded-pill text-end text-white"
                                id="nextBtn"
                                value={"Save"}
                              />
                            </div>
                          )}

                          {currentStep === 1 && (
                            <>
                              <div></div>
                              <div>
                                <div
                                  className="btn rounded-pill btn-primary bg-primary text-end text-white"
                                  id="nextBtn"
                                  onClick={handleNext}
                                >
                                  <span>
                                    Next
                                    <i className="fa fa-arrow-right ml-2" />
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/* end send buttons */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default AddNewMedDeliveryModal;
