import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import PaidClaimsTable from "../../../components/tables/PaidClaimsTable";
import PassPaidClaimsModal from "../../../components/paid-claims/PassPaidClaimsModal";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import { StyledSearch } from "../../../components/StyledSearch";
import { Toast } from "primereact/toast";
import MasterTableComponent from "../../../components/ui/MasterTableComponent";
import batchClaimsApi from "../../../api/batchClaimsApi";
import ClaimsApi from "../../../api/ClaimsApi";

const PaidClaims = () => {
  const toast = useRef<Toast>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServiceData, setFilteredServiceData] = useState<any[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<any>({});
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchedPaidClaims, setFetchedPaidClaims] = useState<any[]>([]);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = fetchedPaidClaims?.filter((data: any) => {
      return (
        data?.hsp_name?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.batch_number?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.total_amount?.toLowerCase()?.includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredServiceData : fetchedPaidClaims;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedPaidClaims]);

  const handleFetchAllPaidClaims = () => {
    setIsFetching(true);
    ClaimsApi.getAllPaidClaims(sessionStorage.getItem("token") || "")
      .then((res) => {
        setIsFetching(false);
        if (res.status === 200) {
          setFetchedPaidClaims(res.info);
          // setTableRows(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        show("error", err.message);
      });
  };

  useEffect(() => {
    handleFetchAllPaidClaims();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claims",
                  url: "/paid-claims",
                },
                {
                  title: "Paid Claims",
                  url: "/paid-claims",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="flex justify-between items-center col-md-12 mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Vetted Claims
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by HSP Name, batch number"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                        </div>
                      </div>
                      {/* <PaidClaimsTable /> */}
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "HSP Name",
                          "Batch No.",
                          "No. Claims",
                          "Progress",
                          "Total Amount",
                          "Actions",
                        ]}
                        loading={isFetching}
                        emptyTableMessage="No Paid Claims available"
                        tableRows={
                          tableRows && tableRows.length > 0
                            ? tableRows?.map((paidClaims: any, idx: number) => {
                                return {
                                  SN: idx + 1,
                                  "HSP Name": paidClaims?.hsp_name,
                                  "No. Claims": paidClaims?.batch_number,
                                  "Batch Number": paidClaims?.batch_number,
                                  Progress: paidClaims?.progress,
                                  "Total Amount": paidClaims?.total_amount,
                                  Actions: (
                                    <div className="flex items-center gap-2">
                                      <i
                                        className="fas fa-check text-success delplan mr-2 cursor-pointer"
                                        data-toggle="modal"
                                        data-target="#pass-claim-modal"
                                      />
                                      <a href="/view-paid-batch-claims">
                                        <i className="fas fa-eye text-info cursor-pointer" />
                                      </a>
                                    </div>
                                  ),
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <PassPaidClaimsModal />
          {/* /.modal */}

          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default PaidClaims;
