import { SurgeryType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import { Toast } from "primereact/toast";
import formatDate from "../../utils/formatDate";
import surgeryApi from "../../api/surgeryApi";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { StyledSearch } from "../../components/StyledSearch";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import BulkUploadModal from "../../components/BulkUploadModal";
import closeAnyModal from "../../utils/closeAnyModal";

const Surgery = () => {
  const [category, setCategory] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [categoryData, setCategoryData] = useState<any>([]);
  const toast = useRef<Toast>(null);
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);

  const [surgery_name, setSurgeryName] = useState<string>("");
  const [addNewSurgeryCode, setAddNewSurgeryCode] = useState<string>("");
  const [surgery_category, setSurgeryCategory] = useState<string>("");
  const [surgeryData, setSurgeryData] = useState<any>([]);
  const [surgeryCode, setSurgeryCode] = useState<string>("");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [selectedSurgery, setSelectedSurgery] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [tableRows, setTableRows] = useState<SurgeryType[]>([]);
  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any>({});
  const [selectedCategoryDateAndStatus, setSelectedCategoryDateAndStatus] =
    useState({
      date: "",
      status: "",
    });

  const handleCategory = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/category/`,
        {
          surgery_category: category,
          surgery_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchAllSurgeryCategories();
          setCategory("");
          setInitials("");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data?.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select a file to upload",
        life: 3000,
      });
      return;
    }
    setBulkUploading(true);
    const formData = new FormData();
    formData.append("excel_file", bulkUploadBody);
    surgeryApi
      .bulkSurgeryUpload(sessionStorage.getItem("token") || "", formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res?.message,
            life: 3000,
          });
          setBulkUploading(false);
          handleFetchAllSurgeries();
          closeAnyModal("surgeryBulkUploadModal");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res?.message,
            life: 3000,
          });
          setBulkUploading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setBulkUploading(false);
      });
  };

  const handleAddSurgeries = (e: any) => {
    e.preventDefault();
    console.log(surgery_category, surgery_name, addNewSurgeryCode);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/`,
        {
          surgery_name,
          surgery_category,
          surgery_code: addNewSurgeryCode,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchAllSurgeries();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data?.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchAllSurgeryCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/category/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFetchAllSurgeries = () => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup-services/surgery/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setSurgeryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActivateSurgery = async (surgery_id: string) => {
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    // const response = await surgeryApi.activateSurgery(tk, {
    //   surgery_id: surgery_id,
    // });
    // if (response.status === 200) {
    //   toast.current?.show({
    //     severity: "success",
    //     summary: "Success Message",
    //     detail: response.data?.message,
    //     life: 3000,
    // //   });
    // //   handleFetchAllSurgeries();
    // } else {
    //   toast.current?.show({
    //     severity: "error",
    //     summary: "Error Message",
    //     detail: response.data?.message,
    //     life: 3000,
    //   });
    // }
  };
  const handleDeactivateSurgery = async (surgery_id: string) => {
    // let tk = sessionStorage.getItem("token");
    // if (!tk) {
    //   tk = "";
    // }
    // const response = await surgeryApi.deactivateSurgery(tk, {
    //   surgery_id: surgery_id,
    // });
    // if (response.status === 200) {
    //   toast.current?.show({
    //     severity: "success",
    //     summary: "Success Message",
    //     detail: response.data?.message,
    //     life: 3000,
    //   });
    //   handleFetchAllSurgeries();
    // } else {
    //   toast.current?.show({
    //     severity: "error",
    //     summary: "Error Message",
    //     detail: response.data?.message,
    //     life: 3000,
    //   });
    // }
  };
  const FilterCategoryFromCategories = (categoryId: string) => {
    let temp = categoryData.filter(
      (category: any) => category.id != categoryId
    );
    setCategoryData(temp);
  };

  const handleDeleteSurgery = () => {
    // /surgery/delete_surgery_category
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/delete`,
        {
          surgery_id: selectedSurgery.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchAllSurgeries();
          // close modal and remove backdrop
          document.getElementById("deleteModal")?.classList.remove("show");
          let backdrop = document.getElementsByClassName("modal-backdrop")[0];
          backdrop?.remove();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data?.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateCategory = (categoryId: string) => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/category/edit`,
        {
          surgery_category_id: categoryId,
          surgery_category: category,
          surgery_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setIsEditable(false);
          setCategory("");
          setInitials("");
          handleFetchAllSurgeryCategories();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/surgery/category/delete`,
        {
          surgery_category_id: categoryToDelete.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          FilterCategoryFromCategories(categoryToDelete.id);
          setIsDeleteCategory(false);
          setCategoryToDelete({});
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = surgeryData.filter((data: any) => {
      return (
        data?.surgery_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.surgery_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.surgery_category.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  useEffect(() => {
    handleFetchAllSurgeryCategories();
    handleFetchAllSurgeries();
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredServiceData : surgeryData;
    setTableRows(dataToRender);
  }, [searchQuery, surgeryData]);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Services",
                  url: "/surgery",
                },
                {
                  title: "Surgery",
                  url: "/surgery",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="col-12 row">
                      <div className="col-5 align-self-center mb-3">
                        <h4 className="card-title">Surgery & Procedure</h4>
                      </div>
                      <div className="col-7 align-self-center mb-3">
                        <div className="customize-input float-right">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by Name, Category, Code"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8]  rounded-pill mx-1"
                            data-toggle="modal"
                            data-target="#addSurgeryModal"
                          >
                            <i className="fas fa-tasks" /> Add Surgery
                          </button>
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8]  rounded-pill mx-1"
                            data-toggle="modal"
                            data-target="#addSurgeryCategoryModal"
                          >
                            <i className="fas fa-tasks" /> Set Category
                          </button>
                          <button
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#surgeryBulkUploadModal"
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Code",
                          "Name",
                          "Category",
                          "Date Added",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          tableRows &&
                          tableRows.map(
                            (surgery: SurgeryType, index: number) => ({
                              SN: index + 1,
                              Code: surgery.surgery_code,
                              Name: surgery.surgery_name,
                              Category: surgery.surgery_category,
                              "Date Added": Number(surgery.created_at)
                                ? convertTimestampToDate(
                                    Number(surgery.created_at)
                                  )
                                : formatDate(surgery.created_at),
                              Status: surgery.status,
                              Action: (
                                <div className="flex justify-center gap-2">
                                  <a
                                    className="mx-1"
                                    href={`/view-surgery?surgery_id=${surgery.id}`}
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "selectedSurgery",
                                        JSON.stringify(surgery)
                                      );
                                    }}
                                  >
                                    <i className="fas fa-eye text-primary"></i>
                                  </a>

                                  <a
                                    className="mx-1"
                                    href={`/view-surgery?surgery_id=${surgery.id}`}
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "selectedSurgery",
                                        JSON.stringify(surgery)
                                      );
                                    }}
                                  >
                                    <i className="fas fa-edit text-primary" />
                                  </a>
                                  {surgery.status?.toLowerCase() ===
                                  "active" ? (
                                    <i
                                      className="fas fa-times text-primary"
                                      onClick={async () => {
                                        await handleDeactivateSurgery(
                                          surgery.id
                                        );
                                      }}
                                    />
                                  ) : (
                                    <i
                                      onClick={async () => {
                                        await handleActivateSurgery(surgery.id);
                                      }}
                                      className="fas fa-power-off text-primary"
                                    />
                                  )}

                                  <button
                                    type="button"
                                    data-target="#deleteModal"
                                    data-toggle="modal"
                                    className="mx-1"
                                    onClick={() => {
                                      setSelectedSurgery(surgery);
                                    }}
                                  >
                                    <i className="fas fa-trash text-primary" />
                                  </button>
                                </div>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="addSurgeryCategoryModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Set Surgery Category
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleCategory}>
                            <div className="form-body">
                              {/* step one */}
                              {!isDeleteCategory && (
                                <>
                                  <div className="step">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Category
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="surgery_category"
                                            placeholder="Category Name"
                                            value={category}
                                            onChange={(e) =>
                                              setCategory(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Code Initials{" "}
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="surgery_initials"
                                            placeholder="Code Initials"
                                            value={initials}
                                            onChange={(e) =>
                                              setInitials(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm float-right mb-4 bg-[#5F76E8] rounded-pill"
                                    >
                                      <i className="fas fa-paper-plane" /> Save
                                    </button>
                                  </div>
                                </>
                              )}
                              {isDeleteCategory && (
                                <div className="border border-danger mb-5 p-2">
                                  <p className="text-[16px] font-normal my-2">
                                    Confirm Deletion of <br />
                                    <span className="text-danger">
                                      {categoryToDelete.medication_category}
                                    </span>
                                    <br />
                                    This action cannot be undone.
                                  </p>
                                  <div className="flex justify-end gap-4">
                                    <div
                                      onClick={() => {
                                        setIsDeleteCategory(false);
                                        setCategoryToDelete({});
                                      }}
                                      className="btn btn-danger rounded-pill bg-danger"
                                    >
                                      <i className="fa fa-times mx-1"></i>
                                      Cancel
                                    </div>
                                    <div
                                      className="btn btn-danger rounded-pill bg-danger"
                                      onClick={handleDelete}
                                    >
                                      <i className="fa fa-trash mx-1"></i>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="table-responsive">
                                <div className="col-md-12"></div>
                                <table
                                  id="default_order"
                                  className="table datatable table-striped table-hover table-sm"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Code Initials</th>
                                      <th>Category</th>
                                      <th>Date Added</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  {categoryData && (
                                    <tbody>
                                      {isEditable && (
                                        <tr className="border-2 border-primary">
                                          <td>
                                            <input
                                              defaultValue={initials}
                                              onChange={(e) => {
                                                setInitials(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              defaultValue={category}
                                              onChange={(e) => {
                                                setCategory(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            {Number(
                                              selectedCategoryDateAndStatus.date
                                            )
                                              ? convertTimestampToDate(
                                                  Number(
                                                    selectedCategoryDateAndStatus.date
                                                  )
                                                )
                                              : formatDate(
                                                  selectedCategoryDateAndStatus.date
                                                )}
                                          </td>
                                          <td>
                                            {
                                              selectedCategoryDateAndStatus.status
                                            }
                                          </td>
                                          <td className="align-center">
                                            <span>
                                              {!isLoading ? (
                                                <>
                                                  <i
                                                    onClick={() => {
                                                      handleUpdateCategory(
                                                        selectedCategoryId
                                                      );
                                                    }}
                                                    className="fa fa-save text-primary mx-1"
                                                  ></i>
                                                  <i
                                                    onClick={() => {
                                                      setIsEditable(false);
                                                    }}
                                                    className="fa fa-times text-warning"
                                                  ></i>
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fa fa-spinner fa-spin text-primary"></i>
                                                </>
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      )}
                                      {categoryData.map(
                                        (category: any, index: number) => (
                                          <tr
                                            key={category.id}
                                            className="clickable-row"
                                          >
                                            <td>
                                              {category?.surgery_initials}
                                            </td>
                                            <td>
                                              {" "}
                                              {category.surgery_category}
                                            </td>
                                            <td>
                                              {Number(category.created_at)
                                                ? convertTimestampToDate(
                                                    Number(category.created_at)
                                                  )
                                                : formatDate(
                                                    category.created_at
                                                  )}
                                            </td>
                                            <td>{category?.status}</td>
                                            <td>
                                              <i
                                                className="fas fa-edit text-primary"
                                                onClick={() => {
                                                  setSelectedCategoryDateAndStatus(
                                                    {
                                                      date: category.created_at,
                                                      status: category.status,
                                                    }
                                                  );
                                                  setCategory(
                                                    category.surgery_category
                                                  );
                                                  setInitials(
                                                    category.surgery_initials
                                                  );
                                                  FilterCategoryFromCategories(
                                                    category.id
                                                  );
                                                  setSelectedCategoryId(
                                                    category.id
                                                  );
                                                  setIsEditable(true);
                                                }}
                                              />
                                              <button
                                                type="button"
                                                className="mx-1"
                                              >
                                                <i
                                                  onClick={() => {
                                                    setIsDeleteCategory(true);
                                                    setCategoryToDelete(
                                                      category
                                                    );
                                                  }}
                                                  className="fas fa-trash text-primary delplan"
                                                />
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="bs-example-modal-lg"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Lab Setup
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form>
                            <div className="form-body">
                              {/* step one */}
                              <div className="step">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">Category</label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="Amoun"
                                        defaultValue="Minor Procedure"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">
                                        Code Initials{" "}
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="Amoun"
                                        defaultValue="MP"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="submit-btn">
                                <div className="btn btn-primary btn-sm float-right mb-4">
                                  <i className="fas fa-paper-plane" /> Save
                                </div>
                              </div>
                              <div className="table-responsive">
                                <div className="col-md-12"></div>
                                <table
                                  id="default_order"
                                  className="table datatable table-striped table-hover table-sm"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Code Initials</th>
                                      <th>Category</th>
                                      {/* <th>Subcategory</th> */}
                                      <th>Date Added</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className="clickable-row"
                                      data-href="manageSchemeView.html"
                                    >
                                      <td>1</td>
                                      <td>Aa</td>
                                      {/* <td> Winters</td> */}
                                      <td>Accountant</td>
                                      <td>
                                        <a href="viewStaff.html">
                                          <i className="fas fa-eye text-warning delplan" />
                                        </a>
                                        <a
                                          href="#"
                                          style={{ zIndex: 200 }}
                                          className="editbtn"
                                          data-toggle="modal"
                                          data-target="#bs-example-modal-lg"
                                        >
                                          <i className="fas fa-edit text-success" />
                                        </a>
                                        <a
                                          href="plan/delete/1"
                                          title="Deactivate"
                                        >
                                          <i className="fas fa-trash text-danger delplan" />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          {/* Modal for Delete Surgery */}
          <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Delete Surgery
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p className="text-center text-sm">
                            Are you sure you want to delete. <br />
                            deleting surgery:{" "}
                            <span className="text-red-500">
                              {selectedSurgery?.surgery_name} <br />
                            </span>
                            cannot be reversed.
                          </p>
                          <div className="flex justify-end gap-4 mt-4">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                            >
                              <i className="fas fa-times" />{" "}
                              {<span className="mx-2">Cancel</span>}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                              onClick={handleDeleteSurgery}
                            >
                              <i className="fas fa-trash" />{" "}
                              {<span className="mx-2">Delete </span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          <div
            className="modal fade"
            id="addSurgeryModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Add Surgery
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleAddSurgeries}>
                            <div className="form-body">
                              {/* step one */}
                              <div className="step">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="plan_type">
                                        Category
                                      </label>
                                      <br />
                                      <select
                                        className="form-control plantype"
                                        name="plan_type"
                                        id="plan_type"
                                        onChange={(e) =>
                                          setSurgeryCategory(e.target.value)
                                        }
                                        // placeholder="Select Type"
                                      >
                                        <option disabled selected>
                                          Select
                                        </option>

                                        {categoryData.map(
                                          (category_data: any) => (
                                            <option
                                              key={category_data.id}
                                              value={
                                                category_data.surgery_category
                                              }
                                              className="generic"
                                            >
                                              {category_data.surgery_category}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="surgery_name">
                                        Surgery Name
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="surgery_name"
                                        placeholder="Surgery Name"
                                        value={surgery_name}
                                        onChange={(e) =>
                                          setSurgeryName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="benefit">Code</label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="surgery_code"
                                        placeholder="HGW01"
                                        value={addNewSurgeryCode}
                                        onChange={(e) =>
                                          setAddNewSurgeryCode(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="submit-btn">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                                >
                                  <i className="fas fa-paper-plane" /> Save
                                </button>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          {/* -------------- BULK UPLOAD MODAL */}
          <BulkUploadModal
            title="Surgery"
            selector="surgeryBulkUploadModal"
            setBulkUploadBody={setBulkUploadBody}
            handleBulkUpload={handleBulkUpload}
            loading={bulkUploading}
          />
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Surgery;
