import { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import axios from "axios";
import { ConsultationType } from "../../types/data";
import { StyledSearch } from "../../components/StyledSearch";
import { Toast } from "primereact/toast";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import formatDate from "../../utils/formatDate";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import BulkUploadModal from "../../components/BulkUploadModal";
import consultationApi from "../../api/consultationApi";
import closeAnyModal from "../../utils/closeAnyModal";

const Consultation = () => {
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);

  const [consultation_category, setConsultationCategory] = useState<string>("");
  const [consultation_initials, setConsultationInitials] = useState<string>("");
  const [category, setConsultationName] = useState<string>("");
  const [initials, setCategory] = useState<string>("");
  const [createNewConsultationCode, setCreateNewConsultationCode] =
    useState<string>("");
  const [categoryData, setCategoryData] = useState<any>([]);
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [selectedConsultation, setSelectedConsultation] = useState<any>({});
  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any>({});
  const [consultationData, setConsultationData] = useState<any>([]);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [tableRows, setTableRows] = useState<ConsultationType[]>([]);
  const [selectedCategoryDateAndStatus, setSelectedCategoryDateAndStatus] =
    useState({
      date: "",
      status: "",
    });
  const FilterCategoryFromCategories = (categoryId: string) => {
    let temp = categoryData.filter(
      (category: any) => category.id != categoryId
    );
    setCategoryData(temp);
  };
  const handleCategory = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/category/`,
        {
          consultation_category,
          consultation_initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          handleFetchConsultationCategory();
          toast.current?.show({
            severity: "success",
            summary: "Successful",
            detail: res.data.message,
            life: 3000,
          });
          setCategory("");
          setConsultationName("");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConsultation = (e: any) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/`,
        {
          consultation_name: category,
          consultation_category: initials,
          consultation_code: createNewConsultationCode,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          handleFetchConsultation();
          toast.current?.show({
            severity: "success",
            summary: "Successful",
            detail: res.data.message,
            life: 3000,
          });
          setCategory("");
          setConsultationName("");
          setCreateNewConsultationCode("");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchConsultationCategory = () => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/category/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchConsultation = () => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setConsultationData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleFetchConsultationCategory();
    handleFetchConsultation();
  }, []);

  const handleDactivateConsultation = async (id: string) => {
    const response = await axios.post(
      `${process.env.REACT_APP_Base_url}/setup-services/consultation/status-change`,
      {
        consultation_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (response.data.status === 200) {
      toast.current?.show({
        severity: "success",
        summary: "Successful",
        detail: response.data.message,
        life: 3000,
      });
      handleFetchConsultation();
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: response.data.message,
        life: 3000,
      });
    }
  };
  const handleActivateConsultation = async (id: string) => {
    const response = await axios.post(
      `${process.env.REACT_APP_Base_url}/setup-services/consultation/status-change`,
      {
        consultation_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (response.data.status === 200) {
      toast.current?.show({
        severity: "success",
        summary: "Successful",
        detail: response.data.message,
        life: 3000,
      });
      handleFetchConsultation();
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: response.data.message,
        life: 3000,
      });
    }
  };

  const handleDeleteConsultation = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/delete`,
        {
          consultation_id: selectedConsultation.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("-----------res", res);
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchConsultation();
          // close modal and remove backdrop
          document.getElementById("deleteModal")?.classList.remove("show");
          let backdrop = document.getElementsByClassName("modal-backdrop")[0];
          backdrop?.remove();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data?.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select a file to upload",
        life: 3000,
      });
      return;
    }
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    setBulkUploading(true);

    formData.append("excel_file", bulkUploadBody);
    consultationApi
      .bulkConsultationUpload(sessionStorage.getItem("token") || "", formData)
      .then((res) => {
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res?.message,
            life: 3000,
          });
          setBulkUploading(false);

          closeAnyModal("consultationBulkUploadModal");
          setBulkUploadBody(null);
          handleFetchConsultation();
        } else {
          setBulkUploading(false);

          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res?.message,
            life: 3000,
          });
        }
      })
      .catch((error) => {
        setBulkUploading(false);
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/category/delete`,
        {
          consultation_category_id: categoryToDelete.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          FilterCategoryFromCategories(categoryToDelete.id);
          setIsDeleteCategory(false);
          setCategoryToDelete({});
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  const handleUpdateCategory = (categoryId: string) => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/category/edit`,
        {
          consultation_category_id: categoryId,
          consultation_category: category,
          consultation_initials: consultation_initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setIsEditable(false);
          setCategory("");
          handleFetchConsultationCategory();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = consultationData.filter((data: any) => {
      return (
        data?.consultation_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.consultation_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.consultation_category.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredServiceData : consultationData;
    setTableRows(dataToRender);
  }, [searchQuery, consultationData]);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Service",
                  url: "/consultation",
                },
                {
                  title: "Consult & Admin",
                  url: "/consultation",
                },
              ]}
            />
            <div className="page-breadcrumb">
              <div className="">
                <div className=""></div>
                <div className="">
                  <div className="">
                    {/* modal for add Labs */}
                    <div
                      className="modal fade"
                      id="bs-example"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                              {" "}
                              Add Consultation
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body">
                                    <form onSubmit={handleConsultation}>
                                      <div className="form-body">
                                        {/* step one */}
                                        <div className="step">
                                          <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="plan_type">
                                                  Category
                                                </label>
                                                <br />
                                                <select
                                                  className="form-control plantype"
                                                  name="plan_type"
                                                  id="plan_type"
                                                  onChange={(e) =>
                                                    setCategory(e.target.value)
                                                  }
                                                  // placeholder="Select Type"
                                                >
                                                  <option disabled selected>
                                                    Select
                                                  </option>
                                                  {categoryData.map(
                                                    (category_data: any) => (
                                                      <option
                                                        key={category_data.id}
                                                        value={
                                                          category_data.consultation_category
                                                        }
                                                        className="generic"
                                                      >
                                                        {
                                                          category_data.consultation_category
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="benefit">
                                                  Consultation Name
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  required
                                                  placeholder="Consultation Name"
                                                  onChange={(e) =>
                                                    setConsultationName(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="benefit">
                                                  Code
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Amoun"
                                                  placeholder="code"
                                                  value={
                                                    createNewConsultationCode
                                                  }
                                                  onChange={(e) =>
                                                    setCreateNewConsultationCode(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="submit-btn">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-sm rounded-pill float-right bg-[#5F76E8]"
                                          >
                                            <i className="fas fa-paper-plane" />{" "}
                                            Save
                                          </button>
                                        </div>
                                        {/* end previous / next buttons */}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                    {/*  Modal content for the above example */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="flex justify-between col-md-12 mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Consult & Admin Setup
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by Name, Category, Code"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] mx-1 rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example"
                          >
                            <i className="fas fa-tasks" /> Add Consult & Admin
                          </button>
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] mx-1 rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-tasks" /> Set Category
                          </button>

                          <button
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#consultationBulkUploadModal"
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Code",
                          "Name",
                          "Category",
                          "Date Added",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          tableRows &&
                          tableRows.map(
                            (
                              consultation: ConsultationType,
                              index: number
                            ) => ({
                              SN: index + 1,
                              Code: consultation.consultation_code,
                              Name: consultation.consultation_name,
                              Category: consultation.consultation_category,
                              "Date Added": Number(consultation.created_at)
                                ? convertTimestampToDate(
                                    Number(consultation.created_at)
                                  )
                                : formatDate(consultation.created_at),
                              Status: consultation.status,
                              Action: (
                                <div className="flex gap-2">
                                  <a
                                    className="mx-1"
                                    href={`/view-consultation?consult_id=${consultation.id}`}
                                  >
                                    <i className="fas fa-eye text-primary delplan"></i>
                                  </a>

                                  <a
                                    className="mx-1"
                                    href={`/view-consultation?consult_id=${consultation.id}`}
                                  >
                                    {/* <i
                                        className="fas fa-times mr-2 text-primary delplan"
                                        title="Deactivate"
                                      /> */}
                                    <i className="fas fa-edit text-primary" />
                                  </a>
                                  {consultation.status === "active" ? (
                                    <i
                                      onClick={() => {
                                        handleDactivateConsultation(
                                          consultation.id
                                        );
                                      }}
                                      className="fas fa-times text-primary delplan"
                                    ></i>
                                  ) : (
                                    <i
                                      onClick={() => {
                                        handleActivateConsultation(
                                          consultation.id
                                        );
                                      }}
                                      className="fas fa-power-off text-primary delplan"
                                    ></i>
                                  )}
                                  <button
                                    type="button"
                                    data-target="#deleteModal"
                                    data-toggle="modal"
                                    className="mx-1"
                                    onClick={() => {
                                      setSelectedConsultation(consultation);
                                    }}
                                  >
                                    <i className="fas fa-trash text-primary delplan" />
                                  </button>
                                </div>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="bs-example-modal-lg"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Add Consultation Category
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleCategory}>
                            <div className="form-body">
                              {/* step one */}
                              {!isDeleteCategory && (
                                <>
                                  <div className="step">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Consultation Category
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            required
                                            placeholder="Consultation Category"
                                            onChange={(e) =>
                                              setConsultationCategory(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Consultation Code Initials{" "}
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            required
                                            placeholder="Code Initials"
                                            onChange={(e) =>
                                              setConsultationInitials(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm float-right mb-4 rounded-pill bg-[#5F76E8]"
                                    >
                                      <i className="fas fa-paper-plane" /> Save
                                    </button>
                                  </div>
                                </>
                              )}

                              {isDeleteCategory && (
                                <div className="border border-danger mb-5 p-2">
                                  <p className="text-[16px] font-normal my-2">
                                    Confirm Deletion of <br />
                                    <span className="text-danger">
                                      {categoryToDelete.consultation_category}
                                    </span>
                                    <br />
                                    This action cannot be undone.
                                  </p>
                                  <div className="flex justify-end gap-4">
                                    <div
                                      onClick={() => {
                                        setIsDeleteCategory(false);
                                        setCategoryToDelete({});
                                      }}
                                      className="btn btn-danger rounded-pill bg-danger"
                                    >
                                      <i className="fa fa-times mx-1"></i>
                                      Cancel
                                    </div>
                                    <div
                                      className="btn btn-danger rounded-pill bg-danger"
                                      onClick={handleDelete}
                                    >
                                      <i className="fa fa-trash mx-1"></i>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="table-responsive">
                                <div className="col-md-12"></div>
                                <table
                                  id="default_order"
                                  className="table datatable table-striped table-hover table-sm"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Code Initials</th>
                                      <th>Category</th>
                                      {/* <th>Subcategory</th> */}
                                      <th>Date Added</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  {categoryData && (
                                    <tbody>
                                      {isEditable && (
                                        <tr className="border-2 border-primary">
                                          <td>
                                            <input
                                              defaultValue={
                                                consultation_initials
                                              }
                                              onChange={(e) => {
                                                setConsultationInitials(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              defaultValue={category}
                                              onChange={(e) => {
                                                setConsultationName(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            {Number(
                                              selectedCategoryDateAndStatus.date
                                            )
                                              ? convertTimestampToDate(
                                                  Number(
                                                    selectedCategoryDateAndStatus.date
                                                  )
                                                )
                                              : formatDate(
                                                  selectedCategoryDateAndStatus.date
                                                )}
                                          </td>
                                          <td>
                                            {
                                              selectedCategoryDateAndStatus.status
                                            }
                                          </td>
                                          <td className="align-center">
                                            <span>
                                              {!isLoading ? (
                                                <>
                                                  <i
                                                    onClick={() => {
                                                      handleUpdateCategory(
                                                        selectedCategoryId
                                                      );
                                                    }}
                                                    className="fa fa-save text-primary mx-1"
                                                  ></i>
                                                  <i
                                                    onClick={() => {
                                                      setIsEditable(false);
                                                      handleFetchConsultationCategory();
                                                    }}
                                                    className="fa fa-times text-warning"
                                                  ></i>
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fa fa-spinner fa-spin text-primary"></i>
                                                </>
                                              )}{" "}
                                            </span>
                                          </td>
                                        </tr>
                                      )}
                                      {categoryData.map(
                                        (
                                          category: ConsultationType,
                                          index: number
                                        ) => (
                                          <tr
                                            key={category.id}
                                            className="clickable-row"
                                          >
                                            <td>
                                              {category.consultation_initials}
                                            </td>
                                            <td>
                                              {" "}
                                              {category.consultation_category}
                                            </td>
                                            <td>
                                              {Number(category.created_at)
                                                ? convertTimestampToDate(
                                                    Number(category.created_at)
                                                  )
                                                : formatDate(
                                                    category.created_at
                                                  )}
                                            </td>
                                            <td>{category.status}</td>
                                            <td>
                                              <i
                                                onClick={() => {
                                                  setIsEditable(true);
                                                  setConsultationInitials(
                                                    category.consultation_initials ||
                                                      ""
                                                  );
                                                  setConsultationCategory(
                                                    category.consultation_category ||
                                                      ""
                                                  );
                                                  setConsultationName(
                                                    category.consultation_category
                                                  );
                                                  setSelectedCategoryDateAndStatus(
                                                    {
                                                      date: category.created_at,
                                                      status: category.status,
                                                    }
                                                  );
                                                  setSelectedCategoryId(
                                                    category.id
                                                  );
                                                  FilterCategoryFromCategories(
                                                    category.id
                                                  );
                                                }}
                                                className="fas fa-edit text-primary"
                                              />

                                              <button
                                                type="button"
                                                className="mx-1"
                                                onClick={() => {
                                                  setIsDeleteCategory(true);
                                                  setCategoryToDelete(category);
                                                }}
                                              >
                                                <i className="fas fa-trash text-primary delplan" />
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* Modal for Delete Surgery */}
          <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Delete Surgery
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p className="text-center text-sm">
                            Are you sure you want to delete. <br />
                            deleting surgery:{" "}
                            <span className="text-red-500">
                              {selectedConsultation?.consultation_name} <br />
                            </span>
                            cannot be reversed.
                          </p>
                          <div className="flex justify-end gap-4 mt-4">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                            >
                              <i className="fas fa-times" />{" "}
                              {<span className="mx-2">Cancel</span>}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                              onClick={handleDeleteConsultation}
                            >
                              <i className="fas fa-trash" />{" "}
                              {<span className="mx-2">Delete </span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* -------------- BULK UPLOAD MODAL */}
          <BulkUploadModal
            title="Consultation"
            selector="consultationBulkUploadModal"
            setBulkUploadBody={setBulkUploadBody}
            handleBulkUpload={handleBulkUpload}
            loading={bulkUploading}
          />

          {/*  Modal content for the above example */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Consultation;
