// import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Birthday from "../../components/birthday";
import Stats from "../../components/stats";
import Breadcrumb from "../../components/breadcrumb";
import BarChart from "../../components/barChart";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { useEffect, useRef, useState } from "react";
import formatDate from "../../utils/formatDate";
import { Toast } from "primereact/toast";

const ViewContractDetails = () => {
  const [currentContract, setCurrentContract] = useState<any>({});
  const [currentHsp, setCurrentHsp] = useState<any>({});
  const [currentContractDetails, setCurrentContractDetails] = useState<any>({});
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const curHsp = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}");
    setCurrentHsp(curHsp);
    const curContract = JSON.parse(
      sessionStorage.getItem("selectedContract") || "{}"
    );
    setCurrentContract(curContract);
    console.log(curContract);
  }, []);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const downloadFile = async (url: string, filename: string) => {
    try {
      // get the file extension from the url
      const ext = url.split(".").pop();
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${filename}.${ext}`;
      document.body.appendChild(link); // this is necessary as Firefox doesn't support the click method on an element not in the DOM
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      show("error", "Error downloading file");
    }
  };

  // Usage in your component
  <a
    href="#"
    onClick={() =>
      downloadFile(
        currentContract?.nhia_accreditation_document,
        "NHIA-ACCREDITATION.pdf"
      )
    }
  >
    NHIA-ACCREDITATION.pdf
  </a>;

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Manage HSP",
                  url: "/accredited-hsp",
                },
                {
                  title: "Accredited HSP",
                  url: "/accredited-hsp",
                },
                {
                  title: `${currentHsp?.hsp_name} Information`,
                  url: "/view-hsp",
                },
                {
                  title: "Contract Details",
                  url: "/view-contract-details",
                },
              ]}
            />
            <div className="row mt-4">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="col-md-12 row mt-4">
                      <div className="col-md-6">
                        <label htmlFor="benefit"> Contract Validity</label>
                      </div>
                      <div className="col-md-6 flex gap-x-4">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="contract_validity_start_date"
                          disabled={true}
                          placeholder="MONTH DAY, YEAR"
                          value={formatDate(
                            currentContract?.contract_validity_start_date
                          )}
                        />
                        <input
                          type="text"
                          className="form-control"
                          aria-label="contract_validity_end_date"
                          disabled={true}
                          placeholder="MONTH DAY, YEAR"
                          value={formatDate(
                            currentContract?.contract_validity_end_date
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 row mt-4">
                      <div className="col-md-6">
                        <label htmlFor="applied_tax"> Applied Tax Rate</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          aria-label="applied_tax"
                          disabled={true}
                          placeholder="0.00"
                          value={currentContract?.applied_tax_rate}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12 row mt-4">
                <div className="col-md-6">
                  <label htmlFor="business_reg_no">
                    {" "}
                    Business Registration Number
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    aria-label="business_reg_no"
                    disabled={true}
                    placeholder="0.00"
                    value={currentContract?.applied_tax_rate}
                  />
                </div>
              </div>
              <div className="col-md-12 row mt-4">
                <div className="col-md-6">
                  <label htmlFor="applied_tax"> Applied Tax Rate</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    aria-label="applied_tax"
                    disabled={true}
                    placeholder="0.00"
                    value={currentContract?.applied_tax_rate}
                  />
                </div>
              </div> */}
                    <div className="table-responsive mt-6">
                      <table className="table table-sm display  border">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Verification Date</th>
                            <th scope="col">Verified By</th>
                            <th scope="col">Documents</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentContract?.nhia_accreditation_date != "" &&
                            currentContract?.nhia_accreditation_document !=
                              "" && (
                              <tr>
                                <td>NHIA Accreditation</td>
                                <td>
                                  {formatDate(
                                    currentContract?.nhia_accreditation_date
                                  )}
                                </td>
                                <td>
                                  {
                                    currentContract?.nhia_accreditation_verified_by
                                  }
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.nhia_accreditation_document,
                                      "NHIA Accreditation"
                                    );
                                  }}
                                >
                                  NHIA Accreditation.pdf
                                </td>
                              </tr>
                            )}
                          {currentContract?.officer_in_charge_date != "" &&
                            currentContract?.officer_in_charge_document !=
                              "" && (
                              <tr>
                                <td>Officer In Charge</td>
                                <td>
                                  {formatDate(
                                    currentContract?.officer_in_charge_date
                                  )}
                                </td>
                                <td>
                                  {
                                    currentContract?.officer_in_charge_verified_by
                                  }
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.officer_in_charge_document,
                                      "Officer In Charge Document"
                                    );
                                  }}
                                >
                                  Officer In Charge Document.pdf
                                </td>
                              </tr>
                            )}
                          {currentContract?.tax_exemption_certificate_date !=
                            "" &&
                            currentContract?.tax_exemption_certificate_document !=
                              "" && (
                              <tr>
                                <td>NHIA Accreditation</td>
                                <td>
                                  {formatDate(
                                    currentContract?.tax_exemption_certificate_date
                                  )}
                                </td>
                                <td>
                                  {
                                    currentContract?.tax_exemption_certificate_verified_by
                                  }
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.tax_exemption_certificate_document,
                                      "Tax Exemption Certificate"
                                    );
                                  }}
                                >
                                  Tax Exemption Certificate.pdf
                                </td>
                              </tr>
                            )}
                          {currentContract?.business_registration_date &&
                            currentContract?.business_registration_document !=
                              "" && (
                              <tr>
                                <td>Bussiness Certificate</td>
                                <td>
                                  {formatDate(
                                    currentContract?.business_registration_date
                                  )}
                                </td>
                                <td>
                                  {
                                    currentContract?.business_registration_verified_by
                                  }
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.business_registration_document,
                                      "Business Registration"
                                    );
                                  }}
                                >
                                  Business Registration.pdf
                                </td>
                              </tr>
                            )}
                          {currentContract?.hefra_license_date != "" &&
                            currentContract?.hefra_license_document != "" && (
                              <tr>
                                <td>HEFRA License</td>
                                <td>
                                  {formatDate(
                                    currentContract?.hefra_license_date
                                  )}
                                </td>
                                <td>
                                  {currentContract?.hefra_license_verified_by}
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.hefra_license_document,
                                      "HEFRA License"
                                    );
                                  }}
                                >
                                  HEFRA License.pdf
                                </td>
                              </tr>
                            )}
                          {currentContract?.proof_of_retention_date != "" &&
                            currentContract?.proof_of_retention_document !=
                              "" && (
                              <tr>
                                <td>Proof of Retention</td>
                                <td>
                                  {formatDate(
                                    currentContract?.proof_of_retention_date
                                  )}
                                </td>
                                <td>
                                  {
                                    currentContract?.proof_of_retention_verified_by
                                  }
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.proof_of_retention_document,
                                      "Proof of Retention"
                                    );
                                  }}
                                >
                                  Proof of Retention.pdf
                                </td>
                              </tr>
                            )}
                          {currentContract?.service_level_agreement_date !=
                            "" &&
                            currentContract?.service_level_agreement_document && (
                              <tr>
                                <td>Service Level Agreement </td>
                                <td>
                                  {formatDate(
                                    currentContract?.service_level_agreement_date
                                  )}
                                </td>
                                <td>
                                  {
                                    currentContract?.service_level_agreement_verified_by
                                  }
                                </td>
                                <td
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    downloadFile(
                                      currentContract?.service_level_agreement_document,
                                      "Service Level Agreement"
                                    );
                                  }}
                                >
                                  Service Level Agreement.pdf
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>
      </div>
    </>
  );
};

export default ViewContractDetails;
