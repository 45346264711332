import { CashflowSetupType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import { StyledSearch } from "../../components/StyledSearch";
import cashflowApi from "../../api/cashflowApi";
import { Toast } from "primereact/toast";
import AddNewCashflowModal from "../../components/system-admin/cashflow-components/AddNewCashflowModal";
import EditCashflowModal from "../../components/system-admin/cashflow-components/EditCashflowModal";
import DisableCashflowModal from "../../components/system-admin/cashflow-components/DisableCashflowModal";
import EnableCashflowModal from "../../components/system-admin/cashflow-components/EnableCashflowModal";
import DeleteCashflowModal from "../../components/system-admin/cashflow-components/DeleteCashflowModal";
import closeAnyModal from "../../utils/closeAnyModal";

const CashflowSetup = () => {
  const [selectedCashflow, setSelectedCashflow] = useState<
    CashflowSetupType | undefined
  >(undefined);
  const [modifiedCashflow, setModifiedCashflow] = useState<any | undefined>(
    undefined
  );
  const [cash_flow_type, setCashflowType] = useState<string>("");
  const [applied_to, setAppliedTo] = useState<string>("");
  const [percentage, setPercentage] = useState<number>(0);
  const [cashflow, setCashflow] = useState<CashflowSetupType[]>([]);
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [fetchedCashflows, setFetchedCashflows] = useState<any[]>([]);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!cash_flow_type || !applied_to || !percentage) {
      show("error", "Please fill all fields");
      return;
    }
    setIsLoading(true);
    cashflowApi
      .createCashflow(sessionStorage.getItem("token") || "", {
        cash_flow_type,
        applied_to,
        percentage,
      })
      .then((res) => {
        console.log("res", res.message);
        if (res.status === 200) {
          show("success", res.message);
          closeAnyModal("addNewCashflowModal");
          handleFetchCashflows();
        }
      })
      .catch((err) => {
        show("error", "Failed to create new cashflow");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // @ desc - delete user
  const handleDelete = (e: any) => {
    e.preventDefault();
    if (!selectedCashflow) {
      show("error", "Please select a cashflow to delete");
      return;
    }
    setIsLoading(true);
    cashflowApi
      .deleteCashflow(sessionStorage.getItem("token") || "", {
        cash_flow_id: selectedCashflow?.id || "",
      })
      .then((res) => {
        if (res.status === 200) {
          show("success", res.message);
          handleFetchCashflows();
          closeAnyModal("deleteCashflowModal");
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // @ desc - deactivate user
  const handleDeactivate = (e: any) => {
    e.preventDefault();

    if (!selectedCashflow) {
      show("error", "Please select a cashflow to deactivate");
      return;
    }
    setIsLoading(true);
    cashflowApi
      .deactivateCashflow(sessionStorage.getItem("token") || "", {
        cash_flow_id: selectedCashflow?.id || "",
      })
      .then((res) => {
        if (res.status === 200) {
          show("success", res.message);
          handleFetchCashflows();
          closeAnyModal("disableCashflowModal");
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // @ desc - activate user
  const handleActivate = (e: any) => {
    e.preventDefault();
    if (!selectedCashflow) {
      show("error", "Please select a cashflow to activate");
      return;
    }
    setIsLoading(true);
    cashflowApi
      .activateCashflow(sessionStorage.getItem("token") || "", {
        cash_flow_id: selectedCashflow?.id || "",
      })
      .then((res) => {
        if (res.data.status === 200) {
          show("success", res.data.message);
          handleFetchCashflows();
          closeAnyModal("enableCashflowModal");
        } else {
          show("error", res.data.message);
        }
      })
      .catch((err) => {
        show("error", "Failed to activate cashflow");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // @ desc - fetch all cashflows
  const handleFetchCashflows = () => {
    setIsLoading(true);
    cashflowApi
      .getAllCashflow(sessionStorage.getItem("token") || "")
      .then((res) => {
        // setCashflow(res.data.info);
        console.log("res.data.info", res.info);
        setFetchedCashflows(res.info);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // update cashflow
  const handleUpdateCashflow = (e: any) => {
    e.preventDefault();
    if (!selectedCashflow || !modifiedCashflow) {
      show("error", "Please select a cashflow to update");
      return;
    }
    setIsLoading(true);
    cashflowApi
      .updateCashflow(sessionStorage.getItem("token") || "", {
        cash_flow_id: selectedCashflow?.id,
        cash_flow_type:
          modifiedCashflow?.revenue_type || modifiedCashflow?.cash_flow_type,
        applied_to: modifiedCashflow?.applied_to,
        percentage: modifiedCashflow?.percentage,
      })
      .then((res) => {
        if (res.status === 200) {
          show("success", res.message);
          closeAnyModal("editCashflowModal");
          handleFetchCashflows();
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = fetchedCashflows.filter((data: any) => {
      return (
        data?.cash_flow_type.toLowerCase().includes(query.toLowerCase()) ||
        data?.applied_to.toLowerCase().includes(query.toLowerCase()) ||
        data?.status?.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  useEffect(() => {
    handleFetchCashflows();
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedCashflows;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedCashflows]);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            {/* breadcrumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Schemes",
                  url: "/cashflow-setup",
                },
                {
                  title: "Cashflow Setup",
                  url: "/cashflow-setup",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7 align-self-center mb-2">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Cashflow Setup
                        </h3>
                      </div>
                      <div className="col-5 align-self-center mb-3">
                        <div className="flex justify-end gap-4">
                          <StyledSearch
                            onChange={handleSearchChange}
                            value={searchQuery}
                            placeholder="🔍 by applied to/status/cashflow type"
                          />
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#addNewCashflowModal"
                          >
                            <i className="fas fa-plus" /> Add Cashflow
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Cashflow Type",
                          "Applied To",
                          "Percentage",
                          "Status",
                          "Action",
                        ]}
                        loading={isLoading}
                        emptyTableMessage="No cashflow setup found"
                        tableRows={
                          tableRows &&
                          tableRows.map(
                            (cashflow_data: any, index: number) => ({
                              SN: index + 1,
                              CashflowType: cashflow_data.cash_flow_type,
                              AppliedTo: cashflow_data.applied_to,
                              Percentage: cashflow_data.percentage + "%",
                              Status: cashflow_data.status,
                              Action: (
                                <div className="flex gap-2 items-center">
                                  <a
                                    className="mx-1"
                                    href={`/view-cashflow-setup?cashflow_id=${cashflow_data.id}`}
                                  >
                                    <i className="fas fa-eye text-primary" />
                                  </a>

                                  <i
                                    data-target="#editCashflowModal"
                                    data-toggle="modal"
                                    className="fas fa-edit text-primary"
                                    onClick={() => {
                                      setSelectedCashflow(cashflow_data);
                                      setModifiedCashflow(cashflow_data);
                                    }}
                                  />
                                  {cashflow_data.status === "active" ? (
                                    <i
                                      data-target="#disableCashflowModal"
                                      data-toggle="modal"
                                      onClick={() =>
                                        setSelectedCashflow(cashflow_data)
                                      }
                                      className="fas fa-times text-primary delplan"
                                    />
                                  ) : (
                                    <i
                                      data-target="#enableCashflowModal"
                                      data-toggle="modal"
                                      onClick={() =>
                                        setSelectedCashflow(cashflow_data)
                                      }
                                      className="fas fa-power-off text-primary delplan"
                                    />
                                  )}

                                  <button
                                    data-target="#deleteCashflowModal"
                                    data-toggle="modal"
                                    onClick={() =>
                                      setSelectedCashflow(cashflow_data)
                                    }
                                    className="mx-1"
                                  >
                                    <i className="fas fa-trash text-primary delplan" />
                                  </button>
                                </div>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------MODALS ------------------------- */}
          <AddNewCashflowModal
            setAppliedTo={setAppliedTo}
            setCashflowType={setCashflowType}
            setPercentage={setPercentage}
            percentage={percentage}
            applied_to={applied_to}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />

          <EditCashflowModal
            selectedCashflow={selectedCashflow}
            handleSubmit={handleUpdateCashflow}
            setModifiedCashflow={setModifiedCashflow}
            modifiedCashflow={modifiedCashflow}
            isLoading={isLoading}
          />

          <DisableCashflowModal
            selectedCashflow={selectedCashflow}
            handleSubmit={handleDeactivate}
            isLoading={isLoading}
          />

          <EnableCashflowModal
            selectedCashflow={selectedCashflow}
            handleSubmit={handleActivate}
            isLoading={isLoading}
          />
          <DeleteCashflowModal
            selectedCashflow={selectedCashflow}
            handleSubmit={handleDelete}
            isLoading={isLoading}
          />

          {/* ----------------------- end modal ---------------- */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default CashflowSetup;
