import React, { useEffect, useState } from "react";
import CustomComboBox from "../ui/CustomComboBox";
import CustomSelector from "../ui/CustomSelector";
import batchClaimsApi from "../../api/batchClaimsApi";
import {
  OptionsType,
  PatientType,
  TrimmedStaffType,
  batchesType,
} from "../../types/data";
import StaffApi from "../../api/StaffApi";
import calculateAgeFromDoB from "../../utils/calculateAgeFromDoB";
import diagnosisApi from "../../api/diagnosisApi";
import consultationApi from "../../api/consultationApi";
import CustomServiceSelector from "../ui/CustomServiceSelector";
import surgeryApi from "../../api/surgeryApi";
import medicationApi from "../../api/medicationApi";
import dentalApi from "../../api/dentalApi";
import OpticalApi from "../../api/OpticalApi";
import radiologyApi from "../../api/radiologyApi";
import labsApi from "../../api/labsApi";
import ClaimsApi from "../../api/ClaimsApi";
import closeAnyModal from "../../utils/closeAnyModal";
import hspMedsAndConsumablesApi from "../../api/hspMedsAndConsumablesApi";
import hspSurgeriesApi from "../../api/hspSurgeriesApi";
import hspDentalsApi from "../../api/hspDentalsApi";
import hspOpticalApi from "../../api/hspOpticalApi";
import hspInvestigationsApi from "../../api/hspInvestigationsApi";
import hspConsultationApi from "../../api/hspConsultationApi";

interface AddNewClaimsModalProps {
  fetchedBatches: batchesType[];
  show: (code: string, message: string) => void;
}

const AddNewClaimsModal: React.FC<AddNewClaimsModalProps> = ({
  show,
  fetchedBatches,
}) => {
  const [hspId, setHspId] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedBatch, setSelectedBatch] = useState<OptionsType | null>(null);
  const [allDetailsAboutSelectedBatch, setAllDetailsAboutSelectedBatch] =
    useState<any>(null);
  const [fetchedDiagnosis, setFetchedDiagnosis] = useState<any[]>([]);
  const [fetchedConsultations, setFetchedConsultations] = useState<any[]>([]);
  const [fetchedInvestigations, setFetchedInvestigations] = useState<any[]>([]);
  const [fetchedSurgeryAndProcedures, setFetchedSurgeryAndProcedures] =
    useState<any[]>([]);
  const [
    fetchedAdmissionWardAccommodation,
    setFetchedAdmissionWardAccommodation,
  ] = useState<any[]>([]);
  const [fetchedMedications, setFetchedMedications] = useState<any[]>([]);
  const [fetchedDentals, setFetchedDentals] = useState<any[]>([]);
  const [fetchedOpticals, setFetchedOpticals] = useState<any[]>([]);
  const [initialDiagnosis, setInitialDiagnosis] = useState([""]);
  const [finalDiagnosis, setFinalDiagnosis] = useState([""]);
  const [attachments, setAttachments] = useState<File[] | null>([]);
  const [loading, setLoading] = useState(false);
  const [listOfStaff, setListOfStaff] = useState<PatientType[] | null>([]);
  const [selectedPatient, setSelectedPatient] = useState<OptionsType | null>(
    null
  );
  const [selectedPatientDetails, setSelectedPatientDetails] =
    useState<PatientType | null>(null);
  const [dateAttended, setDateAttended] = useState({
    start_date: "",
    end_date: "",
  });
  const [generalClinic, setGeneralClinic] = useState({
    opd: false,
    ipd: false,
    maternity_anc: false,
    maternity_pnc: false,
  });
  const [specialClinic, setSpecialClinic] = useState({
    dental: false,
    obs_gyne: false,
    ent: false,
    optical: false,
    dermatology: false,
    urology: false,
    cardio: false,
    pediatrics: false,
    psychiatry: false,
    neurology: false,
    oncology: false,
  });
  const [consultations, setConsultations] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);
  const [investigations, setInvestigations] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);
  const [surgeryAndProcedures, setSurgeryAndProcedures] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);
  const [admissions, setAdmissions] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);
  const [medications, setMedications] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);
  const [dentals, setDentals] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);
  const [opticals, setOpticals] = useState([
    {
      quantity: 0,
      service: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);

  const handleAddNewInitialDiagnosis = () => {
    if (initialDiagnosis[initialDiagnosis.length - 1] === "") {
      show("error", "Please fill the last diagnosis field");
      return;
    }
    setInitialDiagnosis([...initialDiagnosis, ""]);
  };
  const handleAddNewFinalDiagnosis = () => {
    if (finalDiagnosis[finalDiagnosis.length - 1] === "") {
      show("error", "Please fill the last diagnosis field");
      return;
    }
    setFinalDiagnosis([...finalDiagnosis, ""]);
  };

  const handleAddNewConsultation = () => {
    if (consultations[consultations.length - 1].service === "") {
      show("error", "Please fill the last consultation field");
      return;
    }
    setConsultations([
      ...consultations,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };
  const handleAddNewInvestigations = () => {
    if (investigations[investigations.length - 1].service === "") {
      show("error", "Please fill the last investigation field");
      return;
    }
    setInvestigations([
      ...investigations,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };
  const handleAddNewSurgeryAndProcedures = () => {
    if (surgeryAndProcedures[surgeryAndProcedures.length - 1].service === "") {
      show("error", "Please fill the last surgery and procedures field");
      return;
    }
    setSurgeryAndProcedures([
      ...surgeryAndProcedures,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };
  const handleAddNewAdmissionWardAccommodation = () => {
    if (admissions[admissions.length - 1].service === "") {
      show("error", "Please fill the last admission ward accommodation field");
      return;
    }
    setAdmissions([
      ...admissions,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };
  const handleAddNewMedications = () => {
    if (medications[medications.length - 1].service === "") {
      show("error", "Please fill the last medications field");
      return;
    }
    setMedications([
      ...medications,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };
  const handleAddNewDentals = () => {
    if (dentals[dentals.length - 1].service === "") {
      show("error", "Please fill the last dentals field");
      return;
    }
    setDentals([
      ...dentals,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };
  const handleAddNewOpticals = () => {
    if (opticals[opticals.length - 1].service === "") {
      show("error", "Please fill the last opticals field");
      return;
    }
    setOpticals([
      ...opticals,
      {
        quantity: 0,
        service: "",
        unit_cost: "",
        total_cost: "",
      },
    ]);
  };

  const handlePrevious = (e: any) => {
    e.preventDefault();

    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    if (currentStep === 1 && !selectedBatch) {
      return 1;
    }
    if (currentStep === 5) {
      return 5;
    }
    setCurrentStep(currentStep + 1);
  };

  const fetchStaff = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const response = await StaffApi.getShortStaffList(token || "");
    if (response?.status === 200) {
      setLoading(false);
      setListOfStaff(response?.info);
    }
    setLoading(false);
  };

  const fetchDiagnosis = () => {
    diagnosisApi
      .getAllDiagnosis(sessionStorage.getItem("token") || "")
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedDiagnosis(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching diagnosis");
      });
  };

  const fetchConsultations = () => {
    const hsp = fetchedBatches.find(
      (item) => item.batch_number === selectedBatch?.value
    );
    if (!hsp) return;
    console.log("batch details", hsp);
    setAllDetailsAboutSelectedBatch(hsp);
    setHspId(hsp.accredited_hsp_id);
    hspConsultationApi
      .getAllHspConsultations(
        sessionStorage.getItem("token") || "",
        hsp.accredited_hsp_id
      )
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedConsultations(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching consultations");
      });
  };

  const fetchInvestigations = () => {
    const hsp = fetchedBatches.find(
      (item) => item.batch_number === selectedBatch?.value
    );
    if (!hsp) return;
    console.log("batch details", hsp);

    setAllDetailsAboutSelectedBatch(hsp);
    setHspId(hsp.accredited_hsp_id);
    // let tempInvestigations: any[] = [];
    hspInvestigationsApi
      .getAllHspInvestigations(
        sessionStorage.getItem("token") || "",
        hsp.accredited_hsp_id
      )
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedInvestigations(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching radiology");
      });

    // labsApi
    //   .getAllLabs(sessionStorage.getItem("token") || "")
    //   .then((res: any) => {
    //     if (res.status === 200) {
    //       tempInvestigations = [...tempInvestigations, ...res.info];
    //     } else {
    //       show("error", res.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     show("error", "Error fetching labs");
    //   });

    // setFetchedInvestigations(
    //   tempInvestigations.map((item) => {
    //     return {
    //       id: item.id,
    //       investigation_name: item.radiology_name
    //         ? item.radiology_name
    //         : item.lab_name,
    //       // value: item.service_name ? item.service_name : item.lab_name,
    //     };
    //   })
    // );
  };
  const fetchSurgeryAndProcedure = () => {
    const hsp = fetchedBatches.find(
      (item) => item.batch_number === selectedBatch?.value
    );
    if (!hsp) return;
    console.log("batch details", hsp);

    setAllDetailsAboutSelectedBatch(hsp);
    setHspId(hsp.accredited_hsp_id);
    hspSurgeriesApi
      .getAllHspSurgeryAndProcedures(
        sessionStorage.getItem("token") || "",
        hsp.accredited_hsp_id
      )
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedSurgeryAndProcedures(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching surgery and procedures");
      });
  };
  const fetchAccomodations = () => {};
  const fetchMedications = () => {
    const hsp = fetchedBatches.find(
      (item) => item.batch_number === selectedBatch?.value
    );
    if (!hsp) return;
    console.log("batch details", hsp);

    setAllDetailsAboutSelectedBatch(hsp);
    setHspId(hsp.accredited_hsp_id);
    hspMedsAndConsumablesApi
      .getAllHspMedsAndConsumables(
        sessionStorage.getItem("token") || "",
        hsp.accredited_hsp_id
      )
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedMedications(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching medications");
      });
  };

  const fetchDentals = () => {
    const hsp = fetchedBatches.find(
      (item) => item.batch_number === selectedBatch?.value
    );
    if (!hsp) return;
    console.log("batch details", hsp);
    setAllDetailsAboutSelectedBatch(hsp);
    setHspId(hsp.accredited_hsp_id);
    hspDentalsApi
      .getAllHspDentals(
        sessionStorage.getItem("token") || "",
        hsp.accredited_hsp_id
      )
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedDentals(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching dentals");
      });
  };
  const fetchOpticals = () => {
    const hsp = fetchedBatches.find(
      (item) => item.batch_number === selectedBatch?.value
    );
    if (!hsp) return;
    console.log("batch details", hsp);
    setAllDetailsAboutSelectedBatch(hsp);
    setHspId(hsp.accredited_hsp_id);
    hspOpticalApi
      .getAllHspOpticals(
        sessionStorage.getItem("token") || "",
        hsp.accredited_hsp_id
      )
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedOpticals(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        show("error", "Error fetching opticals");
      });
  };

  const handleSave = async () => {
    if (!selectedBatch) {
      show("error", "Please select a batch");
      return;
    }
    if (!selectedPatient) {
      show("error", "Please select a patient");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("batch_id", selectedBatch?.id || "");
    formData.append("staff_or_dependents_id", selectedPatient?.id || "");
    formData.append("patient_name", selectedPatientDetails?.patient_name || "");
    formData.append(
      "claims_relationship",
      selectedPatientDetails?.patient_relationship || ""
    );
    // formData.append(
    //   "claims_age",
    //   String(selectedPatientDetails?.patient_age) || "1"
    // );

    formData.append(
      "claims_dob",
      String(selectedPatientDetails?.patient_dob || "01/01/1999") || ""
    );

    formData.append("claims_sex", selectedPatientDetails?.patient_sex || "");
    formData.append("claims_date_attended_start", dateAttended.start_date);
    formData.append("claims_date_attended_end", dateAttended.end_date);
    formData.append("general_clinic_opd", String(generalClinic.opd || false));
    formData.append("general_clinic_ipd", String(generalClinic.ipd || false));
    formData.append(
      "general_clinic_maternity_anc",
      String(generalClinic.maternity_anc || false)
    );
    formData.append(
      "general_clinic_maternity_pnc",
      String(generalClinic.maternity_pnc || false)
    );
    formData.append(
      "special_clinic_dental",
      String(specialClinic.dental || false)
    );
    formData.append(
      "special_clinic_obs_g",
      String(specialClinic.obs_gyne || false)
    );
    formData.append("special_clinic_ent", String(specialClinic.ent || false));
    formData.append(
      "special_clinic_optical",
      String(specialClinic.optical || false)
    );
    formData.append(
      "special_clinic_dermatology",
      String(specialClinic.dermatology || false)
    );
    formData.append(
      "special_clinic_urology",
      String(specialClinic.urology || false)
    );
    initialDiagnosis.forEach((item, index) => {
      formData.append(`initial_diagnosis[${index}]`, item);
    });
    finalDiagnosis.forEach((item, index) => {
      formData.append(`final_diagnosis[${index}]`, item);
    });
    consultations.forEach((item, index) => {
      formData.append(
        `consultations[${index}][quantity]`,
        `${item.quantity}` || "0"
      );
      formData.append(`consultations[${index}][service]`, item.service);
      formData.append(`consultations[${index}][unit_cost]`, item.unit_cost);
      formData.append(`consultations[${index}][total_cost]`, item.total_cost);
    });
    investigations.forEach((item, index) => {
      formData.append(
        `investigations[${index}][quantity]`,
        `${item.quantity}` || "0"
      );
      formData.append(`investigations[${index}][service]`, item.service);
      formData.append(`investigations[${index}][unit_cost]`, item.unit_cost);
      formData.append(`investigations[${index}][total_cost]`, item.total_cost);
    });
    surgeryAndProcedures.forEach((item, index) => {
      formData.append(
        `surgery_and_procedures[${index}][quantity]`,
        `${item.quantity}` || "0"
      );
      formData.append(
        `surgery_and_procedures[${index}][service]`,
        item.service
      );
      formData.append(
        `surgery_and_procedures[${index}][unit_cost]`,
        item.unit_cost
      );
      formData.append(
        `surgery_and_procedures[${index}][total_cost]`,
        item.total_cost
      );
    });
    medications.forEach((item, index) => {
      formData.append(
        `medications[${index}][quantity]`,
        `${item.quantity}` || "0"
      );
      formData.append(`medications[${index}][service]`, item.service);
      formData.append(`medications[${index}][unit_cost]`, item.unit_cost);
      formData.append(`medications[${index}][total_cost]`, item.total_cost);
    });
    dentals.forEach((item, index) => {
      formData.append(`dentals[${index}][quantity]`, `${item.quantity}` || "0");
      formData.append(`dentals[${index}][service]`, item.service);
      formData.append(`dentals[${index}][unit_cost]`, item.unit_cost);
      formData.append(`dentals[${index}][total_cost]`, item.total_cost);
    });
    opticals.forEach((item, index) => {
      formData.append(
        `optical[${index}][quantity]`,
        `${item.quantity}` || "0"
      );
      formData.append(`optical[${index}][service]`, item.service);
      formData.append(`optical[${index}][unit_cost]`, item.unit_cost);
      formData.append(`optical[${index}][total_cost]`, item.total_cost);
    });
    if (attachments) {
      attachments.forEach((file) => {
        formData.append("attachments", file);
      });
    }
    // formData.append("is_manual_claims", "true");

    const response = await ClaimsApi.addNewManualClaim(
      sessionStorage.getItem("token") || "",
      formData
    );
    setLoading(false);
    if (response?.status === 200) {
      show("success", response.message);
      closeAnyModal("add-new-claims-modal");
    } else {
      show("error", response.message);
    }
  };

  useEffect(() => {
    fetchStaff();
    fetchDiagnosis();
    fetchConsultations();
    fetchInvestigations();
    fetchSurgeryAndProcedure();
    fetchAccomodations();
    fetchMedications();
    fetchDentals();
    fetchOpticals();
  }, [selectedBatch]);

  useEffect(() => {
    if (!listOfStaff) return;
    if (selectedPatient) {
      let selectedPatientDets = listOfStaff.find(
        (item) => item.patient_id === selectedPatient.id
      );
      if (!selectedPatientDets) return;
      setSelectedPatientDetails({
        ...selectedPatientDets,
        patient_age: calculateAgeFromDoB(selectedPatientDets?.patient_dob || ""),
      });
    }
  }, [selectedPatient]);

  return (
    <div
      className="modal fade"
      id="add-new-claims-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg h-[90vh] overflow-y-scroll">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {currentStep === 1
                ? "Batch Claims"
                : currentStep === 2
                ? "Patient Details"
                : currentStep === 3
                ? "Clinic & Diagnosis"
                : currentStep === 4
                ? "Treatment & Service Costing"
                : currentStep === 5
                ? "Attachments"
                : ""}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <form>
                {currentStep === 1 && (
                  <>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <label className="text-xl">Batch </label>
                        <div
                          className="table-responsive  h-[20vh]"
                          id="myTable"
                        >
                          <CustomSelector
                            placeholder="Dropdown to select Batch"
                            searchPlaceholder="Search Batch"
                            options={fetchedBatches.map(
                              (batch: batchesType) => ({
                                id: batch.id,
                                label: `${batch.batch_number || "-"}`,
                                value: batch.batch_number,
                              })
                            )}
                            selected={selectedBatch}
                            setSelected={setSelectedBatch}
                            // setSelected={(selected) => {
                            //   const hsp = fetchedBatches.find(
                            //     (item) =>
                            //       item.batch_number === selectedBatch?.value
                            //   );
                            //   setAllDetailsAboutSelectedBatch(hsp);
                            //   setHspId(hsp?.id || "");
                            //   setSelectedBatch(selected);
                            // }}
                          />
                        </div>{" "}
                      </div>
                    </div>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label>Patient ID</label>
                        <CustomSelector
                          placeholder="Dropdown to select Patient"
                          searchPlaceholder="Search Patient"
                          options={
                            listOfStaff
                              ? listOfStaff.map((patient: PatientType) => ({
                                  id: patient.patient_id,
                                  label: `${patient.patient_id} - ${patient.patient_name}`,
                                  value: `${patient.patient_id} - ${patient.patient_name}`,
                                }))
                              : []
                          }
                          selected={selectedPatient}
                          setSelected={setSelectedPatient}
                        />
                      </div>

                      <div className="col-12 mb-3">
                        <label>Patient Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Patient Name"
                          disabled={true}
                          value={`${
                            selectedPatientDetails?.patient_name || ""
                          }`}
                          onChange={() => {}}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Relationship</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="relationship"
                          disabled={true}
                          onChange={() => {}}
                          value={
                            selectedPatientDetails?.patient_relationship || ""
                          }
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Age</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="34 years"
                          disabled={true}
                          onChange={() => {}}
                          value={selectedPatientDetails?.patient_age || ""}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Sex</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Male/Female"
                          disabled={true}
                          onChange={() => {}}
                          value={selectedPatientDetails?.patient_sex || ""}
                        />
                      </div>
                    </div>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <div className="row">
                      <div className="col-6 mb-3">
                        <label>Date Attended</label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          value={dateAttended.start_date}
                          onChange={(e) =>
                            setDateAttended({
                              ...dateAttended,
                              start_date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <label> </label>
                        <input
                          type="datetime-local"
                          className="form-control mt-2"
                          value={dateAttended.end_date}
                          onChange={(e) =>
                            setDateAttended({
                              ...dateAttended,
                              end_date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>General Clinic</label>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={generalClinic.opd}
                            onChange={(e) =>
                              setGeneralClinic({
                                ...generalClinic,
                                opd: e.target.checked,
                              })
                            }
                          />
                          <label>OPD</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={generalClinic.ipd}
                            onChange={(e) =>
                              setGeneralClinic({
                                ...generalClinic,
                                ipd: e.target.checked,
                              })
                            }
                          />
                          <label>IPD</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={generalClinic.maternity_anc}
                            onChange={(e) =>
                              setGeneralClinic({
                                ...generalClinic,
                                maternity_anc: e.target.checked,
                              })
                            }
                          />
                          <label>
                            Maternity{"("}ANC{")"}
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={generalClinic.maternity_pnc}
                            onChange={(e) =>
                              setGeneralClinic({
                                ...generalClinic,
                                maternity_pnc: e.target.checked,
                              })
                            }
                          />
                          <label>
                            Maternity{"("}PNC{")"}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <label>Special Clinic</label>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.dental}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                dental: e.target.checked,
                              })
                            }
                          />
                          <label>Dental</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.obs_gyne}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                obs_gyne: e.target.checked,
                              })
                            }
                          />
                          <label>Obs {"&"} Gyne</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.ent}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                ent: e.target.checked,
                              })
                            }
                          />
                          <label>ENT</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.optical}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                optical: e.target.checked,
                              })
                            }
                          />
                          <label>Optical{"/"}phthalmology</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.dermatology}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                dermatology: e.target.checked,
                              })
                            }
                          />
                          <label>Dermatology</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.urology}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                urology: e.target.checked,
                              })
                            }
                          />
                          <label>Urology</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.cardio}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                cardio: e.target.checked,
                              })
                            }
                          />
                          <label>Cardio</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.pediatrics}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                pediatrics: e.target.checked,
                              })
                            }
                          />
                          <label>Pediatrics</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.psychiatry}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                psychiatry: e.target.checked,
                              })
                            }
                          />
                          <label>Psychiatry{"/"}Psychologist</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.neurology}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                neurology: e.target.checked,
                              })
                            }
                          />
                          <label>Neurology</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            placeholder=""
                            className="mr-2"
                            checked={specialClinic.oncology}
                            onChange={(e) =>
                              setSpecialClinic({
                                ...specialClinic,
                                oncology: e.target.checked,
                              })
                            }
                          />
                          <label>Oncology</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Initial Diagnosis</label>

                        <div className="col-12">
                          {initialDiagnosis.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="col-12 flex items-end gap-4"
                              >
                                <select
                                  id=""
                                  name=""
                                  className="form-control col-11 mb-3"
                                  value={item}
                                  onChange={(e) => {
                                    if (e.target.value === "") {
                                      return;
                                    }
                                    let newDiagnosis = [...initialDiagnosis];
                                    newDiagnosis[index] = e.target.value;
                                    setInitialDiagnosis(newDiagnosis);
                                  }}
                                >
                                  <option value="Select Diagnosis">
                                    Select Diagnosis
                                  </option>
                                  {fetchedDiagnosis?.length > 0 &&
                                    fetchedDiagnosis.map((item, index) => (
                                      <option
                                        key={index}
                                        value={
                                          item.diagnosis_name +
                                          " - " +
                                          item.diagnosis_code
                                        }
                                      >
                                        {item.diagnosis_name} -{" "}
                                        {item.diagnosis_code}
                                      </option>
                                    ))}
                                </select>
                                {index === initialDiagnosis.length - 1 ? (
                                  <button
                                    onClick={handleAddNewInitialDiagnosis}
                                    type="button"
                                    className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                                  >
                                    +
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      let newDiagnosis = [...initialDiagnosis];
                                      newDiagnosis.splice(index, 1);
                                      setInitialDiagnosis(newDiagnosis);
                                    }}
                                    type="button"
                                    className="btn btn-danger text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                                  >
                                    -
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Final Diagnosis</label>

                        <div className="col-12">
                          {finalDiagnosis.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="col-12 flex items-end gap-4"
                              >
                                <select
                                  id=""
                                  name=""
                                  className="form-control col-11 mb-3"
                                  value={item}
                                  onChange={(e) => {
                                    if (e.target.value === "") {
                                      return;
                                    }
                                    let newDiagnosis = [...finalDiagnosis];
                                    newDiagnosis[index] = e.target.value;
                                    setFinalDiagnosis(newDiagnosis);
                                  }}
                                >
                                  <option value="Select Diagnosis">
                                    Select Diagnosis
                                  </option>
                                  {fetchedDiagnosis?.length > 0 &&
                                    fetchedDiagnosis.map((item, index) => (
                                      <option
                                        key={index}
                                        value={
                                          item.diagnosis_name +
                                          " - " +
                                          item.diagnosis_code
                                        }
                                      >
                                        {item.diagnosis_name} -{" "}
                                        {item.diagnosis_code}
                                      </option>
                                    ))}
                                </select>
                                {index === finalDiagnosis.length - 1 ? (
                                  <button
                                    onClick={handleAddNewFinalDiagnosis}
                                    type="button"
                                    className="btn btn-primary text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                                  >
                                    +
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      let newDiagnosis = [...finalDiagnosis];
                                      newDiagnosis.splice(index, 1);
                                      setFinalDiagnosis(newDiagnosis);
                                    }}
                                    type="button"
                                    className="btn btn-danger text-black col-1 h-6 flex items-center justify-center mb-4 rounded-pill"
                                  >
                                    -
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentStep === 4 && (
                  <>
                    <div className="row">
                      {allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                        "hospital/clinic" && (
                        <div className="col-12 mb-4">
                          <label className="text-xl">Consultation</label>
                          {consultations.map((consultation, idx: number) => {
                            return (
                              <div className="flex col-12 mb-3">
                                <div className="col-2">
                                  <label>QTY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={consultation.quantity}
                                    onChange={(e) => {
                                      let newConsultations = [...consultations];
                                      newConsultations[idx].quantity = Number(
                                        e.target.value
                                      );
                                      setConsultations(newConsultations);
                                    }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label>Service</label>
                                  <CustomServiceSelector
                                    options={fetchedConsultations.map(
                                      (fetchedConsultation, id: number) => {
                                        return {
                                          id: fetchedConsultation.id || id,
                                          label:
                                            fetchedConsultation.consultation_name,
                                          value:
                                            fetchedConsultation.consultation_name,
                                        };
                                      }
                                    )}
                                    selected={consultation.service}
                                    handleSetSelected={(service) => {
                                      let newConsultations = [...consultations];
                                      newConsultations[idx].service =
                                        service || "";
                                      // set unit cost to discount rate
                                      newConsultations[idx].unit_cost =
                                        fetchedConsultations.find(
                                          (item) =>
                                            item.consultation_name === service
                                        )?.discount_rate;
                                      if (newConsultations[idx].quantity <= 0) {
                                        newConsultations[idx].quantity = 1;
                                      }
                                      setConsultations(newConsultations);
                                    }}
                                    placeholder="Dropdown to select Service"
                                    searchPlaceholder="Search Service"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={consultation.unit_cost}
                                    onChange={(e) => {
                                      let newConsultations = [...consultations];
                                      newConsultations[idx].unit_cost =
                                        e.target.value;
                                      newConsultations[idx].total_cost = (
                                        Number(e.target.value) *
                                        Number(newConsultations[idx].quantity)
                                      ).toFixed(2);
                                      setConsultations(newConsultations);
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Total Cost</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={consultation.total_cost}
                                    disabled={true}
                                  />
                                </div>
                                {idx === consultations.length - 1 ? (
                                  <div
                                    onClick={handleAddNewConsultation}
                                    className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    +
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      let newConsultations = [...consultations];
                                      newConsultations.splice(idx, 1);
                                      setConsultations(newConsultations);
                                    }}
                                    className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    -
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                        "hospital/clinic" && (
                        <div className="col-12 mb-4">
                          <label className="text-xl">Investigations</label>
                          {investigations.map((investigation, idx: number) => {
                            return (
                              <div className="flex col-12">
                                <div className="col-2">
                                  <label>QTY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={investigation.quantity}
                                    onChange={(e) => {
                                      let newInvestigations = [
                                        ...investigations,
                                      ];
                                      newInvestigations[idx].quantity = Number(
                                        e.target.value
                                      );
                                      setInvestigations(newInvestigations);
                                    }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label>Service</label>

                                  <CustomServiceSelector
                                    options={fetchedInvestigations.map(
                                      (fetchedInvestigation, id: number) => {
                                        return {
                                          id: fetchedInvestigation.id || id,
                                          label:
                                            fetchedInvestigation.investigation_name,
                                          value:
                                            fetchedInvestigation.investigation_name,
                                        };
                                      }
                                    )}
                                    selected={investigation.service}
                                    handleSetSelected={(service) => {
                                      let newInvestigations = [
                                        ...investigations,
                                      ];
                                      newInvestigations[idx].service =
                                        service || "";
                                      newInvestigations[idx].unit_cost =
                                        fetchedInvestigations.find(
                                          (item) =>
                                            item.investigation_name === service
                                        )?.discount_rate;
                                      if (
                                        newInvestigations[idx].quantity <= 0
                                      ) {
                                        newInvestigations[idx].quantity = 1;
                                      }
                                      setInvestigations(newInvestigations);
                                    }}
                                    placeholder="Dropdown to select Service"
                                    searchPlaceholder="Search Service"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={investigation.unit_cost}
                                    onChange={(e) => {
                                      let newInvestigations = [
                                        ...investigations,
                                      ];
                                      newInvestigations[idx].unit_cost =
                                        e.target.value;
                                      newInvestigations[idx].total_cost = (
                                        Number(e.target.value) *
                                        Number(newInvestigations[idx].quantity)
                                      ).toFixed(2);
                                      setInvestigations(newInvestigations);
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Total Cost</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={investigation.total_cost}
                                    disabled={true}
                                  />
                                </div>
                                {idx === investigations.length - 1 ? (
                                  <div
                                    onClick={handleAddNewInvestigations}
                                    className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    +
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      let newInvestigations = [
                                        ...investigations,
                                      ];
                                      newInvestigations.splice(idx, 1);
                                      setInvestigations(newInvestigations);
                                    }}
                                    className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    -
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                        "hospital/clinic" && (
                        <div className="col-12 mb-4">
                          <label className="text-xl">
                            Surgery and Procedures
                          </label>
                          {surgeryAndProcedures.map(
                            (surgeryAndProcedure, idx: number) => {
                              return (
                                <div className="flex col-12">
                                  <div className="col-2">
                                    <label>QTY</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={surgeryAndProcedure.quantity}
                                      onChange={(e) => {
                                        let newSurgeryAndProcedures = [
                                          ...surgeryAndProcedures,
                                        ];
                                        newSurgeryAndProcedures[idx].quantity =
                                          Number(e.target.value);
                                        setSurgeryAndProcedures(
                                          newSurgeryAndProcedures
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-5">
                                    <label>Service</label>
                                    <CustomServiceSelector
                                      options={fetchedSurgeryAndProcedures.map(
                                        (
                                          fetchedSurgeryAndProcedure,
                                          id: number
                                        ) => {
                                          return {
                                            id:
                                              fetchedSurgeryAndProcedure.id ||
                                              id,
                                            label:
                                              fetchedSurgeryAndProcedure.service_name,
                                            value:
                                              fetchedSurgeryAndProcedure.service_name,
                                          };
                                        }
                                      )}
                                      selected={surgeryAndProcedure.service}
                                      handleSetSelected={(service) => {
                                        let newSurgeryAndProcedures = [
                                          ...surgeryAndProcedures,
                                        ];
                                        newSurgeryAndProcedures[idx].service =
                                          service || "";

                                        newSurgeryAndProcedures[idx].unit_cost =
                                          fetchedSurgeryAndProcedures.find(
                                            (item) =>
                                              item.service_name === service
                                          )?.discount_rate;
                                        if (
                                          newSurgeryAndProcedures[idx]
                                            .quantity < 0
                                        ) {
                                          newSurgeryAndProcedures[
                                            idx
                                          ].quantity = 1;

                                          newSurgeryAndProcedures[
                                            idx
                                          ].total_cost = (
                                            Number(
                                              newSurgeryAndProcedures[idx]
                                                .unit_cost
                                            ) *
                                            Number(
                                              newSurgeryAndProcedures[idx]
                                                .quantity
                                            )
                                          ).toFixed(2);
                                        }
                                        setSurgeryAndProcedures(
                                          newSurgeryAndProcedures
                                        );
                                      }}
                                      placeholder="Dropdown to select Service"
                                      searchPlaceholder="Search Service"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label>Unit Cost</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={surgeryAndProcedure.unit_cost}
                                      onChange={(e) => {
                                        let newSurgeryAndProcedures = [
                                          ...surgeryAndProcedures,
                                        ];
                                        newSurgeryAndProcedures[idx].unit_cost =
                                          e.target.value;
                                        newSurgeryAndProcedures[
                                          idx
                                        ].total_cost = (
                                          Number(e.target.value) *
                                          Number(
                                            newSurgeryAndProcedures[idx]
                                              .quantity
                                          )
                                        ).toFixed(2);
                                        setSurgeryAndProcedures(
                                          newSurgeryAndProcedures
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label>Total Cost</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={surgeryAndProcedure.total_cost}
                                      disabled={true}
                                    />
                                  </div>
                                  {idx === surgeryAndProcedures.length - 1 ? (
                                    <div
                                      onClick={handleAddNewSurgeryAndProcedures}
                                      className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                    >
                                      +
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        let newSurgeryAndProcedures = [
                                          ...surgeryAndProcedures,
                                        ];
                                        newSurgeryAndProcedures.splice(idx, 1);
                                        setSurgeryAndProcedures(
                                          newSurgeryAndProcedures
                                        );
                                      }}
                                      className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                    >
                                      -
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                      {/* <div className="col-12 mb-4">
                        <label className="text-xl">
                          Admission/Ward/Accommodation
                        </label>
                        {admissions.map((accomodation, idx: number) => {
                          return (
                            <div className="flex col-12">
                              <div className="col-2">
                                <label>QTY</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={accomodation.quantity}
                                  onChange={(e) => {
                                    let newAdmissionWardAccommodation = [
                                      ...admissions,
                                    ];
                                    newAdmissionWardAccommodation[
                                      idx
                                    ].quantity = Number(e.target.value);
                                    setAdmissions(
                                      newAdmissionWardAccommodation
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-5">
                                <label>Service</label>
                                <CustomServiceSelector
                                  options={fetchedAdmissionWardAccommodation.map(
                                    (fetchedAccomodation, id: number) => {
                                      return {
                                        id: fetchedAccomodation.id || id,
                                        label:
                                          fetchedAccomodation.accommodation_name,
                                        value:
                                          fetchedAccomodation.accommodation_name,
                                      };
                                    }
                                  )}
                                  selected={accomodation.service}
                                  handleSetSelected={(service) => {
                                    let newAdmissionWardAccommodation = [
                                      ...admissions,
                                    ];
                                    newAdmissionWardAccommodation[idx].service =
                                      service || "";
                                    setAdmissions(
                                      newAdmissionWardAccommodation
                                    );
                                  }}
                                  placeholder="Dropdown to select Service"
                                  searchPlaceholder="Search Service"
                                />
                              </div>
                              <div className="col-2">
                                <label>Unit Cost</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={accomodation.unit_cost}
                                  onChange={(e) => {
                                    let newAdmissionWardAccommodation = [
                                      ...admissions,
                                    ];
                                    newAdmissionWardAccommodation[
                                      idx
                                    ].unit_cost = e.target.value;
                                    newAdmissionWardAccommodation[
                                      idx
                                    ].total_cost = (
                                      Number(e.target.value) *
                                      Number(
                                        newAdmissionWardAccommodation[idx]
                                          .quantity
                                      )
                                    ).toFixed(2);
                                    setAdmissions(
                                      newAdmissionWardAccommodation
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-2">
                                <label>Total Cost</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={accomodation.total_cost}
                                  disabled={true}
                                />
                              </div>
                              {idx === admissions.length - 1 ? (
                                <div
                                  onClick={
                                    handleAddNewAdmissionWardAccommodation
                                  }
                                  className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                >
                                  +
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    let newAdmissionWardAccommodation = [
                                      ...admissions,
                                    ];
                                    newAdmissionWardAccommodation.splice(
                                      idx,
                                      1
                                    );
                                    setAdmissions(
                                      newAdmissionWardAccommodation
                                    );
                                  }}
                                  className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                >
                                  -
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div> */}
                      {(allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                        "hospital/clinic" ||
                        allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                          "labs & diagnostics" ||
                        allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                          "pharmacy") && (
                        <div className="col-12 mb-4">
                          <label className="text-xl">
                            Medications/Consumables
                          </label>
                          {medications.map((medication, idx: number) => {
                            return (
                              <div className="flex col-12">
                                <div className="col-2">
                                  <label>QTY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={medication.quantity}
                                    onChange={(e) => {
                                      let newMedications = [...medications];
                                      newMedications[idx].quantity = Number(
                                        e.target.value
                                      );
                                      setMedications(newMedications);
                                    }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label>Service</label>

                                  <CustomServiceSelector
                                    options={fetchedMedications.map(
                                      (fetchedMedication, id: number) => {
                                        return {
                                          id: fetchedMedication.id || id,
                                          label: fetchedMedication.service_name,
                                          value: fetchedMedication.service_name,
                                        };
                                      }
                                    )}
                                    selected={medication.service}
                                    handleSetSelected={(service) => {
                                      let newMedications = [...medications];
                                      newMedications[idx].service =
                                        service || "";
                                      // set unit cost to discount_rate
                                      newMedications[idx].unit_cost =
                                        fetchedMedications.find(
                                          (item) =>
                                            item.service_name === service
                                        )?.discount_rate;
                                      if (newMedications[idx].quantity < 1) {
                                        newMedications[idx].quantity = 1;
                                        newMedications[idx].total_cost = (
                                          Number(
                                            newMedications[idx].unit_cost
                                          ) *
                                          Number(newMedications[idx].quantity)
                                        ).toFixed(2);
                                      }
                                      setMedications(newMedications);
                                    }}
                                    placeholder="Dropdown to select Service"
                                    searchPlaceholder="Search Service"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={medication.unit_cost}
                                    onChange={(e) => {
                                      let newMedications = [...medications];
                                      newMedications[idx].unit_cost =
                                        e.target.value;
                                      newMedications[idx].total_cost = (
                                        Number(e.target.value) *
                                        Number(newMedications[idx].quantity)
                                      ).toFixed(2);
                                      setMedications(newMedications);
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Total Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={medication.total_cost}
                                    disabled={true}
                                  />
                                </div>
                                {idx === medications.length - 1 ? (
                                  <div
                                    onClick={handleAddNewMedications}
                                    className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    +
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      let newMedications = [...medications];
                                      newMedications.splice(idx, 1);
                                      setMedications(newMedications);
                                    }}
                                    className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    -
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                        "dental service provider" ||
                        allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                          "hospital/clinic") && (
                        <div className="col-12 mb-4">
                          <label className="text-xl">Dental Services</label>
                          {dentals.map((dental, idx: number) => {
                            return (
                              <div className="flex col-12">
                                <div className="col-2">
                                  <label>QTY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={dental.quantity}
                                    onChange={(e) => {
                                      let newDentals = [...dentals];
                                      newDentals[idx].quantity = Number(
                                        e.target.value
                                      );
                                      setDentals(newDentals);
                                    }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label>Service</label>

                                  <CustomServiceSelector
                                    options={fetchedDentals.map(
                                      (fetchedDental, id: number) => {
                                        return {
                                          id: fetchedDental.id || id,
                                          label: fetchedDental.service_name,
                                          value: fetchedDental.service_name,
                                        };
                                      }
                                    )}
                                    selected={dental.service}
                                    handleSetSelected={(service) => {
                                      let newDentals = [...dentals];
                                      newDentals[idx].service = service || "";

                                      newDentals[idx].unit_cost =
                                        fetchedDentals.find(
                                          (item) =>
                                            item.service_name === service
                                        )?.discount_rate;

                                      if (newDentals[idx].quantity <= 0) {
                                        newDentals[idx].quantity = 1;
                                        newDentals[idx].total_cost = (
                                          Number(newDentals[idx].unit_cost) *
                                          Number(newDentals[idx].quantity)
                                        ).toFixed(2);
                                      }
                                      setDentals(newDentals);
                                    }}
                                    placeholder="Dropdown to select Service"
                                    searchPlaceholder="Search Service"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={dental.unit_cost}
                                    onChange={(e) => {
                                      let newDentals = [...dentals];
                                      newDentals[idx].unit_cost =
                                        e.target.value;
                                      newDentals[idx].total_cost = (
                                        Number(e.target.value) *
                                        Number(newDentals[idx].quantity)
                                      ).toFixed(2);
                                      setDentals(newDentals);
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Total Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={dental.total_cost}
                                    disabled={true}
                                  />
                                </div>
                                {idx === opticals.length - 1 ? (
                                  <div
                                    onClick={handleAddNewDentals}
                                    className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    +
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      let newDentals = [...dentals];
                                      newDentals.splice(idx, 1);
                                      setDentals(newDentals);
                                    }}
                                    className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    -
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                        "optical service provider" ||
                        allDetailsAboutSelectedBatch?.hsp_type?.toLowerCase() ===
                          "hospital/clinic") && (
                        <div className="col-12 mb-4">
                          <label className="text-xl">Optical Services</label>
                          {opticals.map((optical, idx: number) => {
                            return (
                              <div className="flex col-12">
                                <div className="col-2">
                                  <label>QTY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={optical.quantity}
                                    onChange={(e) => {
                                      let newOpticals = [...opticals];
                                      newOpticals[idx].quantity = Number(
                                        e.target.value
                                      );
                                      setOpticals(newOpticals);
                                    }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label>Service</label>

                                  <CustomServiceSelector
                                    options={fetchedOpticals.map(
                                      (fetchedOptical, id: number) => {
                                        return {
                                          id: fetchedOptical.id || id,
                                          label: fetchedOptical.service_name,
                                          value: fetchedOptical.service_name,
                                        };
                                      }
                                    )}
                                    selected={optical.service}
                                    handleSetSelected={(service) => {
                                      let newOpticals = [...opticals];
                                      newOpticals[idx].service = service || "";

                                      newOpticals[idx].unit_cost =
                                        fetchedOpticals.find(
                                          (item) =>
                                            item.service_name === service
                                        )?.discount_rate;

                                      if (newOpticals[idx].quantity <= 0) {
                                        newOpticals[idx].quantity = 1;
                                        newOpticals[idx].total_cost = (
                                          Number(newOpticals[idx].unit_cost) *
                                          Number(newOpticals[idx].quantity)
                                        ).toFixed(2);
                                      }
                                      setOpticals(newOpticals);
                                    }}
                                    placeholder="Dropdown to select Service"
                                    searchPlaceholder="Search Service"
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={optical.unit_cost}
                                    onChange={(e) => {
                                      let newOpticals = [...opticals];
                                      newOpticals[idx].unit_cost =
                                        e.target.value;
                                      newOpticals[idx].total_cost = (
                                        Number(e.target.value) *
                                        Number(newOpticals[idx].quantity)
                                      ).toFixed(2);
                                      setOpticals(newOpticals);
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  <label>Total Cost</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={optical.total_cost}
                                    disabled={true}
                                  />
                                </div>
                                {idx === opticals.length - 1 ? (
                                  <div
                                    onClick={handleAddNewOpticals}
                                    className="btn btn-primary mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    +
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      let newOpticals = [...opticals];
                                      newOpticals.splice(idx, 1);
                                      setOpticals(newOpticals);
                                    }}
                                    className="btn btn-danger mt-8 w-14 rounded-pill h-6 flex items-center justify-center"
                                  >
                                    -
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {currentStep === 5 && (
                  <>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="mt-3">
                          <label htmlFor="" className="text">
                            Attachments
                          </label>
                          <input
                            type="file"
                            name=""
                            id=""
                            className="form-control"
                            multiple
                            onChange={(e) => {
                              if (e.target.files) {
                                let files = Array.from(e.target.files);
                                setAttachments(files);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="buttons float-right mt-3 flex gap-4">
                  {currentStep != 1 && (
                    <div
                      onClick={handlePrevious}
                      className="btn btn-primary btn-sm flex items-center justify-center"
                    >
                      <i className="fas fa-backward mr-2" />
                      Previous
                    </div>
                  )}
                  {currentStep === 5 ? (
                    <div
                      onClick={handleSave}
                      className="btn btn-primary btn-sm closs flex items-center justify-center"
                    >
                      {loading ? (
                        <>
                          <i className="fas fa-spinner mr-2" />
                          Saving...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-save mr-2" />
                          Save
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      onClick={handleNext}
                      className="btn btn-primary btn-sm closs flex items-center justify-center"
                    >
                      Next
                      <i className="fas fa-forward ml-2" />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default AddNewClaimsModal;
