import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer";
import { StyledSearch } from "../../../components/StyledSearch";
import CustomComboBox from "../../../components/ui/CustomComboBox";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import AddNewMedDeliveryModal from "../../../components/policy-admin/med-delivery/AddNewMedDeliveryModal";
import EditMedDeliveryModal from "../../../components/policy-admin/med-delivery/EditMedDeliveryModal";
import DeleteMedDeliveryModal from "../../../components/policy-admin/med-delivery/DeleteMedDeliveryModal";
import MasterTableComponent from "../../../components/ui/MasterTableComponent";
import medDeliveryApi from "../../../api/medDeliveryApi";
import diagnosisApi from "../../../api/diagnosisApi";
import { MedDeliveryType } from "../../../types/data";
import medicationApi from "../../../api/medicationApi";
import StaffApi from "../../../api/StaffApi";

const NewMedDeliveries = () => {
  const toast = useRef<Toast>(null);

  const [currentStep, setCurrentStep] = useState(1);
  const [pushToClaimsCurrentStep, setPushToClaimsCurrentStep] = useState(1);
  const [editClaimCurrentStep, setEditClaimCurrentStep] = useState(1);
  const [fetchedMedDeliveries, setFetchedMedDeliveries] = useState([]);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [fetchedDiagnosis, setFetchedDiagnosis] = useState<any[]>([]);
  const [listOfMedicationServices, setListOfMedicationServices] = useState<
    any[]
  >([]);
  const [listOfStaff, setListOfStaff] = useState<any[]>([]);
  const [createNewDeliveryBody, setCreateNewDeliveryBody] =
    useState<MedDeliveryType>({
      staff_id: "",
      staff_name: "",
      location: "",
      actual_delivery_time: "",
      batch_id: "",
      client_remarks_message: "",
      client_remarks_status: false,
      diagnosis: [],
      estimated_delivery_time: "",
      hsp_request_form: null,
      hsp_source: "",
      medication_details: [
        {
          medication_quantity: 0,
          medication_service: "",
          medication_total_cost: 0,
          medication_unit_cost: 0,
        },
      ],
      receiver_contact: "",
      receiver_name: "",
    });

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handlePrevious = () => {
    if (currentStep === 1) {
      return 1;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 2) {
      return 2;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePushToClaimsPrevious = () => {
    if (pushToClaimsCurrentStep === 1) {
      return 1;
    }
    setPushToClaimsCurrentStep(pushToClaimsCurrentStep - 1);
  };

  const handlePushToClaimsNext = () => {
    if (pushToClaimsCurrentStep === 2) {
      return 2;
    }
    setPushToClaimsCurrentStep(pushToClaimsCurrentStep + 1);
  };

  const handleEditClaimPrevious = () => {
    if (editClaimCurrentStep === 1) {
      return 1;
    }
    setEditClaimCurrentStep(editClaimCurrentStep - 1);
  };

  const handleEditClaimNext = () => {
    if (editClaimCurrentStep === 2) {
      return 2;
    }
    setEditClaimCurrentStep(editClaimCurrentStep + 1);
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = fetchedMedDeliveries.filter((data: any) => {
      return (
        data?.staff_name?.toLowerCase().includes(query.toLowerCase()) ||
        data?.staff_id?.toLowerCase().includes(query.toLowerCase()) ||
        data?.location?.toLowerCase().includes(query.toLowerCase()) ||
        data?.hsp_source?.toLowerCase().includes(query.toLowerCase()) ||
        data?.receiver_name?.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  const handleFetchMedDeliveries = async () => {
    medDeliveryApi
      .getAllMedDelivery(sessionStorage.getItem("token") || "")
      .then((res) => {
        if (res.status === 200) {
          setFetchedMedDeliveries(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchDiagnosis = async () => {
    diagnosisApi
      .getAllDiagnosis(sessionStorage.getItem("token") || "")
      .then((res) => {
        if (res.status === 200) {
          setFetchedDiagnosis(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchAllMedicationServices = () => {
    medicationApi
      .getAllMedications(sessionStorage.getItem("token") || "")
      .then((res) => {
        if (res.status === 200) {
          setListOfMedicationServices(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchAllStaff = () => {
    StaffApi.getAllStaff(sessionStorage.getItem("token") || "")
      .then((res) => {
        if (res.status === 200) {
          setListOfStaff(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSaveNewMedDelivery = async (e: any) => {
    e.preventDefault();
    console.log("createNewDeliveryBody", createNewDeliveryBody);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedMedDeliveries;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedMedDeliveries]);

  useEffect(() => {
    handleFetchMedDeliveries();
    handleFetchDiagnosis();
    handleFetchAllMedicationServices();
    handleFetchAllStaff();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* breadcrumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Delivery Claims",
                  url: "/new-deliveries",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="col-sm-12 col-md-12 flex  justify-between mb-3">
                        <h3 className="card-title mb-3">New Delivery List</h3>
                        <div className=" flex ">
                          <div className="mr-4">
                            <StyledSearch
                              onChange={handleSearchChange}
                              value={searchQuery}
                              placeholder="🔍 by Source, staff name, ID,receiver name"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-plus" /> Add New Delivery
                          </button>
                        </div>
                      </div>
                      {/* table */}
                      <MasterTableComponent
                        headers={[
                          "S/N",
                          "Staff Name/ID",
                          "Location",
                          "HSP Source",
                          "Receiver Name",
                          "Delivery Duration",
                          "Delivery Start Time",
                          "Actions",
                        ]}
                        emptyTableMessage="No New Deliveries found"
                        tableRows={
                          tableRows &&
                          tableRows?.map((delivery: any, index: number) => ({
                            "S/N": index + 1,
                            "Staff Name/ID": (
                              <div>
                                delivery?.staff_name <br />
                                <span className="text-sm">
                                  {"("}
                                  {delivery?.staff_id}
                                  {")"}
                                </span>
                              </div>
                            ),
                            Location: delivery?.location,
                            "HSP Source": delivery?.hsp_source,
                            "Receiver Name": delivery?.receiver_name,
                            "Delivery Duration": delivery?.delivery_duration,
                            "Delivery Start Time":
                              delivery?.delivery_start_time,
                            Actions: (
                              <>
                                <a href="/new-delivery-claim-details">
                                  <i className="fas fa-eye text-success delplan mr-2 cursor-pointer" />
                                </a>
                                <i
                                  data-toggle="modal"
                                  data-target="#deleteModal"
                                  className="fas fa-trash text-danger mr-2 cursor-pointer"
                                />
                                <i
                                  data-toggle="modal"
                                  data-target="#editClaimModal"
                                  className="fas fa-edit text-warning delplan mr-2 cursor-pointer"
                                />

                                <i
                                  data-toggle="modal"
                                  data-target="#pushToNewClaimsModal"
                                  className="fas fa-share-square text-info cursor-pointer"
                                />
                              </>
                            ),
                          }))
                        }
                      />

                      {/* ------ END TABLE --------- */}

                      {/*  ADD NEW MODAL */}
                      <AddNewMedDeliveryModal
                        currentStep={currentStep}
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        fetchedDiagnosis={fetchedDiagnosis}
                        createNewDeliveryBody={createNewDeliveryBody}
                        setCreateNewDeliveryBody={setCreateNewDeliveryBody}
                        show={show}
                        handleSaveNewMedDelivery={handleSaveNewMedDelivery}
                        listOfMedicationServices={listOfMedicationServices}
                        listOfStaff={listOfStaff}
                      />
                      {/* /.modal */}

                      {/*  EDIT CLAIM MODAL */}
                      <EditMedDeliveryModal
                        editClaimCurrentStep={editClaimCurrentStep}
                        handleEditClaimNext={handleEditClaimNext}
                        handleEditClaimPrevious={handleEditClaimPrevious}
                      />
                      {/* /.modal */}

                      {/*  DELETE MODAL */}
                      <DeleteMedDeliveryModal handlePrevious={handlePrevious} />

                      {/* /.modal */}

                      {/*  PUSH To NEW CLAIMS  MODAL */}
                      <div
                        className="modal fade"
                        id="pushToNewClaimsModal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-md">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4
                                className="modal-title"
                                id="myLargeModalLabel"
                              >
                                {" "}
                                Enter Batch & Confirm Push
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                              >
                                ×
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <form id="deleteForm">
                                        <div className="form-body">
                                          <div className="">
                                            <div className="row">
                                              {pushToClaimsCurrentStep === 1 ? (
                                                <div className="col-md-12">
                                                  <div className="form-group">
                                                    <label>
                                                      <i className="fas fa-question" />{" "}
                                                      select batch
                                                    </label>
                                                    <CustomComboBox placeholder="begin typing to search" />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="col-md-12">
                                                  <h3 className="text-center">
                                                    You cannot edit this claim
                                                    once it is saved.
                                                  </h3>
                                                  <h3 className="text-center mt-2">
                                                    Are you sure you want to
                                                    save this claim ?
                                                  </h3>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          {/* start send buttons */}
                                          <div className="form-footer d-flex justify-between mt-4">
                                            {pushToClaimsCurrentStep === 1 ? (
                                              <div>
                                                <button
                                                  type="button"
                                                  className="btn bg-danger rounded-pill text-end text-white"
                                                  id="nextBtn"
                                                  // onClick={handlePrevious}
                                                >
                                                  <span>
                                                    <i className="fa fa-times mr-2" />
                                                    Cancel
                                                  </span>
                                                </button>
                                              </div>
                                            ) : (
                                              <div>
                                                <button
                                                  type="button"
                                                  className="btn bg-primary rounded-pill text-end text-white"
                                                  id="nextBtn"
                                                  onClick={
                                                    handlePushToClaimsPrevious
                                                  }
                                                >
                                                  <span>
                                                    <i className="fa fa-arrow-left mr-2" />
                                                    Previous
                                                  </span>
                                                </button>
                                              </div>
                                            )}
                                            <div>
                                              {pushToClaimsCurrentStep === 1 ? (
                                                <button
                                                  type="button"
                                                  className="btn bg-primary rounded-pill text-end text-white"
                                                  id="nextBtn"
                                                  onClick={
                                                    handlePushToClaimsNext
                                                  }
                                                >
                                                  <span>
                                                    Next
                                                    <i className="fas fa-arrow-right ml-2" />
                                                  </span>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn bg-primary rounded-pill text-end text-white"
                                                  id="nextBtn"
                                                >
                                                  <span>
                                                    Push
                                                    <i className="fas fa-share-square ml-2" />
                                                  </span>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                          {/* end send buttons */}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default NewMedDeliveries;
