import React, { useEffect } from "react";
import MasterTableComponent from "../../ui/MasterTableComponent";

interface EditHspSurgeryAndProcedureModalProps {
  serviceType: string;
  itemToEdit: any;
  setItemToEdit: React.Dispatch<React.SetStateAction<any | undefined>>;
  fetchedCashflows: any;
  handleUpdate: (e: any) => void;
  loading: boolean;
}

const EditHspSurgeryAndProcedureModal = ({
  serviceType,
  itemToEdit,
  setItemToEdit,
  fetchedCashflows,
  handleUpdate,
  loading,
}: EditHspSurgeryAndProcedureModalProps) => {
  // useEffect for net rate. net rate = standard rate - discount rate
  useEffect(() => {
    setItemToEdit({
      ...itemToEdit,
      net_rate: itemToEdit.standard_rate - itemToEdit.discount_rate,
    });
  }, [itemToEdit.standard_rate, itemToEdit.discount_rate]);

  return (
    <div
      className="modal fade"
      id="EditHspSurgeryAndProcedureModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      {" "}
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Modify {serviceType}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <MasterTableComponent
                      headers={[
                        "Code",
                        "Service Name",
                        "Included",
                        "Std Tariff",
                        "Agreed Tariff",
                        "Net Tariff",
                        "Authorization",
                        "Cashflow Type",
                        // "Action",
                      ]}
                      tableRows={
                        itemToEdit
                          ? [
                              {
                                Code: itemToEdit.surgery_code,
                                "Service name": itemToEdit.service_name,
                                Included: (
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={
                                      itemToEdit.surgery_and_procedure_included
                                    }
                                    onChange={(e) => {
                                      setItemToEdit({
                                        ...itemToEdit,
                                        surgery_and_procedure_included:
                                          e.target.checked,
                                      });
                                    }}
                                  />
                                ),
                                "Std Tariff": (
                                  <input
                                    type="number"
                                    min={"0"}
                                    className="form-control"
                                    value={itemToEdit.standard_rate}
                                    onChange={(e) => {
                                      setItemToEdit({
                                        ...itemToEdit,
                                        standard_rate: e.target.value,
                                      });
                                    }}
                                  />
                                ),
                                "Agreed Tariff": (
                                  <input
                                    type="number"
                                    min={"0"}
                                    className="form-control"
                                    value={itemToEdit.discount_rate}
                                    onChange={(e) => {
                                      setItemToEdit({
                                        ...itemToEdit,
                                        discount_rate: e.target.value,
                                      });
                                    }}
                                  />
                                ),
                                "Net Tariff": (
                                  <input
                                    type="number"
                                    min={"0"}
                                    disabled
                                    className="form-control"
                                    value={itemToEdit.net_rate}
                                    onChange={(e) => {
                                      setItemToEdit({
                                        ...itemToEdit,
                                        net_rate: e.target.value,
                                      });
                                    }}
                                  />
                                ),
                                Authorization: (
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={itemToEdit.investigation_included}
                                    onChange={(e) => {
                                      setItemToEdit({
                                        ...itemToEdit,
                                        investigation_included:
                                          e.target.checked,
                                      });
                                    }}
                                  />
                                ),
                                "Cashflow Type": (
                                  <div className="form-group mb-3">
                                    <select
                                      className="form-control plantype"
                                      name="plan_type"
                                      id="mySelection"
                                      // placeholder="Select Type"
                                      value={
                                        itemToEdit.surgery_and_procedure_revenue_type
                                      }
                                      onChange={(e) => {
                                        setItemToEdit({
                                          ...itemToEdit,
                                          surgery_and_procedure_revenue_type:
                                            e.target.value,
                                        });
                                      }}
                                    >
                                      <option selected disabled value="">
                                        Select
                                      </option>
                                      {fetchedCashflows?.map(
                                        (cashflow: any, idx: number) => (
                                          <option
                                            key={idx}
                                            value={
                                              cashflow?.cash_flow_type +
                                              " - " +
                                              cashflow?.percentage +
                                              "%"
                                            }
                                          >
                                            {cashflow?.cash_flow_type} -{" "}
                                            {cashflow?.percentage
                                              ? `${cashflow?.percentage}%`
                                              : ""}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ),
                              },
                            ]
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row flex justify-end gap-2 pr-4">
              <button
                className="btn btn-danger btn-sm rounded-pill"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                disabled={loading}
                onClick={handleUpdate}
                className="btn btn-primary btn-sm rounded-pill"
              >
                {loading ? "Updating..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHspSurgeryAndProcedureModal;
