import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHSP = async (token: string) => {
  let url = `${BASE_URL}/hsp/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHSP = async (token: string, hspData: FormData) => {
  let url = `${BASE_URL}/hsp/`;
  try {
    const response = await axios.post(url, hspData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHSP = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/status-change`;
  try {
    const response = await axios.post(
      url,
      { accredited_hsp_id: hspId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHSP = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/status-change`;
  try {
    const response = await axios.post(
      url,
      { accredited_hsp_id: hspId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const deleteHSP = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/delete`;
  try {
    const response = await axios.post(
      url,
      { accredited_hsp_id: hspId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspDetails = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/details`;
  try {
    const response = await axios.post(
      url,
      { accredited_hsp_id: hspId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllActiveHSP = async (token: string) => {
  let url = `${BASE_URL}/hsp/active`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHSP,
  createHSP,
  activateHSP,
  deactivateHSP,
  getHspDetails,
  deleteHSP,
  getAllActiveHSP,
};
