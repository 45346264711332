import axios from "axios";
import { HspDentalsType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspDentals = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/dental/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_owner_id: hspId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllHspDentalsByCategory = async (
  token: string,
  categoryId: string
) => {
  let url = `${BASE_URL}/hsp/dental/category/`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_dental_category: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHspDentals = async (token: string, dentals: HspDentalsType[]) => {
  let url = `${BASE_URL}/hsp/dental/`;
  try {
    // remove dental_code from every item in thte array
    dentals.forEach((item) => {
      delete item.dental_code;
    });
    const response = await axios.post(
      url,
      { Dentals: dentals },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;

    return {};
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspDentalsDetails = async (token: string, DentalsId: string) => {
  let url = `${BASE_URL}/hsp/dental/details`;
  try {
    const response = await axios.post(
      url,
      { dentals_hsp_id: DentalsId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspDentals = async (token: string, DentalsId: string) => {
  let url = `${BASE_URL}/hsp/dental/status-change`;
  try {
    const response = await axios.post(
      url,
      { dentals_hsp_id: DentalsId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspDentals = async (token: string, DentalsId: string) => {
  let url = `${BASE_URL}/hsp/dental/status-change`;
  try {
    const response = await axios.post(
      url,
      { dentals_hsp_id: DentalsId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteHspDentals = async (token: string, DentalsId: string) => {
  let url = `${BASE_URL}/hsp/dental/delete`;
  try {
    const response = await axios.post(
      url,
      { dentals_hsp_id: DentalsId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editHspDentals = async (token: string, data: HspDentalsType[]) => {
  try {
    let url = `${BASE_URL}/hsp/dental/edit`;
    const response = await axios.post(
      url,
      {
        Dentals: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspDentals,
  createHspDentals,
  getHspDentalsDetails,
  deactivateHspDentals,
  activateHspDentals,
  deleteHspDentals,
  editHspDentals,
  getAllHspDentalsByCategory,
};
