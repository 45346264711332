import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const createMediationCategory = async (
  token: string,
  medicationCategoryData: {
    medication_category: string;
    medication_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/medication/category/`;
  try {
    const response = await axios.post(url, medicationCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const createMedication = async (
  token: string,
  medicationData: {
    medication_name: string;
    medication_category: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/medication/`;
  try {
    const response = await axios.post(url, medicationData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAllMedicationCategories = async (token: string) => {
  let url = `${BASE_URL}/setup-services/medication/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAllMedications = async (token: string) => {
  let url = `${BASE_URL}/setup-services/medication/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getMedicationDetails = async (token: string, medicationId: string) => {
  let url = `${BASE_URL}/setup-services/medication/details`;
  try {
    const response = await axios.post(url, { medication_id: medicationId });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const changeMedicationStatus = async (token: string, medicationId: string) => {
  let url = `${BASE_URL}/setup-services/medication/status-change`;
  try {
    const response = await axios.post(
      url,
      { medication_id: medicationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const deactivateMedication = async (token: string, medicationId: string) => {
  let url = `${BASE_URL}/setup-services/medication/status-change`;
  try {
    const response = await axios.post(
      url,
      { medication_id: medicationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const activateMedication = async (token: string, medicationId: string) => {
  let url = `${BASE_URL}/setup-services/medication/status-change`;
  try {
    const response = await axios.post(
      url,
      { medication_id: medicationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const updateMedication = async (
  token: string,
  medicationData: {
    medication_id: string;
    medication_code: string;
    medication_name: string;
    medication_category: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/medication/edit_medication_details`;
  try {
    const response = await axios.post(url, medicationData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const bulkMedicationUpload = async (
  token: string,
  medicationFile: FormData
) => {
  let url = `${BASE_URL}/setup-services/medication/bulk-upload`;
  try {
    const response = await axios.post(url, medicationFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default {
  createMediationCategory,
  createMedication,
  getAllMedicationCategories,
  changeMedicationStatus,
  getAllMedications,
  getMedicationDetails,
  deactivateMedication,
  activateMedication,
  updateMedication,
  bulkMedicationUpload,
};
