// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import formatDate from "../../utils/formatDate";

const ViewOptical = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { optical_id, action_type } = queryString.parse(search);
  const toast = useRef<Toast>(null);

  const [opticalDetails, setOpticalDetails] = useState<any>([]);

  const handleFetchDetails = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/optical/details`,
        {
          optical_id: optical_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setOpticalDetails(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleFetchDetails();
  }, [optical_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/edit`,
        {
          medication_id: optical_id,
          optical_code: opticalDetails.optical_code,
          optical_name: opticalDetails.optical_name,
          optical_category: opticalDetails.optical_category,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Successful",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchDetails();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Services",
                  url: "/optical",
                },
                {
                  title: "Opticals",
                  url: "/optical",
                },
                {
                  title: `${action_type === "view" ? "View" : "Edit"} Optical`,
                  url: "",
                },
              ]}
            />
            <div className="col-md-8 col-sm-12 offset-md-2">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">
                        {opticalDetails.optical_name || ""} Details
                      </h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Dental category</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={opticalDetails.optical_category}
                                  className="form-control"
                                  onChange={(e) => {
                                    setOpticalDetails({
                                      ...opticalDetails,
                                      optical_category: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Dental code</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={opticalDetails.optical_code}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Dental Name</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={opticalDetails.optical_name}
                                  className="form-control"
                                  onChange={(e) => {
                                    setOpticalDetails({
                                      ...opticalDetails,
                                      optical_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>status</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={opticalDetails.status}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={
                                    Number(opticalDetails.updated_at)
                                      ? convertTimestampToDate(
                                          Number(opticalDetails.updated_at)
                                        )
                                      : formatDate(opticalDetails.updated_at)
                                  }
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      {action_type === "edit" && (
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block bg-[#5F76E8] rounded-pill"
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewOptical;
