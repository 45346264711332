import React, { useEffect, useState } from "react";

function HspDetailsTab() {
  const [token, setToken] = useState<string>("");
  const [selectedHSP, setSelectedHSP] = useState<any>({});
  
  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
  }, []);

  useEffect(() => {
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setSelectedHSP(selectedHsp);
  }, []);
  return (
    <div className="tab-pane show active" id="hspDetailsTab">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="card shadow">
            <div className="card-body">
              <img
                src={selectedHSP?.hsp_image}
                alt="HSP Logo"
                className="img-fluid rounded-circle mx-auto w-[200px]"
              />
              <fieldset>
                <form className="px-[10%]">
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                  />
                  <a href="#">
                    <input type="hidden" name="id" defaultValue={796} />
                  </a>
                  <table className="table" id="dataTable">
                    <tbody>
                      <tr>
                        <td>
                          <i className="fa fa-hospital mr-2" />
                          HSP Name
                        </td>
                        <td>
                          <input
                            type="text"
                            id="hsp_name"
                            name="hsp_name"
                            className="form-control"
                            disabled
                            placeholder="HSP Name"
                            value={selectedHSP.hsp_name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="fa fa-id-card mr-2" />
                          HPS ID
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="hsp_id"
                            name="hsp_id"
                            className="form-control "
                            disabled
                            placeholder="HSP ID"
                            value={selectedHSP.hsp_id}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-calender mr-2" /> HSP Type
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="hsp_type"
                            name="hsp_type"
                            className="form-control"
                            disabled
                            placeholder="HSP Type"
                            value={selectedHSP.hsp_type}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-map mr-2" /> Region
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="hsp_region"
                            name="hsp_region"
                            className="form-control"
                            disabled
                            placeholder="Region"
                            value={selectedHSP.region}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-map mr-2" /> Location/GPS Address
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="location"
                            name="location"
                            className="form-control"
                            disabled
                            placeholder="HSP Location"
                            value={
                              selectedHSP?.hsp_location ||
                              "" + "/" + selectedHSP?.gps_address ||
                              ""
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="fa fa-id-card mr-2" />
                          Business Registration Number
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="Business Registration Number"
                            name="Business Registration Number"
                            className="form-control"
                            disabled
                            placeholder="Business Registration Number"
                            value={selectedHSP?.business_reg_no}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="fa fa-id-card mr-2" />
                          TIN
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="tax_identification"
                            name="tax_identification"
                            className="form-control"
                            disabled
                            placeholder="Tax Identification Number"
                            value={selectedHSP?.tax_identification_number}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-phone mr-2" /> Contact
                        </td>
                        <td className="col-6">
                          <input
                            type="number"
                            id="contact"
                            name="contact"
                            className="form-control"
                            disabled
                            placeholder="020XXXXXXX"
                            value={selectedHSP.contact}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </fieldset>
              {/* Contact Person 1 */}

              <fieldset>
                <form className="px-[10%]">
                  <h4 className="card-title py-3">Administrator</h4>
                  <a href="#">
                    <input type="hidden" name="id" defaultValue={796} />
                  </a>
                  <table className="table" id="dataTable">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <i className="icon-user mr-2" /> Name
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="admin_name"
                            name="admin_name"
                            className="form-control"
                            disabled
                            placeholder="Administrator Name"
                            value={selectedHSP.administrator_name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-envelope-letter mr-2" />
                          Email
                        </td>
                        <td className="col-6">
                          <input
                            type="email"
                            id="admin_email"
                            name="admin_email"
                            className="form-control"
                            disabled
                            placeholder="Administrator Email"
                            value={selectedHSP.administrator_email}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-phone mr-2" />
                          Contact
                        </td>
                        <td className="col-6">
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            className="form-control"
                            disabled
                            placeholder={"020XXXXXXX"}
                            value={selectedHSP?.administrator_phone}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </fieldset>
              {/* Contact Person 2 */}
              <fieldset>
                <form className="px-[10%]">
                  <div className="col-md-12 col-sm-12">
                    <h4 className="card-title py-3">Medical Director</h4>
                  </div>
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                  />
                  <a href="#">
                    <input type="hidden" name="id" defaultValue={796} />
                  </a>
                  <table className="table" id="dataTable">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <i className="icon-user mr-2" /> Name
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="medical_director_name"
                            name="medical_director_name"
                            className="form-control"
                            disabled
                            placeholder="Medical Director Name"
                            value={selectedHSP?.medical_director_name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-envelope-letter mr-2" />
                          Email
                        </td>
                        <td className="col-6">
                          <input
                            type="email"
                            id="medical_director_email"
                            name="medical_director_email"
                            className="form-control"
                            disabled
                            value={selectedHSP?.medical_director_email}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-phone mr-2" /> Contact
                        </td>
                        <td className="col-6">
                          <input
                            type="tel"
                            id="medical_director_phone"
                            name="medical_director_phone"
                            className="form-control"
                            disabled
                            placeholder={"Medical Director Phone"}
                            value={selectedHSP?.medical_director_phone}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </fieldset>
              {/* Contact Person 3 */}
              <fieldset>
                <form className="px-[10%]">
                  <div className="col-md-12 col-sm-12">
                    <h4 className="card-title py-3">Claims Officer</h4>
                  </div>
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                  />
                  <a href="#">
                    <input type="hidden" name="id" defaultValue={796} />
                  </a>
                  <table className="table" id="dataTable">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <i className="icon-user mr-2" /> Name
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="claims_officer_name"
                            name="claims_officer_name"
                            className="form-control"
                            disabled
                            placeholder="Claims Officer Name"
                            value={selectedHSP?.claims_officer_name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-envelope-letter mr-2" />
                          Email
                        </td>
                        <td className="col-6">
                          <input
                            type="email"
                            id="claims_officer_email"
                            name="claims_officer_email"
                            className="form-control"
                            disabled
                            placeholder={"Claims Office Email"}
                            value={selectedHSP?.claims_officer_email}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-phone mr-2" /> Contact
                        </td>
                        <td className="col-6">
                          <input
                            type="tel"
                            id="claims_officer_phone"
                            name="claims_officer_phone"
                            className="form-control"
                            disabled
                            placeholder={"Claims Officer Phone"}
                            value={selectedHSP?.claims_officer_phone}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </fieldset>
              {/* Bank Details */}
              <fieldset>
                <form className="px-[10%]">
                  <div className="col-md-12 col-sm-12">
                    <h4 className="card-title py-3">Bank Details</h4>
                  </div>
                  <a href="#">
                    <input type="hidden" name="id" defaultValue={796} />
                  </a>
                  <table className="table" id="dataTable">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <i className="icon-user mr-2" /> Account Name
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="account-name"
                            name="account-name"
                            className="form-control"
                            disabled
                            placeholder="Bank Account Name"
                            value={selectedHSP?.bank_name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="fas fa-hashtag mr-2" />
                          Account Number
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="account-number"
                            name="account-number"
                            className="form-control"
                            disabled
                            placeholder="XXXXXXXXXXXX"
                            value={selectedHSP?.account_number}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="fas fa-globe mr-2" /> Bank Branch
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="bank_branch"
                            name="bank_branch"
                            className="form-control"
                            disabled
                            placeholder="bank branch"
                            value={selectedHSP?.bank_branch}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </fieldset>

              {/* MoMo Details */}
              <fieldset>
                <form className="px-[10%]">
                  <div className="col-md-12 col-sm-12">
                    <h4 className="card-title py-3">Mobile Money Datils</h4>
                  </div>
                  <a href="#">
                    <input type="hidden" name="id" defaultValue={796} />
                  </a>
                  <table className="table" id="dataTable">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <i className="icon-user mr-2" /> Account Name
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="mobile_account_name"
                            name="mobile_account_name"
                            className="form-control"
                            disabled
                            placeholder={"account name"}
                            value={selectedHSP?.mobile_account_name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="icon-phone mr-2" />
                          Account Number
                        </td>
                        <td className="col-6">
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            className="form-control"
                            disabled
                            placeholder={"Account Number"}
                            value={selectedHSP?.mobile_number}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6">
                          <i className="fas fa-globe mr-2" /> Provider
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            id="network_provier"
                            name="network_provier"
                            className="form-control"
                            disabled
                            placeholder={"Provider"}
                            value={selectedHSP?.network_provider}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HspDetailsTab;
