import { styled, alpha } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 220,
  height: 35,
  // marginBottom: 16,
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[700], 0.5)} !important`,
    borderRadius: `20px !important`,
  },
}));
