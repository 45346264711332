import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import queryString from "query-string";
import axios from "axios";
import { RevenueSetupType } from "../../types/data";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const EditCompanySetupDetails = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { revenue_id } = queryString.parse(search);
  const [ editImage, setEditImage ] = useState<boolean>(false)

  const [revenue_details, setRevenueDetails] = useState<RevenueSetupType>();

  useEffect(() => {
    if (revenue_id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/revenue/view_revenue_details`,
          {
            revenue_id: revenue_id,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setRevenueDetails(response.data.info);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [revenue_id]);

  // @ desc - handle submit
  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/revenue/update_revenue`,
        {
          revenue_id: revenue_id,
          revenue_type: revenue_details!.revenue_type,
          applied_to: revenue_details!.applied_to,
          percentage: revenue_details!.percentage,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        alert(response.data.message);
        if (response.data.status === 200) {
          window.location.href = `/view-revenue-setup?revenue_id=${revenue_id}`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />

        <Sidebar />

        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
              
                {
                  title: "Company Setup",
                  url: "/company-setup",
                },
                {
                  title: "Edit Company Setup Details",
                  url: "/company-setup",
                },
              ]}
            />
            {/* {revenue_details && ( */}
              <div className="col-md-8 col-sm-12 offset-md-2">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="m-2">
                      <form onSubmit={handleSubmit}>
                        <div className="card-header text center">
                          {
                            !editImage ? (

                              <img
                            onClick={()=> {
                              setEditImage(true)
                            }}
                              src="https://via.placeholder.com/150"
                              alt="HSP Logo"
                              className="img-fluid rounded-circle mx-auto cursor-pointer"
                              />
                              ) : (
                                  <input
                                  type="file"
                                  name="file"
                                  id="file"
                                  className="form-control"
                                  />

                              )
                          }
                        </div>
                        <table className="table" id="dataTable">
                          <tbody>
                            <tr>
                              <td>
                                <i className="fa fa-hospital mr-2" />
                                Company Name
                              </td>
                              <td>
                                <input
                                  type="email"
                                  defaultValue="Francis Hospital"
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-6">
                                <i className="fa fa-id-card mr-2" />
                                Contact Person
                              </td>
                              <td className="col-6">
                                <input
                                  type="email"
                                  defaultValue={"01112"}
                                  id="email"
                                  name="email"
                                  className="form-control "
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-6">
                                <i className="icon-phone mr-2" /> Phone
                              </td>
                              <td className="col-6">
                                <input
                                  type="tel"
                                  defaultValue="0202000000"
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-6">
                                <i className="icon-envelope mr-2" /> Email
                              </td>
                              <td className="col-6">
                                <input
                                  defaultValue="example@axample.com"
                                  id="phone"
                                  name="phone"
                                  className="form-control"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-6">
                                <i className="icon-calender mr-2" />
                                Validity Date (From)
                              </td>
                              <td className="col-6">
                                <input
                                  type="date"
                                  defaultValue={"30-JAN-2023"}
                                  id="Pentecost"
                                  name="DOB"
                                  className="form-control"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-6">
                                <i className="icon-calender mr-2" /> Validity
                                Date (To)
                              </td>
                              <td className="col-6">
                                <input
                                  type="date"
                                  defaultValue={"30-JAN-2023"}
                                  id="Pentecost"
                                  name="DOB"
                                  className="form-control"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-6">
                                <i className="icon-calender mr-2" /> Status
                              </td>
                              <td className="col-6">
                                <select name="" id="" className="form-control "
                                disabled={true}
                                >
                                  <option value="">Active</option>
                                  <option value="">Inactive</option>
                                  <option value="Canceled">Canceled</option>
                                </select>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-center w-[60%] mx-auto">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded-pill bg-[#5F76E8]"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            {/* )} */}
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default EditCompanySetupDetails;
