import axios from "axios";
import { HspInvestigationType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspInvestigations = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/investigation/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_owner_id: hspId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const getAllHspInvestigationsByCategory = async (
  token: string,
  categoryId: string
) => {
  let url = `${BASE_URL}/hsp/investigation/category/`;
  try {
    const response = await axios.post(
      url,
      {
        investigation_hsp_category: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHsInvestigation = async (
  token: string,
  investigationData: HspInvestigationType[]
) => {
  let url = `${BASE_URL}/hsp/investigation/`;
  try {
    // remove investigation_code from every item in the array
    investigationData.forEach((item) => {
      delete item.investigation_code;
    });

    const response = await axios.post(
      url,
      { Investigations: investigationData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspInvestigationDetails = async (
  token: string,
  investigationId: string
) => {
  let url = `${BASE_URL}/hsp/investigation/details`;
  try {
    const response = await axios.post(
      url,
      { investigation_hsp_id: investigationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspInvestigation = async (
  token: string,
  investigationId: string
) => {
  let url = `${BASE_URL}/hsp/investigation/status-change`;
  try {
    const response = await axios.post(
      url,
      { investigation_hsp_id: investigationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspInvestigation = async (
  token: string,
  investigationId: string
) => {
  let url = `${BASE_URL}/hsp/investigation/status-change`;
  try {
    const response = await axios.post(
      url,
      { investigation_hsp_id: investigationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteHspInvestigation = async (
  token: string,
  investigationId: string
) => {
  let url = `${BASE_URL}/hsp/investigation/delete`;
  try {
    const response = await axios.post(
      url,
      { hsp_consultation_id: investigationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editHspInvestigation = async (
  token: string,
  investigationData: HspInvestigationType[]
) => {
  let url = `${BASE_URL}/hsp/investigation/edit`;
  try {
    const response = await axios.post(
      url,
      { Investigations: investigationData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspInvestigations,
  createHsInvestigation,
  getHspInvestigationDetails,
  deactivateHspInvestigation,
  activateHspInvestigation,
  deleteHspInvestigation,
  getAllHspInvestigationsByCategory,
  editHspInvestigation,
};
