import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { OptionsType } from "../../types/data";

interface CustomServiceSelectorProps {
  options: OptionsType[];
  selected: string | null;
  handleSetSelected: (e: any) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  name?: string;
  id?: string;
  required?: boolean;
  error?: string;
  selecterFor?: string;
}
const CustomServiceSelector: React.FC<CustomServiceSelectorProps> = ({
  options,
  selected,
  handleSetSelected,
  placeholder,
  searchPlaceholder,
  className,
  style,
  name,
  id,
  required,
  error,
  selecterFor,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  return (
    <div
      className={`relative ${className}`}
      style={{ ...style, minWidth: "10rem" }}
    >
      <div
        onClick={() => setOpen(!open)}
        className={`bg-neutral-100 w-full p-2 flex items-center justify-between rounded ${
          !selected && "text-gray-700"
        }`}
      >
        {selected
          ? selected?.length > 25
            ? selected?.substring(0, 25) + "..."
            : selected
          : placeholder}
        <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
      </div>
      <ul
        className={`absolute top-0 z-10 left-0 bg-neutral-200 mt-2 overflow-y-auto ${
          open ? "max-h-60" : "max-h-0"
        } `}
      >
        <div className="flex items-center px-2 sticky top-0 bg-white border-[1px] rounded-sm">
          <AiOutlineSearch size={18} className="text-gray-700" />
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.toLowerCase())}
            placeholder={searchPlaceholder}
            className="placeholder:text-gray-700 p-2 outline-none"
          />
        </div>
        {options?.map((option: OptionsType) => (
          <li
            key={option.id}
            className={`p-2 text-sm hover:bg-sky-600 hover:text-white
                ${
                  option?.label?.toLowerCase() === selected?.toLowerCase() &&
                  "bg-sky-600 text-white"
                }
                ${
                  option?.label?.toLowerCase().includes(inputValue)
                    ? "block"
                    : "hidden"
                }`}
            onClick={() => {
              handleSetSelected(option.value);
              setOpen(false);
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomServiceSelector;
