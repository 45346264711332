// import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Birthday from "../../components/birthday";
import Stats from "../../components/stats";
import Breadcrumb from "../../components/breadcrumb";
import BarChart from "../../components/barChart";

const DashboardHsp = () => {
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full">
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <Breadcrumb />

          {/* ============================================================== */}
          <div className="container-fluid">
            {/* *************************************************************** */}
            <Stats />

            {/* *************************************************************** */}
            <BarChart />

            {/* *************************************************************** */}
            <Birthday />

            {/* *************************************************************** */}
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>
      </div>
    </>
  );
};

export default DashboardHsp;
