import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Sidebar from "../../components/sidebar";
import MedicalVettingEmailModal from "../../components/medical-vetting/MedicalVettingEmailModal";
import MedicalVettingSMSModal from "../../components/medical-vetting/MedicalVettingSMSModal";
import MedicalVettingPDFModal from "../../components/medical-vetting/MedicalVettingPDFModal";
import MedicalVettingCallSummaryModal from "../../components/medical-vetting/MedicalVettingCallSummaryModal";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const MedVettingDetails = () => {
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Medical Vetting",
                  url: "/medical-vetting",
                },
                {
                  title: "Vetted Claims",
                  url: "/view-med-vetting",
                },
                {
                  title: "Vetted Claim Details",
                  url: "/med-vetting-details",
                },
              ]}
            />
            <div className="row">
              <div className="col-md-7">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-end gap-4 mb-2">
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#call-summary-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-phone mr-2" />
                        Call Summary
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#sms-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-comment mr-2" />
                        SMS
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#email-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-envelope mr-2" />
                        Email
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#pdf-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-file mr-2" />
                        PDF
                      </button>
                    </div>
                    <ul className="nav nav-pills bg-nav-pills nav-justified">
                      <li className="nav-item">
                        <a
                          href="#about1"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded active"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                          <span className="d-none d-lg-block">
                            {" "}
                            Claim Details
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#settings1"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-settings-outline d-lg-none d-block mr-1" />
                          <span className="d-none d-lg-block">BENEFITS</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#profile1"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                          <span className="d-none d-lg-block">
                            ADMIN REVIEW
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#medreview"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                          <span className="d-block d-lg-block">MED REVIEW</span>
                        </a>
                      </li>
                      {/* <li class="nav-item">
                                  
                              </li> */}
                    </ul>
                    {/* sms modal content */}
                    <div
                      id="sms2"
                      className="modal fade"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                              SMS
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                          </div>
                          <div className="modal-body">
                            <form>
                              <div className="form-group">
                                <label>
                                  <i /> Type your Message
                                </label>
                                <textarea
                                  name="comment"
                                  id="summernote"
                                  defaultValue={""}
                                />
                              </div>
                              <div className="form-group">
                                <button
                                  type="submit"
                                  id="comment_btn"
                                  className="btn btn-primary btn-rounded mx-auto bg-primary"
                                >
                                  <i className="fas fa-save" /> Save
                                </button>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-light"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary bg-primary"
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                    {/* EMAIL modal content */}
                    <div
                      id="email1"
                      className="modal fade"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                              Email
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                          </div>
                          <div className="modal-body">
                            <form>
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="form-group">
                                    <label>
                                      <i /> To:
                                    </label>
                                    <input
                                      type="text"
                                      defaultValue="latu@gmail.com"
                                      id="relationThree"
                                      name="relationThree"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="form-group">
                                    <label>
                                      <i /> Cc:
                                    </label>
                                    <input
                                      type="text"
                                      defaultValue="latu@gmail.com"
                                      id="relationThree"
                                      name="relationThree"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="form-group">
                                    <label>
                                      <i className="" /> Subject:
                                    </label>
                                    <input
                                      type="text"
                                      defaultValue="latu@gmail.com"
                                      id="relationThree"
                                      name="relationThree"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>
                                      <i className="" /> Type Your Summary
                                    </label>
                                    <textarea
                                      name="comment"
                                      id="summernote"
                                      cols={10}
                                      rows={2}
                                      defaultValue={
                                        "                                                                    My Comment\n                                                                "
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <button
                                  type="submit"
                                  id="comment_btn"
                                  className="btn btn-primary btn-rounded mx-auto"
                                >
                                  <i className="fas fa-save" /> Save
                                </button>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-light"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" className="btn btn-primary">
                              Save changes
                            </button>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                    <div className="tab-content">
                      <div className="policy-details">
                        <div className="card-header text center">
                          <a>
                            <img
                              className="avatar mx-auto  d-block rounded-circle mb-2"
                              style={{ width: "50px" }}
                              src="assets/images/img1.jpg"
                              alt="image"
                            />
                          </a>
                          <h4 className="text-center">PAPA YAW</h4>
                        </div>
                        <form>
                          <fieldset>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                            />
                            <a href="#">
                              <input
                                type="hidden"
                                name="id"
                                defaultValue={796}
                              />
                            </a>
                            <table className="table " id="dataTable">
                              <tbody>
                                <tr>
                                  <td>Patient Name/ID</td>
                                  <td className="d-flex">
                                    <input
                                      type="email"
                                      defaultValue="Staff ID"
                                      id="email"
                                      name="email"
                                      className="form-control mr-3"
                                    />
                                    <input
                                      type="email"
                                      defaultValue="Dependants ID"
                                      id="email"
                                      name="email"
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td> Patient Name</td>
                                  <td>
                                    <input
                                      type="tel"
                                      defaultValue={"0244861535"}
                                      id="phone"
                                      name="phone"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Relationship</td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue="Pentecost"
                                      id="Pentecost"
                                      name="DOB"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Age</td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue="Pentecost"
                                      id="Pentecost"
                                      name="DOB"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Sex</td>
                                  <td>
                                    <input
                                      type="text"
                                      defaultValue="Pentecost"
                                      id="Pentecost"
                                      name="DOB"
                                      className="form-control"
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Date Visited</td>
                                  <td className="d-flex">
                                    <input
                                      type="email"
                                      defaultValue="20 May 2023 "
                                      id="email"
                                      name="email"
                                      className="form-control mr-3"
                                    />
                                    <input
                                      type="email"
                                      defaultValue=""
                                      id="email"
                                      name="email"
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                      <div
                        className="tab-pane process_claim active"
                        id="about1"
                      >
                        <div className="card-header">
                          <h4 className="card-title mb-3">
                            Clinic &amp; Diagnosis(ICD 10)
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-4 col-md-4">
                              <h4>
                                <strong>General Clinic</strong>
                              </h4>
                            </div>
                            <div className="col-sm-8 col-md-8 d-inline-block justify-content-around">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  OPD
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  IPD
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Maternity(ANC)
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Maternity(PNC)
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-4 col-md-4">
                              <h4>
                                <strong>Special Clinic</strong>
                              </h4>
                            </div>
                            <div className="col-sm-8 col-md-8">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Dental
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Obs &amp; Gyne
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  ENT
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Optical/Ophthalmology
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Dermatology
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Urology
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Cardio
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <tr>
                            <td className="font-bold">Initial Diagnosis</td>
                            <td>
                              <div className="my-2 flex gap-2">
                                <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                  Cholera
                                </span>
                                <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                  Maleria
                                </span>
                                <span className="bg-secondary text-white py-2 px-4 rounded-sm ">
                                  HIV
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold">Final Diagnosis</td>
                            <td>
                              <input
                                type="text"
                                disabled={true}
                                id="final_diagnosis"
                                name="final_diagnosis"
                                placeholder="final diagnosis"
                                className="form-control"
                              />
                            </td>
                          </tr>
                        </div>
                      </div>
                      <div className="tab-pane show" id="profile1">
                        {/* <div class="card-header">
                                      <h4 class="card-title">Leave a Comment</h4>
                                  </div> */}
                        <div className="card-body">
                          <div className="sms">
                            <table id="">
                              <thead className=" text-primary">
                                <tr>
                                  <th>Previous Messages</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="wrap"
                                      style={{ margin: "1em" }}
                                    >
                                      <div className="alert alert-info">
                                        <div className="action d-flex justify-content-between">
                                          <h5 className="">
                                            <strong>By:</strong> Hanson Bansah
                                          </h5>
                                          <h5 className="mx-2">
                                            <strong>Sent on: </strong>12.11.21{" "}
                                            <span className="ml-2">
                                              {" "}
                                              10:30AM
                                            </span>{" "}
                                          </h5>
                                        </div>
                                        <div className="row d-flex">
                                          <div className="col col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            Dear EMMANUEL ANDREWS, kindly send a
                                            copy of the Medical Cause of death
                                            to aid in your claim processing.
                                            Send documents to osa@valifeapps.com
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="settings1">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Treatment &amp; Service Costing
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="services">
                              <h3> Consultation</h3>
                              <div className="table-responsive" id="myTable">
                                <table
                                  id="default_order"
                                  className="table table-sm display"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Qty</th>
                                      <th scope="col">Claim Item</th>
                                      <th scope="col">Unit Cost</th>
                                      <th scope="col">Total Cost</th>
                                      <th scope="col" />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>Medications</td>
                                      <td>200</td>
                                      <td>700</td>
                                      <td>
                                        <input type="checkbox" name="" id="" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>Consultaion</td>
                                      <td>255</td>
                                      <td>700</td>
                                      <td>
                                        <input type="checkbox" name="" id="" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/* Investigations */}
                              <h3> Investigations</h3>
                              <div className="table-responsive" id="myTable">
                                <table
                                  id="default_order"
                                  className="table table-sm display"
                                  style={{ width: "100%" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                    </tr>
                                    <tr>
                                      <td>1</td>
                                      <td>Medications</td>
                                      <td>200</td>
                                      <td>700</td>
                                      <td>
                                        <input type="checkbox" name="" id="" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>Consultaion</td>
                                      <td>255</td>
                                      <td>700</td>
                                      <td>
                                        <input type="checkbox" name="" id="" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/*Surgery and Procedures*/}
                              <h3> Surgery and Procedures</h3>
                              <div className="table-responsive" id="myTable">
                                <table
                                  id="default_order"
                                  className="table table-sm display"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th />
                                      <th />
                                      <th />
                                      <th />
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>Medications</td>
                                      <td>200</td>
                                      <td>700</td>
                                      <td>
                                        <input type="checkbox" name="" id="" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>Consultaion</td>
                                      <td>255</td>
                                      <td>700</td>
                                      <td>
                                        <input type="checkbox" name="" id="" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="tab-content">
                            <div
                              className="tab-pane show active"
                              id="profile-b1"
                            >
                              <form id="invoice_form">
                                <fieldset>
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="UayNnZmZ7BczZLwbt2cv5dnJhp3Upr7yozDgjBgs"
                                  />
                                  <input
                                    type="hidden"
                                    name="user_id"
                                    defaultValue={1}
                                  />
                                  <input
                                    type="hidden"
                                    name="claim_id"
                                    defaultValue={20}
                                  />
                                  <div className="form-group d-flex justify-content-center"></div>
                                </fieldset>
                              </form>
                            </div>
                            <div className="tab-pane" id="home-b1">
                              <div
                                className="card-body"
                                id="printContent"
                              ></div>
                            </div>
                            <div className="tab-pane" id="settings-b1">
                              <p>
                                Food truck quinoa dolor sit amet, consectetuer
                                adipiscing elit. Aenean commodo ligula eget
                                dolor. Aenean massa. Cum sociis natoque
                                penatibus et magnis dis parturient montes,
                                nascetur ridiculus mus. Donec quam felis,
                                ultricies nec, pellentesque eu, pretium quis,
                                sem. Nulla consequat massa quis enim.
                              </p>
                              <p className="mb-0">
                                Donec pede justo, fringilla vel, aliquet nec,
                                vulputate eget, arcu. In enim justo, rhoncus ut,
                                imperdiet a, venenatis vitae, justo. Nullam
                                dictum felis eu pede mollis pretium. Integer
                                tincidunt.Cras dapibus. Vivamus elementum semper
                                nisi. Aenean vulputate eleifend tellus. Aenean
                                leo ligula, porttitor eu, consequat vitae,
                                eleifend ac, enim.
                              </p>
                            </div>
                            <div className="tab-pane" id="income-b1">
                              <div
                                className="card-body"
                                id="printContent"
                              ></div>
                            </div>
                            <div className="tab-pane" id="hospital-b1">
                              <div
                                className="card-body"
                                id="printContent"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <table className="table " id="dataTable">
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="medreview">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  <i className="" /> Medication vs Diagnosis
                                </label>
                                <textarea
                                  name="comment"
                                  id="summernote"
                                  cols={10}
                                  rows={2}
                                  defaultValue={
                                    "                                                            My Comment\n                                                        "
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              id="comment_btn"
                              className="btn btn-primary btn-rounded mx-auto bg-primary"
                            >
                              <i className="fas fa-save" /> Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="card shadow">
                  <div className="card-body">
                    <h4 className="card-title">Med Vetting</h4>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custom-control custom-checkbox flex mb-4 gap-24">
                            <div>
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck20"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck20"
                              >
                                Pass
                              </label>
                            </div>
                            {/* <div>
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck22"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck22"
                              >
                                Advice
                              </label>
                            </div> */}
                            <div>
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck21"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck21"
                              >
                                Reject
                              </label>
                            </div>
                          </div>
                          <hr />
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <i className="" /> Diagnosis vs Investigation
                              mismatch
                            </label>
                            <textarea
                              name=""
                              id="form-control"
                              cols={10}
                              rows={2}
                              defaultValue={
                                "                                                "
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          type="submit"
                          id="comment_btn"
                          className="btn btn-primary btn-sm btn-rounded bg-primary"
                        >
                          <i className="fas fa-save" /> Save
                        </button>
                      </div>
                    </form>
                    <hr />
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <i className="" /> Diagnosis vs Medication
                              mismatch
                            </label>
                            <textarea
                              name=""
                              id="form-control"
                              cols={10}
                              rows={2}
                              defaultValue={
                                "                                                     \n                                                "
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          type="submit"
                          id="comment_btn"
                          className="btn btn-primary btn-sm btn-rounded bg-primary"
                        >
                          <i className="fas fa-save" /> Save
                        </button>
                      </div>
                    </form>
                    <hr />
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <i className="" /> Diagnosis vs Gender-Lab-Med
                              mismatch
                            </label>
                            <textarea
                              name=""
                              id="form-control"
                              cols={10}
                              rows={2}
                              defaultValue={
                                "                                                \n                                                "
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          type="submit"
                          id="comment_btn"
                          className="btn btn-primary btn-sm btn-rounded bg-primary"
                        >
                          <i className="fas fa-save" /> Save
                        </button>
                      </div>
                    </form>
                    <hr />
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <i className="" /> Diagnosis vs Gender-Specialty
                              mismatch
                            </label>
                            <textarea
                              name=""
                              id="form-control"
                              cols={10}
                              rows={2}
                              defaultValue={
                                "                                                    My Comment\n                                                "
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          type="submit"
                          id="comment_btn"
                          className="btn btn-primary btn-sm btn-rounded bg-primary"
                        >
                          <i className="fas fa-save" /> Save
                        </button>
                      </div>
                    </form>
                    <hr />
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <i className="" /> Other Comments
                            </label>
                            <textarea
                              name=""
                              id="form-control"
                              cols={10}
                              rows={2}
                              defaultValue={
                                "                                                    \n                                                "
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          type="submit"
                          id="comment_btn"
                          className="btn btn-primary btn-sm btn-rounded bg-primary"
                        >
                          <i className="fas fa-save" /> Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Email Modal */}
          <MedicalVettingEmailModal />

          {/* SMS Modal */}

          <MedicalVettingSMSModal />

          {/* PDF Modal */}
          <MedicalVettingPDFModal />

          {/* Call Summary */}
          <MedicalVettingCallSummaryModal />

          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default MedVettingDetails;
