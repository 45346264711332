import React, { useEffect, useRef, useState } from "react";
import hspContractsApi from "../../../api/hspContractsApi";
import { Toast } from "primereact/toast";
import convertTimestampToDate from "../../../utils/convertTimestampToDate";
import formatDate from "../../../utils/formatDate";
import truncateString from "../../../utils/truncateString";
import extractFilename from "../../../utils/extractFilename";
import convertDateToInputDateType from "../../../utils/convertDateToInputDateType";
import MasterTableComponent from "../../ui/MasterTableComponent";
import { StyledSearch } from "../../StyledSearch";
import closeAnyModal from "../../../utils/closeAnyModal";

function ContractConditionsTab() {
  const toast = useRef<Toast>(null);
  const [token, setToken] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fetchedContracts, setFetchedContracts] = useState([{}]);
  const [selectedContract, setSelectedContract] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMedicationData, setFilteredMedicationData] = useState<any[]>(
    []
  );
  const [tableRows, setTableRows] = useState<any[]>([]);
  // todo: add toast message
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const [createNewContractBody, setCreateNewContractBody] = useState({
    contract_validity_start_date: "",
    contract_validity_end_date: "",
    applied_tax_rate: "",
    business_registration_number: "",
    tax_identification_number: "",
    nhia_accreditation_status: false,
    nhia_accreditation_date: "",
    nhia_accreditation_verified_by: "",
    business_registration_status: false,
    business_registration_date: "",
    business_registration_verified_by: "",
    officer_in_charge_status: false,
    officer_in_charge_date: "",
    officer_in_charge_verified_by: "",
    proof_of_retention_status: false,
    proof_of_retention_date: "",
    proof_of_retention_verified_by: "",
    hefra_license_status: false,
    hefra_license_date: "",
    hefra_license_verified_by: "",
    tax_exemption_certificate_date: "",
    tax_exemption_certificate_status: false,
    tax_exemption_certificate_verified_by: "",
    data_protection_certificate_status: false,
    data_protection_certificate_date: "",
    data_protection_certificate_verified_by: "",
    service_level_agreement_status: false,
    service_level_agreement_date: "",
    service_level_agreement_verified_by: "",
  });
  const [nhia_accreditation_document, setNhiaAccreditationDocument] =
    useState<File | null>(null);
  const [business_registration_document, setBusinessRegistrationDocument] =
    useState<null | File>(null);

  const [officer_in_charge_document, setOfficerInChargeDocument] =
    useState<null | File>(null);

  const [proof_of_retention_document, setProofOfRetentionDocument] =
    useState<null | File>(null);

  const [hefra_license_document, setHefraLicenseDocument] =
    useState<null | File>(null);

  const [
    tax_exemption_certificate_document,
    setTaxExemptionCertificateDocument,
  ] = useState<null | File>(null);

  const [
    data_protection_certificate_document,
    setDataProtectionCertificateDocument,
  ] = useState<null | File>(null);

  const [service_level_agreement_document, setServiceLevelAgreementDocument] =
    useState<null | File>(null);

  const handleCreateNewContract = async (e: any) => {
    e.preventDefault();
    setIsSaving(true);
    let data = new FormData();
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    data.append("hsp_contract_owner_id", hspId);
    data.append(
      "contract_validity_start_date",
      createNewContractBody.contract_validity_start_date
    );
    data.append(
      "contract_validity_end_date",
      createNewContractBody.contract_validity_end_date
    );
    data.append("applied_tax_rate", createNewContractBody.applied_tax_rate);
    data.append(
      "business_registration_number",
      createNewContractBody.business_registration_number || "--"
    );
    data.append(
      "tax_identification_number",
      createNewContractBody.tax_identification_number || "--"
    );
    data.append(
      "nhia_accreditation_status",
      `${createNewContractBody.nhia_accreditation_status}`
    );
    data.append(
      "nhia_accreditation_date",
      createNewContractBody.nhia_accreditation_date
    );
    data.append(
      "nhia_accreditation_verified_by",
      createNewContractBody.nhia_accreditation_verified_by
    );
    data.append(
      "business_registration_status",
      `${createNewContractBody.business_registration_status}`
    );
    data.append(
      "business_registration_date",
      createNewContractBody.business_registration_date
    );
    data.append(
      "business_registration_verified_by",
      createNewContractBody.business_registration_verified_by
    );
    data.append(
      "officer_in_charge_status",
      `${createNewContractBody.officer_in_charge_status}`
    );
    data.append(
      "officer_in_charge_date",
      createNewContractBody.officer_in_charge_date
    );
    data.append(
      "officer_in_charge_verified_by",
      createNewContractBody.officer_in_charge_verified_by
    );
    data.append(
      "proof_of_retention_status",
      `${createNewContractBody.proof_of_retention_status}`
    );
    data.append(
      "proof_of_retention_date",
      createNewContractBody.proof_of_retention_date
    );
    data.append(
      "proof_of_retention_verified_by",
      createNewContractBody.proof_of_retention_verified_by
    );
    data.append(
      "hefra_license_status",
      `${createNewContractBody.hefra_license_status}`
    );
    data.append("hefra_license_date", createNewContractBody.hefra_license_date);
    data.append(
      "hefra_license_verified_by",
      createNewContractBody.hefra_license_verified_by
    );
    data.append(
      "tax_exemption_certificate_status",
      `${createNewContractBody.tax_exemption_certificate_status}`
    );
    data.append(
      "tax_exemption_certificate_date",
      createNewContractBody.tax_exemption_certificate_date
    );
    data.append(
      "tax_exemption_certificate_verified_by",
      createNewContractBody.tax_exemption_certificate_verified_by
    );
    data.append(
      "data_protection_certificate_status",
      `${createNewContractBody.data_protection_certificate_status}`
    );
    data.append(
      "data_protection_certificate_date",
      createNewContractBody.data_protection_certificate_date
    );
    data.append(
      "data_protection_certificate_verified_by",
      createNewContractBody.data_protection_certificate_verified_by
    );
    data.append(
      "service_level_agreement_status",
      `${createNewContractBody.service_level_agreement_status}`
    );
    data.append(
      "service_level_agreement_date",
      `${createNewContractBody.service_level_agreement_date}`
    );
    data.append(
      "service_level_agreement_verified_by",
      `${createNewContractBody.service_level_agreement_verified_by}`
    );

    if (nhia_accreditation_document) {
      data.append("nhia_accreditation_document", nhia_accreditation_document);
    }
    if (business_registration_document) {
      data.append(
        "business_registration_document",
        business_registration_document
      );
    }
    if (officer_in_charge_document) {
      data.append("officer_in_charge_document", officer_in_charge_document);
    }
    if (proof_of_retention_document) {
      data.append("proof_of_retention_document", proof_of_retention_document);
    }
    if (hefra_license_document) {
      data.append("hefra_license_document", hefra_license_document);
    }
    if (tax_exemption_certificate_document) {
      data.append(
        "tax_exemption_certificate_document",
        tax_exemption_certificate_document
      );
    }
    if (data_protection_certificate_document) {
      data.append(
        "data_protection_certificate_document",
        data_protection_certificate_document
      );
    }
    if (service_level_agreement_document) {
      data.append(
        "service_level_agreement_document",
        service_level_agreement_document
      );
    }
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    const response = await hspContractsApi.createHspContract(tk, data);
    setIsSaving(false);
    if (response.status === 200) {
      show("success", response?.message);
      window.location.reload();
      // closeAnyModal("editContractModal");
    } else {
      show("error", response?.message);
    }
  };
  const handleUpdateContract = async (e: any) => {
    e.preventDefault();
    let data = new FormData();
    data.append("hsp_contract_id", selectedContract.id);
    data.append(
      "contract_validity_start_date",
      createNewContractBody.contract_validity_start_date
    );
    data.append(
      "contract_validity_end_date",
      createNewContractBody.contract_validity_end_date
    );
    data.append("applied_tax_rate", createNewContractBody.applied_tax_rate);
    data.append(
      "business_registration_number",
      createNewContractBody.business_registration_number
    );
    data.append(
      "tax_identification_number",
      createNewContractBody.tax_identification_number
    );
    data.append(
      "nhia_accreditation_status",
      `${createNewContractBody.nhia_accreditation_status}`
    );
    data.append(
      "nhia_accreditation_date",
      createNewContractBody.nhia_accreditation_date
    );
    data.append(
      "nhia_accreditation_verified_by",
      createNewContractBody.nhia_accreditation_verified_by
    );
    data.append(
      "business_registration_status",
      `${createNewContractBody.business_registration_status}`
    );
    data.append(
      "business_registration_date",
      createNewContractBody.business_registration_date
    );
    data.append(
      "business_registration_verified_by",
      createNewContractBody.business_registration_verified_by
    );
    data.append(
      "officer_in_charge_status",
      `${createNewContractBody.officer_in_charge_status}`
    );
    data.append(
      "officer_in_charge_date",
      createNewContractBody.officer_in_charge_date
    );
    data.append(
      "officer_in_charge_verified_by",
      createNewContractBody.officer_in_charge_verified_by
    );
    data.append(
      "proof_of_retention_status",
      `${createNewContractBody.proof_of_retention_status}`
    );
    data.append(
      "proof_of_retention_date",
      createNewContractBody.proof_of_retention_date
    );
    data.append(
      "proof_of_retention_verified_by",
      createNewContractBody.proof_of_retention_verified_by
    );
    data.append(
      "hefra_license_status",
      `${createNewContractBody.hefra_license_status}`
    );
    data.append("hefra_license_date", createNewContractBody.hefra_license_date);
    data.append(
      "hefra_license_verified_by",
      createNewContractBody.hefra_license_verified_by
    );
    data.append(
      "tax_exemption_certificate_status",
      `${createNewContractBody.tax_exemption_certificate_status}`
    );
    data.append(
      "tax_exemption_certificate_date",
      createNewContractBody.tax_exemption_certificate_date
    );
    data.append(
      "tax_exemption_certificate_verified_by",
      createNewContractBody.tax_exemption_certificate_verified_by
    );
    data.append(
      "data_protection_certificate_status",
      `${createNewContractBody.data_protection_certificate_status}`
    );
    data.append(
      "data_protection_certificate_date",
      createNewContractBody.data_protection_certificate_date
    );
    data.append(
      "data_protection_certificate_verified_by",
      createNewContractBody.data_protection_certificate_verified_by
    );
    data.append(
      "service_level_agreement_status",
      `${createNewContractBody.service_level_agreement_status}`
    );
    data.append(
      "service_level_agreement_date",
      `${createNewContractBody.service_level_agreement_date}`
    );
    data.append(
      "service_level_agreement_verified_by",
      `${createNewContractBody.service_level_agreement_verified_by}`
    );

    if (nhia_accreditation_document) {
      data.append("nhia_accreditation_document", nhia_accreditation_document);
    }
    if (business_registration_document) {
      data.append(
        "business_registration_document",
        business_registration_document
      );
    }
    if (officer_in_charge_document) {
      data.append("officer_in_charge_document", officer_in_charge_document);
    }
    if (proof_of_retention_document) {
      data.append("proof_of_retention_document", proof_of_retention_document);
    }
    if (hefra_license_document) {
      data.append("hefra_license_document", hefra_license_document);
    }
    if (tax_exemption_certificate_document) {
      data.append(
        "tax_exemption_certificate_document",
        tax_exemption_certificate_document
      );
    }
    if (data_protection_certificate_document) {
      data.append(
        "data_protection_certificate_document",
        data_protection_certificate_document
      );
    }
    if (service_level_agreement_document) {
      data.append(
        "service_level_agreement_document",
        service_level_agreement_document
      );
    }
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    const response = await hspContractsApi.editHspContract(tk, data);

    if (response.status === 200) {
      show("success", response?.message);
      // closeAnyModal("bs-example-modal-lg");
      window.location.reload();
    } else {
      show("error", response?.message);
    }
  };

  const handleFetchHspContracts = async () => {
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;

    const response = await hspContractsApi.getAllHspContracts(tk, hspId);
    setFetchedContracts(response.info || []);
  };

  const handleDeleteContract = async () => {
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }
    setIsSaving(true);
    const response = await hspContractsApi.deleteHspContract(
      tk,
      selectedContract.id
    );
    if (response.status === 200) {
      setIsSaving(false);
      show("success", response?.message);
      window.location.reload();
    } else {
      setIsSaving(false);
      show("error", response?.message);
    }
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = fetchedContracts.filter((contracts: any) => {
      return contracts?.officer_in_charge_verified_by
        .toLowerCase()
        .includes(query.toLowerCase());
    });

    setFilteredMedicationData(filteredData);
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }

    const fn = async () => {
      handleFetchHspContracts();
    };
    fn();
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery
      ? filteredMedicationData
      : fetchedContracts;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedContracts]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane" id="conditionsTab">
        <div className="row">
          <div className="col-12">
            <div className="card shadow">
              <div className="card-body">
                {/*-----------------------  Modal for Create New Condition/Contract ------------------------*/}
                <div
                  className="modal fade"
                  id="bs-example-modal-lg"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">
                          {" "}
                          Add New Contract
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <form>
                                  <div className="form-body">
                                    {/* step one */}
                                    <div className="step">
                                      <h4 className="card-title py-1 text-primary" />
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-4 col-lg-4">
                                              <label htmlFor="benefit">
                                                {" "}
                                                Contract Validity
                                              </label>
                                              <br />
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-lg-4">
                                              <div className="form-group">
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                  aria-label="start_date"
                                                  value={
                                                    createNewContractBody.contract_validity_start_date
                                                  }
                                                  onChange={(e) =>
                                                    setCreateNewContractBody({
                                                      ...createNewContractBody,
                                                      contract_validity_start_date:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-lg-4">
                                              <div className="form-group">
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                  aria-label="end_date"
                                                  value={
                                                    createNewContractBody.contract_validity_end_date
                                                  }
                                                  onChange={(e) =>
                                                    setCreateNewContractBody({
                                                      ...createNewContractBody,
                                                      contract_validity_end_date:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="plan_type">
                                                  Applied Tax
                                                </label>
                                                <br />
                                                <select
                                                  className="form-control plantype"
                                                  name="plan_type"
                                                  id="mySelection"
                                                  // placeholder="Select Type"
                                                  value={
                                                    createNewContractBody.applied_tax_rate
                                                  }
                                                  onChange={(e) =>
                                                    setCreateNewContractBody({
                                                      ...createNewContractBody,
                                                      applied_tax_rate:
                                                        e.target.value,
                                                    })
                                                  }
                                                >
                                                  <option selected disabled>
                                                    Select
                                                  </option>
                                                  <option
                                                    value="Tax Exempted"
                                                    className="custom"
                                                  >
                                                    Tax Exempted
                                                  </option>
                                                  <option
                                                    value="Withholding Tax"
                                                    className="custom"
                                                  >
                                                    Withholding Tax
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            {/* <div className="col-sm-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                              <label htmlFor="benefit">
                                                                Business
                                                                Registration No.
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-label="Amoun"
                                                                defaultValue=""
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                              <label htmlFor="benefit">
                                                                Tax
                                                                Identification
                                                                No.
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-label="Amount"
                                                                defaultValue=""
                                                              />
                                                            </div>
                                                          </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <h4 class="card-title py-3 text-primary">Benefit</h4> */}
                                      {/* <hr class="bg-primary"> */}
                                      <div className="row">
                                        <div className="col col-md-12">
                                          <div
                                            className="table-responsive"
                                            id="myTable"
                                          >
                                            <table
                                              id="default"
                                              className="table table-sm display"
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th scope="col" />
                                                  <th scope="col">Verify</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">
                                                    Verified by
                                                  </th>
                                                  <th scope="col">Documents</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th scope="row">
                                                    NHIA Accreditation
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.nhia_accreditation_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            nhia_accreditation_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.nhia_accreditation_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            nhia_accreditation_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.nhia_accreditation_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            nhia_accreditation_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setNhiaAccreditationDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Business Registration
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.business_registration_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            business_registration_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.business_registration_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            business_registration_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.business_registration_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            business_registration_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setBusinessRegistrationDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Officer In-charge (Prof.
                                                    Certification)
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.officer_in_charge_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            officer_in_charge_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.officer_in_charge_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            officer_in_charge_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.officer_in_charge_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            officer_in_charge_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setOfficerInChargeDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Proof of Retention
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.proof_of_retention_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            proof_of_retention_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.proof_of_retention_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            proof_of_retention_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.proof_of_retention_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            proof_of_retention_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setProofOfRetentionDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    HEFRA License
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.hefra_license_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            hefra_license_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.hefra_license_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            hefra_license_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.hefra_license_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            hefra_license_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setHefraLicenseDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Tax Exemtion Cert.
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.tax_exemption_certificate_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            tax_exemption_certificate_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.tax_exemption_certificate_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            tax_exemption_certificate_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.tax_exemption_certificate_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            tax_exemption_certificate_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setTaxExemptionCertificateDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Data Protection Cert.
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.data_protection_certificate_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            data_protection_certificate_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.data_protection_certificate_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            data_protection_certificate_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.data_protection_certificate_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            data_protection_certificate_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setDataProtectionCertificateDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Service Level Agreement
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        createNewContractBody.service_level_agreement_status
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            service_level_agreement_status:
                                                              e.target.checked,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.service_level_agreement_date
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            service_level_agreement_date:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        createNewContractBody.service_level_agreement_verified_by
                                                      }
                                                      onChange={(e) =>
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            service_level_agreement_verified_by:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setServiceLevelAgreementDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        Choose file
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* step three */}
                                    <div className="step"></div>
                                    <hr />
                                    <div className="submit-btn">
                                      <button
                                        type="submit"
                                        onClick={handleCreateNewContract}
                                        className="btn btn-primary btn-sm float-right mt-3 bg-primary rounded-pill"
                                      >
                                        {isSaving ? (
                                          <div
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          <>
                                            <i className="fas fa-paper-plane" />{" "}
                                            Save
                                          </>
                                        )}
                                      </button>
                                    </div>
                                    {/* end previous / next buttons */}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
                {/* /.modal */}
                {/*-----------------------  Modal for Edit Condition/Contract ------------------------*/}
                <div
                  className="modal fade"
                  id="editContractModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">
                          {" "}
                          Edit Contract
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <form>
                                  <div className="form-body">
                                    {/* step one */}
                                    <div className="step">
                                      <h4 className="card-title py-1 text-primary" />
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                          <div className="row">
                                            <div className="col-sm-4 col-md-4 col-lg-4">
                                              <label htmlFor="benefit">
                                                {" "}
                                                Contract Validity
                                              </label>
                                              <br />
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-lg-4">
                                              <div className="form-group">
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                  aria-label="start_date"
                                                  value={
                                                    selectedContract?.contract_validity_start_date
                                                  }
                                                  onChange={(e) =>
                                                    setSelectedContract({
                                                      ...selectedContract,
                                                      contract_validity_start_date:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-lg-4">
                                              <div className="form-group">
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                  aria-label="end_date"
                                                  value={
                                                    selectedContract.contract_validity_end_date
                                                  }
                                                  onChange={(e) =>
                                                    setSelectedContract({
                                                      ...selectedContract,
                                                      contract_validity_end_date:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="plan_type">
                                                  Applied Tax
                                                </label>
                                                <br />
                                                <select
                                                  className="form-control plantype"
                                                  name="plan_type"
                                                  id="mySelection"
                                                  // placeholder="Select Type"
                                                  value={
                                                    createNewContractBody.applied_tax_rate
                                                  }
                                                  onChange={(e) =>
                                                    setCreateNewContractBody({
                                                      ...createNewContractBody,
                                                      applied_tax_rate:
                                                        e.target.value,
                                                    })
                                                  }
                                                >
                                                  <option selected disabled>
                                                    Select
                                                  </option>
                                                  <option
                                                    value="Tax Exempted"
                                                    className="custom"
                                                  >
                                                    Tax Exempted
                                                  </option>
                                                  <option
                                                    value="Withholding Tax"
                                                    className="custom"
                                                  >
                                                    Withholding Tax
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-md-12">
                                          <div
                                            className="table-responsive"
                                            id="myTable"
                                          >
                                            <table
                                              id="default"
                                              className="table table-sm display"
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th scope="col" />
                                                  <th scope="col">Verify</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">
                                                    Verified by
                                                  </th>
                                                  <th scope="col">Documents</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th scope="row">
                                                    NHIA Accreditation
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      defaultChecked={
                                                        selectedContract.nhia_accreditation_status
                                                      }
                                                      checked={
                                                        selectedContract.nhia_accreditation_status
                                                      }
                                                      onChange={(e) =>
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          nhia_accreditation_status:
                                                            e.target.checked,
                                                        })
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      defaultValue={
                                                        selectedContract.nhia_accreditation_date
                                                      }
                                                      value={
                                                        selectedContract.nhia_accreditation_date
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            nhia_accreditation_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          nhia_accreditation_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      defaultValue={
                                                        selectedContract.nhia_accreditation_verified_by
                                                      }
                                                      value={
                                                        selectedContract.nhia_accreditation_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            nhia_accreditation_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          nhia_accreditation_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setNhiaAccreditationDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {truncateString(
                                                          extractFilename(
                                                            selectedContract.nhia_accreditation_document
                                                          ),
                                                          20
                                                        )}
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Business Registration
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      defaultChecked={
                                                        selectedContract.business_registration_status
                                                      }
                                                      checked={
                                                        selectedContract.business_registration_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            business_registration_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          business_registration_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.business_registration_date
                                                      )}
                                                      // value={
                                                      //   selectedContract.business_registration_date
                                                      // }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            business_registration_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          business_registration_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.business_registration_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            business_registration_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          business_registration_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setBusinessRegistrationDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {truncateString(
                                                          extractFilename(
                                                            selectedContract.business_registration_document
                                                          ),
                                                          20
                                                        )}
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Officer In-charge (Prof.
                                                    Certification)
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        selectedContract.officer_in_charge_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            officer_in_charge_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          officer_in_charge_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.officer_in_charge_date
                                                      )}
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            officer_in_charge_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          officer_in_charge_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.officer_in_charge_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            officer_in_charge_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          officer_in_charge_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setOfficerInChargeDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {truncateString(
                                                          extractFilename(
                                                            selectedContract.officer_in_charge_document
                                                          ),
                                                          20
                                                        )}
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Proof of Retention
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        selectedContract.proof_of_retention_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            proof_of_retention_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          proof_of_retention_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.proof_of_retention_date
                                                      )}
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            proof_of_retention_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          proof_of_retention_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.proof_of_retention_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            proof_of_retention_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          proof_of_retention_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setProofOfRetentionDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {
                                                          truncateString(
                                                            extractFilename(
                                                              selectedContract.proof_of_retention_document
                                                            ),
                                                            20
                                                          )
                                                          // selectedContract.proof_of_retention_document
                                                        }
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    HEFRA License
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        selectedContract.hefra_license_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            hefra_license_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          hefra_license_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.hefra_license_date
                                                      )}
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            hefra_license_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          hefra_license_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.hefra_license_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            hefra_license_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          hefra_license_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setHefraLicenseDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {
                                                          truncateString(
                                                            extractFilename(
                                                              selectedContract.hefra_license_document
                                                            ),
                                                            20
                                                          )
                                                          // selectedContract.hefra_license_document
                                                        }
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Tax Exemtion Cert.
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        selectedContract.tax_exemption_certificate_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            tax_exemption_certificate_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          tax_exemption_certificate_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.tax_exemption_certificate_date
                                                      )}
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            tax_exemption_certificate_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          tax_exemption_certificate_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.tax_exemption_certificate_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            tax_exemption_certificate_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          tax_exemption_certificate_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setTaxExemptionCertificateDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {
                                                          truncateString(
                                                            extractFilename(
                                                              selectedContract.tax_exemption_certificate_document
                                                            ),
                                                            20
                                                          )
                                                          // selectedContract.tax_exemption_certificate_document
                                                        }
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Data Protection Cert.
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        selectedContract.data_protection_certificate_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            data_protection_certificate_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          data_protection_certificate_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.data_protection_certificate_date
                                                      )}
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            data_protection_certificate_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          data_protection_certificate_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.data_protection_certificate_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            data_protection_certificate_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          data_protection_certificate_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setDataProtectionCertificateDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {
                                                          truncateString(
                                                            extractFilename(
                                                              selectedContract.data_protection_certificate_document
                                                            ),
                                                            20
                                                          )
                                                          // selectedContract.data_protection_certificate_document
                                                        }
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th scope="row">
                                                    Service Level Agreement
                                                  </th>
                                                  <td className="text-center pt-2">
                                                    <input
                                                      type="checkbox"
                                                      className=""
                                                      checked={
                                                        selectedContract.service_level_agreement_status
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            service_level_agreement_status:
                                                              e.target.checked,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          service_level_agreement_status:
                                                            e.target.checked,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      value={convertDateToInputDateType(
                                                        selectedContract.service_level_agreement_date
                                                      )}
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            service_level_agreement_date:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          service_level_agreement_date:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        selectedContract.service_level_agreement_verified_by
                                                      }
                                                      onChange={(e) => {
                                                        setCreateNewContractBody(
                                                          {
                                                            ...createNewContractBody,
                                                            service_level_agreement_verified_by:
                                                              e.target.value,
                                                          }
                                                        );
                                                        setSelectedContract({
                                                          ...selectedContract,
                                                          service_level_agreement_verified_by:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="custom-file">
                                                      <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        className="custom-file-input bg-primary"
                                                        id="inputGroupFile01"
                                                        onChange={(e) => {
                                                          if (e.target.files) {
                                                            setServiceLevelAgreementDocument(
                                                              e.target.files[0]
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <label
                                                        className="custom-file-label"
                                                        htmlFor="inputGroupFile01"
                                                      >
                                                        {
                                                          truncateString(
                                                            extractFilename(
                                                              selectedContract.service_level_agreement_document
                                                            ),
                                                            20
                                                          )
                                                          // selectedContract.service_level_agreement_document
                                                        }
                                                      </label>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* step three */}
                                    <div className="step"></div>
                                    <hr />
                                    <div className="submit-btn">
                                      <button
                                        type="submit"
                                        onClick={handleUpdateContract}
                                        className="btn btn-primary btn-sm float-right mt-3 bg-primary rounded-pill"
                                      >
                                        <i className="fas fa-paper-plane" />{" "}
                                        Save
                                      </button>
                                    </div>
                                    {/* end previous / next buttons */}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
                {/* /.modal */}

                {/*-----------------------  View Condition/Contract Modal ------------------------*/}
                <div
                  className="modal fade"
                  id="viewContractDetailsModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">
                          {" "}
                          View Contract Details
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="col-md-12 row">
                          <div className="col-md-6">
                            <label htmlFor="benefit"> Contract Validity</label>
                          </div>
                          <div className="col-md-6 flex gap-4">
                            <input
                              type="text"
                              className="form-control"
                              aria-label="Amount"
                              disabled={true}
                              placeholder="Jan 12, 2023"
                              value={
                                selectedContract?.contract_validity_start_date
                              }
                            />
                            <input
                              type="text"
                              className="form-control"
                              aria-label="Amount"
                              disabled={true}
                              placeholder="Jan 23, 2024"
                              value={
                                selectedContract?.contract_validity_end_date
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12 row mt-4">
                          <div className="col-md-6">
                            <label htmlFor="applied_tax">
                              {" "}
                              Applied Tax Rate
                            </label>
                          </div>
                          <div className="col-md-6 flex gap-4">
                            <input
                              type="number"
                              className="form-control"
                              aria-label="applied_tax"
                              disabled={true}
                              placeholder="34"
                            />
                          </div>
                        </div>
                        <div className="table-responsive mt-6">
                          <table className="table table-sm display  border">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Verification Date</th>
                                <th scope="col">Verified By</th>
                                <th scope="col">Documents</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>NHIA Accreditation</td>
                                <td>Jan 3, 2023</td>
                                <td>Jack Ntow</td>
                                <td className="text-primary cursor-pointer">
                                  nhia_accreditation.pdf
                                </td>
                              </tr>
                              <tr>
                                <td>Bussiness Certificate</td>
                                <td>Jan 3, 2023</td>
                                <td>Ampedu Isaiah</td>
                                <td className="text-primary cursor-pointer">
                                  bussiness_certificate.pdf
                                </td>
                              </tr>
                              <tr>
                                <td>Cert. of Medical Director</td>
                                <td>May 8, 2023</td>
                                <td>Geokaef Agbleke</td>
                                <td className="text-primary cursor-pointer">
                                  cert_of_medical_director.pdf
                                </td>
                              </tr>
                              <tr>
                                <td>HEFRA License</td>
                                <td>June 23, 2023</td>
                                <td>Jakobu Timothy</td>
                                <td className="text-primary cursor-pointer">
                                  hefra_license.pdf
                                </td>
                              </tr>
                              <tr>
                                <td>Proof of Retention</td>
                                <td>Auguest 30, 2023</td>
                                <td>Alimo Seidu</td>
                                <td className="text-primary cursor-pointer">
                                  proof_of_retention.pdf
                                </td>
                              </tr>
                              <tr>
                                <td>Service Level Agreement </td>
                                <td>Jan 21, 2023</td>
                                <td>Alimo Seidu</td>
                                <td className="text-primary cursor-pointer">
                                  sla.pdf
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*----------------------- End  View Condition/Contract Modal ------------------------*/}

                {/*-----------------------  Delete Contract Modal ------------------------*/}
                <div
                  className="modal fade"
                  id="deleteContractModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">
                          {" "}
                          Confirm Delete
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <h4 className="text-center">
                          Contract will be permanently deleted from the system.
                        </h4>
                        <h4 className="text-center">
                          Please confirm deletion of contract with id{" "}
                          <span className="text-red-500">
                            {selectedContract?.id}
                          </span>
                          ?
                        </h4>
                        <div className="flex justify-end gap-4 mt-6">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm bg-red-500 rounded-pill"
                            data-dismiss="modal"
                          >
                            <i className="fas fa-times" /> No
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm bg-primary rounded-pill"
                            data-dismiss="modal"
                            onClick={handleDeleteContract}
                          >
                            {isSaving ? (
                              <>
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </>
                            ) : (
                              <>
                                <i className="fas fa-check" /> Yes
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <StyledSearch
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="🔍  by Verified by"
                  />
                  <button
                    type="button"
                    className="btn btn-info btn-sm mb-4 bg-primary rounded-pill"
                    data-toggle="modal"
                    data-target="#bs-example-modal-lg"
                  >
                    <i className="fas fa-plus" /> Add Contract
                  </button>
                </div>
                <div className="table-responsive">
                  <MasterTableComponent
                    headers={[
                      "SN",
                      "Contract Validity",
                      "No. of Docs",
                      "Date Verified",
                      "Verified by",
                      "Action",
                    ]}
                    emptyTableMessage="No Contract found"
                    tableRows={
                      tableRows &&
                      tableRows.map((contracts: any, index: number) => {
                        let number_of_documents = 0;
                        if (contracts.service_level_agreement_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.data_protection_certificate_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.tax_exemption_certificate_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.hefra_license_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.proof_of_retention_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.officer_in_charge_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.business_registration_document) {
                          number_of_documents += 1;
                        }
                        if (contracts.nhia_accreditation_document) {
                          number_of_documents += 1;
                        }

                        return {
                          SN: index + 1,
                          "Contract Validity": `${formatDate(
                            contracts?.contract_validity_start_date
                          )} - ${formatDate(
                            contracts?.contract_validity_end_date
                          )}`,
                          "No. of Docs": number_of_documents,
                          "Date Verified": formatDate(contracts?.created_at),
                          "Verified by":
                            contracts?.officer_in_charge_verified_by,
                          Action: (
                            <div className="flex items-center">
                              <a
                                href="/view-contract-details"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "selectedContract",
                                    JSON.stringify(contracts)
                                  );
                                }}
                              >
                                <i className="fas fa-eye text-primary" />
                              </a>
                              <span
                                style={{ zIndex: 200 }}
                                className="mx-1"
                                data-toggle="modal"
                                data-target="#editContractModal"
                                onClick={() => {
                                  setSelectedContract(contracts);
                                  console.log(contracts);
                                }}
                              >
                                <i className="fas fa-edit text-primary" />
                              </span>
                              <span
                                // className="btn"
                                data-toggle="modal"
                                data-target="#deleteContractModal"
                                onClick={() => {
                                  setSelectedContract(contracts);
                                }}
                              >
                                <i className="fas fa-trash text-primary" />
                              </span>
                            </div>
                          ),
                        };
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractConditionsTab;
