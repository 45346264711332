// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import manageSchemeApi from "../../api/manageSchemeApi";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";

const ViewManageScheme = () => {
  //@ desc - get staff_id from location search
  const toast = useRef<Toast>(null);

  const { search } = useLocation();
  const { scheme_id, action } = queryString.parse(search);
  const [currentMode, setCurrentMode] = useState<string>("view");

  const [manageSchemeData, setManageSchemeData] = useState<any>([]);
  const [authToken, setAuthToken] = useState<string | null>("");

  useEffect(() => {
    setCurrentMode(action as string);

    const token = sessionStorage.getItem("token");

    if (token) {
      setAuthToken(token);
    }
    manageSchemeApi
      .getSchemeDetails(token || "", scheme_id as string)
      .then((res) => {
        setManageSchemeData(res.info);
        console.log(res.info);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [scheme_id]);

  // total number of dependents
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        total_number_of_dependents:
          (Number(prevState.other_dependents) || 0) +
          (Number(prevState.total_number_of_children) || 0),
      };
    });
  }, [
    manageSchemeData.other_dependents,
    manageSchemeData.total_number_of_children,
  ]);

  // updating principal total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_principal_for_opd_ipd_dental_optical:
          (Number(prevState.opd_principal) || 0) +
          (Number(prevState.ipd_principal) || 0) +
          (Number(prevState.dental_principal) || 0) +
          (Number(prevState.optical_principal) || 0),
      };
    });
  }, [
    manageSchemeData.opd_principal,
    manageSchemeData.ipd_principal,
    manageSchemeData.dental_principal,
    manageSchemeData.optical_principal,
  ]);

  // updating spouse total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_spouse_for_opd_ipd_dental_optical:
          (Number(prevState.spouse_opd) || 0) +
          (Number(prevState.spouse_ipd) || 0) +
          (Number(prevState.spouse_dental) || 0) +
          (Number(prevState.spouse_optical) || 0),
      };
    });
  }, [
    manageSchemeData.spouse_opd,
    manageSchemeData.spouse_ipd,
    manageSchemeData.spouse_dental,
    manageSchemeData.spouse_optical,
  ]);

  // updating child one total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_child_one_for_opd_ipd_dental_optical:
          (Number(prevState.opd_child_one) || 0) +
          (Number(prevState.ipd_child_one) || 0) +
          (Number(prevState.dental_child_one) || 0) +
          (Number(prevState.optical_child_one) || 0),
      };
    });
  }, [
    manageSchemeData.opd_child_one,
    manageSchemeData.ipd_child_one,
    manageSchemeData.dental_child_one,
    manageSchemeData.optical_child_one,
  ]);

  // updating child two total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_child_two_for_opd_ipd_dental_optical:
          (Number(prevState.opd_child_two) || 0) +
          (Number(prevState.ipd_child_two) || 0) +
          (Number(prevState.dental_child_two) || 0) +
          (Number(prevState.optical_child_two) || 0),
      };
    });
  }, [
    manageSchemeData.opd_child_two,
    manageSchemeData.ipd_child_two,
    manageSchemeData.dental_child_two,
    manageSchemeData.optical_child_two,
  ]);

  // updating child three total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_child_three_for_opd_ipd_dental_optical:
          (Number(prevState.opd_child_three) || 0) +
          (Number(prevState.ipd_child_three) || 0) +
          (Number(prevState.dental_child_three) || 0) +
          (Number(prevState.optical_child_three) || 0),
      };
    });
  }, [
    manageSchemeData.opd_child_three,
    manageSchemeData.ipd_child_three,
    manageSchemeData.dental_child_three,
    manageSchemeData.optical_child_three,
  ]);

  // updating child four total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_child_four_for_opd_ipd_dental_optical:
          (Number(prevState.opd_child_four) || 0) +
          (Number(prevState.ipd_child_four) || 0) +
          (Number(prevState.dental_child_four) || 0) +
          (Number(prevState.optical_child_four) || 0),
      };
    });
  }, [
    manageSchemeData.opd_child_four,
    manageSchemeData.ipd_child_four,
    manageSchemeData.dental_child_four,
    manageSchemeData.optical_child_four,
  ]);

  // updating other dependents total
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_other_dependents_for_opd_ipd_dental_optical:
          (Number(prevState.opd_other_dependents) || 0) +
          (Number(prevState.ipd_other_dependents) || 0) +
          (Number(prevState.dental_other_dependents) || 0) +
          (Number(prevState.optical_other_dependents) || 0),
      };
    });
  }, [
    manageSchemeData.opd_other_dependents,
    manageSchemeData.ipd_other_dependents,
    manageSchemeData.dental_other_dependents,
    manageSchemeData.optical_other_dependents,
  ]);

  // updating total opd benefit
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_all_opd_benefit:
          (Number(prevState.opd_principal) || 0) +
          (Number(prevState.spouse_opd) || 0) +
          (Number(prevState.opd_child_one) || 0) +
          (Number(prevState.opd_child_two) || 0) +
          (Number(prevState.opd_child_three) || 0) +
          (Number(prevState.opd_child_four) || 0) +
          (Number(prevState.opd_other_dependents) || 0),
      };
    });
  }, [
    manageSchemeData.opd_principal,
    manageSchemeData.spouse_opd,
    manageSchemeData.opd_child_one,
    manageSchemeData.opd_child_two,
    manageSchemeData.opd_child_three,
    manageSchemeData.opd_child_four,
    manageSchemeData.opd_other_dependents,
  ]);

  // updating total ipd benefit
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_all_ipd_benefit:
          (Number(prevState.ipd_principal) || 0) +
          (Number(prevState.spouse_ipd) || 0) +
          (Number(prevState.ipd_child_one) || 0) +
          (Number(prevState.ipd_child_two) || 0) +
          (Number(prevState.ipd_child_three) || 0) +
          (Number(prevState.ipd_child_four) || 0) +
          (Number(prevState.ipd_other_dependents) || 0),
      };
    });
  }, [
    manageSchemeData.ipd_principal,
    manageSchemeData.spouse_ipd,
    manageSchemeData.ipd_child_one,
    manageSchemeData.ipd_child_two,
    manageSchemeData.ipd_child_three,
    manageSchemeData.ipd_child_four,
    manageSchemeData.ipd_other_dependents,
  ]);

  // updating total dental benefit
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_all_dental_benefit:
          (Number(prevState.dental_principal) || 0) +
          (Number(prevState.spouse_dental) || 0) +
          (Number(prevState.dental_child_one) || 0) +
          (Number(prevState.dental_child_two) || 0) +
          (Number(prevState.dental_child_three) || 0) +
          (Number(prevState.dental_child_four) || 0) +
          (Number(prevState.dental_other_dependents) || 0),
      };
    });
  }, [
    manageSchemeData.dental_principal,
    manageSchemeData.spouse_dental,
    manageSchemeData.dental_child_one,
    manageSchemeData.dental_child_two,
    manageSchemeData.dental_child_three,
    manageSchemeData.dental_child_four,
    manageSchemeData.dental_other_dependents,
  ]);

  // updating total optical benefit
  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_all_optical_benefit:
          (Number(prevState.optical_principal) || 0) +
          (Number(prevState.spouse_optical) || 0) +
          (Number(prevState.optical_child_one) || 0) +
          (Number(prevState.optical_child_two) || 0) +
          (Number(prevState.optical_child_three) || 0) +
          (Number(prevState.optical_child_four) || 0) +
          (Number(prevState.optical_other_dependents) || 0),
      };
    });
  }, [
    manageSchemeData.optical_principal,
    manageSchemeData.spouse_optical,
    manageSchemeData.optical_child_one,
    manageSchemeData.optical_child_two,
    manageSchemeData.optical_child_three,
    manageSchemeData.optical_child_four,
    manageSchemeData.optical_other_dependents,
  ]);

  // updating total benefit

  useEffect(() => {
    setManageSchemeData((prevState: any) => {
      return {
        ...prevState,
        sum_of_everything:
          (Number(prevState.sum_of_all_opd_benefit) || 0) +
          (Number(prevState.sum_of_all_ipd_benefit) || 0) +
          (Number(prevState.sum_of_all_dental_benefit) || 0) +
          (Number(prevState.sum_of_all_optical_benefit) || 0),
      };
    });
  }, [
    manageSchemeData.sum_of_all_opd_benefit,
    manageSchemeData.sum_of_all_ipd_benefit,
    manageSchemeData.sum_of_all_dental_benefit,
    manageSchemeData.sum_of_all_optical_benefit,
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/manage_scheme/edit`,
        {
          id: scheme_id,
          scheme_name: manageSchemeData.scheme_name,
          scheme_type: manageSchemeData.scheme_type,
          total_number_of_children: manageSchemeData.total_number_of_children,
          other_dependents: manageSchemeData.other_dependents,
          total_number_of_dependents:
            manageSchemeData.total_number_of_dependents,
          staff_category_junior_staff:
            manageSchemeData.staff_category_junior_staff,
          staff_category_senior_staff:
            manageSchemeData.staff_category_senior_staff,
          staff_category_executive: manageSchemeData.staff_category_executive,
          staff_category_retirees: manageSchemeData.staff_category_retirees,
          staff_category_others: manageSchemeData.staff_category_others,
          opd_principal: manageSchemeData.opd_principal,
          opd_spouse: manageSchemeData.opd_spouse,
          opd_child_one: manageSchemeData.opd_child_one,
          opd_child_two: manageSchemeData.opd_child_two,
          opd_child_three: manageSchemeData.opd_child_three,
          opd_child_four: manageSchemeData.opd_child_four,
          opd_other_dependents: manageSchemeData.opd_other_dependents,
          sum_of_all_opd_benefit: manageSchemeData.sum_of_all_opd_benefit,
          ipd_principal: manageSchemeData.ipd_principal,
          ipd_spouse: manageSchemeData.ipd_spouse,
          ipd_child_one: manageSchemeData.ipd_child_one,
          ipd_child_two: manageSchemeData.ipd_child_two,
          ipd_child_three: manageSchemeData.ipd_child_three,
          ipd_child_four: manageSchemeData.ipd_child_four,
          ipd_other_dependents: manageSchemeData.ipd_other_dependents,
          sum_of_all_ipd_benefit: manageSchemeData.sum_of_all_ipd_benefit,
          dental_principal: manageSchemeData.dental_principal,
          dental_spouse: manageSchemeData.dental_spouse,
          dental_child_one: manageSchemeData.dental_child_one,
          dental_child_two: manageSchemeData.dental_child_two,
          dental_child_three: manageSchemeData.dental_child_three,
          dental_child_four: manageSchemeData.dental_child_four,
          dental_other_dependents: manageSchemeData.dental_other_dependents,
          sum_of_all_dental_benefit: manageSchemeData.sum_of_all_dental_benefit,
          optical_principal: manageSchemeData.optical_principal,
          optical_spouse: manageSchemeData.optical_spouse,
          optical_child_one: manageSchemeData.optical_child_one,
          optical_child_two: manageSchemeData.optical_child_two,
          optical_child_three: manageSchemeData.optical_child_three,
          optical_child_four: manageSchemeData.optical_child_four,
          optical_other_dependents: manageSchemeData.optical_other_dependents,
          sum_of_all_optical_benefit:
            manageSchemeData.sum_of_all_optical_benefit,
          sum_of_everything: manageSchemeData.sum_of_everything,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("res.data", res.data);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Scheme Updated Successfully",
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Manage Scheme",
                  url: "/manage-scheme",
                },
                {
                  title: "Schemes",
                  url: "/manage-scheme",
                },
                {
                  title: "Scheme Details",
                  url: "/manage-scheme",
                },
              ]}
            />
            <div className="col-sm-10 offset-1">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">
                        {manageSchemeData.scheme_name === ""
                          ? "Scheme"
                          : manageSchemeData.scheme_name}{" "}
                        Details
                      </h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Scheme Type</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={manageSchemeData.scheme_type}
                                  id="email"
                                  name="email"
                                  disabled
                                  className="form-control"
                                  onChange={(e) => {
                                    setManageSchemeData({
                                      ...manageSchemeData,
                                      scheme_type: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Scheme Name</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={manageSchemeData.scheme_name}
                                  disabled={currentMode === "view"}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  onChange={(e) => {
                                    setManageSchemeData({
                                      ...manageSchemeData,
                                      scheme_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Total Benefit</td>
                              <td>
                                <input
                                  type="number"
                                  disabled
                                  defaultValue={
                                    manageSchemeData.sum_of_everything
                                  }
                                  id="email"
                                  name="email"
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Number of Children</td>
                              <td>
                                <input
                                  type="number"
                                  value={
                                    manageSchemeData.total_number_of_children
                                  }
                                  defaultValue={
                                    manageSchemeData.total_number_of_children
                                  }
                                  disabled={currentMode === "view"}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  onChange={(e) => {
                                    if (e.target.value === "") return;

                                    if (
                                      Number(e.target.value) >= 0 &&
                                      Number(e.target.value) <= 4
                                    ) {
                                      setManageSchemeData({
                                        ...manageSchemeData,
                                        total_number_of_children:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Other Dependants</td>
                              <td>
                                <input
                                  type="number"
                                  defaultValue={
                                    manageSchemeData.other_dependents
                                  }
                                  value={manageSchemeData.other_dependents}
                                  disabled={currentMode === "view"}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  onChange={(e) => {
                                    if (e.target.value === "") return;
                                    if (
                                      Number(e.target.value) >= 0 &&
                                      Number(e.target.value) <= 2
                                    ) {
                                      setManageSchemeData({
                                        ...manageSchemeData,
                                        other_dependents: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Total Number of Dependants</td>
                              <td>
                                <input
                                  type="number"
                                  disabled
                                  defaultValue={
                                    manageSchemeData.total_number_of_dependents
                                  }
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  onChange={(e) => {
                                    setManageSchemeData({
                                      ...manageSchemeData,
                                      total_number_of_dependents:
                                        e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      {/* staff */}

                      <fieldset>
                        <h4 className="card-title text-primary">
                          Staff Category
                        </h4>
                        <div className="d-flex justify-content-between">
                          <div className="form-group mb-3 align-items-center d-inline">
                            <input
                              type="checkbox"
                              disabled={currentMode === "view"}
                              id="age1"
                              name="age"
                              defaultChecked={
                                manageSchemeData.staff_category_junior_staff
                              }
                            />
                            <label htmlFor="age1" className="mr-3">
                              Junior Staff
                            </label>
                            <input
                              disabled={currentMode === "view"}
                              type="checkbox"
                              id="age2"
                              name="age"
                              defaultChecked={
                                manageSchemeData.staff_category_senior_staff
                              }
                            />
                            <label htmlFor="age2" className="mr-3">
                              Senior Staff
                            </label>
                            <input
                              disabled={currentMode === "view"}
                              type="checkbox"
                              id="age3"
                              name="age"
                              defaultChecked={
                                manageSchemeData.staff_category_executive
                              }
                            />
                            <label htmlFor="age3" className="mr-3">
                              Executive
                            </label>
                            <input
                              disabled={currentMode === "view"}
                              type="checkbox"
                              id="age3"
                              name="age"
                              defaultChecked={
                                manageSchemeData.staff_category_retirees
                              }
                            />
                            <label htmlFor="age3" className="mr-3">
                              Retirees
                            </label>
                            <input
                              disabled={currentMode === "view"}
                              type="checkbox"
                              id="age4"
                              name="age"
                              defaultChecked={
                                manageSchemeData.staff_category_others
                              }
                            />
                            <label htmlFor="age4">Others</label>
                          </div>
                        </div>
                      </fieldset>

                      {/* bedefits */}
                      <fieldset style={{ overflowX: "scroll" }}>
                        <h4 className="card-title text-primary">Benefits</h4>
                        <div className="col col-md-12">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th scope="col" />
                                <th scope="col" />
                                <th scope="col">
                                  OPD{"("}GHS{")"}
                                </th>
                                <th scope="col">
                                  IPD{"("}GHS{")"}
                                </th>
                                <th scope="col">Dental</th>
                                <th scope="col">Optical</th>
                                <th scope="col">
                                  Total{"("}GHS{")"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Principal</th>
                                <td />
                                <td>
                                  <input
                                    type="number"
                                    min={0}
                                    disabled={currentMode === "view"}
                                    className="form-control"
                                    defaultValue={
                                      manageSchemeData.opd_principal
                                    }
                                    onChange={(e) => {
                                      if (e.target.value === "") return;
                                      if (Number(e.target.value) >= 0) {
                                        setManageSchemeData({
                                          ...manageSchemeData,
                                          opd_principal: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    disabled={currentMode === "view"}
                                    defaultValue={
                                      manageSchemeData.ipd_principal
                                    }
                                    onChange={(e) => {
                                      if (e.target.value === "") return;
                                      if (Number(e.target.value) >= 0) {
                                        setManageSchemeData({
                                          ...manageSchemeData,
                                          ipd_principal: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    disabled={currentMode === "view"}
                                    defaultValue={
                                      manageSchemeData.dental_principal
                                    }
                                    onChange={(e) => {
                                      if (e.target.value === "") return;
                                      if (Number(e.target.value) >= 0) {
                                        setManageSchemeData({
                                          ...manageSchemeData,
                                          dental_principal: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    disabled={currentMode === "view"}
                                    min={0}
                                    className="form-control"
                                    defaultValue={
                                      manageSchemeData.optical_principal
                                    }
                                    onChange={(e) => {
                                      if (e.target.value === "") return;
                                      if (Number(e.target.value) >= 0) {
                                        setManageSchemeData({
                                          ...manageSchemeData,
                                          optical_principal: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    disabled
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    defaultValue={
                                      manageSchemeData.sum_of_principal_for_opd_ipd_dental_optical
                                    }
                                  />
                                </td>
                              </tr>
                              {manageSchemeData.scheme_type?.toLowerCase() ===
                                "universal cover" && (
                                <>
                                  <tr>
                                    <th scope="row">Spouse</th>
                                    <td />
                                    <td>
                                      <input
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        defaultValue={
                                          manageSchemeData.spouse_opd || 0
                                        }
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              spouse_opd: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        defaultValue={
                                          manageSchemeData.spouse_ipd || 0
                                        }
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              spouse_ipd: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        defaultValue={
                                          manageSchemeData.spouse_dental || 0
                                        }
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              spouse_dental: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        defaultValue={
                                          manageSchemeData.spouse_optical || 0
                                        }
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              spouse_optical: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        defaultValue={
                                          manageSchemeData.sum_of_spouse_for_opd_ipd_dental_optical
                                        }
                                        value={
                                          manageSchemeData.sum_of_spouse_for_opd_ipd_dental_optical
                                        }
                                        type="number"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Child One</th>
                                    <td />
                                    <td>
                                      <input
                                        disabled={
                                          Number(
                                            manageSchemeData.total_number_of_children
                                          ) < 1 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.opd_child_one
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              opd_child_one: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            1 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.ipd_child_one
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              ipd_child_one: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            1 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.dental_child_one
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              dental_child_one: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            1 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.optical_child_one
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              optical_child_one: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        defaultValue={
                                          manageSchemeData.sum_of_child_one_for_opd_ipd_dental_optical ||
                                          0
                                        }
                                        value={
                                          manageSchemeData.sum_of_child_one_for_opd_ipd_dental_optical
                                        }
                                        type="number"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Child Two</th>
                                    <td />
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            2 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.opd_child_two
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              opd_child_two: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            2 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.ipd_child_two
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              ipd_child_two: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            2 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.dental_child_two
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              dental_child_two: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            2 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.optical_child_two
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              optical_child_two: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        defaultValue={
                                          manageSchemeData.sum_of_child_two_for_opd_ipd_dental_optical ||
                                          0
                                        }
                                        type="number"
                                        className="form-control"
                                        value={
                                          manageSchemeData.sum_of_child_two_for_opd_ipd_dental_optical
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Child Three</th>
                                    <td />
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            3 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.opd_child_three
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              opd_child_three: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            3 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.ipd_child_three
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              ipd_child_three: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            3 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.dental_child_three
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              dental_child_three:
                                                e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            3 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.optical_child_three
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,

                                              optical_child_three:
                                                e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        defaultValue={
                                          manageSchemeData.sum_of_child_three_for_opd_ipd_dental_optical ||
                                          0
                                        }
                                        type="number"
                                        value={
                                          manageSchemeData.sum_of_child_three_for_opd_ipd_dental_optical
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Child Four</th>
                                    <td />
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            4 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.opd_child_four
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              opd_child_four: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            4 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.ipd_child_four
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              ipd_child_four: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            4 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.dental_child_four
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          if (Number(e.target.value) >= 0) {
                                            setManageSchemeData({
                                              ...manageSchemeData,
                                              dental_child_four: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          manageSchemeData.total_number_of_children <
                                            4 || currentMode === "view"
                                        }
                                        defaultValue={
                                          manageSchemeData.optical_child_four
                                        }
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          setManageSchemeData({
                                            ...manageSchemeData,
                                            optical_child_four: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        defaultValue={
                                          manageSchemeData.sum_of_child_four_for_opd_ipd_dental_optical
                                        }
                                        value={
                                          manageSchemeData.sum_of_child_four_for_opd_ipd_dental_optical
                                        }
                                        type="number"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Other Dependants</th>
                                    <td />
                                    <td>
                                      <input
                                        defaultValue={
                                          manageSchemeData.opd_other_dependents ||
                                          0
                                        }
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          setManageSchemeData({
                                            ...manageSchemeData,
                                            opd_other_dependents:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        defaultValue={
                                          manageSchemeData.ipd_other_dependents ||
                                          0
                                        }
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          setManageSchemeData({
                                            ...manageSchemeData,
                                            ipd_other_dependents:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        defaultValue={
                                          manageSchemeData.dental_other_dependents ||
                                          0
                                        }
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          setManageSchemeData({
                                            ...manageSchemeData,
                                            dental_other_dependents:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        defaultValue={
                                          manageSchemeData.optical_other_dependents ||
                                          0
                                        }
                                        disabled={currentMode === "view"}
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value === "") return;
                                          setManageSchemeData({
                                            ...manageSchemeData,
                                            optical_other_dependents:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        defaultValue={
                                          manageSchemeData.sum_of_other_dependents_for_opd_ipd_dental_optical ||
                                          0
                                        }
                                        type="number"
                                        className="form-control"
                                        value={
                                          manageSchemeData.sum_of_other_dependents_for_opd_ipd_dental_optical
                                        }
                                      />
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <th scope="row">TOTAL</th>
                                <td />
                                <td>
                                  <input
                                    disabled
                                    type="number"
                                    defaultValue={
                                      manageSchemeData.sum_of_all_opd_benefit
                                    }
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    disabled
                                    defaultValue={
                                      manageSchemeData.sum_of_all_ipd_benefit
                                    }
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    disabled
                                    type="number"
                                    defaultValue={
                                      manageSchemeData.sum_of_all_dental_benefit
                                    }
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    disabled
                                    type="number"
                                    defaultValue={
                                      manageSchemeData.sum_of_all_optical_benefit
                                    }
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    disabled
                                    type="number"
                                    className="form-control"
                                    defaultValue={
                                      manageSchemeData.sum_of_everything
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </fieldset>
                      {currentMode === "edit" && (
                        <div className="text-center mt-6">
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="btn btn-primary btn-block rounded-pill bg-[#5F76E8]"
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewManageScheme;
