// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const ViewLabs = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { labs_id, action_type } = queryString.parse(search);
  const [categoryData, setCategoryData] = useState<any>([]);

  const [medication, setMedication] = useState<any>([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/details`,
        {
          lab_id: labs_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setMedication(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (action_type != "view") {
      handleFetchLabCategories();
    }
  }, [labs_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/medication/edit`,
        {
          medication_id: labs_id,
          medication_code: medication.medication_code,
          medication_name: medication.medication_name,
          medication_category: medication.medication_category,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
        if (res.data.status === 200) {
          window.location.href = `/view-labs?labs_id=${labs_id}`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchLabCategories = () => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup-services/labs/category/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Service",
                  url: "/radiology",
                },
                {
                  title: "Labs",
                  url: "/radiology",
                },
                {
                  title: `${medication.lab_name || "Labs Details"} `,
                  url: "/radiology",
                },
              ]}
            />
            <div className="col-md-8 col-sm-12 offset-md-2 mt-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">Labs Details</h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Labs category</td>
                              <td>
                                {/* <input
                                  type="text"
                                  defaultValue={medication.lab_category}
                                  disabled={action_type === "view"}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      lab_category: e.target.value,
                                    });
                                  }}
                                /> */}
                                <select
                                  disabled={action_type === "view"}
                                  className="form-control plantype"
                                  name=""
                                  id=""
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      lab_category: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">
                                    {medication.lab_category}
                                  </option>
                                  {categoryData.map((item: any) => {
                                    return (
                                      <option value={item.lab_category}>
                                        {item.lab_category}
                                      </option>
                                    );
                                  })}
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td>labs code</td>
                              <td>
                                <input
                                  type="text"
                                  disabled={action_type === "view"}
                                  defaultValue={medication.lab_code}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Labs Name</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.lab_name}
                                  disabled={action_type === "view"}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      lab_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>status</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.status}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At</td>
                              <td>
                                <input
                                  type="email"
                                  defaultValue={medication.updated_at}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      {action_type !== "view" ? (
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded-pill mt-4 bg-[#5F76E8]"
                          >
                            Update
                          </button>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewLabs;
