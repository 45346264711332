import Sidebar from "../../components/sidebar";
import { StaffType } from "../../types/data";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import setupUserApi from "../../api/setupUserApi";
import { Toast } from "primereact/toast";

const UserSetup = () => {
  const [users, setUsers] = useState<any>([]);

  const [full_name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [currentUserRole, setCurrentUserRole] = useState<string>("");
  const toast = useRef<Toast>(null);

  const [policy_admin_dashboard, setPolicyDashboard] = useState<boolean>(false);
  const [policy_admin_staff, setPolicyStaff] = useState<boolean>(false);
  const [policy_admin_crm, setPolicyCRM] = useState<boolean>(false);
  const [policy_admin_med_delivery, setPolicyMedDelivery] =
    useState<boolean>(false);
  const [policy_admin_authorization, setPolicyAuthorization] =
    useState<boolean>(false);
  const [policy_admin_reports, setPolicyReport] = useState<boolean>(false);

  const [claims_dashboard, setClaimsDashboard] = useState<boolean>(false);
  const [claims_claim_list, setClaimsClaimList] = useState<boolean>(false);
  const [claims_manual, setClaimManual] = useState<boolean>(false);
  const [claims_med_vetting, setClaimsMedVetting] = useState<boolean>(false);
  const [claims_refund, setClaimsRefund] = useState<boolean>(false);
  const [claims_reports, setClaimsReport] = useState<boolean>(false);

  const [manage_hsp_accredited_hsp, setManageHSP] = useState<boolean>(false);
  const [manage_hsp_qos_survey, setManageSurvey] = useState<boolean>(false);
  const [manage_hsp_reports, setManageReport] = useState<boolean>(false);

  const [finance_dashboard, setFinanceDashboard] = useState<boolean>(false);
  const [finance_approved_claims, setFinanceApprovedClaims] =
    useState<boolean>(false);
  const [finance_paid_claims, setFinancePaidClaims] = useState<boolean>(false);
  const [finance_refund, setFinanceRefund] = useState<boolean>(false);
  const [finance_reports, setFinanceReport] = useState<boolean>(false);

  const [system_admin_manage_scheme, setSystemManageScheme] =
    useState<boolean>(false);
  const [system_admin_setup_services, setSystemSetupServices] =
    useState<boolean>(false);
  const [system_admin_user_setup, setSystemUserSetup] =
    useState<boolean>(false);
  const [system_admin_hsp_classification, setSystemHSPClassification] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleSubmit = (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    setupUserApi
      .registerUser(token || "", {
        full_name: full_name,
        email: email,
        contact: contact,
        role: "user",
        policy_admin: {
          policy_admin_dashboard: policy_admin_dashboard,
          policy_admin_staff: policy_admin_staff,
          // policy_admin_reminders: false,
          // policy_admin_complaints: false,
          policy_admin_reports: policy_admin_reports,
        },
        claims_section: {
          claims_dashboard: claims_dashboard,
          claims_claim_list: claims_claim_list,
          claims_med_vetting: claims_med_vetting,
          claims_refund: claims_refund,
          claims_reports: claims_reports,
        },
        manage_hsp: {
          manage_hsp_accredited_hsp: manage_hsp_accredited_hsp,
          manage_hsp_qos_survey: manage_hsp_qos_survey,
          manage_hsp_reports: manage_hsp_reports,
        },
        finance_section: {
          finance_dashboard: finance_dashboard,
          finance_approved_claims: finance_approved_claims,
          finance_paid_claims: finance_paid_claims,
          finance_refund: finance_refund,
          finance_reports: finance_reports,
        },
        system_admin: {
          system_admin_manage_scheme: system_admin_manage_scheme,
          system_admin_setup_services: system_admin_setup_services,
          system_admin_user_setup: system_admin_user_setup,
          system_admin_hsp_classification: system_admin_hsp_classification,
        },
      })
      .then((res) => {
        console.log("res.data", res);
        if (res.status === 201 || res.status === 200) {
          handleFetchAllUsers();
          show("success", res.message);
          setName("");
          setEmail("");
          setContact("");
          setPolicyDashboard(false);
          setPolicyStaff(false);
          setPolicyCRM(false);
          setPolicyMedDelivery(false);
          setPolicyAuthorization(false);
          setPolicyReport(false);
          setClaimsDashboard(false);
          setClaimsClaimList(false);
          setClaimManual(false);
          setClaimsMedVetting(false);
          setClaimsRefund(false);
          setClaimsReport(false);
          setManageHSP(false);
          setManageSurvey(false);
          setManageReport(false);
          setFinanceDashboard(false);
          setFinanceApprovedClaims(false);
          setFinancePaidClaims(false);
          setFinanceRefund(false);
          setFinanceReport(false);
          setSystemManageScheme(false);
          setSystemSetupServices(false);
          setSystemUserSetup(false);
          setSystemHSPClassification(false);
        }
      })
      .catch((err) => {
        show("error", err.data);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDelete = (id: any) => {
    setIsDeleting(true);
    setupUserApi
      .deleteUser(localStorage.getItem("token") || "", {
        user_id: id,
      })
      .then((res) => {
        if (res.data.status === 200) {
          show("success", res.data.message);
          handleFetchAllUsers();
        }
      })
      .catch((err) => {
        show("error", err.data.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handleFetchAllUsers = () => {
    setupUserApi
      .getAllUsers(localStorage.getItem("token") || "")
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.info);
        }
      })
      .catch((err) => {
        show("error", err.message);
      });
  };

  useEffect(() => {
    let userDetails = sessionStorage.getItem("userDetails");
    if (userDetails) {
      setCurrentUserRole(JSON.parse(userDetails).role);
    }
    handleFetchAllUsers();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                { title: "System Admin", url: "/system-admin" },
                { title: "User Setup", url: "/user-setup" },
              ]}
            />
            {/*  Modal content for the above example */}
            <div
              className="modal fade"
              id="bs-example-modal-lg"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="myLargeModalLabel">
                      {" "}
                      Add User
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <form onSubmit={handleSubmit}>
                              <div className="form-body">
                                {/* step one */}
                                <div className="step">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="name">Name</label>
                                        <br />
                                        <input
                                          type="text"
                                          className="form-control"
                                          aria-label="name"
                                          placeholder="Jack Kweku"
                                          name="name"
                                          id="name"
                                          required
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="email">Email</label>
                                        <br />
                                        <input
                                          type="email"
                                          className="form-control"
                                          required
                                          name="email"
                                          id="email"
                                          placeholder="kkwes@gmail.com"
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="benefit">
                                          {" "}
                                          Contact
                                        </label>
                                        <br />
                                        <input
                                          type="tel"
                                          className="form-control"
                                          placeholder="050 000 0000"
                                          name="benefit"
                                          id="benefit"
                                          required
                                          maxLength={10}
                                          onChange={(e) =>
                                            setContact(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="premium">
                                          Password
                                        </label>
                                        <br />
                                        <input
                                          type="text"
                                          id="premium"
                                          className="form-control"
                                          placeholder="Auto Generated"
                                          name="premium"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* step two */}
                                <div className="step my-2">
                                  <fieldset style={{ overflowX: "scroll" }}>
                                    <h4 className="cad-title">Authorization</h4>

                                    <table className="table " id="dataTable">
                                      <tbody>
                                        <tr>
                                          <td>Policy Admin</td>
                                          <td></td>
                                          <td className="text-center font-8">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Dashboard
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setPolicyDashboard(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>

                                          <td className="text-center font-8">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Staff
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setPolicyStaff(e.target.checked)
                                              }
                                            />
                                          </td>

                                          <td className="text-center font-8">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              CRM
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setPolicyCRM(e.target.checked)
                                              }
                                            />
                                          </td>

                                          <td className="text-center font-8">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Med Delivery
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setPolicyMedDelivery(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>

                                          <td className="text-center font-8">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Authorization
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setPolicyAuthorization(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center font-8">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Report
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setPolicyReport(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>

                                          <td className="text-center"></td>
                                        </tr>

                                        <tr>
                                          <td> Claims</td>
                                          <td></td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Dashboard
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setClaimsDashboard(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Claim List
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setClaimsClaimList(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Manual Claims
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setClaimManual(e.target.checked)
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Med Vetting
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setClaimsMedVetting(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Refund
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setClaimsRefund(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Report
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setClaimsReport(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center"></td>
                                          <td className="text-center"></td>
                                        </tr>
                                        <tr>
                                          <td> Manage HSP's</td>
                                          <td></td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Accredited HSP
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setManageHSP(e.target.checked)
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              QOs Survey
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setManageSurvey(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Report
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setManageReport(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center"></td>
                                          <td className="text-center"></td>
                                          <td className="text-center"></td>
                                        </tr>
                                        <tr>
                                          <td> Finance</td>
                                          <td></td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Dashboard
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setFinanceDashboard(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Approved Claims
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setFinanceApprovedClaims(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Paid Claims
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setFinancePaidClaims(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Refund
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setFinanceRefund(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Report
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setFinanceReport(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center"></td>
                                        </tr>
                                        <tr>
                                          <td> System Admin</td>
                                          <td></td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Manage Scheme
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setSystemManageScheme(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              Setup Services
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setSystemSetupServices(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              User Setup
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setSystemUserSetup(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <h5
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              HSP Classification
                                            </h5>
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                setSystemHSPClassification(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center"></td>
                                          <td className="text-center"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </fieldset>
                                </div>
                                <hr />
                                <div className="submit-btn my-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm rounded-pill float-right bg-[#5F76E8]"
                                  >
                                    <i className="fas fa-paper-plane" /> Save
                                  </button>
                                </div>
                                {/* end previous / next buttons */}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.modal-content */}
              </div>
              {/* /.modal-dialog */}
            </div>
            {/* /.modal */}
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7 align-self-center mb-2">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          User Setup
                        </h3>
                      </div>
                      <div className="col-5 align-self-center mb-3">
                        <div className="customize-input float-right">
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-tasks" /> Add User
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MasterTableComponent
                        headers={["SN", "Name", "Contact", "Email", "Action"]}
                        tableRows={
                          users &&
                          users.map((user: StaffType, index: number) => ({
                            SN: index + 1,
                            Name: user.full_name,
                            Contact: user.contact,
                            Email: user.email,
                            Action: (
                              <>
                                <a
                                  className="mx-1"
                                  href={`/view-user-setup?staff_id=${user.id}`}
                                >
                                  <i className="fas fa-edit text-primary" />
                                </a>

                                <button
                                  type="button"
                                  onClick={() => handleDelete(user.id)}
                                  className="mx-1"
                                >
                                  <i className="fas fa-trash text-primary delplan" />
                                </button>
                              </>
                            ),
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default UserSetup;
