import React from "react";

function VettedClaimSMSModal() {
  return (
    <div
      className="modal fade"
      id="sms-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              SMS
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <form>
                <div>
                  <label htmlFor="To">To:</label>
                  <input
                    type="tel"
                    name=""
                    id=""
                    disabled={true}
                    className="form-control"
                    placeholder="02099999"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="summary">Type Your Summary</label>
                  {/* <input type="email" name="" id="" className="form-control" /> */}
                  <textarea name="" id=""></textarea>
                </div>
                <div className="buttons float-right mt-3 flex gap-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                  >
                    <i className="fas fa-envelope mr-2" />
                    Send
                  </button>
                  <button
                    className="btn btn-danger btn-sm closs flex items-center justify-center"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times mr-2" />
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default VettedClaimSMSModal;
