// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";

const ViewConsultation = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { consult_id } = queryString.parse(search);

  const [medication, setMedication] = useState<any>([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/consultation/details`,
        {
          consultation_id: consult_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setMedication(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [consult_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}setup-services/medication/edit_medication_details`,
        {
          medication_id: consult_id,
          medication_code: medication.medication_code,
          medication_name: medication.medication_name,
          medication_category: medication.medication_category,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
        if (res.data.status === 200) {
          window.location.href = `/view-labs?labs_id=${consult_id}`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full">
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <div className="page-breadcrumb">
              <div className="row">
                <div className="col-7 align-self-center mb-2">
                  <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                    Consult & Admin Details
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 offset-md-2">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">Consult & Admin Details</h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Consultation category</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={
                                    medication.consultation_category
                                  }
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      consultation_category: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Consultation code</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.consultation_code}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Consultation Name</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.consultation_name}
                                  className="form-control"
                                  onChange={(e) => {
                                    setMedication({
                                      ...medication,
                                      consultation_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>status</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={medication.status}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At</td>
                              <td>
                                <input
                                  type="email"
                                  defaultValue={medication.updated_at}
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block bg-[#5F76E8] rounded-pill">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewConsultation;
