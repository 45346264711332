import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import formatDate from "../../utils/formatDate";
import manageSchemeApi from "../../api/manageSchemeApi";
import { Toast } from "primereact/toast";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { parse } from "path";
import formatDateToDateTime from "../../utils/formatDateToDateTime";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import formatMoney from "../../utils/formatMoney";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import closeAnyModal from "../../utils/closeAnyModal";

const ManageScheme = () => {
  const [manageSchemeData, setManageSchemeData] = useState<any>([]);

  const [scheme_name, setScheme_name] = useState("");
  const [scheme_type, setScheme_type] = useState("");
  const [number_of_children, setNumberOfChildren] = useState<number>(0);
  const [other_dependants, setOther_dependants] = useState<number>(0);
  const [total_number_of_dependants, setTotal_number_of_dependants] =
    useState<number>(0);
  const [junior_staff, setJunior_staff] = useState<boolean>(false);
  const [senior_staff, setSenior_staff] = useState<boolean>(false);
  const [executive, setExecutive] = useState<boolean>(false);
  const [retirees, setRetirees] = useState<boolean>(false);
  const [others, setOthers] = useState<boolean>(false);
  const [opd_benefit_principal, setOpd_benefit_principal] = useState<number>(0);
  const [ipd_benefit_principal, setIpd_benefit_principal] = useState<number>(0);
  const [dental_principal, setDentalPrincipal] = useState<number>(0);
  const [optical_principal, setOpticalPrincipal] = useState<number>(0);
  const [sum_of_principal_for_opd_ipd_dental_optical, setSumOfPrincipal] =
    useState<number>(0);
  const [opd_benefit_spouse, setOpd_benefit_spouse] = useState<number>(0);
  const [ipd_benefit_spouse, setIpd_benefit_spouse] = useState<number>(0);
  const [dental_benefit_spouse, setDental_benefit_spouse] = useState<number>(0);
  const [optical_benefits_spouse, setOptical_benefit_spouse] =
    useState<number>(0);
  const [sum_of_spouse_for_opd_ipd_dental_optical, setSumOfSpouse] =
    useState<number>(0);
  const [child_one_opd, setChildOneOPD] = useState<number>(0);
  const [child_one_ipd, setChildOneIPD] = useState<number>(0);
  const [child_one_dental, setChildOneDental] = useState<number>(0);
  const [child_one_optical, setChildOneOptical] = useState<number>(0);
  const [sum_of_child_one_for_opd_ipd_dental_optical, setSumOfChildOne] =
    useState<number>(0);
  const [child_two_opd, setChildTwoOPD] = useState<number>(0);
  const [child_two_ipd, setChildTwoIPD] = useState<number>(0);
  const [child_two_dental, setChildTwoDental] = useState<number>(0);
  const [child_two_optical, setChildTwoOptical] = useState<number>(0);
  const [sum_of_child_two_for_opd_ipd_dental_optical, setSumOfChildTwo] =
    useState<number>(0);
  const [child_three_opd, setChildThreeOPD] = useState<number>(0);
  const [child_three_ipd, setChildThreeIPD] = useState<number>(0);
  const [child_three_dental, setChildThreeDental] = useState<number>(0);
  const [child_three_optical, setChildThreeOptical] = useState<number>(0);
  const [sum_of_child_three_for_opd_ipd_dental_optical, setSumOfChildThree] =
    useState<number>(0);
  const [child_four_opd, setChildFourOPD] = useState<number>(0);
  const [child_four_ipd, setChildFourIPD] = useState<number>(0);
  const [child_four_dental, setChildFourDental] = useState<number>(0);
  const [child_four_optical, setChildFourOptical] = useState<number>(0);
  const [sum_of_child_four_for_opd_ipd_dental_optical, setSumOfChildFour] =
    useState<number>(0);
  const [other_dependants_opd, setOtherDependantsOPD] = useState<number>(0);
  const [other_dependants_ipd, setOtherDependantsIPD] = useState<number>(0);
  const [other_dependants_dental, setOtherDependantsDental] =
    useState<number>(0);
  const [other_dependants_optical, setOtherDependantsOptical] =
    useState<number>(0);
  const [sum_of_other_dependents_for_opd_ipd_dental_optical, setSumOfOthers] =
    useState<number>(0);
  const [sum_of_all_opd_benefit, setTotal_opd] = useState<number>(0);
  const [sum_of_all_ipd_benefit, setTotal_ipd] = useState<number>(0);
  const [sum_of_all_dental_benefit, setTotal_dental] = useState<number>(0);
  const [sum_of_all_optical_benefit, setTotal_optical] = useState<number>(0);
  const [sum_of_everything, setSumOfEverything] = useState<number>(0);
  const [authToken, setAuthToken] = useState<string>("");
  const [selectedScheme, setSelectedScheme] = useState<any>({});
  const toast = useRef<Toast>(null);

  const handleFetchAllManagedScheme = async () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setAuthToken(token);
    }
    manageSchemeApi
      .getAllManageScheme(token)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setManageSchemeData(res.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // todo:@ handle delete
  const handleStatusChange = (item_id: string, status_flag: string) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/manage_scheme/change-status`,
        {
          id: item_id.toString(),
          status_flag: status_flag,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchAllManagedScheme();
        }
      })
      .catch((err) => {
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: err.response.data.message,
          life: 3000,
        });
        console.log(err);
      });
  };

  //todo: @ desc handle submit
  const handleSubmit = (e: any) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/manage_scheme/`,
        {
          scheme_name: scheme_name,
          scheme_type: scheme_type,
          total_number_of_children: number_of_children,
          other_dependents: other_dependants,
          total_number_of_dependents: total_number_of_dependants,
          staff_category_junior_staff: junior_staff,
          staff_category_senior_staff: senior_staff,
          staff_category_executive: executive,
          staff_category_retirees: retirees,
          staff_category_others: others,
          opd_principal: opd_benefit_principal,
          opd_spouse: opd_benefit_spouse,
          opd_child_one: child_one_opd,
          opd_child_two: child_two_opd,
          opd_child_three: child_three_opd,
          opd_child_four: child_four_opd,
          opd_other_dependents: other_dependants_opd,
          sum_of_all_opd_benefit: sum_of_all_opd_benefit,
          ipd_principal: ipd_benefit_principal,
          ipd_spouse: ipd_benefit_spouse,
          ipd_child_one: child_one_ipd,
          ipd_child_two: child_two_ipd,
          ipd_child_three: child_three_ipd,
          ipd_child_four: child_four_ipd,
          ipd_other_dependents: other_dependants_ipd,
          sum_of_all_ipd_benefit: sum_of_all_ipd_benefit,
          dental_principal: dental_principal,
          dental_spouse: dental_benefit_spouse,
          dental_child_one: child_one_dental,
          dental_child_two: child_two_dental,
          dental_child_three: child_three_dental,
          dental_child_four: child_four_dental,
          dental_other_dependents: other_dependants_dental,
          sum_of_all_dental_benefit: sum_of_all_dental_benefit,
          optical_principal: optical_principal,
          optical_spouse: optical_benefits_spouse,
          optical_child_one: child_one_optical,
          optical_child_two: child_two_optical,
          optical_child_three: child_three_optical,
          optical_child_four: child_four_optical,
          optical_other_dependents: other_dependants_optical,
          sum_of_all_optical_benefit: sum_of_all_optical_benefit,
          sum_of_principal_for_opd_ipd_dental_optical:
            sum_of_principal_for_opd_ipd_dental_optical,
          sum_of_spouse_for_opd_ipd_dental_optical:
            sum_of_spouse_for_opd_ipd_dental_optical,
          sum_of_child_one_for_opd_ipd_dental_optical:
            sum_of_child_one_for_opd_ipd_dental_optical,
          sum_of_child_two_for_opd_ipd_dental_optical:
            sum_of_child_two_for_opd_ipd_dental_optical,
          sum_of_child_three_for_opd_ipd_dental_optical:
            sum_of_child_three_for_opd_ipd_dental_optical,
          sum_of_child_four_for_opd_ipd_dental_optical:
            sum_of_child_four_for_opd_ipd_dental_optical,
          sum_of_other_dependents_for_opd_ipd_dental_optical:
            sum_of_other_dependents_for_opd_ipd_dental_optical,
          sum_of_everything: sum_of_everything,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // alert(res.data.message);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchAllManagedScheme();
          closeAnyModal("bs-example-modal-lg");
          setScheme_name("");
          setScheme_type("");
          setNumberOfChildren(0);
          setOther_dependants(0);
          setTotal_number_of_dependants(0);
          setJunior_staff(false);
          setSenior_staff(false);
          setExecutive(false);
          setRetirees(false);
          setOthers(false);
          setOpd_benefit_principal(0);
          setIpd_benefit_principal(0);
          setDentalPrincipal(0);
          setOpticalPrincipal(0);
          setOpd_benefit_spouse(0);
          setIpd_benefit_spouse(0);
          setDental_benefit_spouse(0);
          setOptical_benefit_spouse(0);
          setChildOneOPD(0);
          setChildOneIPD(0);
          setChildOneDental(0);
          setChildOneOptical(0);
          setChildTwoOPD(0);
          setChildTwoIPD(0);
          setChildTwoDental(0);
          setChildTwoOptical(0);
          setChildThreeOPD(0);
          setChildThreeIPD(0);
          setChildThreeDental(0);
          setChildThreeOptical(0);
          setChildFourOPD(0);
          setChildFourIPD(0);
          setChildFourDental(0);
          setChildFourOptical(0);
          setOtherDependantsOPD(0);
          setOtherDependantsIPD(0);
          setOtherDependantsDental(0);
          setOtherDependantsOptical(0);
          setSumOfPrincipal(0);
          setSumOfSpouse(0);
          setSumOfChildOne(0);
          setSumOfChildTwo(0);
          setSumOfChildThree(0);
        }
      })
      .catch((err) => {
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: err.response.data.message,
          life: 3000,
        });
        console.log(err);
      });
  };

  const handleDeleteScheme = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/manage_scheme/delete`,
        {
          manage_scheme_id: selectedScheme.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchAllManagedScheme();
          // close modal and remove backdrop
          document.getElementById("deleteModal")?.classList.remove("show");
          let backdrop = document.getElementsByClassName("modal-backdrop")[0];
          backdrop?.remove();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to delete Scheme",
          life: 3000,
        });
      });
  };

  useEffect(() => {
    handleFetchAllManagedScheme();
  }, []);

  // setTotal_opd
  useEffect(() => {
    setTotal_opd(
      opd_benefit_principal +
        opd_benefit_spouse +
        child_one_opd +
        child_two_opd +
        child_three_opd +
        child_four_opd +
        other_dependants_opd
    );
  }, [
    opd_benefit_principal,
    opd_benefit_spouse,
    child_one_opd,
    child_two_opd,
    child_three_opd,
    child_four_opd,
    other_dependants_opd,
  ]);

  // setTotal_ipd
  useEffect(() => {
    setTotal_ipd(
      ipd_benefit_principal +
        ipd_benefit_spouse +
        child_one_ipd +
        child_two_ipd +
        child_three_ipd +
        child_four_ipd +
        other_dependants_ipd
    );
  }, [
    ipd_benefit_principal,
    ipd_benefit_spouse,
    child_one_ipd,
    child_two_ipd,
    child_three_ipd,
    child_four_ipd,
    other_dependants_ipd,
  ]);

  // setTotal_dental
  useEffect(() => {
    setTotal_dental(
      dental_principal +
        dental_benefit_spouse +
        child_one_dental +
        child_two_dental +
        child_three_dental +
        child_four_dental +
        other_dependants_dental
    );
  }, [
    dental_principal,
    dental_benefit_spouse,
    child_one_dental,
    child_two_dental,
    child_three_dental,
    child_four_dental,
    other_dependants_dental,
  ]);

  // sum_of_all_optical_benefit
  useEffect(() => {
    setTotal_optical(
      optical_principal +
        optical_benefits_spouse +
        child_one_optical +
        child_two_optical +
        child_three_optical +
        child_four_optical +
        other_dependants_optical
    );
  }, [
    optical_principal,
    optical_benefits_spouse,
    child_one_optical,
    child_two_optical,
    child_three_optical,
    child_four_optical,
    other_dependants_optical,
  ]);

  // todo: @ desc handle sum of all opd benefit
  useEffect(() => {
    setSumOfEverything(
      sum_of_all_opd_benefit +
        sum_of_all_ipd_benefit +
        sum_of_all_dental_benefit +
        sum_of_all_optical_benefit
    );
  }, [
    sum_of_all_opd_benefit,
    sum_of_all_ipd_benefit,
    sum_of_all_dental_benefit,
    sum_of_all_optical_benefit,
  ]);

  // total number of dependants
  useEffect(() => {
    setTotal_number_of_dependants(number_of_children + other_dependants);
  }, [number_of_children, other_dependants]);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}

          <div className="container-fluid">
            <div className="page-breadcrumb">
              <div className="flex justify-between">
                <BreadCrumbsComponent
                  breadcrumbs={[
                    {
                      title: "Manage Schemes",
                      url: "/",
                    },
                    {
                      title: "Schemes",
                      url: "/manage-scheme",
                    },
                  ]}
                />
                <div className="align-self-center mb-3">
                  <div className="customize-input float-right">
                    <button
                      type="button"
                      className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                      data-toggle="modal"
                      data-target="#bs-example-modal-lg"
                    >
                      <i className="fas fa-tasks" /> Add Scheme
                    </button>
                    {/*  Modal for adding content */}
                    <div
                      className="modal fade"
                      id="bs-example-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                              Add Scheme
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                      <div className="form-body">
                                        {/* step one */}
                                        <div className="step">
                                          <h4 className="card-title py-1 text-primary">
                                            Scheme Details
                                          </h4>
                                          <hr className="bg" />
                                          <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="benefit">
                                                  Scheme Name
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={scheme_name}
                                                  onChange={(e) => {
                                                    setScheme_name(
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="plan_type">
                                                  Scheme Type
                                                </label>
                                                <br />
                                                <select
                                                  className="form-control plantype"
                                                  name="plan_type"
                                                  id="mySelection"
                                                  value={scheme_type}
                                                  onChange={(e) => {
                                                    setScheme_type(
                                                      e.target.value
                                                    );
                                                  }}
                                                  // placeholder="Select Type"
                                                >
                                                  <option>Select</option>
                                                  <option
                                                    value="universal cover"
                                                    className="generic"
                                                  >
                                                    Universal Cover
                                                  </option>
                                                  <option
                                                    value="limited cover"
                                                    className="custom"
                                                  >
                                                    Limited Cover
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="benefit">
                                                  Number of Children
                                                </label>
                                                <br />
                                                <input
                                                  type="number"
                                                  min={0}
                                                  max={4}
                                                  className="form-control"
                                                  aria-label="Number of Children"
                                                  name="number_of_children"
                                                  id="number_of_children"
                                                  value={number_of_children}
                                                  onChange={(e) => {
                                                    if (e.target.value === "") {
                                                      return;
                                                    }
                                                    if (
                                                      Number(e.target.value) >=
                                                        0 &&
                                                      Number(e.target.value) <=
                                                        4
                                                    ) {
                                                      setNumberOfChildren(
                                                        parseInt(e.target.value)
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="total_benefit">
                                                  Total benefit
                                                </label>
                                                <br />
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder={"0"}
                                                  name="total_benefit"
                                                  id="total_benefit"
                                                  disabled
                                                  value={(
                                                    sum_of_principal_for_opd_ipd_dental_optical +
                                                    sum_of_spouse_for_opd_ipd_dental_optical +
                                                    sum_of_child_one_for_opd_ipd_dental_optical +
                                                    sum_of_child_two_for_opd_ipd_dental_optical +
                                                    sum_of_child_three_for_opd_ipd_dental_optical +
                                                    sum_of_child_four_for_opd_ipd_dental_optical +
                                                    sum_of_other_dependents_for_opd_ipd_dental_optical
                                                  )?.toFixed(2)}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="plan_type">
                                                  Other Dependant
                                                </label>
                                                <br />
                                                <input
                                                  type="number"
                                                  min={0}
                                                  max={2}
                                                  className="form-control"
                                                  name="other_dependants"
                                                  id="other_dependants"
                                                  required
                                                  value={other_dependants}
                                                  onChange={(e) => {
                                                    if (e.target.value === "")
                                                      return;
                                                    if (
                                                      Number(e.target.value) >=
                                                        0 &&
                                                      Number(e.target.value) <=
                                                        2
                                                    ) {
                                                      setOther_dependants(
                                                        parseInt(e.target.value)
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="premium">
                                                  Total Number of Dependants
                                                </label>
                                                <br />
                                                <input
                                                  type="number"
                                                  id="premium"
                                                  className="form-control"
                                                  aria-label="Amount (to the nearest Ghana Cedi)"
                                                  placeholder={"0"}
                                                  name="premium"
                                                  disabled
                                                  value={
                                                    total_number_of_dependants
                                                  }
                                                  onChange={(e) => {
                                                    setTotal_number_of_dependants(
                                                      parseInt(e.target.value)
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="step">
                                          <h4 className="card-title text-primary mt-2">
                                            Staff Category
                                          </h4>
                                          <hr />
                                          <div className="d-flex justify-content-between">
                                            <div className="form-group mb-3 align-items-center d-inline">
                                              <input
                                                type="checkbox"
                                                id="age1"
                                                name="age"
                                                checked={junior_staff}
                                                onChange={(e) => {
                                                  setJunior_staff(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                              <label
                                                htmlFor="age1"
                                                className="mx-1"
                                              >
                                                Junior Staff
                                              </label>
                                              <input
                                                type="checkbox"
                                                id="age2"
                                                name="age"
                                                checked={senior_staff}
                                                onChange={(e) => {
                                                  setSenior_staff(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                              <label
                                                htmlFor="age2"
                                                className="mx-1"
                                              >
                                                Senior Staff
                                              </label>
                                              <input
                                                type="checkbox"
                                                id="age3"
                                                name="age"
                                                checked={executive}
                                                onChange={(e) => {
                                                  setExecutive(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                              <label
                                                htmlFor="age3"
                                                className="mx-1"
                                              >
                                                Executive
                                              </label>
                                              <input
                                                type="checkbox"
                                                id="age3"
                                                name="age"
                                                checked={retirees}
                                                onChange={(e) => {
                                                  setRetirees(e.target.checked);
                                                }}
                                              />
                                              <label
                                                htmlFor="age3"
                                                className="mx-1"
                                              >
                                                Retirees
                                              </label>
                                              <input
                                                type="checkbox"
                                                id="age4"
                                                name="age"
                                                checked={others}
                                                onChange={(e) => {
                                                  setOthers(e.target.checked);
                                                }}
                                              />
                                              <label
                                                htmlFor="age4"
                                                className="mx-1"
                                              >
                                                Others
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        {/* step two */}
                                        <div className="step">
                                          <h4 className="card-title py-3 text-primary">
                                            Benefits
                                          </h4>
                                          {/* <hr class="bg-primary"> */}
                                          <div className="row">
                                            <div className="col col-md-12">
                                              <table className="table mb-0">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" />
                                                    <th scope="col" />
                                                    <th scope="col">
                                                      OPD{"("}GHS{")"}
                                                    </th>
                                                    <th scope="col">
                                                      IPD{"("}GHS{")"}
                                                    </th>
                                                    <th scope="col">Dental</th>
                                                    <th scope="col">Optical</th>
                                                    <th scope="col">
                                                      Total{"("}GHS{")"}
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {/* principal */}
                                                  <tr>
                                                    <th scope="row">
                                                      Principal
                                                    </th>
                                                    <td />
                                                    <td>
                                                      <input
                                                        type="number"
                                                        min={0}
                                                        className="form-control"
                                                        placeholder="0.00"
                                                        onChange={(e) => {
                                                          setOpd_benefit_principal(
                                                            Number(
                                                              e.target.value
                                                            )
                                                          );
                                                          setSumOfPrincipal(
                                                            Number(
                                                              e.target.value
                                                            ) +
                                                              ipd_benefit_principal +
                                                              dental_principal +
                                                              optical_principal
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        min={0}
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                          setIpd_benefit_principal(
                                                            Number(
                                                              e.target.value
                                                            )
                                                          );
                                                          setSumOfPrincipal(
                                                            Number(
                                                              e.target.value
                                                            ) +
                                                              opd_benefit_principal +
                                                              dental_principal +
                                                              optical_principal
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        min={0}
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                          setDentalPrincipal(
                                                            Number(
                                                              e.target.value
                                                            )
                                                          );
                                                          setSumOfPrincipal(
                                                            Number(
                                                              e.target.value
                                                            ) +
                                                              opd_benefit_principal +
                                                              ipd_benefit_principal +
                                                              optical_principal
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        min={0}
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                          setOpticalPrincipal(
                                                            Number(
                                                              e.target.value
                                                            )
                                                          );
                                                          setSumOfPrincipal(
                                                            Number(
                                                              e.target.value
                                                            ) +
                                                              opd_benefit_principal +
                                                              ipd_benefit_principal +
                                                              dental_principal
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        disabled
                                                        value={`${sum_of_principal_for_opd_ipd_dental_optical?.toFixed(
                                                          2
                                                        )}`}
                                                        onChange={(e) => {
                                                          // setSumOfPrincipal(
                                                          //   parseFloat(
                                                          //     e.target.value
                                                          //   )
                                                          // );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                  {scheme_type ===
                                                    "limited cover" && (
                                                    <>
                                                      {/* Spouse */}

                                                      <tr>
                                                        <th scope="row">
                                                          Spouse
                                                        </th>
                                                        <td />

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setOpd_benefit_spouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfSpouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  ipd_benefit_spouse +
                                                                  dental_benefit_spouse +
                                                                  optical_benefits_spouse
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setIpd_benefit_spouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfSpouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  opd_benefit_spouse +
                                                                  dental_benefit_spouse +
                                                                  optical_benefits_spouse
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setDental_benefit_spouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfSpouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  opd_benefit_spouse +
                                                                  ipd_benefit_spouse +
                                                                  optical_benefits_spouse
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setOptical_benefit_spouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfSpouse(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  opd_benefit_spouse +
                                                                  ipd_benefit_spouse +
                                                                  dental_benefit_spouse
                                                              );
                                                            }}
                                                          />
                                                        </td>

                                                        <td>
                                                          <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            disabled
                                                            value={sum_of_spouse_for_opd_ipd_dental_optical?.toFixed(
                                                              2
                                                            )}
                                                            onChange={(e) => {
                                                              // setSumOfSpouse(
                                                              //   parseFloat(
                                                              //     e.target.value
                                                              //   )
                                                              // );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>

                                                      {/* Child one */}

                                                      <tr>
                                                        <th scope="row">
                                                          Child one
                                                        </th>
                                                        <td />

                                                        <td>
                                                          <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            min={0}
                                                            className="form-control"
                                                            disabled={
                                                              number_of_children <
                                                              1
                                                            }
                                                            onChange={(e) => {
                                                              setChildOneOPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildOne(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_one_ipd +
                                                                  child_one_dental +
                                                                  child_one_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            disabled={
                                                              number_of_children <
                                                              1
                                                            }
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildOneIPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildOne(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_one_opd +
                                                                  child_one_dental +
                                                                  child_one_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              1
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildOneDental(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildOne(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_one_opd +
                                                                  child_one_ipd +
                                                                  child_one_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            disabled={
                                                              number_of_children <
                                                              1
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildOneOptical(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildOne(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_one_opd +
                                                                  child_one_ipd +
                                                                  child_one_dental
                                                              );
                                                            }}
                                                          />
                                                        </td>

                                                        <td>
                                                          <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            disabled
                                                            value={sum_of_child_one_for_opd_ipd_dental_optical?.toFixed(
                                                              2
                                                            )}
                                                            onChange={(e) => {
                                                              // setSumOfChildOne(
                                                              //   parseFloat(
                                                              //     e.target.value
                                                              //   )
                                                              // );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>

                                                      {/* Child two */}

                                                      <tr>
                                                        <th scope="row">
                                                          Child two
                                                        </th>
                                                        <td />

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              2
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildTwoOPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildTwo(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_two_ipd +
                                                                  child_two_dental +
                                                                  child_two_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              2
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildTwoIPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildTwo(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_two_opd +
                                                                  child_two_dental +
                                                                  child_two_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            disabled={
                                                              number_of_children <
                                                              2
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildTwoDental(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildTwo(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_two_opd +
                                                                  child_two_ipd +
                                                                  child_two_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            disabled={
                                                              number_of_children <
                                                              2
                                                            }
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildTwoOptical(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildTwo(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_two_opd +
                                                                  child_two_ipd +
                                                                  child_two_dental
                                                              );
                                                            }}
                                                          />
                                                        </td>

                                                        <td>
                                                          <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            disabled
                                                            value={sum_of_child_two_for_opd_ipd_dental_optical?.toFixed(
                                                              2
                                                            )}
                                                            onChange={(e) => {
                                                              // setSumOfChildTwo(
                                                              //   parseFloat(
                                                              //     e.target.value
                                                              //   )
                                                              // );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>

                                                      {/* Child three */}

                                                      <tr>
                                                        <th scope="row">
                                                          Child three
                                                        </th>
                                                        <td />

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              3
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildThreeOPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildThree(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_three_ipd +
                                                                  child_three_dental +
                                                                  child_three_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              3
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildThreeIPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildThree(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_three_opd +
                                                                  child_three_dental +
                                                                  child_three_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              3
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildThreeDental(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildThree(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_three_opd +
                                                                  child_three_ipd +
                                                                  child_three_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              3
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildThreeOptical(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildThree(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_three_opd +
                                                                  child_three_ipd +
                                                                  child_three_dental
                                                              );
                                                            }}
                                                          />
                                                        </td>

                                                        <td>
                                                          <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            disabled
                                                            value={sum_of_child_three_for_opd_ipd_dental_optical?.toFixed(
                                                              2
                                                            )}
                                                            onChange={(e) => {
                                                              // setSumOfChildThree(
                                                              //   parseFloat(
                                                              //     e.target.value
                                                              //   )
                                                              // );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>

                                                      {/* Child four */}

                                                      <tr>
                                                        <th scope="row">
                                                          Child four
                                                        </th>
                                                        <td />

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              4
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildFourOPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildFour(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_four_ipd +
                                                                  child_four_dental +
                                                                  child_four_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              4
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildFourIPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildFour(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_four_opd +
                                                                  child_four_dental +
                                                                  child_four_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              4
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildFourDental(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildFour(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_four_opd +
                                                                  child_four_ipd +
                                                                  child_four_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            disabled={
                                                              number_of_children <
                                                              4
                                                            }
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setChildFourOptical(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfChildFour(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  child_four_opd +
                                                                  child_four_ipd +
                                                                  child_four_dental
                                                              );
                                                            }}
                                                          />
                                                        </td>

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            disabled
                                                            value={sum_of_child_four_for_opd_ipd_dental_optical?.toFixed(
                                                              2
                                                            )}
                                                            onChange={(e) => {
                                                              // setSumOfChildFour(
                                                              //   parseFloat(
                                                              //     e.target.value
                                                              //   )
                                                              // );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>

                                                      {/* Other Dependants */}

                                                      <tr>
                                                        <th scope="row">
                                                          Other Dependants
                                                        </th>
                                                        <td />

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setOtherDependantsOPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfOthers(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  other_dependants_ipd +
                                                                  other_dependants_dental +
                                                                  other_dependants_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setOtherDependantsIPD(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfOthers(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  other_dependants_opd +
                                                                  other_dependants_dental +
                                                                  other_dependants_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setOtherDependantsDental(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfOthers(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  other_dependants_opd +
                                                                  other_dependants_ipd +
                                                                  other_dependants_optical
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            min={0}
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                              setOtherDependantsOptical(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              );
                                                              setSumOfOthers(
                                                                parseFloat(
                                                                  e.target.value
                                                                ) +
                                                                  other_dependants_opd +
                                                                  other_dependants_ipd +
                                                                  other_dependants_dental
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            className="form-control"
                                                            disabled
                                                            value={sum_of_other_dependents_for_opd_ipd_dental_optical?.toFixed(
                                                              2
                                                            )}
                                                            onChange={(e) => {
                                                              // setSumOfOthers(
                                                              //   parseFloat(
                                                              //     e.target.value
                                                              //   )
                                                              // );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )}

                                                  {/* total */}

                                                  <tr>
                                                    <th scope="row">TOTAL</th>
                                                    <td />
                                                    <td>
                                                      <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        disabled
                                                        value={(
                                                          opd_benefit_principal +
                                                          opd_benefit_spouse +
                                                          child_one_opd +
                                                          child_two_opd +
                                                          child_three_opd +
                                                          child_four_opd +
                                                          other_dependants_opd
                                                        ).toFixed(2)}
                                                        onChange={(e) => {
                                                          // setTotal_opd(
                                                          //   parseFloat(
                                                          //     e.target.value
                                                          //   )
                                                          // );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        disabled
                                                        value={(
                                                          ipd_benefit_principal +
                                                          ipd_benefit_spouse +
                                                          child_one_ipd +
                                                          child_two_ipd +
                                                          child_three_ipd +
                                                          child_four_ipd +
                                                          other_dependants_ipd
                                                        )?.toFixed(2)}
                                                        onChange={(e) => {
                                                          // setTotal_ipd(
                                                          //   parseFloat(
                                                          //     e.target.value
                                                          //   )
                                                          // );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        disabled
                                                        value={(
                                                          dental_principal +
                                                          dental_benefit_spouse +
                                                          child_one_dental +
                                                          child_two_dental +
                                                          child_three_dental +
                                                          child_four_dental +
                                                          other_dependants_dental
                                                        )?.toFixed(2)}
                                                        onChange={(e) => {
                                                          // setTotal_dental(
                                                          //   parseFloat(
                                                          //     e.target.value
                                                          //   )
                                                          // );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        disabled
                                                        value={(
                                                          optical_principal +
                                                          optical_benefits_spouse +
                                                          child_one_optical +
                                                          child_two_optical +
                                                          child_three_optical +
                                                          child_four_optical +
                                                          other_dependants_optical
                                                        )?.toFixed(2)}
                                                        onChange={(e) => {
                                                          // setTotal_optical(
                                                          //   parseFloat(
                                                          //     e.target.value
                                                          //   )
                                                          // );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        className="form-control"
                                                        disabled
                                                        value={(
                                                          sum_of_principal_for_opd_ipd_dental_optical +
                                                          sum_of_spouse_for_opd_ipd_dental_optical +
                                                          sum_of_child_one_for_opd_ipd_dental_optical +
                                                          sum_of_child_two_for_opd_ipd_dental_optical +
                                                          sum_of_child_three_for_opd_ipd_dental_optical +
                                                          sum_of_child_four_for_opd_ipd_dental_optical +
                                                          sum_of_other_dependents_for_opd_ipd_dental_optical
                                                        )?.toFixed(2)}
                                                        onChange={(e) => {
                                                          // setSumOfEverything(
                                                          //   parseFloat(
                                                          //     e.target.value
                                                          //   )
                                                          // );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <hr />
                                        <div className="submit-btn py-2">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-sm float-right bg-[#5F76E8]"
                                          >
                                            <i className="fas fa-paper-plane" />
                                            Save
                                          </button>
                                        </div>
                                        {/* end previous / next buttons */}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Scheme Name",
                          "OPD Benefit (GHS)",
                          "IPD Benefit (GHS)",
                          "Total Benefit (GHS)",
                          "Date Created",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          manageSchemeData &&
                          manageSchemeData?.map(
                            (
                              manage_scheme: Manage_SchemeType,
                              index: number
                            ) => ({
                              SN: index + 1,
                              "Scheme Name": manage_scheme.scheme_name,
                              "OPD Benefit (GHS)": formatMoney(
                                manage_scheme.sum_of_all_opd_benefit
                              ),
                              "IPD Benefit (GHS)": formatMoney(
                                manage_scheme.sum_of_all_ipd_benefit
                              ),
                              "Total Benefit (GHS)": formatMoney(
                                manage_scheme.sum_of_everything
                              ),
                              "Date Created": Number(manage_scheme.created_at)
                                ? convertTimestampToDate(
                                    Number(manage_scheme.created_at)
                                  )
                                : formatDate(manage_scheme.created_at),
                              Status: manage_scheme.status?.toUpperCase(),
                              Actions: (
                                <div className="flex gap-2">
                                  <a
                                    className="mx-1"
                                    href={`/view-manage-scheme?scheme_id=${manage_scheme.id}&action=view`}
                                  >
                                    <i className="fas fa-eye text-primary delplan"></i>
                                  </a>

                                  <a
                                    className="mx-1"
                                    href={`/view-manage-scheme?scheme_id=${manage_scheme.id}&action=edit`}
                                  >
                                    <i className="fas fa-edit text-primary" />
                                  </a>
                                  {manage_scheme.status?.toLowerCase() ===
                                  "active" ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleStatusChange(
                                          manage_scheme.id,
                                          "inactive"
                                        )
                                      }
                                      className="mx-1"
                                    >
                                      <i className="fas fa-times text-primary delplan" />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleStatusChange(
                                          manage_scheme.id,
                                          "active"
                                        )
                                      }
                                      className="mx-1"
                                    >
                                      <i className="fas fa-power-off text-primary delplan" />
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    data-target="#deleteModal"
                                    data-toggle="modal"
                                    onClick={() => {
                                      setSelectedScheme(manage_scheme);
                                    }}
                                  >
                                    <i className="fas fa-trash cursor-pointer text-primary mx-1" />
                                  </button>
                                </div>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal for Delete Surgery */}
          <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Delete Surgery
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p className="text-center text-sm">
                            Are you sure you want to delete. <br />
                            deleting scheme:{" "}
                            <span className="text-red-500">
                              {selectedScheme?.scheme_name} <br />
                            </span>
                            cannot be reversed.
                          </p>
                          <div className="flex justify-end gap-4 mt-4">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                            >
                              <i className="fas fa-times" />{" "}
                              {<span className="mx-2">Cancel</span>}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                              onClick={handleDeleteScheme}
                            >
                              <i className="fas fa-trash" />{" "}
                              {<span className="mx-2">Delete </span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>
      </div>
    </>
  );
};

export default ManageScheme;
