import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import NewClaimsTable from "../../components/tables/NewClaimsTable";
import { StyledSearch } from "../../components/StyledSearch";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import { batchesType } from "../../types/data";
import batchClaimsApi from "../../api/batchClaimsApi";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import formatMoney from "../../utils/formatMoney";
import closeAnyModal from "../../utils/closeAnyModal";

const NewClaims = () => {
  const toast = useRef<Toast>(null);
  const [fetchedBatches, setFetchedBatches] = useState<batchesType[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState<batchesType | null>(null);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const fetchListOfBatches = () => {
    setIsFetching(true);
    batchClaimsApi
      .getAllBatches(sessionStorage.getItem("token") || "")
      .then((res: any) => {
        if (res.status === 200) {
          setFetchedBatches(res.info);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = fetchedBatches.filter((data: batchesType) => {
      return (
        data?.batch_number.toLowerCase().includes(query.toLowerCase()) ||
        data?.hsp_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  useEffect(() => {
    fetchListOfBatches();
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedBatches;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedBatches]);

  const handleApproveBatch = (batchId: string) => {
    setIsApproving(true);
    batchClaimsApi
      .confirmingReceiptStatus(sessionStorage.getItem("token") || "", batchId)
      .then((res: any) => {
        if (res.status === 200) {
          show("success", "Batch approved successfully");
          fetchListOfBatches();
          closeAnyModal("#pass-claim-modal");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsApproving(false);
      });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* ============================================================== */}
            {/* Bread crumb and right sidebar toggle */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claims",
                  url: "/claims",
                },
                {
                  title: "New Claims",
                  url: "/claims/new-claims",
                },
              ]}
            />
            {/* ============================================================== */}

            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-between my-2">
                      <h3 className="card-title mb-3">New Claims</h3>
                      <div className="flex gap-4 items-center">
                        <StyledSearch
                          placeholder="🔍 by HSP Name or Batch No"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>

                    <div className="table-responsive" id="myTable">
                      <MasterTableComponent
                        headers={[
                          "S/N",
                          "HSP Name",
                          "Batch No.",
                          "No. Claims",
                          "Total Amount",
                          "Actions",
                        ]}
                        loading={isFetching}
                        emptyTableMessage="No New batch claims found"
                        tableRows={
                          tableRows && tableRows?.length > 0
                            ? tableRows?.map((batchClaim, index: number) => {
                                return {
                                  "S/N": index + 1,
                                  "HSP Name": batchClaim.hsp_name,
                                  "Batch No.": batchClaim.batch_number,
                                  "No. Claims": batchClaim.number_of_claims,
                                  "Total Amount": formatMoney(
                                    batchClaim.total_amount_of_claims || 0
                                  ),
                                  Actions: (
                                    <div className="flex gap-2 items-center">
                                      <i
                                        className="fas fa-check text-success delplan mr-2 cursor-pointer"
                                        data-toggle="modal"
                                        data-target="#pass-claim-modal"
                                        onClick={() => {
                                          setSelectedBatch(batchClaim);
                                        }}
                                      />
                                      <a
                                        href={`/new-claims-batch-view?id=${
                                          batchClaim?.id || ""
                                        }`}
                                        onClick={() => {
                                          let bc = JSON.stringify(batchClaim);
                                          sessionStorage.setItem(
                                            "selectedNewBatchClaim",
                                            bc
                                          );
                                        }}
                                      >
                                        <i className="fas fa-eye text-info cursor-pointer" />
                                      </a>
                                    </div>
                                  ),
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal for Passing Claims */}
          <div
            className="modal fade"
            id="pass-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    Confirm claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <form>
                      <div className="form-body">
                        <div className="col-12 mb-3">
                          <label>
                            You are confirming receipt of claims from{" "}
                            <span className="text-[#5F76E8]">
                              {selectedBatch?.hsp_name}
                            </span>{" "}
                            with Batch No.{" "}
                            <span className="text-[#5F76E8]">
                              {selectedBatch?.batch_number}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                        <button
                          type="submit"
                          disabled={isApproving}
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        >
                          {isApproving ? (
                            <>
                              <i className="fas fa-spinner mr-2" />
                              Approving...
                            </>
                          ) : (
                            <>
                              <i className="fas fa-check mr-2" />
                              Approve
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          {/*  Modal for Flagging Claims */}
          <div
            className="modal fade"
            id="flag-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    Flag claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <form>
                      <div className="form-body">
                        <div className="col-12 mb-3">
                          <label>
                            You are flagging receipt of claims from{" "}
                            <span className="text-[#5F76E8]">
                              Francis Hospital
                            </span>{" "}
                            with Batch No.{" "}
                            <span className="text-[#5F76E8]">001</span>
                          </label>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-question mr-1" />
                              Please select a reason for flagging.
                            </label>
                            <select
                              className="form-control"
                              // placeholder=" select one"
                            >
                              <option value="">Select a comment</option>
                              <option
                                value={"diagnosis vs medication mismatch"}
                              >
                                diagnosis vs medication mismatch
                              </option>
                              <option
                                value={"diagnosis vs investigation mismatch"}
                              >
                                diagnosis vs investigation mismatch
                              </option>
                              <option
                                value={" diagnosis vs medication-age mismatch"}
                              >
                                diagnosis vs medication-age mismatch
                              </option>
                              <option value={"no related diagnosis"}>
                                no related diagnosis
                              </option>
                              <option value={"Others"}>Others</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        >
                          <i className="fas fa-check mr-2" />
                          Proceed
                        </button>
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
      {/* </TableContainer> */}
    </>
  );
};

export default NewClaims;
