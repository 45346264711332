import React from "react";

const Stats = () => {
  return (
    <>
      <div className="card-group">
        <div className="card border-right shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <div className="d-inline-flex align-items-center">
                  <h2 className="text-dark mb-1 font-weight-medium">236</h2>
                  <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none">
                    +18.33%
                  </span>
                </div>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Total Policy Holders
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="user-plus" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card border-right shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                  <sup className="set-doller">$</sup>18,306
                </h2>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Total Assured Lives
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="dollar-sign" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card border-right shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <div className="d-inline-flex align-items-center">
                  <h2 className="text-dark mb-1 font-weight-medium">1538</h2>
                  <span className="badge bg-danger font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block">
                    -18.33%
                  </span>
                </div>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Total Premium Received
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="file-plus" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <h2 className="text-dark mb-1 font-weight-medium">864</h2>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Total Lapsed Premium
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="globe" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-group">
        <div className="card border-right shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <div className="d-inline-flex align-items-center">
                  <h2 className="text-dark mb-1 font-weight-medium">236</h2>
                  <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none">
                    +18.33%
                  </span>
                </div>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Total Claims
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="user-plus" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card border-right shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                  <sup className="set-doller">$</sup>18,306
                </h2>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Hospitalization Claims
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="dollar-sign" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card border-right shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <div className="d-inline-flex align-items-center">
                  <h2 className="text-dark mb-1 font-weight-medium">1538</h2>
                  <span className="badge bg-danger font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block">
                    -18.33%
                  </span>
                </div>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Disability Claims
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="file-plus" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex d-lg-flex d-md-block align-items-center">
              <div>
                <h2 className="text-dark mb-1 font-weight-medium">864</h2>
                <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                  Death Claims
                </h6>
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                <span className="opacity-7 text-muted">
                  <i data-feather="globe" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
