import axios from "axios";
import { CreateNewUserType, UpdateUserDetailsType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const registerUser = async (
  token: string,
  registerUserData: CreateNewUserType
) => {
  let url = `${BASE_URL}/setup_user/register`;
  try {
    const response = await axios.post(url, registerUserData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const updateUserDetails = async (
  token: string,
  updateUserData: UpdateUserDetailsType
) => {
  const { email, ...newBody } = updateUserData;

  let url = `${BASE_URL}/setup_user/edit-details`;
  try {
    const response = await axios.post(url, newBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const loginUser = async (loginData: { email: string; password: string }) => {
  let url = `${BASE_URL}/setup_user/login`;
  try {
    const response = await axios.post(
      url,
      loginData
      //   , {
      //   // headers: {
      //   //   Authorization: `Bearer ${token}`,
      //   // },
      // }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllUsers = async (token: string) => {
  let url = `${BASE_URL}/setup_user/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeUserPassword = async (
  token: string,
  changePasswordData: {
    user_id: string;
    old_password: string;
    new_password: string;
  }
) => {
  let url = `${BASE_URL}/setup_user/password/change`;
  try {
    const response = await axios.post(url, changePasswordData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const resetPassword = async (token: string, new_password: string) => {
  let url = `${BASE_URL}/setup_user/password/reset`;
  try {
    const response = await axios.post(
      url,
      {
        token,
        new_password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const forgotPassword = async (email: string) => {
  let url = `${BASE_URL}/setup_user/password/forgot`;
  try {
    const response = await axios.post(url, {
      email,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getUserDetails = async (
  token: string,
  userDetailsData: {
    user_id: string;
  }
) => {
  let url = `${BASE_URL}/setup_user/details`;
  try {
    const response = await axios.post(url, userDetailsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteUser = async (
  token: string,
  deleteUserData: {
    user_id: string;
  }
) => {
  let url = `${BASE_URL}/setup_user/delete`;
  try {
    const response = await axios.post(url, deleteUserData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeUserStatus = async (
  token: string,
  changeUserStatusData: {
    id: string;
    status_flag: string;
  }
) => {
  let url = `${BASE_URL}/setup_user/update-status`;
  try {
    const response = await axios.post(url, changeUserStatusData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const sendResetPasswordEmail = async (token: string, email: string) => {
  let url = `${BASE_URL}/setup_user/test_email`;
  try {
    const response = await axios.post(
      url,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  registerUser,
  loginUser,
  getAllUsers,
  changeUserPassword,
  resetPassword,
  getUserDetails,
  deleteUser,
  updateUserDetails,
  changeUserStatus,
  sendResetPasswordEmail,
  forgotPassword,
};
