import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;
import { ClaimType } from "../types/data";

const createClaim = async (token: string | null, ClaimData: FormData) => {
  let url = `${BASE_URL}/claims/`;
  try {
    const response = await axios.post(url, ClaimData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteClaim = async (
  token: string | null,
  ClaimId: string | undefined
) => {
  let url = `${BASE_URL}/claims/delete`;
  try {
    const response = await axios.post(
      url,
      { claims_id: ClaimId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeClaimStatus = async (
  token: string | null,
  ClaimId: string | undefined,
  flag: string
) => {
  let url = `${BASE_URL}/claims/status-change`;
  try {
    const response = await axios.post(
      url,
      { claims_id: ClaimId, status_flag: flag },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllClaims = async (token: string | null) => {
  let url = `${BASE_URL}/claims/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAllApprovedClaims = async (token: string | null) => {
  let url = `${BASE_URL}/claims/get-progress-status`;
  try {
    const response = await axios.post(
      url,
      {
        progress_status: "approved",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// const getAllPendingClaims = async (token: string | null) => {
//   let url = `${BASE_URL}/claims/get-progress-status`;
//   try {
//     const response = await axios.post(
//       url,
//       {
//         progress_status: "pending",
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };

const getAllNewClaims = async (token: string | null) => {
  let url = `${BASE_URL}/claims/get-progress-status`;
  try {
    const response = await axios.post(
      url,
      {
        progress_status: "pending",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getAllVettedClaims = async (token: string | null) => {
  let url = `${BASE_URL}/claims/get-progress-status`;
  try {
    const response = await axios.post(
      url,
      {
        progress_status: "vetted",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getAllPaidClaims = async (token: string | null) => {
  let url = `${BASE_URL}/claims/get-progress-status`;
  try {
    const response = await axios.post(
      url,
      {
        progress_status: "paid",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getAllUnpaidClaims = async (token: string | null) => {
  let url = `${BASE_URL}/claims/get-progress-status`;
  try {
    const response = await axios.post(
      url,
      {
        progress_status: "unpaid",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAllManualClaims = async (token: string | null) => {
  // {{base_url}}/claims/flag
  let url = `${BASE_URL}/claims/flag`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const editClaimDetails = async (
  token: string | null,
  editClaimData: FormData
) => {
  let url = `${BASE_URL}/claims/edit`;
  try {
    const response = await axios.post(url, editClaimData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const confirmClaimReceipt = async (
  token: string | null,
  claimId: string | undefined
) => {
  let url = `${BASE_URL}/claims/confirm-receipt`;
  try {
    const response = await axios.post(
      url,
      { claims_id: claimId, progress_status: "approved" },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const pushClaimFromBatch = async (token: string, claimId: string) => {
  try {
    let url = `${BASE_URL}/claims/push-claim-to-batch`;
    const response = await axios.post(
      url,
      {
        claim_id: claimId,
        claim_flag: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const addNewManualClaim = async (token: string | null, body: FormData) => {
  let url = `${BASE_URL}/claims/`;
  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  createClaim,
  deleteClaim,
  changeClaimStatus,
  getAllClaims,
  editClaimDetails,
  getAllApprovedClaims,
  getAllNewClaims,
  getAllVettedClaims,
  getAllPaidClaims,
  confirmClaimReceipt,
  getAllUnpaidClaims,
  addNewManualClaim,
  getAllManualClaims,
  pushClaimFromBatch,
};
