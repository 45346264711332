import React from "react";

function DisableCategoryModal() {
  return (
    <div
      id="sms2"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Disable Category
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <h3 className="mb-5 mx-4 text-center card-title">
                  Are you sure you want to this able this category of service
                </h3>
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  id="comment_btn"
                  className="btn btn-primary btn-rounded mx-auto"
                >
                  Proceed
                </button>
                <button
                  type="submit"
                  id="comment_btn"
                  className="btn btn-primary btn-rounded mx-auto"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default DisableCategoryModal;
