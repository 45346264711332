import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;
import { MedDeliveryType } from "../types/data";

const getAllMedDelivery = async (token: string) => {
  let url = `${BASE_URL}/med_delivery/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createMedDelivery = async (
  token: string,
  medDeliveryData: MedDeliveryType
) => {
  let url = `${BASE_URL}/med_delivery/`;
  try {
    delete medDeliveryData.med_delivery_id;
    const response = await axios.post(url, medDeliveryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editMedDelivery = async (
  token: string,
  medDeliveryData: MedDeliveryType
) => {
  let url = `${BASE_URL}/med_delivery/edit`;
  try {
    const response = await axios.post(url, medDeliveryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getMedDeliveryDetails = async (token: string, medDeliveryId: string) => {
  let url = `${BASE_URL}/med_delivery/details`;
  try {
    const response = await axios.post(
      url,
      {
        med_delivery_id: medDeliveryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const changeMedDeliveryStatus = async (
  token: string,
  medDeliveryId: string,
  status: string
) => {
  let url = `${BASE_URL}/med_delivery/status-change`;
  try {
    const response = await axios.post(
      url,
      {
        med_delivery_id: medDeliveryId,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllMedDelivery,
  createMedDelivery,
  editMedDelivery,
  getMedDeliveryDetails,
  changeMedDeliveryStatus,
};
