import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import queryString from "query-string";
import axios from "axios";
import { RevenueSetupType } from "../../types/data";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import companySetupApi from "../../api/companySetupApi";

const ViewCompanySetupDetails = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { company_id } = queryString.parse(search);

  const [companyDetails, setCompanyDetails] = useState<any>(null);

  const handleFetchCompanyDetails = () => {
    companySetupApi
      .viewCompanyProfileDetails(
        sessionStorage.getItem("token") || "",
        company_id as string
      )
      .then((response) => {
        console.log(response.info);
        setCompanyDetails(response.info);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (company_id) {
      handleFetchCompanyDetails();
    }
  }, [company_id]);

  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Header />

        <Sidebar />

        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Company Setup",
                  url: "/company-setup",
                },
                {
                  title: "View Company Setup Details",
                  url: "/company-setup",
                },
              ]}
            />
            {/* {companyDetails && ( */}
            <div className="col-md-8 col-sm-12 offset-md-2">
              <div className="card shadow">
                <div className="card-body">
                  <div className="m-2">
                    <form>
                      <div className="card-header text center">
                        <img
                          width={150}
                          height={150}
                          src={
                            companyDetails?.company_logo
                              ? companyDetails?.company_logo
                              : "https://via.placeholder.com/150"
                          }
                          alt="HSP Logo"
                          className="img-fluid rounded-circle mx-auto"
                        />
                      </div>
                      <table className="table" id="dataTable">
                        <tbody>
                          <tr>
                            <td>
                              <i className="fa fa-hospital mr-2" />
                              Company Name
                            </td>
                            <td>
                              <input
                                type="text"
                                defaultValue={companyDetails?.company_name}
                                id="company_name"
                                name="company_name"
                                className="form-control"
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-6">
                              <i className="fa fa-id-card mr-2" />
                              Contact Person
                            </td>
                            <td className="col-6">
                              <input
                                type="text"
                                defaultValue={
                                  companyDetails?.contact_person_name
                                }
                                id="contact_person"
                                name="contact_person"
                                className="form-control "
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-6">
                              <i className="icon-phone mr-2" /> Phone
                            </td>
                            <td className="col-6">
                              <input
                                type="tel"
                                defaultValue={companyDetails?.company_telephone}
                                id="company_telephone"
                                name="company_telephone"
                                className="form-control"
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-6">
                              <i className="icon-envelope mr-2" /> Email
                            </td>
                            <td className="col-6">
                              <input
                                type="email"
                                defaultValue={companyDetails?.company_email}
                                id="company_email"
                                name="company_email"
                                className="form-control"
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-6">
                              <i className="icon-calender mr-2" />
                              Validity Date (From)
                            </td>
                            <td className="col-6">
                              <input
                                type="date"
                                defaultValue={
                                  companyDetails?.contract_validity_start_date
                                }
                                id="contract_validity_start_date"
                                name="contract_validity_start_date"
                                className="form-control"
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-6">
                              <i className="icon-calender mr-2" /> Validity Date
                              (To)
                            </td>
                            <td className="col-6">
                              <input
                                type="date"
                                defaultValue={
                                  companyDetails?.contract_validity_end_date
                                }
                                id="contract_validity_end_date"
                                name="contract_validity_end_date"
                                className="form-control"
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-6">
                              <i className="icon-calender mr-2" /> Status
                            </td>
                            <td className="col-6">
                              <select
                                name=""
                                id=""
                                className="form-control "
                                disabled={true}
                              >
                                <option value="">Active</option>
                                <option value="">Inactive</option>
                                <option value="Canceled">Canceled</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className="text-center w-[60%] mx-auto">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded-pill bg-[#5F76E8]"
                          >
                            Update
                          </button>
                        </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default ViewCompanySetupDetails;
