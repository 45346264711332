import React from "react";

interface AddNewCashflowModalProps {
  handleSubmit: any;
  setCashflowType: any;
  setPercentage: any;
  setAppliedTo: any;
  isLoading: boolean;
  percentage: number;
  applied_to: string;
}

function AddNewCashflowModal({
  handleSubmit,
  setCashflowType,
  setPercentage,
  setAppliedTo,
  isLoading,
  percentage,
  applied_to,
}: AddNewCashflowModalProps) {
  return (
    <div
      className="modal fade"
      id="addNewCashflowModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Add Cashflow Type
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-body">
                        {/* step one */}
                        <div className="step">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="plan_type">Cashflow Type</label>
                                <br />
                                <select
                                  className="form-control plantype"
                                  name="plan_type"
                                  id="mySelection"
                                  onChange={(e) =>
                                    setCashflowType(e.target.value)
                                  }
                                  // placeholder="Select Type"
                                >
                                  <option disabled selected>
                                    Select
                                  </option>
                                  <option value="Sponsored" className="generic">
                                    Sponsored
                                  </option>
                                  <option value="Co-payment" className="custom">
                                    Co-payment
                                  </option>
                                  <option
                                    value="Out Of Pocket"
                                    className="custom"
                                  >
                                    Out Of Pocket
                                  </option>
                                  <option
                                    value="Tax Deductions (Withholding)"
                                    className="custom"
                                  >
                                    Tax Deductions (Withholding)
                                  </option>
                                  <option
                                    value="Tax Deductions (Exemptions)"
                                    className="custom"
                                  >
                                    Tax Deductions (Exemptions)
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="benefit"> Percentage</label>
                                <br />
                                <input
                                  type="number"
                                  className="form-control"
                                  aria-label="Amount (to the nearest Ghana Cedi)"
                                  name="benefit"
                                  max={100}
                                  min={0}
                                  id="benefit"
                                  value={percentage}
                                  onChange={(e) => {
                                    if (parseFloat(e.target.value) > 100) {
                                      setPercentage(100);
                                      return;
                                    } else if (parseFloat(e.target.value) < 0) {
                                      setPercentage(0);
                                      return;
                                    }
                                    setPercentage(parseInt(e.target.value));
                                  }}
                                />
                              </div>
                            </div>

                            <textarea
                              className="form-control rounded-md"
                              placeholder=" Applied To"
                              rows={3}
                              defaultValue={""}
                              value={applied_to}
                              onChange={(e) => setAppliedTo(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="submit-btn my-2">
                          <button
                            disabled={isLoading}
                            type="submit"
                            onClick={handleSubmit}
                            className="mt-2 btn btn-primary btn-sm float-right bg-[#5F76E8]"
                          >
                            {!isLoading ? (
                              <>
                                <i className="fas fa-paper-plane" /> Save
                              </>
                            ) : (
                              <div className="flex gap-2 items-center">
                                <i className="fas fa-spinner fa-spin" />
                                Saving...
                              </div>
                            )}
                          </button>
                        </div>
                        {/* end previous / next buttons */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default AddNewCashflowModal;
