// import { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineBell, AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";

const Header = () => {
  //@ desc - logic to logout the user and clear the session storage
  const Logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  //@ desc - logic to toggle the mobile menu
  const handleMobileMenu = () => {
    const dview = document.getElementById("dview");
    if (dview) {
      dview.classList.toggle("show");
    }
  };

  //@ desc - get the name of the user from the session storage and slice it to get the first name
  const name = sessionStorage.getItem("name");
  const firstName: any = name?.slice(0, name.indexOf(" "));

  // get auth_user from session storage
  const auth_user = sessionStorage.getItem("auth_user");

  return (
    <>
      <header className="topbar" data-navbarbg="skin6">
        <nav className="navbar top-navbar navbar-expand-md">
          <div className="navbar-header" data-logobg="skin6">
            {/* <a
              className="nav-toggler waves-effect waves-light d-block d-md-none"
              href="#/"
            >
              <i className="ti-menu ti-close" />
            </a> */}
            <div className="h-[20vh] w-full relative">

              {/* Logo icon */}
              <Link className="w-full" to="/dashboard">
                <img
                  src="assets/images/bhasys_logo_v1.jpeg"
                  alt="homepage"
                  className="dark-logo object-cover mt-5 w-full h-[20vh]"

                  />
              </Link>
            </div>
            {/* <a
              className="topbartoggler d-block d-md-none waves-effect waves-light"
              href="#/"
              onClick={handleMobileMenu}
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti-more" />
            </a> */}
          </div>

          {/* only visible when ti-more is clicked on a moble screen */}
          <section className="momo" id="dview">
            <ul className="flex justify-center items-center">
              {/* Notification */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle pl-md-3 flex justify-center items-center"
                  href="#/"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    <AiOutlineBell size={20} color="#000" />
                  </span>
                  <span className="badge badge-primary notify-no rounded-circle">
                    5
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <a
                          href="#/"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          {/* <span className="btn btn-primary rounded-circle btn-circle flex items-center">
                            <AiOutlineSetting size={12} color="white" />
                          </span> */}
                          <div className="w-75 d-inline-block v-middle pl-2 text-xs">
                            <h6 className="message-title mb-0 mt-1 text-xs">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted text-xs">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted text-xs">
                              9:02 AM
                            </span>
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <a
                        className="nav-link pt-3 text-center text-dark text-xs"
                        href="#/"
                      >
                        <strong>Check all notifications</strong>
                        <i className="fa fa-angle-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Notification */}
              {/* ============================================================== */}
              {/* create new */}
              {/* ============================================================== */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <AiOutlineSetting size={20} color="#000" />
                </a>
                <div
                  className="dropdown-menu text-xs"
                  aria-labelledby="navbarDropdown"
                >
                  <a className="dropdown-item" href="#/">
                    Action
                  </a>
                  <a className="dropdown-item" href="#/">
                    Another action
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="#/">
                    Something else here
                  </a>
                </div>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle flex justify-center"
                  href="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="assets/images/users/profile-pic.jpg"
                    alt="user"
                    className="rounded-circle"
                    width={35}
                  />
                  {firstName && (
                    <span className="ml-2 flex items-center">
                      <span className="text-dark">{firstName}</span>{" "}
                      <RiArrowDropDownLine size={20} color="#000" />
                    </span>
                  )}
                </a>
                <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY text-xs">
                  {auth_user && (
                    <a
                      className="dropdown-item flex"
                      href={`/account-settings?staff_id=${auth_user}`}
                    >
                      <i
                        data-feather="settings"
                        className="svg-icon mr-2 ml-1"
                      />
                      Account Setting
                    </a>
                  )}
                  <div className="dropdown-divider" />
                  <a className="dropdown-item flex" href="#/" onClick={Logout}>
                    <i data-feather="power" className="svg-icon mr-2 ml-1" />
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </section>

          {/* navbar only visible on big screens (> 768px) */}
          <section className="max-md:hidden  pcview">
            <ul className="flex justify-center items-center">
              {/* Notification */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle pl-md-3 flex justify-center items-center"
                  href="#/"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    <AiOutlineBell size={24} color="#000" />
                  </span>
                  <span className="badge badge-primary notify-no rounded-circle">
                    2
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <a
                          href="#/"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle flex items-center">
                            <AiOutlineSetting size={24} color="white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <a
                        className="nav-link pt-3 text-center text-dark"
                        href="#/"
                      >
                        <strong>Check all notifications</strong>
                        <i className="fa fa-angle-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Notification */}
              {/* ============================================================== */}
              {/* create new */}
              {/* ============================================================== */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <AiOutlineSetting size={24} color="#000" />
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#/">
                    Action
                  </a>
                  <a className="dropdown-item" href="#/">
                    Another action
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="#/">
                    Something else here
                  </a>
                </div>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle flex justify-center"
                  href="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="assets/images/users/profile-pic.jpg"
                    alt="user"
                    className="rounded-circle"
                    width={40}
                  />
                  {firstName && (
                    <span className="ml-2 flex items-center">
                      <span className="text-dark">{firstName}</span>{" "}
                      <RiArrowDropDownLine size={20} color="#000" />
                    </span>
                  )}
                </a>
                <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                  {auth_user && (
                    <a
                      className="dropdown-item flex"
                      href={`/account-settings?staff_id=${auth_user}`}
                    >
                      <i
                        data-feather="settings"
                        className="svg-icon mr-2 ml-1"
                      />
                      Account Setting
                    </a>
                  )}

                  <div className="dropdown-divider" />
                  <a className="dropdown-item flex" href="#/" onClick={Logout}>
                    <i data-feather="power" className="svg-icon mr-2 ml-1" />
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </section>
        </nav>
      </header>
    </>
  );
};

export default Header;
