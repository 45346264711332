import React, { useEffect, useRef, useState } from "react";
import { HspMedsAndConsumablesType } from "../../../types/data";
import medicationApi from "../../../api/medicationApi";
import { Toast } from "primereact/toast";
import hspMedsAndConsumablesApi from "../../../api/hspMedsAndConsumablesApi";
import { StyledSearch } from "../../StyledSearch";
import SaveAllServicesModal from "./SaveAllServicesModal";
import MasterTableComponent from "../../ui/MasterTableComponent";
import formatMoney from "../../../utils/formatMoney";
import closeAnyModal from "../../../utils/closeAnyModal";
import EditMedsAndConsumablesService from "./EditHspMedsAndConsumablesModal";

interface MedsAndConsumablesTabProps {
  fetchedCashflows: any[];
}

const MedsAndConsumablesTab: React.FC<MedsAndConsumablesTabProps> = ({
  fetchedCashflows,
}) => {
  const [createNewMedsAndConsumablesBody, setCreateNewMedsAndConsumablesBody] =
    useState<HspMedsAndConsumablesType[]>([]);
  const [includedData, setIncludedData] = useState<HspMedsAndConsumablesType[]>(
    []
  );
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>({});

  const [fetchedMedsAndConsumables, setFetchedMedsAndConsumables] = useState([
    {},
  ]);
  const [alreadyAddedMedsAndConsumables, setAlreadyAddedMedsAndConsumables] =
    useState<any[]>([]);
  const [token, setToken] = useState<string>("");
  const toast = useRef<Toast>(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [hspName, setHspName] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });

  const handleAlreadyFetchHspMedsAndConsumables = async () => {
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    const response: any =
      await hspMedsAndConsumablesApi.getAllHspMedsAndConsumables(
        sessionStorage.getItem("token") || "",
        selectedHsp?.id || ""
      );
    setAlreadyAddedMedsAndConsumables(response?.info);
  };

  const handleAddNewMedsAndConsumablesToHSPList = async (e: any) => {
    setFetchIsLoading(true);

    const category: any = fetchedMedsAndConsumables?.find(
      (item: any) => item?.id === e.target.value
    );
    setSelectedCategory({
      name: category?.medication_category,
      id: category?.id,
    });
    const res =
      await hspMedsAndConsumablesApi.getAllHspMedsAndConsumablesByCategory(
        sessionStorage.getItem("token") || "",
        e.target.value
      );

    const response = res?.info ? res?.info : res?.data;
    if (response?.length > 0) {
      const tempThings = response?.map((item: any) => {
        const alreadyAdded = alreadyAddedMedsAndConsumables?.find(
          (addedItem: any) => addedItem?.service_name === item?.medication_name
        );

        if (alreadyAdded) {
          return {
            med_consumables_code: item?.medication_code,
            service_name: alreadyAdded?.service_name,
            med_consumables_included: true,
            standard_rate: alreadyAdded?.standard_rate,
            discount_rate: alreadyAdded?.discount_rate,
            net_rate: alreadyAdded?.net_rate,
            med_consumables_approval: alreadyAdded?.med_consumables_approval,
            med_consumables_revenue_type:
              alreadyAdded?.med_consumables_revenue_type,
            has_been_added: true,
            med_consumables_id: alreadyAdded?.id || "",
            hsp_category: alreadyAdded?.hsp_category,
          };
        } else {
          return {
            med_consumables_code: item?.medication_code,
            service_name: item?.medication_name,
            med_consumables_included: false,
            standard_rate: 0.0,
            discount_rate: 0.0,
            net_rate: 0.0,
            med_consumables_approval: false,
            med_consumables_revenue_type: "",
            has_been_added: false,
          };
        }
      });
      setCreateNewMedsAndConsumablesBody(tempThings);
      setFetchIsLoading(false);
    } else {
      setCreateNewMedsAndConsumablesBody([]);
      setFetchIsLoading(false);
    }
  };
  const handleFetchHspMedsAndConsumables = async () => {
    const response: any = await medicationApi.getAllMedicationCategories(
      sessionStorage.getItem("token") || ""
    );
    setFetchedMedsAndConsumables(response?.info);
  };

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleCreateNewMedsAndConsumables = async () => {
    setIsLoading(true);
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;

    const includedDat = getIncludedData();
    let dataToSave: HspMedsAndConsumablesType[] = includedDat.map(
      (item: HspMedsAndConsumablesType) => ({
        ...item,
        hsp_owner_id: hspId,
        hsp_category: selectedCategory.name,
      })
    );
    dataToSave.forEach((item) => {
      delete item.has_been_added;
    });
    const response = await hspMedsAndConsumablesApi.createHspMedsAndConsumables(
      !token ? "" : token,
      dataToSave
    );
    if (response?.status === 200) {
      show("success", "Meds and Consumables created successfully");
      closeAnyModal("saveAllServicesModal-medication");
      await handleFetchHspMedsAndConsumables();
      setIsLoading(false);
    } else {
      show("error", response?.message);
      setIsLoading(false);
    }
  };

  const handleUpdateMedsAndConsumables = async () => {
    setIsUpdateLoading(true);

    const response = await hspMedsAndConsumablesApi.editHspMedsAndConsumables(
      !token ? "" : token,
      [
        {
          med_and_consumables_hsp_id: selectedItemToEdit?.med_consumables_id,
          hsp_category: selectedItemToEdit?.hsp_category,
          service_name: selectedItemToEdit?.service_name,
          med_consumables_included:
            selectedItemToEdit?.med_consumables_included,
          standard_rate: selectedItemToEdit?.standard_rate,
          discount_rate: selectedItemToEdit?.discount_rate,
          net_rate: selectedItemToEdit?.net_rate,
          med_consumables_approval:
            selectedItemToEdit?.med_consumables_approval,
          med_consumables_revenue_type:
            selectedItemToEdit?.med_consumables_revenue_type,
        },
      ]
    );
    if (response?.status === 200) {
      show("success", response?.message);
      await handleFetchHspMedsAndConsumables();
      setIsUpdateLoading(false);
      closeAnyModal("EditMedsAndConsumablesService");
    } else {
      show("error", response?.message);
      setIsUpdateLoading(false);
    }
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setHspName(selectedHsp?.hsp_name);
    const fn = async () => {
      await handleFetchHspMedsAndConsumables();
      await handleAlreadyFetchHspMedsAndConsumables();
    };
    fn();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = createNewMedsAndConsumablesBody.filter(
      (data: any) => {
        return (
          data?.med_consumables_code
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          data?.service_name.toLowerCase().includes(query.toLowerCase())
        );
      }
    );

    setFilteredData(filData);
  };

  const getIncludedData = () => {
    const data = tableRows.filter(
      (med_consumables: HspMedsAndConsumablesType) =>
        med_consumables.med_consumables_included === true &&
        med_consumables.has_been_added === false
    );
    setIncludedData(data);
    return data;
  };

  const toggleSaveAllModal = () => {
    const included_data = getIncludedData();
    if (included_data.length === 0) {
      show("error", "No services selected");
    }
  };

  useEffect(() => {
    const dataToRender = searchQuery
      ? filteredData
      : createNewMedsAndConsumablesBody;
    setTableRows(dataToRender);
  }, [searchQuery, createNewMedsAndConsumablesBody]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane" id="medsAndConsumablesTab">
        <div className="row">
          <div className="col-md-8 offset-md-2 ">
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  <select
                    name=""
                    id=""
                    className="col-md-12 border-2 py-2 rounded-pill"
                    onChange={handleAddNewMedsAndConsumablesToHSPList}
                  >
                    <option value="">select a category </option>
                    {fetchedMedsAndConsumables?.map(
                      (item: any, idx: number) => (
                        <option key={idx} value={item?.id}>
                          {item?.medication_category} -{" "}
                          {item?.medication_initials}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive" id="myTable">
              <div className="table-responsive" id="myTable">
                <div className="flex justify-end gap-4">
                  <StyledSearch
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search by Service Name or Code"
                  />
                  <button
                    className={`btn btn-primary rounded-pill mb-1 bg-primary ${
                      tableRows.length === 0 && "disabled"
                    }`}
                    onClick={toggleSaveAllModal}
                    data-target={`${
                      includedData.length === 0
                        ? ""
                        : "#saveAllServicesModal-medication"
                    }`}
                    data-toggle="modal"
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light spinner-border-sm">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <i className="fas fa-save" /> Save All
                      </>
                    )}
                  </button>
                </div>
                <MasterTableComponent
                  headers={[
                    "Code",
                    "Service Name",
                    "Included",
                    "Std Tariff",
                    "Agreed Tariff",
                    "Net Tariff",
                    "Authorization",
                    "Cashflow Type",
                    "Action",
                  ]}
                  loading={fetchIsLoading}
                  emptyTableMessage="No Meds & Consumables Available"
                  tableRows={
                    tableRows &&
                    tableRows.map(
                      (item: HspMedsAndConsumablesType, idx: number) => ({
                        Code: item?.med_consumables_code,
                        "Service Name": item.service_name,
                        Included: (
                          <input
                            type="checkbox"
                            className=""
                            disabled={item.has_been_added}
                            checked={item.med_consumables_included}
                            onChange={(e) => {
                              const newMedsAndConsumablesBody = [...tableRows];
                              newMedsAndConsumablesBody[
                                idx
                              ].med_consumables_included = e.target.checked;
                              setTableRows(newMedsAndConsumablesBody);
                            }}
                          />
                        ),
                        "Std Tariff": (
                          <input
                            type="number"
                            min="0"
                            disabled={item.has_been_added}
                            className="form-control"
                            value={item.standard_rate}
                            onChange={(e) => {
                              const newMedsAndConsumablesBody = [...tableRows];
                              newMedsAndConsumablesBody[idx].standard_rate =
                                Number(e.target.value);
                              newMedsAndConsumablesBody[idx].net_rate = (
                                parseFloat(e.target.value) -
                                parseFloat(
                                  newMedsAndConsumablesBody[idx].discount_rate
                                )
                              ).toFixed(2);
                              setTableRows(newMedsAndConsumablesBody);
                            }}
                          />
                        ),
                        "Agreed Tariff": (
                          <input
                            type="number"
                            min="0"
                            disabled={item.has_been_added}
                            className="form-control"
                            value={item.discount_rate}
                            onChange={(e) => {
                              const newMedsAndConsumablesBody = [...tableRows];
                              newMedsAndConsumablesBody[idx].discount_rate =
                                Number(e.target.value);
                              (newMedsAndConsumablesBody[idx].net_rate =
                                parseFloat(
                                  newMedsAndConsumablesBody[idx].standard_rate
                                ) - parseFloat(e.target.value)).toFixed(2);
                              setTableRows(newMedsAndConsumablesBody);
                            }}
                          />
                        ),
                        "Net Tariff": (
                          <input
                            type="number"
                            className="form-control"
                            value={item.net_rate}
                            disabled
                            onChange={(e) => {
                              const newMedsAndConsumablesBody = [...tableRows];
                              newMedsAndConsumablesBody[idx].net_rate = Number(
                                e.target.value
                              );
                              setTableRows(newMedsAndConsumablesBody);
                            }}
                          />
                        ),
                        Authorization: (
                          <input
                            type="checkbox"
                            className=""
                            disabled={item.has_been_added}
                            checked={item.med_consumables_approval}
                            onChange={(e) => {
                              const newMedsAndConsumablesBody = [...tableRows];
                              newMedsAndConsumablesBody[
                                idx
                              ].med_consumables_approval = e.target.checked;
                              setTableRows(newMedsAndConsumablesBody);
                            }}
                          />
                        ),
                        "Cashflow Type": (
                          <div className="form-group mb-3">
                            <select
                              className="form-control plantype"
                              name="plan_type"
                              id="mySelection"
                              disabled={item.has_been_added}
                              value={item.med_consumables_revenue_type}
                              // placeholder="Select Type"
                              onChange={(e) => {
                                const newMedsAndConsumablesBody = [
                                  ...tableRows,
                                ];
                                newMedsAndConsumablesBody[
                                  idx
                                ].med_consumables_revenue_type = e.target.value;
                                setTableRows(newMedsAndConsumablesBody);
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              {fetchedCashflows?.map(
                                (cashflow: any, idx: number) => (
                                  <option
                                    key={idx}
                                    value={
                                      cashflow?.cash_flow_type +
                                      " - " +
                                      cashflow?.percentage +
                                      "%"
                                    }
                                  >
                                    {cashflow?.cash_flow_type} -{" "}
                                    {cashflow?.percentage
                                      ? `${cashflow?.percentage}%`
                                      : ""}{" "}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        ),
                        Action: (
                          <button
                            disabled={!item.has_been_added}
                            className={`btn 
                            ${
                              item?.has_been_added
                                ? "text-primary"
                                : "text-gray-500 cursor-not-allowed"
                            }
                            `}
                            data-toggle="modal"
                            data-target="#EditMedsAndConsumablesService"
                            onClick={() => {
                              setSelectedItemToEdit(item);
                            }}
                          >
                            <i className="fas fa-edit" />
                          </button>
                        ),
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* save all modal */}
      <SaveAllServicesModal
        selectedCategory={selectedCategory}
        hspName={hspName}
        type={"Meds & Consumables"}
        handleSaveAllServices={handleCreateNewMedsAndConsumables}
        itemsToSave={
          includedData &&
          includedData?.map((item) => ({
            code: item?.med_consumables_code,
            name: item?.service_name,
            included: item?.med_consumables_included ? "Yes" : "No",
            std_tariff: formatMoney(item?.standard_rate),
            agreed_tariff: formatMoney(item?.discount_rate),
            net_tariff: formatMoney(item?.net_rate),
            authorization: item?.med_consumables_approval ? "Yes" : "No",
            cashflow_type: item?.med_consumables_revenue_type,
          }))
        }
        selector={"saveAllServicesModal-medication"}
      />
      {/* // edit modal */}
      <EditMedsAndConsumablesService
        serviceType="Meds & Consumables"
        itemToEdit={selectedItemToEdit}
        setItemToEdit={setSelectedItemToEdit}
        fetchedCashflows={fetchedCashflows}
        handleUpdate={handleUpdateMedsAndConsumables}
        loading={isUpdateLoading}
      />
    </>
  );
};

export default MedsAndConsumablesTab;
