import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import ViewHSPPDFModal from "../../components/hsp/ViewHSPPDFModal";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import ClaimsHistoryTab from "../../components/hsp/view-hsp/ClaimsHistoryTab";
import SurgeryAndProceduresTab from "../../components/hsp/view-hsp/SurgeryAndProceduresTab";
import ViewHspNavBar from "../../components/hsp/view-hsp/ViewHspNavBar";
import DisableCategoryModal from "../../components/hsp/view-hsp/DisableCategoryModal";
import MedsAndConsumablesTab from "../../components/hsp/view-hsp/MedsAndConsumablesTab";
import InvestigationsAndPricingTab from "../../components/hsp/view-hsp/InvestigationsAndPricingTab";
import ConsultationTab from "../../components/hsp/view-hsp/ConsultationTab";
import HspDetailsTab from "../../components/hsp/view-hsp/HspDetailsTab";
import ContractConditionsTab from "../../components/hsp/view-hsp/ContractConditionsTab";
import OpticalTab from "../../components/hsp/view-hsp/OpticalTab";
import DentalTab from "../../components/hsp/view-hsp/DentalTab";
import cashflowApi from "../../api/cashflowApi";

const ViewHsp = () => {
  const toast = useRef<Toast>(null);

  // --------------------------------------- State Management ---------------------------------------
  const [token, setToken] = useState<string>("");
  const [selectedHSP, setSelectedHSP] = useState<any>({});
  const [fetchedCashflows, setFetchedCashflows] = useState<any[]>([]);
  // ------------------- HSP Contract -------------------

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    const fn = async () => {
      const response = await cashflowApi.getAllCashflow(tk || "");

      if (response?.info?.length > 0) {
        setFetchedCashflows(response.info);
      } else {
        setFetchedCashflows([]);
      }
    };
    fn();
  }, []);

  useEffect(() => {
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setSelectedHSP(selectedHsp);
  }, []);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* End Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}

          {/* Container fluid  */}
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Manage HSP",
                  url: "/accredited-hsp",
                },
                {
                  title: "Accredited HSP",
                  url: "/accredited-hsp",
                },
                {
                  title: `${selectedHSP.hsp_name} Information`,
                  url: "/view-hsp",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="tab-pane" id="settings1">
                  <ViewHspNavBar />
                </div>
                <div className="tab-content">
                  {/* ------------------------ hsp details ---------------------------------- */}
                  <HspDetailsTab />
                  {/* ---------------------  conditions/ Contracts -----------------------------*/}
                  <ContractConditionsTab />
                  {/* --------------------- Consultation Pricing ------------------------------- */}
                  <ConsultationTab fetchedCashflows={fetchedCashflows} />
                  {/* --------------------- Investigation Pricing  --------------------------------*/}
                  <InvestigationsAndPricingTab
                    fetchedCashflows={fetchedCashflows}
                  />
                  {/*  ----------------------- meds and consumable Pricing ------------------------- */}
                  <MedsAndConsumablesTab fetchedCashflows={fetchedCashflows} />
                  {/* ------------------------- Surgery and Procedures Pricing --------------------- */}
                  <SurgeryAndProceduresTab
                    fetchedCashflows={fetchedCashflows}
                  />

                  {/* ------------------------- Optical Tab --------------------- */}
                  <OpticalTab fetchedCashflows={fetchedCashflows} />

                  {/* ------------------------- Dental Tab --------------------- */}
                  <DentalTab fetchedCashflows={fetchedCashflows} />

                  {/* -------------------------- Claim history --------------------------------------- */}
                  <ClaimsHistoryTab />
                </div>
              </div>
            </div>
          </div>
          {/* Disable category modal content */}
          <DisableCategoryModal />

          {/* View modal */}

          <ViewHSPPDFModal />
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewHsp;
