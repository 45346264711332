import axios from "axios";
import { HspMedsAndConsumablesType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspMedsAndConsumables = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/med_consumables/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_owner_id: hspId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllHspMedsAndConsumablesByCategory = async (
  token: string,
  categoryId: string
) => {
  let url = `${BASE_URL}/hsp/med_consumables/category/`;
  try {
    const response = await axios.post(
      url,
      {
        med_and_consumables_hsp_category: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHspMedsAndConsumables = async (
  token: string,
  medsAndConsumablesData: HspMedsAndConsumablesType[]
) => {
  let url = `${BASE_URL}/hsp/med_consumables/`;
  try {
    // remove med_consumables_code from every item in the array
    medsAndConsumablesData.forEach((item) => {
      delete item.med_consumables_code;
    });

    const response = await axios.post(
      url,
      { Med_and_Consumables: medsAndConsumablesData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspMedsAndConsumablesDetails = async (
  token: string,
  medsAndConsumablesId: string
) => {
  let url = `${BASE_URL}/hsp/med_consumables/details`;
  try {
    const response = await axios.post(
      url,
      { hsp_consultation_id: medsAndConsumablesId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspMedsAndConsumables = async (
  token: string,
  medsAndConsumablesId: string
) => {
  let url = `${BASE_URL}/hsp/med_consumables/status-change`;
  try {
    const response = await axios.post(
      url,
      { med_and_consumables_hsp_id: medsAndConsumablesId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspMedsAndConsumables = async (
  token: string,
  medsAndConsumablesId: string
) => {
  let url = `${BASE_URL}/hsp/med_consumables/status-change`;
  try {
    const response = await axios.post(
      url,
      { med_and_consumables_hsp_id: medsAndConsumablesId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteHspMedsAndConsumables = async (
  token: string,
  medsAndConsumablesId: string
) => {
  let url = `${BASE_URL}/hsp/med_consumables/delete`;
  try {
    const response = await axios.post(
      url,
      { med_and_consumables_hsp_id: medsAndConsumablesId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editHspMedsAndConsumables = async (
  token: string,
  medsAndConsumablesData: HspMedsAndConsumablesType[]
) => {
  let url = `${BASE_URL}/hsp/med_consumables/edit`;
  try {
    const response = await axios.post(
      url,
      { Med_and_Consumables: medsAndConsumablesData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspMedsAndConsumables,
  createHspMedsAndConsumables,
  getHspMedsAndConsumablesDetails,
  deactivateHspMedsAndConsumables,
  activateHspMedsAndConsumables,
  deleteHspMedsAndConsumables,
  getAllHspMedsAndConsumablesByCategory,
  editHspMedsAndConsumables,
};
