import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { HspSurgeriesType } from "../../../types/data";
import hspSurgeriesApi from "../../../api/hspSurgeriesApi";
import axios from "axios";
import { StyledSearch } from "../../StyledSearch";
import SaveAllServicesModal from "./SaveAllServicesModal";
import MasterTableComponent from "../../ui/MasterTableComponent";
import formatMoney from "../../../utils/formatMoney";
import EditHspSurgeryAndProcedureModal from "./EditHspSurgeryAndProcedureModal";
import closeAnyModal from "../../../utils/closeAnyModal";

interface SurgeryAndProceduresTabProps {
  fetchedCashflows: any[];
}

const SurgeryAndProceduresTab: React.FC<SurgeryAndProceduresTabProps> = ({
  fetchedCashflows,
}) => {
  const toast = useRef<Toast>(null);
  const [token, setToken] = useState<string>("");
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>({});

  const [fetchedSurgeries, setFetchedSurgeries] = useState<HspSurgeriesType[]>(
    []
  );
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [hspName, setHspName] = useState<string>("");
  const [hspOwnerId, setHspOwnerId] = useState("");

  const [alreadyAddedSurgeries, setAlreadyAddedSurgeries] = useState<any[]>([]);
  const [createNewSurgeriesBody, setCreateNewSurgeriesBody] = useState<
    HspSurgeriesType[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });
  const [createNewRecordIsLoading, setCreateNewRecordIsLoading] =
    useState(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [includedData, setIncludedData] = useState<HspSurgeriesType[]>([]);

  const getIncludedData = () => {
    const data = tableRows.filter(
      (surgery: HspSurgeriesType) =>
        surgery.surgery_and_procedure_included === true &&
        surgery.has_been_added === false
    );
    setIncludedData(data);
    return data;
  };

  const toggleSaveAllModal = () => {
    const included_data = getIncludedData();
    if (included_data.length === 0) {
      show("error", "No services selected");
    }
  };
  [] > [];

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handlefetchAlreadyAddedSurgeries = async () => {
    const selectedHSP = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    const res = await hspSurgeriesApi.getAllHspSurgeryAndProcedures(
      sessionStorage.getItem("token") || "",
      selectedHSP?.id || ""
    );
    if (res.status === 200) {
      setAlreadyAddedSurgeries(res.info);
    }
  };

  const handleAddNewSugeryToHSPList = async (e: any) => {
    setFetchIsLoading(true);
    const category: any = fetchedSurgeries.find(
      (item: any) => item.id === e.target.value
    );
    setSelectedCategory({
      name: category?.surgery_category,
      id: category?.id,
    });

    const res = await hspSurgeriesApi.getAllHspSurgeryAndProceduresByCategory(
      sessionStorage.getItem("token") || "",
      e.target.value
    );
    const response = res?.data ? res?.data : res?.info;
    if (response?.length > 0) {
      const tempThings = response?.map((item: any) => {
        const alreadyAdded = alreadyAddedSurgeries.find(
          (surgery: any) => surgery.service_name === item.surgery_name
        );
        if (alreadyAdded) {
          return {
            surgery_code: item.surgery_code,
            service_name: alreadyAdded.service_name,
            surgery_and_procedure_included: true,
            standard_rate: alreadyAdded.standard_rate,
            discount_rate: alreadyAdded.discount_rate,
            net_rate: alreadyAdded.net_rate,
            surgery_and_procedure_approval:
              alreadyAdded.surgery_and_procedure_approval,
            surgery_and_procedure_revenue_type:
              alreadyAdded.surgery_and_procedure_revenue_type,
            has_been_added: true,
            surgery_id: alreadyAdded?.id || "",
          };
        } else {
          return {
            surgery_code: item.surgery_code,
            service_name: item.surgery_name,
            surgery_and_procedure_included: false,
            standard_rate: 0.0,
            discount_rate: 0.0,
            net_rate: 0.0,
            surgery_and_procedure_approval: false,
            surgery_and_procedure_revenue_type: "",
            has_been_added: false,
          };
        }
      });
      setCreateNewSurgeriesBody(tempThings);
      setFetchIsLoading(false);
    } else {
      setCreateNewSurgeriesBody([]);
      setFetchIsLoading(false);
    }
  };

  const handleCreateNewSurgery = async () => {
    setCreateNewRecordIsLoading(true);
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    const includedDat = getIncludedData();
    let dataToSave: HspSurgeriesType[] = includedDat.map(
      (item: HspSurgeriesType) => ({
        ...item,
        hsp_owner_id: hspId,
        hsp_category: selectedCategory.name,
      })
    );
    // remove has_been_added property
    dataToSave = dataToSave.map((item: HspSurgeriesType) => {
      delete item.has_been_added;
      return item;
    });

    const response = await hspSurgeriesApi.createHspSurgeryAndProcedure(
      token,
      dataToSave
    );
    if (response.status === 200) {
      setCreateNewRecordIsLoading(false);
      // setCreateNewSurgeriesBody([]);
      // setFetchedSurgeries(response.data);
      show("success", "New Surgery Created Successfully");
      document
        .getElementById("saveAllServicesModal-surgeries")
        ?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      handleFetchAllSurgeryCategories();
    } else {
      setCreateNewRecordIsLoading(false);
      show("error", response?.message);
    }
  };

  const handleUpdateSurgery = () => {
    setIsUpdateLoading(true);

    hspSurgeriesApi
      .editHspSurgeryAndProcedure(token, [
        {
          hsp_category: selectedCategory?.name,
          service_name: selectedItemToEdit?.service_name,
          surgery_and_procedure_approval:
            selectedItemToEdit?.surgery_and_procedure_approval,
          surgery_and_procedure_included:
            selectedItemToEdit?.surgery_and_procedure_included,
          surgery_and_procedure_revenue_type:
            selectedItemToEdit?.surgery_and_procedure_revenue_type,
          discount_rate: selectedItemToEdit?.discount_rate,
          net_rate: selectedItemToEdit?.net_rate,
          standard_rate: selectedItemToEdit?.standard_rate,
          surgery_and_procedure_hsp_id: selectedItemToEdit?.surgery_id,
          hsp_owner_id: hspOwnerId,
        },
      ])
      .then(async (res) => {
        if (res.status === 200) {
          show("success", "Surgery Updated Successfully");
          await handlefetchAlreadyAddedSurgeries();
          setIsUpdateLoading(false);
          closeAnyModal("EditHspSurgeryAndProcedureModal");
        } else {
          show("error", res.message);
          setIsUpdateLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchAllSurgeryCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/hsp/surgery/category/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setFetchedSurgeries(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setHspName(selectedHsp?.hsp_name);
    setHspOwnerId(selectedHsp?.id);
    handleFetchAllSurgeryCategories();
    handlefetchAlreadyAddedSurgeries();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = createNewSurgeriesBody.filter((data: any) => {
      return (
        data?.surgery_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.service_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : createNewSurgeriesBody;
    setTableRows(dataToRender);
  }, [searchQuery, createNewSurgeriesBody]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane" id="sugeryAndProceduresTab">
        <div className="row">
          <div className="col-md-8 offset-md-2 ">
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  <select
                    name=""
                    id=""
                    className="col-md-12 border-2 py-2 rounded-pill"
                    onChange={handleAddNewSugeryToHSPList}
                  >
                    <option value="">select multiple options</option>
                    {fetchedSurgeries.length > 0 &&
                      fetchedSurgeries.map((item: any, idx: number) => {
                        return (
                          <option key={`surgery-${idx}`} value={item.id}>
                            {item.surgery_category} - {item.surgery_initials}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive" id="myTable">
              <div className="table-responsive" id="myTable">
                <div className="flex justify-end  gap-4">
                  <StyledSearch
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search by Investigation or Code"
                  />
                  <button
                    onClick={toggleSaveAllModal}
                    data-target={`${
                      includedData.length === 0
                        ? ""
                        : "#saveAllServicesModal-surgeries"
                    }`}
                    data-toggle="modal"
                    className={`btn btn-primary rounded-pill mb-1 bg-primary ${
                      tableRows.length === 0 && "disabled"
                    }`}
                  >
                    <i className="fas fa-save" /> Save All
                  </button>
                </div>
                <MasterTableComponent
                  headers={[
                    "Code",
                    "Service Name",
                    "Included",
                    "Std Tariff",
                    "Agreed Tariff",
                    "Net Tariff",
                    "Authorization",
                    "Cashflow Type",
                    "Action",
                  ]}
                  loading={fetchIsLoading}
                  emptyTableMessage="No Surgery & Procedures Available"
                  tableRows={
                    tableRows &&
                    tableRows.map((item: HspSurgeriesType, idx: number) => ({
                      Code: item?.surgery_code,
                      "Service Name": item.service_name,
                      Included: (
                        <input
                          type="checkbox"
                          className=""
                          disabled={item.has_been_added}
                          checked={item.surgery_and_procedure_included}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].surgery_and_procedure_included =
                              e.target.checked;
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Std Tariff": (
                        <input
                          type="number"
                          disabled={item.has_been_added}
                          className="form-control"
                          value={item.standard_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].standard_rate = e.target.value;
                            newBody[idx].net_rate =
                              parseFloat(e.target.value) -
                              parseFloat(newBody[idx].discount_rate);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Agreed Tariff": (
                        <input
                          type="number"
                          disabled={item.has_been_added}
                          className="form-control"
                          value={item.discount_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].discount_rate = e.target.value;
                            newBody[idx].net_rate =
                              parseFloat(newBody[idx].standard_rate) -
                              parseFloat(e.target.value);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Net Tariff": (
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={item.net_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].net_rate = e.target.value;
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      Authorization: (
                        <input
                          type="checkbox"
                          disabled={item.has_been_added}
                          checked={item.surgery_and_procedure_approval}
                          onChange={(e: any) => {
                            const newBody = [...tableRows];
                            newBody[idx].surgery_and_procedure_approval =
                              e.target.checked;
                            setTableRows(newBody);
                          }}
                          className=""
                        />
                      ),
                      "Cashflow Type": (
                        <div className="form-group mb-3">
                          <select
                            className="form-control plantype"
                            name="plan_type"
                            disabled={item.has_been_added}
                            id="mySelection"
                            value={item.surgery_and_procedure_revenue_type}
                            // placeholder="Select Type"
                            onChange={(e: any) => {
                              const newSugery = [...tableRows];
                              newSugery[
                                idx
                              ].surgery_and_procedure_revenue_type =
                                e.target.value;
                              setTableRows(newSugery);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {fetchedCashflows?.map(
                              (cashflow: any, idx: number) => (
                                <option
                                  key={idx}
                                  value={
                                    cashflow?.cash_flow_type +
                                    " - " +
                                    cashflow?.percentage +
                                    "%"
                                  }
                                >
                                  {cashflow?.cash_flow_type} -{" "}
                                  {cashflow?.percentage
                                    ? `${cashflow?.percentage}%`
                                    : ""}{" "}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ),
                      Action: (
                        <button
                          disabled={!item.has_been_added}
                          className={`
                          btn ${
                            item.has_been_added
                              ? "text-primary"
                              : "text-gray-500 cursor-not-allowed"
                          } 
                          `}
                          data-toggle="modal"
                          data-target="#EditHspSurgeryAndProcedureModal"
                          onClick={() => {
                            setSelectedItemToEdit(item);
                          }}
                        >
                          <i className="fas fa-edit" />
                        </button>
                      ),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* save all modal */}
      <SaveAllServicesModal
        selectedCategory={selectedCategory}
        hspName={hspName}
        type={"Surgeries & Procedures"}
        handleSaveAllServices={handleCreateNewSurgery}
        itemsToSave={includedData?.map(
          (surgery: HspSurgeriesType, index: number) => ({
            Code: surgery.surgery_code,
            "Service Name": surgery.service_name,
            Included: surgery.surgery_and_procedure_included ? "Yes" : "No",
            "Std. Tariff": formatMoney(surgery.standard_rate),
            "Agreed Tariff": formatMoney(surgery.discount_rate),
            "Net Tariff": formatMoney(surgery.net_rate),
            Authorization: surgery.surgery_and_procedure_approval
              ? "Yes"
              : "No",
            "Cashflow Type": surgery.surgery_and_procedure_revenue_type,
          })
        )}
        selector="saveAllServicesModal-surgeries"
      />
      {/* // edit modal */}
      <EditHspSurgeryAndProcedureModal
        serviceType="Surgery & Procedure"
        itemToEdit={selectedItemToEdit}
        setItemToEdit={setSelectedItemToEdit}
        fetchedCashflows={fetchedCashflows}
        handleUpdate={handleUpdateSurgery}
        loading={isUpdateLoading}
      />
    </>
  );
};

export default SurgeryAndProceduresTab;
