import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;
import { createNewBatchType } from "../types/data";

const createNewBatch = async (
  token: string | null,
  batchData: createNewBatchType
) => {
  let url = `${BASE_URL}/batch/`;
  try {
    const response = await axios.post(url, batchData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllBatches = async (
  token: string | null
  // requestBody: getBatchesType
) => {
  let url = `${BASE_URL}/batch/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllBatchByFlag = async (token: string | null) => {
  let url = `${BASE_URL}/batch/flag`;
  try {
    const response = await axios.post(
      url,
      {
        batch_flag: false,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const confirmingReceiptStatus = async (
  toekn: string | null,
  batchId: string
) => {
  let url = `${BASE_URL}confirm-status`;
  try {
    const response = await axios.post(
      url,
      { batch_id: batchId },
      {
        headers: {
          Authorization: `Bearer ${toekn}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getClaimsByBatchId = async (token: string | null, batchId: string) => {
  let url = `${BASE_URL}/batch/claims/batch_id`;
  try {
    const response = await axios.post(
      url,
      { batch_id: batchId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const unconfirmedReceiptStatus = async (
  token: string | null,
  batchId: string
) => {
  let url = `${BASE_URL}/batch/unconfirmed-status`;
  try {
    const response = await axios.post(
      url,
      { batch_id: batchId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const pushBatch = async (token: string | null, batchId: string) => {
  let url = `${BASE_URL}/batch/push_batch`;
  try {
    const response = await axios.post(
      url,
      { batch_id: batchId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editBatchProgressStatus = async (
  token: string | null,
  batchId: string,
  progressStatus: string
) => {
  let url = `${BASE_URL}/batch/edit-status`;
  try {
    const response = await axios.post(
      url,
      { batch_id: batchId, progress_status: progressStatus },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  createNewBatch,
  getAllBatches,
  confirmingReceiptStatus,
  getClaimsByBatchId,
  unconfirmedReceiptStatus,
  pushBatch,
  editBatchProgressStatus,
  getAllBatchByFlag,
};
