import { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import axios from "axios";
import Sidebar from "../../components/sidebar";
import { LabsType } from "../../types/data";
import { StyledSearch } from "../../components/StyledSearch";
import { Toast } from "primereact/toast";
import formatDate from "../../utils/formatDate";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import BulkUploadModal from "../../components/BulkUploadModal";
import labsApi from "../../api/labsApi";
import closeAnyModal from "../../utils/closeAnyModal";

const Labs = () => {
  const [category, setCategory] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [categoryData, setCategoryData] = useState<any>([]);
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);

  const [medication_name, setMedicationName] = useState<string>("");
  const [medication_category, setMedicationCategory] = useState<string>("");
  const [labsData, setLabsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medicationCode, setMedicationCode] = useState<string>("");
  const toast = useRef<Toast>(null);
  const [selectedLab, setSelectedLab] = useState<any>({});
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [tableRows, setTableRows] = useState<LabsType[]>([]);
  const [selectedCategoryDateAndStatus, setSelectedCategoryDateAndStatus] =
    useState({
      date: "",
      status: "",
    });

  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any>({});

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      return toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select a file",
        life: 3000,
      });
    }

    setBulkUploading(true);
    const formData = new FormData();
    formData.append("excel_file", bulkUploadBody);
    labsApi
      .bulkLabsUpload(sessionStorage.getItem("token") || "", formData)
      .then((res) => {
        setBulkUploading(false);
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.message,
            life: 3000,
          });

          handleFetchLabs();
          closeAnyModal("labsBulkUploadModal");
        }
      })
      .catch((err) => {
        setBulkUploading(false);
        console.log(err);
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: err.data.message,
          life: 3000,
        });
      });
  };

  const handleAddLabCategory = (e: any) => {
    e.preventDefault();
    if (initials.length !== 3) {
      return toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Lab Initials must be 3 characters",
        life: 3000,
      });
    }
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/category/`,
        {
          labs_category: category,
          labs_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        handleFetchLabCategories();
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: err.data.message,
          life: 3000,
        });
      });
  };

  const handleAddLab = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/`,
        {
          labs_category: medication_category,
          labs_name: medication_name,
          lab_code: medicationCode,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        console.log("res", res);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchLabs();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: err.message,
          life: 3000,
        });
      });
  };

  const handleFetchLabCategories = () => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup-services/labs/category/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchLabs = () => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup-services/labs/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setLabsData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteLabs = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/delete`,
        {
          lab_id: selectedLab.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchLabs();
          // close modal and remove backdrop
          document.getElementById("deleteModal")?.classList.remove("show");
          let backdrop = document.getElementsByClassName("modal-backdrop")[0];
          backdrop?.remove();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data?.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const FilterCategoryFromCategories = (categoryId: string) => {
    let temp = categoryData.filter(
      (category: any) => category.id != categoryId
    );
    setCategoryData(temp);
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/category/delete`,
        {
          labs_category_id: categoryToDelete.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          FilterCategoryFromCategories(categoryToDelete.id);
          setIsDeleteCategory(false);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = labsData.filter((data: any) => {
      return (
        data?.lab_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.lab_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.lab_category.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  useEffect(() => {
    handleFetchLabCategories();
    handleFetchLabs();
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredServiceData : labsData;
    setTableRows(dataToRender);
  }, [searchQuery, labsData]);

  const handleDeactivate = (id: string) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/status-change`,
        {
          lab_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchLabs();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAactivate = (id: string) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/status-change`,
        {
          lab_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchLabs();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUpdateCategory = (categoryId: string) => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/labs/category/edit`,
        {
          labs_category_id: categoryId,
          labs_category: category,
          labs_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setIsEditable(false);
          setCategory("");
          setInitials("");
          handleFetchLabCategories();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          {/* Bread crumb and right sidebar toggle */}
          {/* ============================================================== */}

          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Services",
                  url: "/labs",
                },
                {
                  title: "Labs",
                  url: "/labs",
                },
              ]}
            />
            <div className="page-breadcrumb">
              <div className="">
                <div className="">
                  <div className="">
                    {/* modal for add Labs */}
                    <div
                      className="modal fade"
                      id="bs-example"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                              {" "}
                              Add Labs
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body">
                                    <form onSubmit={handleAddLab}>
                                      <div className="form-body">
                                        {/* step one */}
                                        <div className="step">
                                          <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="plan_type">
                                                  Category
                                                </label>
                                                <br />
                                                <select
                                                  className="form-control plantype"
                                                  name="plan_type"
                                                  id="plan_type"
                                                  onChange={(e) =>
                                                    setMedicationCategory(
                                                      e.target.value
                                                    )
                                                  }
                                                  // placeholder="Select Type"
                                                >
                                                  <option disabled selected>
                                                    Select
                                                  </option>
                                                  {categoryData.map(
                                                    (category_data: any) => (
                                                      <option
                                                        key={category_data.id}
                                                        value={
                                                          category_data.lab_category
                                                        }
                                                        className="generic"
                                                      >
                                                        {
                                                          category_data.lab_category
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="benefit">
                                                  Lab Name
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Amoun"
                                                  placeholder="Hepatitis B"
                                                  value={medication_name}
                                                  onChange={(e) =>
                                                    setMedicationName(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group mb-3">
                                                <label htmlFor="benefit">
                                                  Code
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Amoun"
                                                  placeholder="HG09"
                                                  value={medicationCode}
                                                  onChange={(e) =>
                                                    setMedicationCode(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="submit-btn">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-sm float-right bg-[#5F76E8]"
                                          >
                                            {isLoading ? (
                                              <>
                                                <i className="fas fa-circle-notch fa-spin" />
                                                Saving..
                                              </>
                                            ) : (
                                              <>
                                                <i className="fas fa-paper-plane" />{" "}
                                                Save
                                              </>
                                            )}
                                          </button>
                                        </div>
                                        {/* end previous / next buttons */}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                    {/*  Modal content for the above example */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="col-md-12 flex justify-between mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Lab Setup
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by Name, Category, Code"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] mx-1 rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example"
                          >
                            <i className="fas fa-tasks" /> Add Labs
                          </button>
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] mx-1 rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-tasks" /> Set Up Category
                          </button>
                          <button
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#labsBulkUploadModal"
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Code",
                          "Name",
                          "Category",
                          "Date Added",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          tableRows &&
                          tableRows.map((lab: LabsType, index: number) => ({
                            SN: index + 1,
                            Code: lab.lab_code,
                            Name: lab.lab_name,
                            Category: lab.lab_category,
                            "Date Added": Number(lab.created_at)
                              ? convertTimestampToDate(Number(lab.created_at))
                              : formatDate(lab.created_at),
                            Status: lab.status,
                            Action: (
                              <>
                                <a
                                  className="mx-1"
                                  href={`/view-labs?labs_id=${lab.id}&action_type=view`}
                                >
                                  <i className="fas fa-eye text-primary delplan"></i>
                                </a>

                                <a
                                  className="mx-1"
                                  href={`/view-labs?labs_id=${lab.id}&action_type=edit`}
                                >
                                  <i className="fas fa-edit text-primary" />
                                </a>
                                {lab.status.toLowerCase() === "active" ? (
                                  <i
                                    className="fas fa-times text-primary"
                                    onClick={() => {
                                      handleDeactivate(lab.id);
                                    }}
                                  />
                                ) : (
                                  <i
                                    onClick={() => {
                                      handleAactivate(lab.id);
                                    }}
                                    className="fas fa-power-off text-primary"
                                  />
                                )}
                                <button
                                  type="button"
                                  className="mx-1"
                                  data-target="#deleteModal"
                                  data-toggle="modal"
                                  onClick={() => {
                                    setSelectedLab(lab);
                                  }}
                                >
                                  <i className="fas fa-trash text-primary delplan" />
                                </button>
                              </>
                            ),
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="bs-example-modal-lg"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Add Labs
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleAddLabCategory}>
                            <div className="form-body">
                              {/* step one */}
                              {!isDeleteCategory && (
                                <>
                                  <div className="step">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Category
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Amoun"
                                            placeholder="Test Category"
                                            onChange={(e) =>
                                              setCategory(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Code Initials{" "}
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Amoun"
                                            placeholder="T09"
                                            onChange={(e) =>
                                              setInitials(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm float-right mb-4 bg-[#5F76E8]"
                                    >
                                      {isLoading ? (
                                        <>
                                          <i className="fas fa-circle-notch fa-spin" />
                                          Saving..
                                        </>
                                      ) : (
                                        <>
                                          <i className="fas fa-paper-plane" />{" "}
                                          Save
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </>
                              )}
                              {isDeleteCategory && (
                                <div className="border border-danger mb-5 p-2">
                                  <p className="text-[16px] font-normal my-2">
                                    Confirm Deletion of <br />
                                    <span className="text-danger">
                                      {categoryToDelete.lab_category}
                                    </span>
                                    <br />
                                    This action cannot be undone.
                                  </p>
                                  <div className="flex justify-end gap-4">
                                    <div
                                      onClick={() => {
                                        setIsDeleteCategory(false);
                                        setCategoryToDelete({});
                                      }}
                                      className="btn btn-danger rounded-pill bg-danger"
                                    >
                                      <i className="fa fa-times mx-1"></i>
                                      Cancel
                                    </div>
                                    <div
                                      className="btn btn-danger rounded-pill bg-danger"
                                      onClick={handleDelete}
                                    >
                                      <i className="fa fa-trash mx-1"></i>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="table-responsive">
                                <div className="col-md-12"></div>
                                <table
                                  id="default_order"
                                  className="table datatable table-striped table-hover table-sm"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Code Initials</th>
                                      <th>Category</th>
                                      <th>Date Added</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  {categoryData && (
                                    <tbody>
                                      {isEditable && (
                                        <tr className="border-2 border-primary">
                                          <td>
                                            <input
                                              defaultValue={initials}
                                              onChange={(e) => {
                                                setInitials(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              defaultValue={category}
                                              onChange={(e) => {
                                                setCategory(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            {Number(
                                              selectedCategoryDateAndStatus.date
                                            )
                                              ? convertTimestampToDate(
                                                  Number(
                                                    selectedCategoryDateAndStatus.date
                                                  )
                                                )
                                              : formatDate(
                                                  selectedCategoryDateAndStatus.date
                                                )}
                                          </td>
                                          <td>
                                            {
                                              selectedCategoryDateAndStatus.status
                                            }
                                          </td>
                                          <td className="align-center">
                                            <span>
                                              {!isLoading ? (
                                                <>
                                                  <i
                                                    onClick={() => {
                                                      handleUpdateCategory(
                                                        selectedCategoryId
                                                      );
                                                    }}
                                                    className="fa fa-save text-primary mx-1"
                                                  ></i>
                                                  <i
                                                    onClick={() => {
                                                      setIsEditable(false);
                                                    }}
                                                    className="fa fa-times text-warning"
                                                  ></i>
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fa fa-spinner fa-spin text-primary"></i>
                                                </>
                                              )}{" "}
                                            </span>
                                          </td>
                                        </tr>
                                      )}
                                      {categoryData.map(
                                        (category: LabsType, index: number) => (
                                          <tr
                                            key={index}
                                            className="clickable-row"
                                          >
                                            <td>{category.lab_initials}</td>
                                            <td> {category.lab_category}</td>
                                            <td>
                                              {Number(
                                                selectedCategoryDateAndStatus.date
                                              )
                                                ? convertTimestampToDate(
                                                    Number(
                                                      selectedCategoryDateAndStatus.date
                                                    )
                                                  )
                                                : formatDate(
                                                    selectedCategoryDateAndStatus.date
                                                  )}
                                            </td>
                                            <td>{category.status}</td>
                                            <td>
                                              <i
                                                onClick={() => {
                                                  setCategory(
                                                    category.lab_category
                                                  );
                                                  setInitials(
                                                    category.lab_initials || ""
                                                  );
                                                  setSelectedCategoryDateAndStatus(
                                                    {
                                                      date: category.created_at,
                                                      status: category.status,
                                                    }
                                                  );
                                                  FilterCategoryFromCategories(
                                                    category.id
                                                  );
                                                  setSelectedCategoryId(
                                                    category.id
                                                  );
                                                  setIsEditable(true);
                                                }}
                                                className="fas fa-edit text-primary"
                                              />
                                              <button
                                                type="button"
                                                className="mx-1"
                                                onClick={() => {
                                                  setIsDeleteCategory(true);
                                                  setCategoryToDelete(category);
                                                }}
                                              >
                                                <i className="fas fa-trash text-primary delplan" />
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/* Modal for Delete Surgery */}
          <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Delete Lab
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p className="text-center text-sm">
                            Are you sure you want to delete. <br />
                            deleting labs:{" "}
                            <span className="text-red-500">
                              {selectedLab?.lab_name} <br />
                            </span>
                            cannot be reversed.
                          </p>
                          <div className="flex justify-end gap-4 mt-4">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                            >
                              <i className="fas fa-times" />{" "}
                              {<span className="mx-2">Cancel</span>}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                              onClick={handleDeleteLabs}
                            >
                              <i className="fas fa-trash" />{" "}
                              {<span className="mx-2">Delete </span>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/* -------------- BULK UPLOAD MODAL */}
          <BulkUploadModal
            title="Labs"
            selector="labsBulkUploadModal"
            setBulkUploadBody={setBulkUploadBody}
            handleBulkUpload={handleBulkUpload}
            loading={bulkUploading}
          />

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Labs;
