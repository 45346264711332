import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { StyledSearch } from "../../components/StyledSearch";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";

const ViewedMedVett = () => {
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          {/* Bread crumb and right sidebar toggle */}
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Medical Vetting",
                  url: "/medical-vetting",
                },
                {
                  title: "Vetted Claims",
                  url: "/view-med-vetting",
                },
              ]}
            />

            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <h3 className="card-title text-center">Batch Number</h3>
                    <div className="flex justify-end mb-4">
                      <StyledSearch />
                    </div>
                    <div className="table-responsive" id="myTable">
                      <table
                        id="default_order"
                        className="table table-sm table-striped table-bordered table-hover display"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">SN</th>
                            <th scope="col">Claim Number</th>
                            <th scope="col">Patient Name</th>
                            <th scope="col">Patient ID</th>
                            <th scope="col">Date Attended</th>
                            <th scope="col">Total Amount</th>
                            <th>Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <a href="medvetind.html"> 1234</a>
                            </td>
                            <td>Abena Stanly</td>
                            <td>123213</td>
                            <td>22 May, 2023</td>
                            <td>
                              <span className="badge badge-pill badge-success">
                                5000
                              </span>
                            </td>
                            <td>confirmed</td>
                            <td className="justify-content-around d-flex">
                              <a href="/med-vetting-details" type="button">
                                <i className="fas fa-eye text-primary" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewedMedVett;
