import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllLabs = async (token: string) => {
  let url = `${BASE_URL}/setup-services/labs/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getAllLabCategories = async (token: string) => {
  let url = `${BASE_URL}/setup-services/labs/category/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createLabCategory = async (
  token: string,
  categoryData: {
    labs_category: string;
    labs_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/labs/category/`;
  try {
    const response = await axios.post(url, categoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteLabCategory = async (
  token: string,
  deleteCategoryData: {
    labs_category_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/labs/category/delete`;
  try {
    const response = await axios.post(url, deleteCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editLabCategory = async (
  token: string,
  editCategoryData: {
    labs_category_id: string;
    labs_category: string;
    labs_initials: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/labs/category/edit`;
  try {
    const response = await axios.post(url, editCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getLabDetails = async (token: string, lab_id: string) => {
  let url = `${BASE_URL}/setup-services/labs/details`;
  try {
    const response = await axios.post(
      url,
      { lab_id: lab_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateLab = async (token: string, lab_id: string) => {
  let url = `${BASE_URL}/setup-services/labs/deactivate_labs`;
  try {
    const response = await axios.post(
      url,
      { lab_id: lab_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateLab = async (token: string, lab_id: string) => {
  let url = `${BASE_URL}/setup-services/labs/deactivate_labs`;
  try {
    const response = await axios.post(
      url,
      { lab_id: lab_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createLab = async (
  token: string,
  labData: {
    labs_name: string;
    labs_category: string;
    labs_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/labs/`;
  try {
    const response = await axios.post(url, labData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editLab = async (
  token: string,
  editLabData: {
    labs_id: string;
    labs_name: string;
    labs_category: string;
    labs_code: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/labs/edit`;
  try {
    const response = await axios.post(url, editLabData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteLab = async (
  token: string,
  deleteLabData: {
    labs_id: string;
  }
) => {
  let url = `${BASE_URL}/setup-services/labs/delete`;
  try {
    const response = await axios.post(url, deleteLabData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const bulkLabsUpload = async (token: string | null, labsFile: FormData) => {
  let url = `${BASE_URL}/setup-services/labs/bulk-upload`;
  try {
    const response = await axios.post(url, labsFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export default {
  createLab,
  activateLab,
  deactivateLab,
  getLabDetails,
  createLabCategory,
  getAllLabCategories,
  getAllLabs,
  deleteLabCategory,
  editLabCategory,
  editLab,
  deleteLab,
  bulkLabsUpload,
};
