import axios from "axios";
import { HspSurgeriesType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspSurgeryAndProcedures = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/surgery/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_owner_id: hspId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const getAllHspSurgeryAndProceduresByCategory = async (
  token: string,
  categoryId: string
) => {
  let url = `${BASE_URL}/hsp/surgery/category/`;
  try {
    const response = await axios.post(
      url,
      {
        surgery_and_procedure_hsp_category: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHspSurgeryAndProcedure = async (
  token: string,
  surgeries: HspSurgeriesType[]
) => {
  let url = `${BASE_URL}/hsp/surgery/category/`;
  try {
    surgeries.forEach((item) => {
      delete item.surgery_code;
    });

    const response = await axios.post(
      url,
      { Surgery_and_Procedure: surgeries },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspSurgeryAndProcedureDetails = async (
  token: string,
  surgeryAndProcedureId: string
) => {
  let url = `${BASE_URL}/hsp/surgery/details`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: surgeryAndProcedureId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspSurgeryAndProcedure = async (
  token: string,
  surgeryAndProcedureId: string
) => {
  let url = `${BASE_URL}/hsp/surgery/activate_surgery_and_procedure_hsp`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: surgeryAndProcedureId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspSurgeryAndProcedure = async (
  token: string,
  surgeryAndProcedureId: string
) => {
  let url = `${BASE_URL}/hsp/surgery/deactivate_surgery_and_procedure_hsp`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: surgeryAndProcedureId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteHspSurgeryAndProcedure = async (
  token: string,
  surgeryAndProcedureId: string
) => {
  let url = `${BASE_URL}/hsp/surgery/delete`;
  try {
    const response = await axios.post(
      url,
      { surgery_and_procedure_hsp_id: surgeryAndProcedureId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editHspSurgeryAndProcedure = async (
  token: string,
  surgeryAndProcedureData: HspSurgeriesType[]
) => {
  let url = `${BASE_URL}/hsp/surgery/edit`;
  try {
    const response = await axios.post(
      url,
      { Surgery_and_Procedure: surgeryAndProcedureData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspSurgeryAndProcedures,
  createHspSurgeryAndProcedure,
  getHspSurgeryAndProcedureDetails,
  deactivateHspSurgeryAndProcedure,
  activateHspSurgeryAndProcedure,
  deleteHspSurgeryAndProcedure,
  getAllHspSurgeryAndProceduresByCategory,
  editHspSurgeryAndProcedure,
};
