import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";
import setupUserApi from "../api/setupUserApi";

const ChangePassword = () => {
  const [old_password, setOldPassword] = useState<string>("");
  const [new_password, setNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [confirm_password, setConfirmPassword] = useState<string>("");
  const toast = useRef<Toast>(null);

  // get authuser from session storage
  const authUser = sessionStorage.getItem("auth_user");

  useEffect(() => {
    const name = sessionStorage.getItem("name");
    const isverified = sessionStorage.getItem("isverified");
    if (isverified === "false") {
      show(
        "success",
        `Hello ${name}, please set a new password in order to proceed`
      );
    }
  }, []);

  const handleForm = (e: any) => {
    e.preventDefault();

    if (new_password !== confirm_password) {
      show("info", "passwords do not match");
    } else if (new_password === confirm_password) {
      setLoading(true);
      // axios
      //   .post(
      //     `${process.env.REACT_APP_Base_url}/setup_user/change_setup_user_password`,
      //     {
      //       user_id: authUser,
      //       old_password,
      //       new_password,
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      //       },
      //     }
      //   )
      setupUserApi
        .changeUserPassword(sessionStorage.getItem("token") || "", {
          user_id: authUser || "",
          old_password,
          new_password,
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          show("info", res.message);

          if (res.status === 200) {
            sessionStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 4000);
          }
        })
        .catch((err) => {
          setLoading(false);
          alert(err.message);
        });
    }
  };

  // todo: add toast message
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "info",
      detail: message,
      sticky: true,
      life: 5000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative">
          <div className="auth-box row">
            <div
              className="col-lg-7 col-md-5 modal-bg-img bg-white border-r-4"
              style={{
                backgroundImage: "url(assets/images/logo.png)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>

            <div className="col-lg-5 col-md-7 bg-white">
              <div className="p-3">
                <div className="text-center">
                  <div
                    className="icon-lock text-primary"
                    style={{ fontSize: "50px" }}
                  />
                </div>
                <h2 className="mt-3 text-center">Sign In</h2>
                <form className="mt-4" onSubmit={handleForm}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="old_password">
                          old password
                        </label>
                        <input
                          className="form-control"
                          id="old_password"
                          type="password"
                          placeholder="enter your old password"
                          required
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="new_password">
                          new password
                        </label>
                        <input
                          className="form-control"
                          id="new_password"
                          type="password"
                          placeholder="enter your new password"
                          required
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="text-dark" htmlFor="pwd">
                          confirm password
                        </label>
                        <input
                          className="form-control"
                          id="pwd"
                          type="password"
                          placeholder="confirm your password"
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-rounded btn-block btn-primary bg-[#5F76E8]"
                      >
                        {loading ? (
                          <p>loading..</p>
                        ) : (
                          <>
                            <i className="icon-login mr-4" /> submit
                          </>
                        )}
                      </button>
                    </div>
                    <div className="col-lg-12 text-center mt-5">
                      forgot password?{" "}
                      <a href="/reset-password" className="text-danger">
                        reset here
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
