import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import DynamicTable from "../../components/tables/DynamicTable";
import StaffApi from "../../api/StaffApi";
import { StyledSearch } from "../../components/StyledSearch";
import AddNewStaffModal from "../../components/policy-admin/staff/AddNewStaffModal";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import formatMoney from "../../utils/formatMoney";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import DeleteStaffModal from "../../components/policy-admin/staff/DeleteStaffModal";
import capitalizeText from "../../utils/capitalizeText";
import { Toast } from "primereact/toast";
import EditStaffModal from "../../components/policy-admin/staff/EditStaffModal";
import closeAnyModal from "../../utils/closeAnyModal";
import BulkUploadModal from "../../components/BulkUploadModal";

const Staff = () => {
  // states
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [listOfStaff, setListOfStaff] = useState<any[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<any>({});
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [editStaffData, setEditStaffData] = useState<any>({});
  const [createStaffDataImage, setCreateStaffDataImage] = useState<null | File>(
    null
  );
  const [
    createStaffDependantOneDataImage,
    setCreateStaffDependantOneDataImage,
  ] = useState<null | File>(null);
  const [
    createStaffDependantTwoDataImage,
    setCreateStaffDependantTwoDataImage,
  ] = useState<null | File>(null);
  const [
    createStaffDependantThreeDataImage,
    setCreateStaffDependantThreeDataImage,
  ] = useState<null | File>(null);
  const [
    createStaffDependantFourDataImage,
    setCreateStaffDependantFourDataImage,
  ] = useState<null | File>(null);
  const [
    createStaffDependantFiveDataImage,
    setCreateStaffDependantFiveDataImage,
  ] = useState<null | File>(null);
  const [
    createStaffDependantSixDataImage,
    setCreateStaffDependantSixDataImage,
  ] = useState<null | File>(null);

  const [createStaffData, setCreateStaffData] = useState({
    // staff_image: null,
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    contact: "",
    gender: "",
    id: "",
    category: "",
    // password: "",
    location: "",
    ghana_card: "",
  });
  const [createStaffDependantData, setCreateStaffDependantData] = useState({
    dependantOne: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      contact: "",
      gender: "",
      relationship: "",
      ghana_card: "",
    },
    dependantTwo: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      contact: "",
      gender: "",
      relationship: "",
      ghana_card: "",
    },
    dependantThree: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      contact: "",
      gender: "",
      relationship: "",
      ghana_card: "",
    },
    dependantFour: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      contact: "",
      gender: "",
      relationship: "",
      ghana_card: "",
    },
    dependantFive: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      contact: "",
      gender: "",
      relationship: "",
      ghana_card: "",
    },
    dependantSix: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      contact: "",
      gender: "",
      relationship: "",
      ghana_card: "",
    },
  });

  // functions
  const fetchStaff = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const response = await StaffApi.getAllStaff(token);
    if (response?.status === 200) {
      setLoading(false);
      setListOfStaff(response?.info);
    }
    console.log(response);
    setLoading(false);
  };

  const handlePrevious = () => {
    if (currentStep === 1) {
      return currentStep;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 3) {
      return currentStep;
    }
    setCurrentStep(currentStep + 1);
  };

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    const token = sessionStorage.getItem("token");

    const formData = new FormData();
    formData.append("staff_image", `${createStaffDataImage}`);
    formData.append("staff_first_name", `${createStaffData.first_name}`);
    formData.append("staff_last_name", `${createStaffData.last_name}`);
    formData.append("staff_email", `${createStaffData.email}`);
    formData.append("staff_contact", `${createStaffData.contact}`);
    formData.append("staff_gender", createStaffData.gender);
    formData.append("staff_dob", `${createStaffData.date_of_birth}`);

    formData.append("staff_id", createStaffData.id.toString());
    formData.append("staff_category", createStaffData.category);
    // formData.append("staff_password", createStaffData.password);
    formData.append("staff_location", createStaffData.location);
    // formData.append("staff_ghana_card", createStaffData.ghana_card);
    formData.append(
      "staff_dependents[staff_dependents_one_image]",
      `${createStaffDependantOneDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_one_first_name]",
      createStaffDependantData.dependantOne.first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_one_last_name]",
      createStaffDependantData.dependantOne.last_name
    );

    formData.append(
      "staff_dependents[staff_dependents_one_dob]",
      createStaffDependantData.dependantOne.date_of_birth
    );

    formData.append(
      "staff_dependents[staff_dependents_one_email]",
      createStaffDependantData.dependantOne.email
    );
    formData.append(
      "staff_dependents[staff_dependents_one_contact]",
      createStaffDependantData.dependantOne.contact
    );
    formData.append(
      "staff_dependents[staff_dependents_one_gender]",
      createStaffDependantData.dependantOne.gender
    );
    formData.append(
      "staff_dependents[staff_dependents_one_relationship]",
      createStaffDependantData.dependantOne.relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_one_ghana_card]",
      createStaffDependantData.dependantOne.ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_two_image]",
      `${createStaffDependantTwoDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_two_first_name]",
      createStaffDependantData.dependantTwo.first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_two_last_name]",
      createStaffDependantData.dependantTwo.last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_two_dob]",
      createStaffDependantData.dependantTwo.date_of_birth
    );

    formData.append(
      "staff_dependents[staff_dependents_two_email]",
      createStaffDependantData.dependantTwo.email
    );
    formData.append(
      "staff_dependents[staff_dependents_two_contact]",
      createStaffDependantData.dependantTwo.contact
    );
    formData.append(
      "staff_dependents[staff_dependents_two_gender]",
      createStaffDependantData.dependantTwo.gender
    );
    formData.append(
      "staff_dependents[staff_dependents_two_relationship]",
      createStaffDependantData.dependantTwo.relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_two_ghana_card]",
      createStaffDependantData.dependantTwo.ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_three_image]",
      `${createStaffDependantThreeDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_three_first_name]",
      createStaffDependantData.dependantThree.first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_three_last_name]",
      createStaffDependantData.dependantThree.last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_three_dob]",
      createStaffDependantData.dependantThree.date_of_birth
    );

    formData.append(
      "staff_dependents[staff_dependents_three_email]",
      createStaffDependantData.dependantThree.email
    );
    formData.append(
      "staff_dependents[staff_dependents_three_contact]",
      createStaffDependantData.dependantThree.contact
    );
    formData.append(
      "staff_dependents[staff_dependents_three_gender]",
      createStaffDependantData.dependantThree.gender
    );
    formData.append(
      "staff_dependents[staff_dependents_three_relationship]",
      createStaffDependantData.dependantThree.relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_three_ghana_card]",
      createStaffDependantData.dependantThree.ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_four_image]",
      `${createStaffDependantFourDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_four_first_name]",
      createStaffDependantData.dependantFour.first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_four_last_name]",
      createStaffDependantData.dependantFour.last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_four_dob]",
      createStaffDependantData.dependantFour.date_of_birth
    );

    formData.append(
      "staff_dependents[staff_dependents_four_email]",
      createStaffDependantData.dependantFour.email
    );
    formData.append(
      "staff_dependents[staff_dependents_four_contact]",
      createStaffDependantData.dependantFour.contact
    );
    formData.append(
      "staff_dependents[staff_dependents_four_gender]",
      createStaffDependantData.dependantFour.gender
    );
    formData.append(
      "staff_dependents[staff_dependents_four_relationship]",
      createStaffDependantData.dependantFour.relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_four_ghana_card]",
      createStaffDependantData.dependantFour.ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_five_image]",
      `${createStaffDependantFiveDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_five_first_name]",
      createStaffDependantData.dependantFive.first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_five_last_name]",
      createStaffDependantData.dependantFive.last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_five_email]",
      createStaffDependantData.dependantFive.email
    );
    formData.append(
      "staff_dependents[staff_dependents_five_contact]",
      createStaffDependantData.dependantFive.contact
    );
    formData.append(
      "staff_dependents[staff_dependents_five_dob]",
      createStaffDependantData.dependantFive.date_of_birth
    );

    formData.append(
      "staff_dependents[staff_dependents_five_gender]",
      createStaffDependantData.dependantFive.gender
    );
    formData.append(
      "staff_dependents[staff_dependents_five_relationship]",
      createStaffDependantData.dependantFive.relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_five_ghana_card]",
      createStaffDependantData.dependantFive.ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_six_image]",
      `${createStaffDependantSixDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_six_first_name]",
      createStaffDependantData.dependantSix.first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_six_last_name]",
      createStaffDependantData.dependantSix.last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_six_dob]",
      createStaffDependantData.dependantSix.date_of_birth
    );

    formData.append(
      "staff_dependents[staff_dependents_six_email]",
      createStaffDependantData.dependantSix.email
    );
    formData.append(
      "staff_dependents[staff_dependents_six_contact]",
      createStaffDependantData.dependantSix.contact
    );
    formData.append(
      "staff_dependents[staff_dependents_six_gender]",
      createStaffDependantData.dependantSix.gender
    );
    formData.append(
      "staff_dependents[staff_dependents_six_relationship]",
      createStaffDependantData.dependantSix.relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_six_ghana_card]",
      createStaffDependantData.dependantSix.ghana_card
    );
    StaffApi.registerStaff(token, formData).then(async (res) => {
      console.log(res);
      if (res?.status === 201) {
        show("success", res?.message);
        setCurrentStep(1);
        await fetchStaff();
        setLoading(false);
        closeAnyModal("addNewStaffModal");
      } else {
        setLoading(false);
        show("error", res?.message);
      }
    });
  };
  const handleUpdateStaffDetails = (e: any) => {
    e.preventDefault();
    const token = sessionStorage.getItem("token");

    const formData = new FormData();
    formData.append("id", editStaffData?.id);
    formData.append("staff_image", `${createStaffDataImage}`);
    formData.append("staff_first_name", `${editStaffData?.staff_first_name}`);
    formData.append("staff_last_name", `${editStaffData?.staff_last_name}`);
    formData.append("staff_email", `${editStaffData.staff_email}`);
    formData.append("staff_contact", `${editStaffData.staff_contact}`);
    formData.append("staff_gender", editStaffData.staff_gender);
    formData.append("staff_dob", `${editStaffData.staff_dob}`);

    formData.append("staff_id", editStaffData.staff_id);
    formData.append("staff_category", editStaffData.staff_category);
    // formData.append("staff_password", editStaffData.staff_password);
    formData.append("staff_location", editStaffData.staff_location);
    formData.append("staff_ghana_card", editStaffData.staff_ghana_card);
    formData.append(
      "staff_dependents_one_id",
      editStaffData.staff_dependents.staff_dependents_one_id
    );
    formData.append(
      "staff_dependents[staff_dependents_one_image]",
      `${createStaffDependantOneDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_one_first_name]",
      editStaffData.staff_dependents.staff_dependents_one_first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_one_last_name]",
      editStaffData.staff_dependents.staff_dependents_one_last_name
    );

    formData.append(
      "staff_dependents[staff_dependents_one_dob]",
      editStaffData?.staff_dependents.staff_dependents_one_dob
    );

    formData.append(
      "staff_dependents[staff_dependents_one_email]",
      editStaffData?.staff_dependents.staff_dependents_one_email
    );
    formData.append(
      "staff_dependents[staff_dependents_one_contact]",
      editStaffData?.staff_dependents.staff_dependents_one_contact
    );
    formData.append(
      "staff_dependents[staff_dependents_one_gender]",
      editStaffData?.staff_dependents.staff_dependents_one_gender
    );
    formData.append(
      "staff_dependents[staff_dependents_one_relationship]",
      editStaffData?.staff_dependents.staff_dependents_one_relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_one_ghana_card]",
      editStaffData?.staff_dependents.staff_dependents_one_ghana_card
    );
    // formData.append(
    //   "staff_dependents_two_id",
    //   editStaffData.staff_dependents_two_id
    // );

    formData.append(
      "staff_dependents[staff_dependents_two_image]",
      `${createStaffDependantTwoDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_two_first_name]",
      editStaffData?.staff_dependents.staff_dependents_two_first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_two_last_name]",
      editStaffData?.staff_dependents.staff_dependents_two_last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_two_dob]",
      editStaffData?.staff_dependents.staff_dependents_two_dob
    );
    // formData.append(
    //   "staff_dependents[staff_dependents_two_age]",
    //   calculateAge(editStaffData?.staff_dependents_two_date_of_birth).toString()
    // );
    formData.append(
      "staff_dependents[staff_dependents_two_email]",
      editStaffData?.staff_dependents.staff_dependents_two_email
    );
    formData.append(
      "staff_dependents[staff_dependents_two_contact]",
      editStaffData?.staff_dependents.staff_dependents_two_contact
    );
    formData.append(
      "staff_dependents[staff_dependents_two_gender]",
      editStaffData?.staff_dependents.staff_dependents_two_gender
    );
    formData.append(
      "staff_dependents[staff_dependents_two_relationship]",
      editStaffData?.staff_dependents.staff_dependents_two_relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_two_ghana_card]",
      editStaffData?.staff_dependents.staff_dependents_two_ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_two_id]",
      editStaffData?.staff_dependents.staff_dependents_two_id
    );

    formData.append(
      "staff_dependents[staff_dependents_three_image]",
      `${createStaffDependantThreeDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_three_first_name]",
      editStaffData?.staff_dependents.staff_dependents_three_first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_three_last_name]",
      editStaffData?.staff_dependents.staff_dependents_three_last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_three_dob]",
      editStaffData?.staff_dependents.staff_dependents_three_dob
    );
    // formData.append(
    //   "staff_dependents[staff_dependents_three_age]",
    //   calculateAge(
    //     editStaffData?.staff_dependents_three_date_of_birth
    //   ).toString()
    // );
    formData.append(
      "staff_dependents[staff_dependents_three_email]",
      editStaffData?.staff_dependents.staff_dependents_three_email
    );
    formData.append(
      "staff_dependents[staff_dependents_three_contact]",
      editStaffData?.staff_dependents.staff_dependents_three_contact
    );
    formData.append(
      "staff_dependents[staff_dependents_three_gender]",
      editStaffData?.staff_dependents.staff_dependents_three_gender
    );
    formData.append(
      "staff_dependents[staff_dependents_three_relationship]",
      editStaffData?.staff_dependents.staff_dependents_three_relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_three_ghana_card]",
      editStaffData?.staff_dependents.staff_dependents_three_ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_three_id]",
      editStaffData?.staff_dependents.staff_dependents_three_id
    );

    formData.append(
      "staff_dependents[staff_dependents_four_image]",
      `${createStaffDependantFourDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_four_first_name]",
      editStaffData?.staff_dependents.staff_dependents_four_first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_four_last_name]",
      editStaffData?.staff_dependents.staff_dependents_four_last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_four_dob]",
      editStaffData?.staff_dependents.staff_dependents_four_dob
    );
    formData.append(
      "staff_dependents[staff_dependents_four_email]",
      editStaffData?.staff_dependents.staff_dependents_four_email
    );
    formData.append(
      "staff_dependents[staff_dependents_four_contact]",
      editStaffData?.staff_dependents.staff_dependents_four_contact
    );
    formData.append(
      "staff_dependents[staff_dependents_four_gender]",
      editStaffData?.staff_dependents.staff_dependents_four_gender
    );
    formData.append(
      "staff_dependents[staff_dependents_four_relationship]",
      editStaffData?.staff_dependents.staff_dependents_four_relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_four_ghana_card]",
      editStaffData?.staff_dependents.staff_dependents_four_ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_four_id]",
      editStaffData?.staff_dependents.staff_dependents_four_id
    );

    formData.append(
      "staff_dependents[staff_dependents_five_image]",
      `${createStaffDependantFiveDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_five_first_name]",
      editStaffData?.staff_dependents.staff_dependents_five_first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_five_last_name]",
      editStaffData?.staff_dependents.staff_dependents_five_last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_five_email]",
      editStaffData?.staff_dependents.staff_dependents_five_email
    );
    formData.append(
      "staff_dependents[staff_dependents_five_contact]",
      editStaffData?.staff_dependents.staff_dependents_five__contact
    );
    formData.append(
      "staff_dependents[staff_dependents_five_dob]",
      editStaffData?.staff_dependents.staff_dependents_five_dob
    );

    formData.append(
      "staff_dependents[staff_dependents_five_gender]",
      editStaffData?.staff_dependents.staff_dependents_five_gender
    );
    formData.append(
      "staff_dependents[staff_dependents_five_relationship]",
      editStaffData?.staff_dependents.staff_dependents_five_relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_five_ghana_card]",
      editStaffData?.staff_dependents.staff_dependents_five_ghana_card
    );
    formData.append(
      "staff_dependents[staff_dependents_five_id]",
      editStaffData?.staff_dependents.staff_dependents_five_id
    );
    formData.append(
      "staff_dependents[staff_dependents_six_id]",
      editStaffData?.staff_dependents.staff_dependents_six_id
    );

    formData.append(
      "staff_dependents[staff_dependents_six_image]",
      `${createStaffDependantSixDataImage}`
    );
    formData.append(
      "staff_dependents[staff_dependents_six_first_name]",
      editStaffData?.staff_dependents.staff_dependents_six_first_name
    );
    formData.append(
      "staff_dependents[staff_dependents_six_last_name]",
      editStaffData?.staff_dependents.staff_dependents_six_last_name
    );
    formData.append(
      "staff_dependents[staff_dependents_six_dob]",
      editStaffData?.staff_dependents.staff_dependents_six_dob
    );
    formData.append(
      "staff_dependents[staff_dependents_six_email]",
      editStaffData?.staff_dependents.staff_dependents_six_email
    );
    formData.append(
      "staff_dependents[staff_dependents_six_contact]",
      editStaffData?.staff_dependents.staff_dependents_six_contact
    );
    formData.append(
      "staff_dependents[staff_dependents_six_gender]",
      editStaffData?.staff_dependents.staff_dependents_six_gender
    );
    formData.append(
      "staff_dependents[staff_dependents_six_relationship]",
      editStaffData?.staff_dependents.staff_dependents_six_relationship
    );
    formData.append(
      "staff_dependents[staff_dependents_six_ghana_card]",
      editStaffData?.staff_dependents.staff_dependents_six_ghana_card
    );
    StaffApi.updateStaffDetails(token, formData)
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          show("success", res?.message);
          closeAnyModal("editStaffModal");
          fetchStaff();
        } else {
          show("error", res?.message);
        }
      })
      .catch((err) => {
        show("error", err?.message);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = listOfStaff.filter((data: any) => {
      return (
        data?.staff_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.staff_id.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      show("error", "Please select a file");
      return;
    }
    setBulkUploading(true);
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", `${bulkUploadBody}`);
    StaffApi.bulkStaffUpload(token, formData).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        fetchStaff();
        setBulkUploading(false);
        show("success", res?.message);
        closeAnyModal("staffBulkUploadModal");
        setBulkUploadBody(null);
      } else {
        setBulkUploading(false);
        show("error", res?.message);
      }
    });
  };

  const handleDeleteStaff = () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    StaffApi.deleteStaff(token, selectedStaff?.id).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        show("success", res?.message);
        fetchStaff();
        setLoading(false);
        closeAnyModal("deleteStaffModal");
      } else {
        show("error", res?.message);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : listOfStaff;
    setTableRows(dataToRender);
  }, [searchQuery, listOfStaff]);

  const handleFetchStaffDetailsForEdit = async (staff: any) => {
    // set the values of the staff to the editStaffData state
    setEditStaffData(staff);
    console.log(editStaffData);
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Policy Admin",
                  url: "/policy-admin",
                },
                {
                  title: "Staff",
                  url: "/policy-admin/staff",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="row">
                        <div className="flex gap-4 w-full justify-between mx-4 mb-2">
                          <div className="">
                            <h3 className="card-title mb-3">Staff List</h3>
                          </div>
                          <div className="flex gap-4">
                            <StyledSearch
                              placeholder="🔍 by ID or name"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <button
                              type="button"
                              className="btn btn-info btn-sm bg-primary rounded-pill"
                              data-toggle="modal"
                              data-target="#addNewStaffModal"
                            >
                              <i className="fas fa-plus" /> Add Staff
                            </button>
                            <button
                              type="button"
                              className="btn btn-info btn-sm bg-primary rounded-pill flex gap-2 items-center"
                              data-toggle="modal"
                              data-target="#staffBulkUploadModal"
                            >
                              <i className="fas fa-upload" />
                              Bulk Upload
                            </button>
                          </div>
                        </div>
                      </div>
                      <AddNewStaffModal
                        loading={loading}
                        setLoading={setLoading}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        handleSubmit={handleSubmit}
                        createStaffData={createStaffData}
                        setCreateStaffData={setCreateStaffData}
                        createStaffDataImage={createStaffDataImage}
                        setCreateStaffDataImage={setCreateStaffDataImage}
                        createStaffDependantData={createStaffDependantData}
                        setCreateStaffDependantData={
                          setCreateStaffDependantData
                        }
                        createStaffDependantOneDataImage={
                          createStaffDependantOneDataImage
                        }
                        setCreateStaffDependantOneDataImage={
                          setCreateStaffDependantOneDataImage
                        }
                        createStaffDependantTwoDataImage={
                          createStaffDependantTwoDataImage
                        }
                        setCreateStaffDependantTwoDataImage={
                          setCreateStaffDependantTwoDataImage
                        }
                        createStaffDependantThreeDataImage={
                          createStaffDependantThreeDataImage
                        }
                        setCreateStaffDependantThreeDataImage={
                          setCreateStaffDependantThreeDataImage
                        }
                        createStaffDependantFourDataImage={
                          createStaffDependantFourDataImage
                        }
                        setCreateStaffDependantFourDataImage={
                          setCreateStaffDependantFourDataImage
                        }
                        createStaffDependantFiveDataImage={
                          createStaffDependantFiveDataImage
                        }
                        setCreateStaffDependantFiveDataImage={
                          setCreateStaffDependantFiveDataImage
                        }
                        createStaffDependantSixDataImage={
                          createStaffDependantSixDataImage
                        }
                        setCreateStaffDependantSixDataImage={
                          setCreateStaffDependantSixDataImage
                        }
                      />
                      <EditStaffModal
                        editStaffData={editStaffData}
                        setEditStaffData={setEditStaffData}
                        loading={loading}
                        setLoading={setLoading}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        handleSubmit={handleUpdateStaffDetails}
                        createStaffData={createStaffData}
                        setCreateStaffData={setCreateStaffData}
                        createStaffDataImage={createStaffDataImage}
                        setCreateStaffDataImage={setCreateStaffDataImage}
                        createStaffDependantData={createStaffDependantData}
                        setCreateStaffDependantData={
                          setCreateStaffDependantData
                        }
                        createStaffDependantOneDataImage={
                          createStaffDependantOneDataImage
                        }
                        setCreateStaffDependantOneDataImage={
                          setCreateStaffDependantOneDataImage
                        }
                        createStaffDependantTwoDataImage={
                          createStaffDependantTwoDataImage
                        }
                        setCreateStaffDependantTwoDataImage={
                          setCreateStaffDependantTwoDataImage
                        }
                        createStaffDependantThreeDataImage={
                          createStaffDependantThreeDataImage
                        }
                        setCreateStaffDependantThreeDataImage={
                          setCreateStaffDependantThreeDataImage
                        }
                        createStaffDependantFourDataImage={
                          createStaffDependantFourDataImage
                        }
                        setCreateStaffDependantFourDataImage={
                          setCreateStaffDependantFourDataImage
                        }
                        createStaffDependantFiveDataImage={
                          createStaffDependantFiveDataImage
                        }
                        setCreateStaffDependantFiveDataImage={
                          setCreateStaffDependantFiveDataImage
                        }
                        createStaffDependantSixDataImage={
                          createStaffDependantSixDataImage
                        }
                        setCreateStaffDependantSixDataImage={
                          setCreateStaffDependantSixDataImage
                        }
                      />
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Staff Name/ID",
                          "Dependants",
                          "Total Benefit(%)",
                          "Outstanding Benefit(%)",
                          "Actions",
                        ]}
                        emptyTableMessage="No staff found"
                        loading={loading}
                        headerStyles="bg-primary text-white"
                        tableRows={
                          tableRows &&
                          tableRows.map((staff, index) => ({
                            sn: index + 1,
                            staff_name_id: (
                              <>
                                {capitalizeText(staff?.staff_first_name)}{" "}
                                {capitalizeText(staff?.staff_last_name)}
                                <br /> {staff.staff_id}
                              </>
                            ),
                            dependants: staff.staff_dependants || "-",
                            total_benefit: formatMoney(
                              staff.total_benefit || "0"
                            ),
                            outstanding_benefit:
                              staff?.outstanding_benefit_percentage || "-",
                            actions: (
                              <div className="flex gap-4">
                                <a
                                  href={`/view-staff?id=${staff.id}`}
                                  onClick={() => {
                                    setSelectedStaff(staff);

                                    // localStorage.setItem(
                                    //   "selectedStaff",
                                    //   JSON.stringify(staff)
                                    // );
                                  }}
                                >
                                  <i className="fas fa-eye text-primary hover:text-primary-light" />
                                </a>
                                <button
                                  data-toggle="modal"
                                  data-target="#editStaffModal"
                                  onClick={() => {
                                    handleFetchStaffDetailsForEdit(staff);
                                  }}
                                >
                                  <i className="fas fa-edit text-primary hover:text-primary-light" />
                                </button>
                                <span
                                  data-toggle="modal"
                                  data-target="#deleteStaffModal"
                                  onClick={() => {
                                    setSelectedStaff(staff);
                                  }}
                                >
                                  <i className="fas fa-trash text-primary hover:text-red-600" />
                                </span>
                              </div>
                            ),
                          }))
                        }
                      />

                      <DeleteStaffModal
                        staff={selectedStaff}
                        handleDeleteStaff={handleDeleteStaff}
                      />

                      {/* -------------- BULK UPLOAD MODAL */}
                      <BulkUploadModal
                        title="Staff"
                        selector="staffBulkUploadModal"
                        setBulkUploadBody={setBulkUploadBody}
                        handleBulkUpload={handleBulkUpload}
                        loading={bulkUploading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Staff;
