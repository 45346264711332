import React from "react";
import { StaffDataType, StaffDependantData } from "../../../types/data";
import convertTimestampToDate from "../../../utils/convertTimestampToDate";

interface EditStaffModalProps {
  handleNext: () => void;
  handlePrevious: () => void;
  handleSubmit: (e: any) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  createStaffDependantData: StaffDependantData;
  setCreateStaffDependantData: (data: StaffDependantData) => void;
  createStaffData: StaffDataType;
  setCreateStaffData: (data: StaffDataType) => void;
  createStaffDependantSixDataImage: null | File;
  setCreateStaffDependantSixDataImage: (image: null | File) => void;
  createStaffDependantFiveDataImage: null | File;
  setCreateStaffDependantFiveDataImage: (image: null | File) => void;
  createStaffDependantFourDataImage: null | File;
  setCreateStaffDependantFourDataImage: (image: null | File) => void;
  createStaffDependantThreeDataImage: null | File;
  setCreateStaffDependantThreeDataImage: (image: null | File) => void;
  createStaffDependantTwoDataImage: null | File;
  setCreateStaffDependantTwoDataImage: (image: null | File) => void;
  createStaffDependantOneDataImage: null | File;
  setCreateStaffDependantOneDataImage: (image: null | File) => void;
  createStaffDataImage: null | File;
  setCreateStaffDataImage: (image: null | File) => void;
  editStaffData: any;
  setEditStaffData: (data: any) => void;
}

function EditStaffModal({
  handleNext,
  handlePrevious,
  handleSubmit,
  currentStep,
  setCurrentStep,
  loading,
  setLoading,
  createStaffDependantData,
  setCreateStaffDependantData,
  createStaffData,
  setCreateStaffData,
  createStaffDependantSixDataImage,
  setCreateStaffDependantSixDataImage,
  createStaffDependantFiveDataImage,
  setCreateStaffDependantFiveDataImage,
  createStaffDependantFourDataImage,
  setCreateStaffDependantFourDataImage,
  createStaffDependantThreeDataImage,
  setCreateStaffDependantThreeDataImage,
  createStaffDependantTwoDataImage,
  setCreateStaffDependantTwoDataImage,
  createStaffDependantOneDataImage,
  setCreateStaffDependantOneDataImage,
  createStaffDataImage,
  setCreateStaffDataImage,
  editStaffData,
  setEditStaffData,
}: EditStaffModalProps) {
  return (
    <div
      className="modal fade"
      id="editStaffModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Add Staff
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form id="signUpForm" onSubmit={handleSubmit}>
                      <div className="form-body">
                        {/* step one */}
                        {currentStep === 1 && (
                          <div className="">
                            <h4 className="card-title ">
                              Personal Details
                              {/* <i
                              className="fas fa-file"
                              id="benefit"
                            /> */}
                            </h4>
                            <hr className="bg-dark" />
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-image" />
                                    Picture
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={editStaffData.staff_first_name}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_first_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={editStaffData.staff_last_name}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_last_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="select DoB"
                                    value={editStaffData.staff_dob}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dob: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={editStaffData.staff_email}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_email: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="0240000000"
                                    value={editStaffData.staff_contact}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_contact: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="Company Name"
                                    value={editStaffData.staff_gender}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_gender: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-history" /> Staff ID
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Staff ID"
                                    value={editStaffData.staff_id}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_id: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-4">
                                  <label htmlFor="exampleFormControlSelect1">
                                    <i className="fas fa-tasks" /> Staff
                                    Category
                                  </label>
                                  <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                    value={editStaffData.staff_category}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_category: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Select Category</option>
                                    <option value="Junior Staff">
                                      Junior Staff
                                    </option>
                                    <option value="Senior Staff">
                                      Senior Staff
                                    </option>
                                    <option value="Executive Staff">
                                      Executive Staff
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-map-pin" /> Branch
                                    Office/Location
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Branch Office/Location"
                                    value={editStaffData.staff_location}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_location: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={editStaffData.staff_ghana_card}
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_ghana_card: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* step two */}
                        {currentStep === 2 && (
                          <div className="">
                            <div className="row">
                              <div className="col-md-12 col-sm-12">
                                <h4 className="card-title">Dependants</h4>
                                <hr className="bg-dark" />
                              </div>
                              {/* dep 1 */}
                              <div className="col-md-12 col-sm-12">
                                <h5 className="sidebar-heading d-flex justify-content-between align-items-center mb-1 text-danger">
                                  <span>Dependant One</span>
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-image" />
                                    Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    // value={createStaffDependantOneDataImage}
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDependantOneDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_first_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_first_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_last_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_last_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DoB"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_dob
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_dob:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_email
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_email:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0500000000"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_contact
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_contact:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_gender
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_gender:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Relationship
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder=" select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_relationship
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_relationship:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">
                                      Select a relationship
                                    </option>
                                    <option value={"Parent"}>Parent</option>
                                    <option value={"Spouse"}>Spouse</option>
                                    <option value={"Sibling"}>Sibling</option>
                                    <option value={"Ward"}>Ward</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_one_ghana_card
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_one_ghana_card:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* dep 2 */}
                              <div className="col-md-12 col-sm-12">
                                <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                  <span>Dependant Two</span>
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-image" />
                                    Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    // value={createStaffDependantTwoDataImage}
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDependantTwoDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_first_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_two_first_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_last_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_two_last_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DoB"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_dob
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_two_dob:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_email
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_two_email:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0500000000"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_contact
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_two_contact:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="Gender"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_gender
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_two_gender:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Relationship
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder=" select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_relationship
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_two_relationship:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">
                                      Select a relationship
                                    </option>
                                    <option value={"Parent"}>Parent</option>
                                    <option value={"Spouse"}>Spouse</option>
                                    <option value={"Sibling"}>Sibling</option>
                                    <option value={"Ward"}>Ward</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_two_ghana_card
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_two_ghana_card:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* dep 3 */}
                              <div className="col-md-12 col-sm-12">
                                <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                  <span>Dependant Three</span>
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-image" />
                                    Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDependantThreeDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_first_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_three_first_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_last_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_last_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DoB"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_dob
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_dob:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_email
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_email:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0500000000"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_contact
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_contact:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="Gender"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_gender
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_gender:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Relationship
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder=" select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_relationship
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_relationship:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">
                                      Select a relationship
                                    </option>
                                    <option value={"Parent"}>Parent</option>
                                    <option value={"Spouse"}>Spouse</option>
                                    <option value={"Sibling"}>Sibling</option>
                                    <option value={"Ward"}>Ward</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_three_ghana_card
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_three_ghana_card:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* step three */}
                        {currentStep === 3 && (
                          <div className="">
                            <div className="row">
                              <div className="col-md-12 col-sm-12">
                                <h4 className="card-title">Dependants</h4>
                                <hr className="bg-dark" />
                              </div>
                              {/* dep 4 */}
                              <div className="col-md-12 col-sm-12">
                                <h5 className="sidebar-heading d-flex justify-content-between align-items-center mb-1 text-danger">
                                  <span>Dependant Four</span>
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-image" />
                                    Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    // value={createStaffDependantFourDataImage}
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDependantFourDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_first_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_four_first_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_last_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_four_last_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DoB"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_dob
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_four_dob:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_email
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_four_email:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0500000000"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_contact
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_four_contact:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="Gender"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_gender
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_four_gender:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Relationship
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder=" select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_relationship
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_four_relationship:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">
                                      Select a relationship
                                    </option>
                                    <option value={"Parent"}>Parent</option>
                                    <option value={"Spouse"}>Spouse</option>
                                    <option value={"Sibling"}>Sibling</option>
                                    <option value={"Ward"}>Ward</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_four_ghana_card
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_four_ghana_card:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* dep 5 */}
                              <div className="col-md-12 col-sm-12">
                                <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                  <span>Dependant Five</span>
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    // value={createStaffDependantFiveDataImage}
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDependantFiveDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_first_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_first_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_last_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_last_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DoB"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_dob
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_dob:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_email
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_email:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0500000000"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_contact
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_contact:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="Gender"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_gender
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_gender:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Relationship
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder=" select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_relationship
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_relationship:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">
                                      Select a relationship
                                    </option>
                                    <option value={"Parent"}>Parent</option>
                                    <option value={"Spouse"}>Spouse</option>
                                    <option value={"Sibling"}>Sibling</option>
                                    <option value={"Ward"}>Ward</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_five_ghana_card
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData?.staff_dependents,
                                          staff_dependents_five_ghana_card:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* dep 6*/}
                              <div className="col-md-12 col-sm-12">
                                <h5 className="sidebar-heading d-flex justify-content-between align-items-center my-2 text-danger">
                                  <span>Dependant Six</span>
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-image" />
                                    Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    // value={createStaffDependantSixDataImage}
                                    onChange={(e) => {
                                      if (e.target.files != null) {
                                        setCreateStaffDependantSixDataImage(
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_first_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_first_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" /> Last
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_last_name
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_last_name:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-calendar-alt" /> Date
                                    of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Company Name"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_dob
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_dob:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-envelope" /> E-mail
                                    Address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="me@gmail.com"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_email
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_email:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-phone" /> Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0500000000"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_contact
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_contact:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user-circle" />
                                    Gender
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder="Gender"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_gender
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_gender:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">Select a gender</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-address-book" />
                                    Relationship
                                  </label>
                                  <select
                                    className="form-control"
                                    // placeholder=" select one"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_relationship
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_relationship:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value="">
                                      Select a relationship
                                    </option>
                                    <option value={"Parent"}>Parent</option>
                                    <option value={"Spouse"}>Spouse</option>
                                    <option value={"Sibling"}>Sibling</option>
                                    <option value={"Ward"}>Ward</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    <i className="fas fa-user" /> Ghana Card
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="GHA-XXXXXXXXX-XX"
                                    value={
                                      editStaffData?.staff_dependents
                                        ?.staff_dependents_six_ghana_card
                                    }
                                    onChange={(e) => {
                                      setEditStaffData({
                                        ...editStaffData,
                                        staff_dependents: {
                                          ...editStaffData.staff_dependents,
                                          staff_dependents_six_ghana_card:
                                            e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* start previous / next buttons */}
                        <div className="form-footer d-flex justify-content-between">
                          <div className="mt-1">
                            <button
                              type="button"
                              id="prevBtn"
                              className="text-start btn-primary text-white"
                              onClick={handlePrevious}
                            >
                              <span>
                                <i className="fa fa-arrow-left" />
                              </span>{" "}
                              Previous
                            </button>
                          </div>
                          {currentStep === 3 ? (
                            <div>
                              <button
                                type="submit"
                                className="text-end text-white"
                                id="nextBtn"
                                onClick={handleSubmit}
                              >
                                <span>
                                  <i className="fa fa-save" />
                                </span>{" "}
                                Save
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                type="button"
                                className="text-end text-white"
                                id="nextBtn"
                                onClick={handleNext}
                              >
                                <span>
                                  <i className="fa fa-arrow-right" />
                                </span>{" "}
                                Next
                              </button>
                            </div>
                          )}
                        </div>
                        {/* end previous / next buttons */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default EditStaffModal;
