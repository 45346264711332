import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import { StyledSearch } from "../../components/StyledSearch";
import ClaimsApi from "../../api/ClaimsApi";

const ApprovedClaims = () => {
  const toast = useRef<Toast>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [fetchedApprovedClaims, setFetchedApprovedClaims] = useState<any[]>([]);
  const [filteredServiceData, setFilteredServiceData] = useState<any[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<any>({});
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleFetchApprovedClaims = () => {
    setIsFetching(true);
    ClaimsApi.getAllApprovedClaims(sessionStorage.getItem("token") || "")
      .then((res) => {
        setIsFetching(false);
        if (res.status === 200) {
          setFetchedApprovedClaims(res.info);
          // setTableRows(res.info);
        } else {
          show("error", res.message);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        show("error", err.message);
      });
  };



  useEffect(() => {
    handleFetchApprovedClaims();
  }, []);

  
  useEffect(() => {
    const dataToRender = searchQuery
      ? filteredServiceData
      : fetchedApprovedClaims;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedApprovedClaims]);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = fetchedApprovedClaims?.filter((data: any) => {
      return (
        data?.hsp_name?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.batch_number?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.total_amount?.toLowerCase()?.includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claims",
                  url: "/approved-claims",
                },
                {
                  title: "Approved Claims",
                  url: "/approved-claims",
                },
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive" id="myTable">
                      <div className="flex justify-between items-center col-md-12 mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Approved Claims
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by HSP Name, batch number"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "HSP Name",
                          "Batch Number",
                          "No. Claims",
                          "Total Amount(GHS)",
                          "Actions",
                        ]}
                        loading={isFetching}
                        emptyTableMessage="No Approved Claims found"
                        tableRows={
                          tableRows && tableRows?.length > 0
                            ? tableRows?.map(
                                (approvedClaim: any, idx: number) => {
                                  return {
                                    SN: idx + 1,
                                    "HSP Name": approvedClaim?.hsp_name,
                                    "Batch Number": approvedClaim?.batch_number,
                                    "No. Claims":
                                      approvedClaim?.number_of_claims,
                                    "Total Amount(GHS)":
                                      approvedClaim?.total_amount,
                                    Actions: (
                                      <div className="flex gap-2">
                                        {/* <i
                                    className="fa fa-check text-success delplan mr-2"
                                    data-toggle="modal"
                                    data-target="#payment-modal"
                                  /> */}
                                        <a href="/approved-claims-batch-view">
                                          <i className="fa fa-eye text-primary mr-2" />
                                        </a>
                                        {/* <i
                                    className="fa fa-trash text-danger mr-2"
                                    data-toggle="modal"
                                    data-target="#delete-modal"
                                    onClick={() => {
                                      setSelectedClaim(approvedClaim);
                                    }}
                                  /> */}
                                      </div>
                                    ),
                                  };
                                }
                              )
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="payment-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    Payment Form
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <label>Select Payment Method</label>
                          <select
                            name="myselection"
                            id="myselection"
                            className="form-control"
                          >
                            <option value="one">Depoit Bank/Cash</option>
                            <option value="two">Bank Transfer</option>
                            <option value="three">Cheque</option>
                          </select>
                        </div>
                      </div>
                      <div className="row myDiv" id="showone">
                        <div className="col-12">
                          <label>Depositor's Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Depositor's Contact</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Receipt No.</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Amount Paid</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="row myDiv" id="showtwo">
                        <div className="col-12">
                          <label>Bank Transfer</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Account Holder</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Account No.</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Receipt No.</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Amount Paid</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="row myDiv" id="showthree">
                        <div className="col-12">
                          <label>Bank Transfer</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Account Holder</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Account No.</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Receipt No.</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label>Amount Paid</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="buttons float-right mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                        >
                          Proceed
                        </button>
                        <button
                          className="btn btn-primary btn-sm closs"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ApprovedClaims;
