import React, { useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import ViewNewClaimTable from "../../components/tables/ViewNewClaimsTable";
import { TableContainer, Paper } from "@mui/material";
import ViewMManualClaimTable from "../../components/tables/ViewManualClaimsTable";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import batchClaimsApi from "../../api/batchClaimsApi";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import { Toast } from "primereact/toast";
import formatMoney from "../../utils/formatMoney";
import { StyledSearch } from "../../components/StyledSearch";
import formatDate from "../../utils/formatDate";
import ClaimsApi from "../../api/ClaimsApi";
import closeAnyModal from "../../utils/closeAnyModal";

const ViewManualBatchClaims = () => {
  const toast = React.useRef<Toast>(null);

  const [batchDetails, setBatchDetails] = React.useState<any>({});
  const [fetchedClaims, setFetchedClaims] = React.useState<any>([]);
  const [tableRows, setTableRows] = React.useState<any[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const [selectedClaims, setSelectedClaims] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hspName, setHspName] = React.useState<any>("");
  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
    // Filter the medicationData based on the search query
    const filData: any[] = fetchedClaims.filter((data: any) => {
      return (
        data?.claims_unique_number
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        data?.staff_or_dependents_id
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        data?.patient_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };
  React.useEffect(() => {
    // pull selectedBatch from session storage
    const selectedBatch = JSON.parse(
      sessionStorage.getItem("selectedBatch") || "{}"
    );
    setBatchDetails(selectedBatch);
    handleFetchRelatedClaims(selectedBatch.id);
  }, []);

  const handleFetchRelatedClaims = async (id: string) => {
    batchClaimsApi
      .getClaimsByBatchId(sessionStorage.getItem("token"), id)
      .then((res) => {
        setFetchedClaims(res.info);
        setHspName(res.message?.split("batch ")[1]);
        console.log(res.info);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const pushBatch = async () => {
    batchClaimsApi
      .pushBatch(sessionStorage.getItem("token"), batchDetails.id)
      .then((res) => {
        show("success", res.message);
      })
      .catch((err) => {
        show("error", err.message);
      });
  };

  const handlePushClaimToPayment = async () => {
    setIsLoading(true);
    ClaimsApi.pushClaimFromBatch(
      sessionStorage.getItem("token") as string,
      selectedClaims.id
    )
      .then((res) => {
        setIsLoading(false);
        closeAnyModal("vet-claim-modal")
        show("success", res.message);
      })
      .catch((err) => {
        setIsLoading(false);
        show("error", err.message);
      });
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedClaims;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedClaims]);

  return (
    <>
      <Toast ref={toast} />

      <TableContainer
        component={Paper}
        sx={{
          minHeight: "100vh",
        }}
      >
        <div
          id="main-wrapper"
          data-theme="light"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
          data-boxed-layout="full"
        >
          {/* ============================================================== */}
          {/* Topbar header - style you can find in pages.scss */}
          {/* ============================================================== */}
          <Header />
          {/* ============================================================== */}
          {/* End Topbar header */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* Left Sidebar - style you can find in sidebar.scss  */}
          {/* ============================================================== */}
          <Sidebar />
          {/* ============================================================== */}
          {/* Page wrapper  */}
          {/* ============================================================== */}
          <div className="page-wrapper">
            <div className="container-fluid">
              <BreadCrumbsComponent
                breadcrumbs={[
                  {
                    title: "Manual Claims",
                    url: "/manual-claims",
                  },
                  {
                    title: "Batch Claims",
                    url: "/view-manual-batch-claim",
                  },
                ]}
              />
              <div className="row">
                <div className="col-12">
                  <div className="card shadow">
                    <div className="card-body">
                      <div className="flex justify-center items-center">
                        <h1 className="card-title text-xl font-bold mb-3">
                          Batch Number: {batchDetails.batch_number}
                        </h1>
                      </div>
                      <div className="flex justify-end mb-2 gap-x-4">
                        {/* <button
                          className="btn btn-primary bg-[#5F76E8] hover:bg-[#5F76E8] rounded-pill"
                          data-toggle="modal"
                          data-target="#push-batch-claims-modal"
                        >
                          <i className="fa fa-tasks mr-1 " />
                          Push Batch
                        </button> */}
                        <StyledSearch
                          placeholder="🔍 by Patient Name/ID"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                      <div className="table-responsive" id="myTable">
                        <MasterTableComponent
                          headers={[
                            "S/N",
                            "Claim Number",
                            "Patient Name",
                            "Patient ID",
                            "Date Attended",
                            "Total Amount",
                            "Status",
                            "Action",
                          ]}
                          emptyTableMessage="No claims found"
                          tableRows={
                            tableRows &&
                            tableRows.map((row: any, idx) => {
                              return {
                                "S/N": idx + 1,
                                "Claim Number": row.claims_unique_number,
                                "Patient Name": row.patient_name,
                                "Patient ID": row.staff_or_dependents_id,
                                "Date Attended": `${formatDate(
                                  row.claims_date_attended_start
                                )} - ${formatDate(
                                  row.claims_date_attended_end
                                )}`,
                                "Total Amount": formatMoney(
                                  row.total_cost_of_services || 0
                                ),
                                Status: row.progress_status,
                                Actions: (
                                  <div className=" flex gap-2">
                                    <a
                                      href="/view-manual-claims-details"
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "selectedClaim",
                                          JSON.stringify(row)
                                        );
                                      }}
                                    >
                                      <i className="fa fa-eye text-primary" />
                                    </a>
                                    <button
                                      type="button"
                                      data-target="#vet-claim-modal"
                                      data-toggle="modal"
                                      className="mx-1"
                                      onClick={() => {
                                        setSelectedClaims(row);
                                      }}
                                    >
                                      <i className="fa fa-check text-primary" />
                                    </button>
                                  </div>
                                ),
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Modal content for PUSH claims */}
            <div
              className="modal fade"
              id="push-batch-claims-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="myLargeModalLabel">
                      {" "}
                      Confirm Claims receipt
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="card-body">
                      <h4 className="text-center mb-4">
                        You are Approving{" "}
                        <span className="text-primary">
                          {fetchedClaims.length}/{fetchedClaims.length}
                        </span>{" "}
                        claims from{" "}
                        <span className="font-20 text-primary">{hspName} </span>{" "}
                        with{" "}
                        <span className="font-20 text-primary">
                          Batch No. {batchDetails.batch_number}{" "}
                        </span>{" "}
                        for payment
                      </h4>
                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          onClick={pushBatch}
                          type="submit"
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        >
                          <i className="fas fa-check mr-2" />
                          Proceed
                        </button>
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.modal-content */}
              </div>
              {/* /.modal-dialog */}
            </div>

            {/*  Modal content for VET claims */}
            <div
              className="modal fade"
              id="vet-claim-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="myLargeModalLabel">
                      {" "}
                      Confirm Claim receipt
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="card-body">
                      <h4 className="text-center mb-4">
                        You are Approving{" "}
                        <span className="text-primary">
                          1/{fetchedClaims.length}
                        </span>{" "}
                        claims from{" "}
                        <span className="font-20 text-primary">{hspName} </span>{" "}
                        with{" "}
                        <span className="font-20 text-primary">
                          Claim No. {selectedClaims.claims_unique_number}{" "}
                        </span>{" "}
                        for payment
                      </h4>
                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          onClick={handlePushClaimToPayment}
                          type="submit"
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                          disabled={isLoading}
                        >
                          <i className="fas fa-check mr-2" />
                          {isLoading ? "Pushing..." : "Proceed"}
                        </button>
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.modal-content */}
              </div>
              {/* /.modal-dialog */}
            </div>
            {/* /.modal */}
            {/* ============================================================== */}
            {/* End Container fluid  */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* footer */}
            {/* ============================================================== */}
            <Footer />
            {/* ============================================================== */}
            {/* End footer */}
            {/* ============================================================== */}
          </div>
          {/* ============================================================== */}
          {/* End Page wrapper  */}
          {/* ============================================================== */}
        </div>
      </TableContainer>
    </>
  );
};

export default ViewManualBatchClaims;
