import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import ApprovedClaimsPaymentsModal from "../../../components/financials/ApprovedClaimsPaymentsModal";
import FinancialsPaidClaimsTable from "../../../components/tables/FinancialsPaidClaimsTable";
import PaidClaimPDFModal from "../../../components/financials/PaidClaimPDFModal";

function FinancialsPaidClaims() {
  return (
    <div
      id="main-wrapper"
      data-theme="light"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-boxed-layout="full"
    >
      {/* ============================================================== */}
      {/* Topbar header - style you can find in pages.scss */}
      {/* ============================================================== */}
      <Header />
      {/* ============================================================== */}
      {/* End Topbar header */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* Left Sidebar - style you can find in sidebar.scss  */}
      {/* ============================================================== */}
      <Sidebar />

      {/* ============================================================== */}
      {/* Page wrapper  */}
      {/* ============================================================== */}
      <div className="page-wrapper">
        <div className="container-fluid">
          <BreadCrumbsComponent
            breadcrumbs={[
              {
                title: "Financials",
                url: "/fn-paid-claims",
              },
              {
                title: "Paid HSP Claims",
                url: "/fn-paid-claims",
              },
            ]}
          />
          <div className="row">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body">
                  <h3 className="card-title mb-3">Paid Claims</h3>
                  <div className="table-responsive" id="myTable">
                    <FinancialsPaidClaimsTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Modal content for the above example */}

        <PaidClaimPDFModal />

        {/* /.modal */}
        {/* ============================================================== */}
        {/* End Container fluid  */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* footer */}
        {/* ============================================================== */}
        <Footer />
        {/* ============================================================== */}
        {/* End footer */}
        {/* ============================================================== */}
      </div>
      {/* ============================================================== */}
      {/* End Page wrapper  */}
      {/* ============================================================== */}
    </div>
  );
}

export default FinancialsPaidClaims;
