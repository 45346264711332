import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { GiMoneyStack } from "react-icons/gi";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { FaStethoscope } from "react-icons/fa";
import NewClaimEmailModal from "../../../components/new-claim/NewClaimEmailModal";
import NewClaimSMSModal from "../../../components/new-claim/NewClaimSMSModal";
import NewClaimPDFModal from "../../../components/new-claim/NewClaimPDFModal";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import NewClaimsCallSummaryModal from "../../../components/new-claim/NewClaimsCallSummaryModal";
import capitalizeText from "../../../utils/capitalizeText";
import calculateAgeFromDoB from "../../../utils/calculateAgeFromDoB";
import CustomComboBox from "../../../components/ui/CustomComboBox";
import formatDate from "../../../utils/formatDate";
import formatMoney from "../../../utils/formatMoney";

const ViewNewClaimDetails = () => {
  const headers = ["Qty", "Claim Item", "Unit Cost", "Total Cost"];
  const navigate = useNavigate();
  const [claimDetails, setClaimDetails] = React.useState<any>({});


  React.useEffect(() => {
    const claim = JSON.parse(sessionStorage.getItem("selectedClaim") || "{}");
    console.log(claim);
    setClaimDetails(claim);
  }, []);

  return (
    <TableContainer component={Paper}>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* Bread crumbs */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claim List",
                  url: "/new-claims",
                },
                {
                  title: "New Claims",
                  url: "/new-claims",
                },
                {
                  title: "New Batch Claims",
                  url: "/view-new-batch-claims",
                },
                {
                  title: "New Claim Details",
                  url: "/view-new-claims-details",
                },
              ]}
            />
            <div className="row">
              <div className="col-11 mx-auto">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-end gap-4 mb-2">
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#call-summary-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-phone mr-2" />
                        Call Summary
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#sms-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-comment mr-2" />
                        SMS
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#email-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-envelope mr-2" />
                        Email
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#pdf-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-file mr-2" />
                        PDF
                      </button>
                    </div>
                    <ul className="nav nav-pills bg-nav-pills nav-justified">
                      <li className="nav-item">
                        <a
                          href="#claimdetails"
                          data-toggle="tab"
                          aria-expanded="false"
                          className="nav-link btn btn-rounded active"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Claim Details
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#benefits"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">Benefits</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#adminreview"
                          data-toggle="tab"
                          aria-expanded="true"
                          className="nav-link btn btn-rounded"
                          style={{ borderRadius: "130px" }}
                        >
                          <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                          <span className="d-none d-lg-block">
                            Admin Review{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="policy-details">
                        <div className="card-header text center">
                          <a>
                            <img
                              className="avatar mx-auto d-block rounded-circle mb-2"
                              style={{ width: "50px" }}
                              src="assets/images/img1.jpg"
                              alt="PAPA"
                            />
                          </a>
                          <div className="text-center">
                            <h3 className="font-bold text-2xl">
                              {claimDetails.patient_name || "--"}
                            </h3>
                            <p className="text-xl">
                              {capitalizeText(claimDetails?.claims_sex || "-")}{" "}
                              |{" "}
                              {calculateAgeFromDoB(claimDetails.claims_dob) ||
                                "-"}{" "}
                              | {claimDetails.claims_relationship || "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* pane */}
                      <div
                        className="tab-pane process_claim active"
                        id="claimdetails"
                      >
                        <form>
                          <fieldset>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                            />
                            <a href="#">
                              <input
                                type="hidden"
                                name="id"
                                defaultValue={796}
                              />
                            </a>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="icon-user mr-1" />
                                    Patient ID
                                  </td>
                                  <td className="flex gap-4">
                                    <CustomComboBox
                                      disabled={true}
                                      placeholder={
                                        claimDetails.staff_or_dependents_id
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-address-card mr-1" />
                                    Ghana Card
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="ghana_card"
                                      name="ghana_card"
                                      className="form-control"
                                      placeholder="Ghana Card No."
                                      disabled={true}
                                      value={claimDetails?.ghana_card}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-calendar mr-1" />
                                    Date Visited
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      disabled={true}
                                      type="text"
                                      id="date_visited"
                                      name="date_visited"
                                      className="form-control"
                                      placeholder={formatDate(
                                        claimDetails.claims_date_attended_start
                                      )}
                                    />
                                    <input
                                      disabled={true}
                                      type="text"
                                      id="date_visited"
                                      name="date_visited"
                                      className="form-control"
                                      value={formatDate(
                                        claimDetails.claims_date_attended_end
                                      )}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div className="bg-[#F7F7F7] p-4 card-title text-xl">
                            <h2>
                              Clinic & Diagnosis{"("}ICD 10{")"}
                            </h2>
                          </div>
                          <fieldset>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td className="font-bold">General Clinic</td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.general_clinic
                                            ?.general_clinic_ipd
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        IPD
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.general_clinic
                                            ?.general_clinic_opd
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        OPD
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.general_clinic
                                            ?.general_clinic_maternity_anc
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Maternity {"("}ANC {")"}
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.general_clinic
                                            ?.general_clinic_maternity_pnc
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Maternity {"("}PNC {")"}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Special Clinic</td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_cardio
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Cardiology
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_dental
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Dental
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_dermatology
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Dermatology
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_ent
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        ENT
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_neurologist
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Neurologist
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_obs_g
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Obs {"&"} Gyne
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_oncology
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Oncology
                                      </label>
                                    </div>

                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_optical
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Optical{"/"}Ophthalmology
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_pediatrics
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Pediatrics
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_psychologist
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Psychologist
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="opdCheckbox"
                                        name="opdCheckbox"
                                        checked={
                                          claimDetails?.special_clinic
                                            ?.special_clinic_urology
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="opdCheckbox"
                                      >
                                        Urologist
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">
                                    Initial Diagnosis
                                  </td>
                                  <td>
                                    <div className="my-2 flex gap-2">
                                      {claimDetails?.initial_diagnosis?.map(
                                        (item: string, idx: number) => (
                                          <span
                                            key={idx}
                                            className="bg-secondary text-white py-2 px-4 rounded-sm "
                                          >
                                            {item}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Final Diagnosis</td>
                                  <td>
                                    <div className="my-2 flex gap-2">
                                      {claimDetails?.final_diagnosis?.map(
                                        (item: string, idx: number) => (
                                          <span
                                            key={idx}
                                            className="bg-secondary text-white py-2 px-4 rounded-sm "
                                          >
                                            {item}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                      {/* pane */}
                      <div className="tab-pane process_claim" id="benefits">
                        <div className="card-shadow shadow-sm pb-2 ">
                          <div className="bg-[#F7F7F7] p-2 card-title text-xl mt-4">
                            <h2>Treatment & Service Costing</h2>
                          </div>
                          <div className="px-2">
                            <div className="">
                              <h3 className="text-xl mb-4">Consultations</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {claimDetails?.consultations?.length > 0 &&
                                    claimDetails?.consultations?.map(
                                      (consultation: any, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {consultation.quantity}
                                          </TableCell>
                                          <TableCell>
                                            {consultation.service}
                                          </TableCell>
                                          <TableCell>
                                            {consultation.unit_cost}
                                          </TableCell>
                                          <TableCell>
                                            {consultation.total_cost}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                            <div className=" mt-6">
                              <h3 className="text-xl mb-4 ">Dentals</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {claimDetails?.dentals?.length > 0 &&
                                    claimDetails?.dentals?.map(
                                      (dental: any, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {dental.quantity}
                                          </TableCell>
                                          <TableCell>
                                            {dental.service}
                                          </TableCell>
                                          <TableCell>
                                            {dental.unit_cost}
                                          </TableCell>
                                          <TableCell>
                                            {dental.total_cost}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                            <div className=" mt-6">
                              <h3 className="text-xl mb-4 ">Investigations</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {claimDetails?.investigations?.length > 0 &&
                                    claimDetails?.investigations?.map(
                                      (investigation: any, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {investigation.quantity}
                                          </TableCell>
                                          <TableCell>
                                            {investigation.service}
                                          </TableCell>
                                          <TableCell>
                                            {investigation.unit_cost}
                                          </TableCell>
                                          <TableCell>
                                            {investigation.total_cost}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                            <div className=" mt-6">
                              <h3 className="text-xl mb-4 ">Medications</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {claimDetails?.medications?.length > 0 &&
                                    claimDetails?.medications?.map(
                                      (medication: any, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {medication.quantity}
                                          </TableCell>
                                          <TableCell>
                                            {medication.service}
                                          </TableCell>
                                          <TableCell>
                                            {medication.unit_cost}
                                          </TableCell>
                                          <TableCell>
                                            {medication.total_cost}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                            <div className=" mt-6">
                              <h3 className="text-xl mb-4 ">Opticals</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {claimDetails?.optical?.length > 0 &&
                                    claimDetails?.optical?.map(
                                      (opt: any, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell>{opt.quantity}</TableCell>
                                          <TableCell>{opt.service}</TableCell>
                                          <TableCell>{opt.unit_cost}</TableCell>
                                          <TableCell>
                                            {opt.total_cost}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                            <div className=" mt-6">
                              <h3 className="text-xl mb-4 ">
                                Surgeries & Procedures
                              </h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {claimDetails?.surgery_and_procedures
                                    ?.length > 0 &&
                                    claimDetails?.surgery_and_procedures?.map(
                                      (
                                        surgery_and_procedure: any,
                                        index: number
                                      ) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {surgery_and_procedure.quantity}
                                          </TableCell>
                                          <TableCell>
                                            {surgery_and_procedure.service}
                                          </TableCell>
                                          <TableCell>
                                            {surgery_and_procedure.unit_cost}
                                          </TableCell>
                                          <TableCell>
                                            {surgery_and_procedure.total_cost}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </div>

                            <div className="flex gap-4 justify-end mb-2 pr-8">
                              {/* add a decline and approve button */}
                              <h3 className="btn flex items-center justify-center">
                                Total Amount:
                              </h3>
                              <h3 className="btn  btn-primary  flex items-center justify-center">
                                {formatMoney(
                                  claimDetails?.total_cost_of_services | 0
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane process_claim" id="adminreview">
                        <div className="card-body">
                          <fieldset>
                            <div className=" p-4 w-[90%] mx-auto mt-4">
                              <p className="mt-4 mb-2 text-left w-full">
                                Please validate claim information. You may
                                request further information from the claimant as
                                well.
                              </p>
                              <div className="flex justify-between gap-4">
                                <textarea className="w-full  border-[2px] min-h-[15vh] p-2"></textarea>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center h-8 my-auto"
                                >
                                  <i className="fa fa-save mr-2" />
                                  Save
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className="buttons flex justify-end mt-3  gap-4 w-[90%] ">
                          <button
                            className="btn btn-info btn-sm closs flex items-center justify-center"
                            data-dismiss="modal"
                          >
                            <i className="fas fa-check mr-2" />
                            Vetted
                          </button>
                          <button
                            className="btn btn-warning btn-sm flex gap-2 items-center justify-center"
                            data-dismiss="modal"
                          >
                            <FaStethoscope /> Medical Vetting
                          </button>
                        </div>
                      </div>
                      <div className="tab-pane process_claim" id="medreview">
                        <div className="card-body">
                          <fieldset>
                            <h3 className="font-bold ml-2 text-xl">
                              Diagnosis
                            </h3>

                            <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                              Previous Messages
                            </p>
                            <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                              <div className="flex justify-content-between text-lg">
                                <h5>
                                  <span className="font-bold">By: </span>
                                  Hanson Bansah
                                </h5>
                                <h5>
                                  <span className="font-bold">Sent on: </span>
                                  30-AUG-2023
                                </h5>
                              </div>
                              <h4 className="mt-2">
                                Dear EMMANUEL ANDREWS, kindly send a copy of the
                                Medical Cause of death to aid in your claim
                                processing. Send documents to osa@valifeapps.com
                              </h4>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  Modal for Flagging Claims */}
          <div
            className="modal fade"
            id="flag-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    Flag claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <form>
                      <div className="form-body">
                        <div className="col-12 mb-3">
                          <label>
                            You are flagging receipt of claims from{" "}
                            <span className="text-[#5F76E8]">
                              Francis Hospital
                            </span>{" "}
                            with Batch No.{" "}
                            <span className="text-[#5F76E8]">001</span>
                          </label>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-question mr-1" />
                              Please select a reason for flagging.
                            </label>
                            <select
                              className="form-control"
                              // placeholder=" select one"
                            >
                              <option value="">Select a comment</option>
                              <option
                                value={"diagnosis vs medication mismatch"}
                              >
                                diagnosis vs medication mismatch
                              </option>
                              <option
                                value={"diagnosis vs investigation mismatch"}
                              >
                                diagnosis vs investigation mismatch
                              </option>
                              <option
                                value={" diagnosis vs medication-age mismatch"}
                              >
                                diagnosis vs medication-age mismatch
                              </option>
                              <option value={"no related diagnosis"}>
                                no related diagnosis
                              </option>
                              <option value={"Others"}>Others</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="buttons float-right mt-3 flex gap-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                        >
                          <i className="fas fa-check mr-2" />
                          Proceed
                        </button>
                        <button
                          className="btn btn-danger btn-sm closs flex items-center justify-center"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times mr-2" />
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* Call Summary */}
          <NewClaimsCallSummaryModal />

          {/*  SMS Modal */}
          <NewClaimSMSModal />

          {/* Email Modal */}
          <NewClaimEmailModal />

          {/* Email Modal */}
          <NewClaimPDFModal />

          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </TableContainer>
  );
};

export default ViewNewClaimDetails;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
