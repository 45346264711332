const calculateAgeFromDoB = (dateOfBirth?: string): number => {
    if (!dateOfBirth) {
        return 0;
    }
    // dateOfBirth format: DD/MM/YYYY
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    // console.log("month", month);
    // console.log("today.getDate()", today.getDate());
    // console.log("birthDate.getDate()", birthDate.getDate());
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    // console.log("age", age);
    return age;
}


export default calculateAgeFromDoB;