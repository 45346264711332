// import { Manage_SchemeType } from "../../types/data";
import Sidebar from "../../components/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import formatDate from "../../utils/formatDate";

const ViewDental = () => {
  //@ desc - get staff_id from location search
  const { search } = useLocation();
  const { dental_id, action_type } = queryString.parse(search);
  const toast = useRef<Toast>(null);

  const [dentalDetails, setDentalDetails] = useState<any>([]);

  const handleFetchDentalDetails = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/dental/details`,
        {
          dental_id: dental_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setDentalDetails(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleFetchDentalDetails();
  }, [dental_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/dental/edit`,
        {
          medication_id: dental_id,
          dental_code: dentalDetails.dental_code,
          dental_name: dentalDetails.dental_name,
          dental_category: dentalDetails.dental_category,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Successful",
            detail: res.data.message,
            life: 3000,
          });
          handleFetchDentalDetails();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Services",
                  url: "/dental",
                },
                {
                  title: "Dentals",
                  url: "/dental",
                },
                {
                  title: "View Dental",
                  url: "",
                },
              ]}
            />
            <div className="col-md-8 col-sm-12 offset-md-2">
              <div className="card shadow">
                <div className="card-body">
                  <div className="">
                    <div className="card-header text center">
                      <h4 className="text-center">
                        {dentalDetails.dental_name || ""} Details
                      </h4>
                    </div>
                    {/* scheme details */}
                    <form onSubmit={handleSubmit}>
                      <fieldset>
                        <table className="table " id="dataTable">
                          <tbody>
                            <tr>
                              <td>Dental category</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={dentalDetails.dental_category}
                                  className="form-control"
                                  onChange={(e) => {
                                    setDentalDetails({
                                      ...dentalDetails,
                                      dental_category: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Dental code</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={dentalDetails.dental_code}
                                  className="form-control"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Dental Name</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={dentalDetails.dental_name}
                                  className="form-control"
                                  onChange={(e) => {
                                    setDentalDetails({
                                      ...dentalDetails,
                                      dental_name: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>status</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={dentalDetails.status}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={
                                    Number(dentalDetails.updated_at)
                                      ? convertTimestampToDate(
                                          Number(dentalDetails.updated_at)
                                        )
                                      : formatDate(dentalDetails.updated_at)
                                  }
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      {action_type === "edit" && (
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block bg-[#5F76E8] rounded-pill"
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewDental;
