import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { HspOpticalsType, OpticalType } from "../../../types/data";
import hspOpticalApi from "../../../api/hspOpticalApi";
import OpticalApi from "../../../api/OpticalApi";
import { StyledSearch } from "../../StyledSearch";
import SaveAllServicesModal from "./SaveAllServicesModal";
import MasterTableComponent from "../../ui/MasterTableComponent";
import formatMoney from "../../../utils/formatMoney";
import EditHspOpticalModal from "./EditHspOpticalModal";
import closeAnyModal from "../../../utils/closeAnyModal";

interface OpticalTabProps {
  fetchedCashflows: any[];
}

const OpticalTab: React.FC<OpticalTabProps> = ({ fetchedCashflows }) => {
  const toast = useRef<Toast>(null);
  const [token, setToken] = useState<string>("");
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>({});

  const [fetchedOpticals, setFetchedOpticals] = useState<HspOpticalsType[]>([]);
  const [includedData, setIncludedData] = useState<HspOpticalsType[]>([]);

  const [fetchOpticalCategories, setFetchOpticalCategories] = useState<any>([]);
  const [listOfPreviouslyAddedOpticals, setListOfPreviouslyAddedOpticals] =
    useState<any[]>([]);
  const [createNewOpticalBody, setCreateNewOpticalBody] = useState<
    HspOpticalsType[]
  >([]);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [createNewRecordIsLoading, setCreateNewRecordIsLoading] =
    useState(false);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [hspName, setHspName] = useState<string>("");

  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleUpdateHspOptical = async () => {
    setIsUpdateLoading(true);
    hspOpticalApi
      .editHspOptical(token, [
        {
          hsp_optical_id: selectedItemToEdit?.optical_id,
          service_name: selectedItemToEdit?.service_name,
          optical_included: selectedItemToEdit?.optical_included,
          standard_rate: selectedItemToEdit?.standard_rate,
          discount_rate: selectedItemToEdit?.discount_rate,
          net_rate: selectedItemToEdit?.net_rate,
          optical_approval: selectedItemToEdit?.optical_approval,
          optical_revenue_type: selectedItemToEdit?.optical_revenue_type,
        },
      ])
      .then((res) => {
        if (res.status === 200) {
          show("success", "Optical Service Updated Successfully");
          handleFetchPreviouslyAddedOpticals();
          setIsUpdateLoading(false);
          closeAnyModal("EditHspOpticalModal");
        } else {
          setIsUpdateLoading(false);
          show("error", res.message);
        }
      })
      .catch((err) => {
        show("error", "Something went wrong, please try again later");
        setIsUpdateLoading(false);
      });
  };

  const handleAddNewOpticalToHSPList = async (e: any) => {
    setFetchIsLoading(true);
    const category: any = fetchedOpticals.find(
      (item: any) => item.id === e.target.value
    );
    setSelectedCategory({
      name: category?.optical_category,
      id: category?.id,
    });
    const res = await hspOpticalApi.getAllHspOpticalsByCategory(
      sessionStorage.getItem("token") || "",
      e.target.value
    );
    const response = res?.data ? res?.data : res?.info;
    if (response.length > 0) {
      const tempOptical = response?.map((item: any) => {
        const prevAdded = listOfPreviouslyAddedOpticals.find(
          (prev: any) => prev.optical_code === item?.optical_code
        );
        if (prevAdded) {
          return {
            dental_code: item?.optical_code,
            service_name: item?.optical_name,
            optical_included: true,
            standard_rate: prevAdded?.standard_rate,
            discount_rate: prevAdded?.discount_rate,
            net_rate: prevAdded?.net_rate,
            optical_approval: prevAdded?.optical_approval,
            optical_revenue_type: prevAdded?.optical_revenue_type,
            has_been_added: true,
            optical_id: prevAdded?.id,
          };
        } else {
          return {
            dental_code: item?.optical_code,
            service_name: item?.optical_name,
            optical_included: false,
            standard_rate: 0.0,
            discount_rate: 0.0,
            net_rate: 0.0,
            optical_approval: false,
            optical_revenue_type: "",
            has_been_added: false,
          };
        }
      });
      setCreateNewOpticalBody(tempOptical);
      setFetchIsLoading(false);
    } else {
      setCreateNewOpticalBody([]);
      setFetchIsLoading(false);
    }
  };

  const handleCreateNewOptical = async () => {
    setCreateNewRecordIsLoading(true);
    const hspId = JSON.parse(sessionStorage.getItem("selectedHSP") || "{}")?.id;
    const includedDat = getIncludedData();
    let dataToSave: HspOpticalsType[] = includedDat.map(
      (item: HspOpticalsType) => ({
        ...item,
        hsp_owner_id: hspId,
        hsp_category: selectedCategory.name,
      })
    );
    // remove has_been_added property from the data
    dataToSave = dataToSave.map((item: HspOpticalsType) => {
      delete item.has_been_added;
      return item;
    });
    const response = await hspOpticalApi.createHspOptical(token, dataToSave);
    if (response.status === 200) {
      setCreateNewRecordIsLoading(false);
      setCreateNewOpticalBody([]);
      setIncludedData([]);
      setFetchedOpticals(response.data);
      show("success", "New Optical Services Added Successfully");
      document
        .getElementById("saveAllServicesModal-optical")
        ?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
    } else {
      setCreateNewRecordIsLoading(false);
      show("error", "Something went wrong, please try again later");
    }
  };

  const handleFetchAllOpticals = async () => {
    const response = await OpticalApi.getAllOpticalCategory(
      sessionStorage.getItem("token") || ""
    );
    if (response.status === 200) {
      setFetchedOpticals(response.info);
    }
  };

  const handleFetchPreviouslyAddedOpticals = async () => {
    const selectedHSP = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    const response = await hspOpticalApi.getAllHspOpticals(
      sessionStorage.getItem("token") || "",
      selectedHSP?.id || ""
    );
    if (response.status === 200) {
      setListOfPreviouslyAddedOpticals(response.info);
    } else {
      setListOfPreviouslyAddedOpticals([]);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const selectedHsp = JSON.parse(
      sessionStorage.getItem("selectedHSP") || "{}"
    );
    setHspName(selectedHsp?.hsp_name);
    if (token) {
      setToken(token);
      handleFetchAllOpticals();
      handleFetchPreviouslyAddedOpticals();
    }
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filData: any[] = createNewOpticalBody.filter((data: any) => {
      return (
        data?.optical_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.service_name.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  const getIncludedData = () => {
    const data = tableRows.filter(
      (optical: HspOpticalsType) =>
        optical.optical_included === true && optical.has_been_added === false
    );
    setIncludedData(data);
    return data;
  };

  const toggleSaveAllModal = () => {
    const included_data = getIncludedData();
    if (included_data.length === 0) {
      show("error", "No services selected");
    }
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : createNewOpticalBody;
    setTableRows(dataToRender);
  }, [searchQuery, createNewOpticalBody]);

  return (
    <>
      <Toast ref={toast} />
      <div className="tab-pane" id="opticalTab">
        <div className="row">
          <div className="col-md-8 offset-md-2 ">
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  <select
                    name=""
                    id=""
                    className="col-md-12 border-2 py-2 rounded-pill"
                    onChange={handleAddNewOpticalToHSPList}
                  >
                    <option value="">select multiple options</option>
                    {fetchedOpticals.length > 0 &&
                      fetchedOpticals.map((item: any, idx: number) => {
                        return (
                          <option key={`optical-${idx}`} value={item?.id}>
                            {item?.optical_category} - {item?.optical_initials}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive" id="myTable">
              <div className="table-responsive" id="myTable">
                <div className="flex justify-end gap-4">
                  <StyledSearch
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search by Investigation or Code"
                  />
                  <button
                    onClick={toggleSaveAllModal}
                    data-target={`${
                      includedData.length === 0
                        ? ""
                        : "#saveAllServicesModal-optical"
                    }`}
                    data-toggle="modal"
                    className={`btn btn-primary rounded-pill mb-1 bg-primary ${
                      tableRows.length === 0 && "disabled"
                    }`}
                  >
                    <i className="fas fa-save" /> Save All
                  </button>
                </div>
                <MasterTableComponent
                  headers={[
                    "Code",
                    "Service Name",
                    "Included",
                    "Std Tariff",
                    "Agreed Tariff",
                    "Net Tariff",
                    "Authorization",
                    "Cashflow Type",
                    "Action",
                  ]}
                  loading={fetchIsLoading}
                  emptyTableMessage="No Opticals Available"
                  tableRows={
                    tableRows &&
                    tableRows.map((item: HspOpticalsType, idx: number) => ({
                      Code: item?.optical_code,
                      "Service Name": item.service_name,
                      Included: (
                        <input
                          type="checkbox"
                          className=""
                          disabled={item.has_been_added}
                          checked={item.optical_included}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].optical_included = e.target.checked;
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Std Tariff": (
                        <input
                          type="number"
                          min={0}
                          disabled={item.has_been_added}
                          className="form-control"
                          value={item.standard_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].standard_rate = parseFloat(
                              e.target.value
                            );

                            newBody[idx].net_rate =
                              parseFloat(e.target.value) -
                              parseFloat(newBody[idx].discount_rate);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Agreed Tariff": (
                        <input
                          type="number"
                          min={0}
                          disabled={item.has_been_added}
                          className="form-control"
                          value={item.discount_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].discount_rate = parseFloat(
                              e.target.value
                            );
                            newBody[idx].net_rate =
                              parseFloat(newBody[idx].standard_rate) -
                              parseFloat(e.target.value);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Net Tariff": (
                        <input
                          type="number"
                          min={0}
                          disabled
                          className="form-control"
                          value={item.net_rate}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].net_rate = Number(e.target.value);
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      Authorization: (
                        <input
                          type="checkbox"
                          className=""
                          disabled={item.has_been_added}
                          checked={item.optical_approval}
                          onChange={(e) => {
                            const newBody = [...tableRows];
                            newBody[idx].optical_approval = e.target.checked;
                            setTableRows(newBody);
                          }}
                        />
                      ),
                      "Cashflow Type": (
                        <div className="form-group mb-3">
                          <select
                            className="form-control plantype"
                            name="plan_type"
                            id="mySelection"
                            disabled={item.has_been_added}
                            // placeholder="Select Type"
                            value={item.optical_revenue_type}
                            onChange={(e) => {
                              const newBody = [...tableRows];
                              newBody[idx].optical_revenue_type =
                                e.target.value;
                              setTableRows(newBody);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {fetchedCashflows?.map(
                              (cashflow: any, idx: number) => (
                                <option
                                  key={idx}
                                  value={
                                    cashflow?.cash_flow_type +
                                    " - " +
                                    cashflow?.percentage +
                                    "%"
                                  }
                                >
                                  {cashflow?.cash_flow_type} -{" "}
                                  {cashflow?.percentage
                                    ? `${cashflow?.percentage}%`
                                    : ""}{" "}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ),
                      Action: (
                        <button
                          disabled={!item.has_been_added}
                          className={`btn ${
                            item.has_been_added
                              ? "text-primary"
                              : "text-gray-500 cursor-not-allowed"
                          } 
                          }`}
                          data-toggle="modal"
                          data-target="#EditHspOpticalModal"
                          onClick={() => {
                            setSelectedItemToEdit(item);
                          }}
                        >
                          <i className="fas fa-edit" />
                        </button>
                      ),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* save all modal */}
      <SaveAllServicesModal
        selectedCategory={selectedCategory}
        hspName={hspName}
        type={"Opticals"}
        handleSaveAllServices={handleCreateNewOptical}
        itemsToSave={includedData?.map((optical: HspOpticalsType) => ({
          Code: optical.optical_code,
          "Service Name": optical.service_name,
          Included: optical.optical_included ? "Yes" : "No",
          "Std. Tariff": formatMoney(optical.standard_rate),
          "Agreed Tariff": formatMoney(optical.discount_rate),
          "Net Tariff": formatMoney(optical.net_rate),
          Authorization: optical.optical_approval ? "Yes" : "No",
          "Cashflow Type": optical.optical_revenue_type,
        }))}
        selector="saveAllServicesModal-optical"
      />
      {/* // edit modal */}
      <EditHspOpticalModal
        serviceType="Optical"
        itemToEdit={selectedItemToEdit}
        setItemToEdit={setSelectedItemToEdit}
        fetchedCashflows={fetchedCashflows}
        handleUpdate={handleUpdateHspOptical}
        loading={isUpdateLoading}
      />
    </>
  );
};

export default OpticalTab;
