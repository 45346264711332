const formatDate = (date_created: string) => {
  try {
    // 2023-11-24 08:50:19.963622+00
    if (date_created === "") {
      return "";
    } else if (date_created === undefined) {
      return "";
    }

    let day = date_created.slice(8, 10);
    let month = date_created.slice(5, 7);
    let year = date_created.slice(0, 4);

    let monthInWords = "";
    if (month === "01") {
      monthInWords = "Jan";
    } else if (month === "02") {
      monthInWords = "Feb";
    } else if (month === "03") {
      monthInWords = "Mar";
    } else if (month === "04") {
      monthInWords = "Apr";
    } else if (month === "05") {
      monthInWords = "May";
    } else if (month === "06") {
      monthInWords = "Jun";
    } else if (month === "07") {
      monthInWords = "Jul";
    } else if (month === "08") {
      monthInWords = "Aug";
    } else if (month === "09") {
      monthInWords = "Sep";
    } else if (month === "10") {
      monthInWords = "Oct";
    } else if (month === "11") {
      monthInWords = "Nov";
    } else if (month === "12") {
      monthInWords = "Dec";
    }
    let dateOutput = `${day}-${monthInWords?.toUpperCase()}-${year}`;
    return dateOutput;
  } catch (error) {
    return date_created;
  }
};

export default formatDate;
