import React, { useState } from "react";
import { AiOutlineBank } from "react-icons/ai";
import {
  BsFileEarmarkPdf,
  BsFilePerson,
  BsFillCreditCard2FrontFill,
  BsReceiptCutoff,
} from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";
import { LuReceipt } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";

function ApprovedClaimsPaymentsModal() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [bankPaymentOptions, setBankPaymentOptions] = useState("");
  return (
    <div
      className="modal fade"
      id="payment-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              Payment Form
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Payment Method</label>
                    <select
                      name="myselection"
                      id="myselection"
                      className="form-control"
                      onChange={(e) => {
                        setSelectedPaymentMethod(e.target.value);
                      }}
                    >
                      <option value="">Select Payment Method</option>
                      <option value="bank-transfer">Bank Transfer</option>
                      <option value="MoMo-transfer">
                        Mobile Money Transfer
                      </option>
                      <option value="cheque">Cheque Deposit</option>
                      <option value="cheque-delivery">Cheque Delivery</option>
                      <option value="cash-deposit">Cash Deposit</option>
                    </select>
                  </div>
                </div>
                {selectedPaymentMethod === "" && <></>}
                {selectedPaymentMethod === "cheque" && (
                  <div className="row myDiv" id="showone">
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <i className="fa fa-calendar mr-1" />
                        Date {"&"} Time
                      </label>
                      <input type="datetime-local" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Depositor's Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Depositor's Contact</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Cheque No.</label>
                      <input type="text" className="form-control" />
                    </div>

                    <div className="col-12 mt-2">
                      <label>Transaction ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt No./Deposit Slip No."
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Amount Paid</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Select Bank Details</label>
                      <select
                        name="myselection"
                        id="myselection"
                        className="form-control"
                        onChange={(e) => {
                          setBankPaymentOptions(e.target.value);
                        }}
                      >
                        <option value="hsp">HSP Bank Details</option>
                        <option value="Alternate">
                          Alternate Bank Details
                        </option>
                      </select>
                    </div>
                    {bankPaymentOptions === "Alternate" && (
                      <>
                        <div className="col-12 mt-2">
                          <label className="flex items-center">
                            <AiOutlineBank className="mr-1 " />
                            Bank
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12 mt-2">
                          <label className="flex items-center">
                            <BsFilePerson className="mr-1 " />
                            Account Holder
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12 mt-2">
                          <label className="flex items-center">
                            <BsFillCreditCard2FrontFill className="mr-1 " />
                            Account No.
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                      </>
                    )}
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <BsFileEarmarkPdf className="mr-1 " />
                        Payment Advise{" "}
                      </label>
                      <input type="file" className="form-control" />
                    </div>
                  </div>
                )}
                {selectedPaymentMethod === "cash-deposit" && (
                  <div className="row myDiv" id="showone">
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <i className="fa fa-calendar mr-1" />
                        Date {"&"} Time
                      </label>
                      <input type="datetime-local" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Depositor's Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Depositor's Contact</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Transaction ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt No./Deposit Slip No."
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Amount Paid</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Select Bank Details</label>
                      <select
                        name="myselection"
                        id="myselection"
                        className="form-control"
                        onChange={(e) => {
                          setBankPaymentOptions(e.target.value);
                        }}
                      >
                        <option value="hsp">HSP Bank Details</option>
                        <option value="Alternate">
                          Alternate Bank Details
                        </option>
                      </select>
                    </div>
                    {bankPaymentOptions === "Alternate" && (
                      <>
                        <div className="col-12 mt-2">
                          <label className="flex items-center">
                            <AiOutlineBank className="mr-1 " />
                            Bank
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12 mt-2">
                          <label className="flex items-center">
                            <BsFilePerson className="mr-1 " />
                            Account Holder
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12 mt-2">
                          <label className="flex items-center">
                            <BsFillCreditCard2FrontFill className="mr-1 " />
                            Account No.
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                      </>
                    )}
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <BsFileEarmarkPdf className="mr-1 " />
                        Payment Advise{" "}
                      </label>
                      <input type="file" className="form-control" />
                    </div>
                  </div>
                )}
                {selectedPaymentMethod === "cheque-delivery" && (
                  <div className="row myDiv" id="showone">
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <i className="fa fa-calendar mr-1" />
                        Date {"&"} Time
                      </label>
                      <input type="datetime-local" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Delivered By </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Delivery Contact </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Contact"
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Cheque No.</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Transaction ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt No./Deposit Slip No."
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>Amount Paid</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Received By </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name of Receiver"
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label>Receiver's Contact </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Contact"
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <BsFileEarmarkPdf className="mr-1 " />
                        Payment Advise{" "}
                      </label>
                      <input type="file" className="form-control" />
                    </div>
                  </div>
                )}
                {selectedPaymentMethod === "bank-transfer" && (
                  <div className="row myDiv" id="showtwo">
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <i className="fa fa-calendar mr-1" />
                        Date {"&"} Time
                      </label>
                      <input type="datetime-local" className="form-control" />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <AiOutlineBank className="mr-1 " />
                        Bank
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <BsFilePerson className="mr-1 " />
                        Account Holder
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <BsFillCreditCard2FrontFill className="mr-1 " />
                        Account No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <GiPayMoney className="mr-1 " />
                        Amount Paid{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <LuReceipt className="mr-1 " />
                        Transaction ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt No./Deposit Slip No."
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="flex items-center">
                        <BsFileEarmarkPdf className="mr-1 " />
                        Payment Advise{" "}
                      </label>
                      <input type="file" className="form-control" />
                    </div>
                  </div>
                )}
                {selectedPaymentMethod === "MoMo-transfer" && (
                  <div className="row myDiv" id="showtwo">
                    <div className="col-12 mb-3">
                      <label>Select Network</label>
                      <select
                        name="myselection"
                        id="myselection"
                        className="form-control"
                        onChange={(e) => {
                          setSelectedPaymentMethod(e.target.value);
                        }}
                      >
                        <option value="">Provider</option>
                        <option value="MTN">MTN</option>
                        <option value="Vodafone">Vodafone</option>
                        <option value="AT">AT</option>
                      </select>
                    </div>
                    <div className="col-12 mb-3">
                      <div className="col-12 mt-2">
                        <label className="flex items-center">
                          <i className="fa fa-phone mr-1" />
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="phone number"
                        />
                      </div>
                      <div className="col-12 mt-2">
                        <label className="flex items-center">
                          <i className="fa fa-phone mr-1" />
                          Confirm Phone Number
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="phone number"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="buttons  mt-3 w-full flex justify-between">
                  <button
                    className="btn btn-primary btn-sm closs bg-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times mr-1" />
                    Cancel
                  </button>
                  {selectedPaymentMethod !== "" && (
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm bg-primary rounded-pill flex gap-1 items-center"
                    >
                      <MdOutlinePayments className="mr-1" />
                      Pay
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ApprovedClaimsPaymentsModal;
