import axios from "axios";
import { HspConsultationType } from "../types/data";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllHspConsultations = async (token: string, hspId: string) => {
  let url = `${BASE_URL}/hsp/consultation/all`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_owner_id: hspId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const getAllHspConsultationsByCategory = async (
  token: string,
  categoryId: string
) => {
  let url = `${BASE_URL}/hsp/consultation/category/`;
  try {
    const response = await axios.post(
      url,
      {
        hsp_category: categoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createHspConsultation = async (
  token: string,
  consultationData: HspConsultationType[]
) => {
  let url = `${BASE_URL}/hsp/consultation/`;
  try {
    // remove consultation_code from every item in the array
    consultationData.forEach((item) => {
      delete item.consultation_code;
    });
    const response = await axios.post(
      url,
      { consultations: consultationData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getHspConsultationDetails = async (
  token: string,
  consultationId: string
) => {
  let url = `${BASE_URL}/hsp/consultation/details`;
  try {
    const response = await axios.post(
      url,
      { hsp_consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateHspConsultation = async (
  token: string,
  consultationId: string
) => {
  let url = `${BASE_URL}/hsp/consultation/status-change`;
  try {
    const response = await axios.post(
      url,
      { hsp_consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateHspConsultation = async (
  token: string,
  consultationId: string
) => {
  let url = `${BASE_URL}/hsp/consultation/status-change`;
  try {
    const response = await axios.post(
      url,
      { hsp_consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteHspConsultation = async (token: string, consultationId: string) => {
  let url = `${BASE_URL}/hsp/consultation/delete`;
  try {
    const response = await axios.post(
      url,
      { hsp_consultation_id: consultationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editHspConsultation = async (
  token: string,
  consultationData: HspConsultationType[]
) => {
  let url = `${BASE_URL}/hsp/consultation/edit`;
  try {
    const response = await axios.post(
      url,
      { consultations: consultationData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllHspConsultations,
  createHspConsultation,
  getHspConsultationDetails,
  deactivateHspConsultation,
  activateHspConsultation,
  deleteHspConsultation,
  getAllHspConsultationsByCategory,
  editHspConsultation,
};
