import React from "react";
import { GiMoneyStack } from "react-icons/gi";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
} from "@mui/material";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { styled } from "@mui/material/styles";
import { FaStethoscope } from "react-icons/fa";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import CustomComboBox from "../../../components/ui/CustomComboBox";

function NewDeliveryClaimDetails() {
  const headers = ["Qty", "Claim Item", "Unit Cost", "Total Cost"];
  const rows = [
    {
      qty: "1",
      claim_item: "Medication LTFYKGHJB, VJSHD FJHKDSKVJDSGLKSD GRD",
      unit_cost: "200.00",
      total_cost: "600.00",
    },
    {
      qty: "4",
      claim_item: "Consultation",
      unit_cost: "200.00",
      total_cost: "800.00",
    },
  ];
  return (
    <div>
      <TableContainer component={Paper}>
        <div
          id="main-wrapper"
          data-theme="light"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
          data-boxed-layout="full"
        >
          {/* ============================================================== */}
          {/* Topbar header - style you can find in pages.scss */}
          {/* ============================================================== */}
          <Header />
          {/* ============================================================== */}
          {/* End Topbar header */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* Left Sidebar - style you can find in sidebar.scss  */}
          {/* ============================================================== */}
          <Sidebar />
          {/* ============================================================== */}
          {/* Page wrapper  */}
          {/* ============================================================== */}
          <div className="page-wrapper">
            <div className="container-fluid">
              <BreadCrumbsComponent
                breadcrumbs={[
                  {
                    title: "Delivery Claims",
                    url: "/new-deliveries",
                  },
                  {
                    title: "Delivery Claim Details",
                    url: "/new-delivery-claim-details",
                  },
                ]}
              />

              <div className="row">
                <div className="col-11 mx-auto">
                  <div className="card shadow">
                    <div className="card-body">
                      {/* <div className="flex justify-end gap-4 mb-2">
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#sms-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-comment mr-2" />
                        SMS
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#email-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-envelope mr-2" />
                        Email
                      </button>
                      <button
                        className="btn btn-primary btn-sm  bg-transparent flex items-center justify-center text-[#5F76E8] border-0 "
                        data-toggle="modal"
                        data-target="#pdf-modal"
                        style={{ borderRadius: "130px" }}
                      >
                        <i className="fas fa-file mr-2" />
                        PDF
                      </button>
                    </div> */}
                      <ul className="nav nav-pills bg-nav-pills nav-justified">
                        <li className="nav-item">
                          <a
                            href="#claimdetails"
                            data-toggle="tab"
                            aria-expanded="false"
                            className="nav-link btn btn-rounded active"
                            style={{ borderRadius: "130px" }}
                          >
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                            <span className="d-none d-lg-block">
                              Claim Details
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#benefits"
                            data-toggle="tab"
                            aria-expanded="true"
                            className="nav-link btn btn-rounded"
                            style={{ borderRadius: "130px" }}
                          >
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                            <span className="d-none d-lg-block">Benefits</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="policy-details">
                          <div className="card-header text center">
                            <a>
                              <img
                                className="avatar mx-auto d-block rounded-circle mb-2"
                                style={{ width: "50px" }}
                                src="assets/images/img1.jpg"
                                alt="PAPA"
                              />
                            </a>
                            <div className="text-center">
                              <h3 className="font-bold text-2xl">
                                Francis Amoah
                              </h3>
                              <p className="text-xl">Male| 64yrs | Father </p>
                            </div>
                          </div>
                        </div>
                        {/* pane */}
                        <div
                          className="tab-pane process_claim active"
                          id="claimdetails"
                        >
                          <form>
                            <fieldset>
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                              />
                              <a href="#">
                                <input
                                  type="hidden"
                                  name="id"
                                  defaultValue={796}
                                />
                              </a>
                              <table className="table" id="dataTable">
                                <tbody>
                                  <tr>
                                    <td>
                                      <i className="icon-user mr-1" />
                                      Patient ID
                                    </td>
                                    <td className="flex gap-4">
                                      <CustomComboBox
                                        disabled={true}
                                        placeholder="Staff/Dependant ID"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="icon-user" /> Ghana Card
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        defaultValue="GHA-XXXXXXXXX-XX"
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        disabled
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-calendar mr-1" />
                                      Date Visited
                                    </td>
                                    <td className="flex gap-4">
                                      <input
                                        disabled={true}
                                        type="date"
                                        id="date_visited"
                                        name="date_visited"
                                        className="form-control"
                                      />
                                      <input
                                        disabled={true}
                                        type="date"
                                        id="date_visited"
                                        name="date_visited"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </fieldset>
                            <div className="bg-[#F7F7F7] p-4 card-title text-xl">
                              <h2>
                                Medication
                              </h2>
                            </div>
                            <fieldset>
                              <table className="table" id="dataTable">
                                <tbody>
                            
                                  <tr>
                                    <td className="font-bold">
                                      Special Clinic
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="opdCheckbox"
                                          name="opdCheckbox"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="opdCheckbox"
                                        >
                                          Dental
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="opdCheckbox"
                                          name="opdCheckbox"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="opdCheckbox"
                                        >
                                          Obs {"&"} Gyne
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Diagnosis
                                    </td>
                                    <td>
                                      <div className="my-2 flex gap-2">
                                        <span className=" text-white py-2 px-3 rounded-pill bg-[#7973eaa7] ">
                                          Cholera
                                        </span>
                                        <span className=" text-white py-2 px-3 rounded-pill bg-[#7973eaa7] ">
                                          Maleria
                                        </span>
                                        <span className=" text-white py-2 px-3 rounded-pill bg-[#7973eaa7] ">
                                          HIV
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                            
                                </tbody>
                              </table>
                            </fieldset>
                          </form>
                        </div>
                        {/* pane */}
                        <div className="tab-pane process_claim" id="benefits">
                          <div className="card-shadow shadow-sm pb-2 ">
                            <div className="bg-[#F7F7F7] p-2 card-title text-xl mt-4">
                              <h2>Treatment & Service Costing</h2>
                            </div>
                            <div className="px-2">
                              <h3 className="text-xl mb-4">Consultation</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{row.qty}</TableCell>
                                      <TableCell>{row.claim_item}</TableCell>
                                      <TableCell>{row.unit_cost}</TableCell>
                                      <TableCell>{row.total_cost}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <h3 className="text-xl mb-4 mt-6">
                                Investigations
                              </h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{row.qty}</TableCell>
                                      <TableCell>{row.claim_item}</TableCell>
                                      <TableCell>{row.unit_cost}</TableCell>
                                      <TableCell>{row.total_cost}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <h3 className="text-xl mb-4 mt-6">
                                Surgery and Procedures
                              </h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{row.qty}</TableCell>
                                      <TableCell>{row.claim_item}</TableCell>
                                      <TableCell>{row.unit_cost}</TableCell>
                                      <TableCell>{row.total_cost}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <h3 className="text-xl mb-4 mt-6">Medications</h3>
                              <Table
                                className="table table-sm table-striped table-bordered table-hover display"
                                id="default_order"
                                aria-label="simple table"
                                sx={{ width: "100%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <StyledTableCell key={index}>
                                        {header}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows?.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        backgroundColor: "#43e932",
                                      }}
                                    >
                                      <TableCell>{row.qty}</TableCell>
                                      <TableCell>{row.claim_item}</TableCell>
                                      <TableCell>{row.unit_cost}</TableCell>
                                      <TableCell>{row.total_cost}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>

                              <div className="flex gap-4 justify-end mb-2 pr-8">
                                {/* add a decline and approve button */}
                                <h3 className="btn flex items-center justify-center">
                                  Total Amount:
                                </h3>
                                <h3 className="btn  btn-primary  flex items-center justify-center">
                                  12,892.00
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane process_claim"
                          id="adminreview"
                        >
                          <div className="card-body">
                            <fieldset>
                              <div className=" p-4 w-[90%] mx-auto mt-4">
                                <p className="mt-4 mb-2 text-left w-full">
                                  Please validate claim information. You may
                                  request further information from the claimant
                                  as well.
                                </p>
                                <div className="flex justify-between gap-4">
                                  <textarea className="w-full  border-[2px] min-h-[15vh] p-2"></textarea>
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center h-8 my-auto"
                                  >
                                    <i className="fa fa-save mr-2" />
                                    Save
                                  </button>
                                </div>
                              </div>
                            </fieldset>
                          </div>

                          <div className="buttons flex justify-end mt-3  gap-4 w-[90%] ">
                            <button
                              className="btn btn-info btn-sm closs flex items-center justify-center"
                              data-dismiss="modal"
                            >
                              <i className="fas fa-check mr-2" />
                              Vetted
                            </button>
                            <button
                              className="btn btn-warning btn-sm flex gap-2 items-center justify-center"
                              data-dismiss="modal"
                            >
                              <FaStethoscope /> Medical Vetting
                            </button>
                          </div>
                        </div>
                        <div className="tab-pane process_claim" id="medreview">
                          <div className="card-body">
                            <fieldset>
                              <h3 className="font-bold ml-2 text-xl">
                                Diagnosis
                              </h3>

                              <p className="text-[#5F76E8] font-bold text-left text-lg ml-14 mt-4 ">
                                Previous Messages
                              </p>
                              <div className="bg-[#DFE4FA] p-4 w-[90%] mx-auto mt-4">
                                <div className="flex justify-content-between text-lg">
                                  <h5>
                                    <span className="font-bold">By: </span>
                                    Hanson Bansah
                                  </h5>
                                  <h5>
                                    <span className="font-bold">Sent on: </span>
                                    30-AUG-2023
                                  </h5>
                                </div>
                                <h4 className="mt-2">
                                  Dear EMMANUEL ANDREWS, kindly send a copy of
                                  the Medical Cause of death to aid in your
                                  claim processing. Send documents to
                                  osa@valifeapps.com
                                </h4>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TableContainer>
    </div>
  );
}

export default NewDeliveryClaimDetails;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
