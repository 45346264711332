import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import VettedClaimsTable from "../../../components/tables/VettedClaimsTable";
import ViewVettedClaimTable from "../../../components/tables/ViewVettedClaimsTable";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";

const ViewVettedBatchClaims = () => {
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Claims",
                  url: "/vetted-claims",
                },
                {
                  title: "Vetted Claims",
                  url: "/vetted-claims",
                },
                {
                  title: "Vetted Batch Claims",
                  url: "/vetted-claims-batch-view",
                }
              ]}
            />
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-center items-center">
                      <h1 className="card-title text-xl font-bold mb-3">
                        Batch Number: 003
                      </h1>
                    </div>
                    <div className="table-responsive" id="myTable">
                      <ViewVettedClaimTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="vet-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Confirm Claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <h4 className="text-center mb-4">
                      You are Approving{" "}
                      <span className="text-primary">10/10</span> claims from{" "}
                      <span className="font-20 text-primary">
                        Francis Hospital
                      </span>{" "}
                      with{" "}
                      <span className="font-20 text-primary">
                        Batch No. 001{" "}
                      </span>{" "}
                      for payment
                    </h4>
                    <div className="buttons float-right mt-3 flex gap-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                      >
                        <i className="fas fa-check mr-2" />
                        Proceed
                      </button>
                      <button
                        className="btn btn-danger btn-sm closs flex items-center justify-center"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times mr-2" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewVettedBatchClaims;
