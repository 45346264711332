import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;

const getAllCashflow = async (token: string) => {
  let url = `${BASE_URL}/cash_flow/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const getCashflowDetails = async (token: string, cashflowId: string) => {
  let url = `${BASE_URL}/cash_flow/details`;
  try {
    const response = await axios.post(
      url,
      {
        cash_flow_id: cashflowId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createCashflow = async (
  token: string,
  createCashflowData: {
    cash_flow_type: string;
    applied_to: string;
    percentage: number;
  }
) => {
  let url = `${BASE_URL}/cash_flow/`;
  try {
    const response = await axios.post(url, createCashflowData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const activateCashflow = async (
  token: string,
  activateCashflowData: {
    cash_flow_id: string;
  }
) => {
  let url = `${BASE_URL}/cash_flow/status-change`;
  try {
    const response = await axios.post(url, activateCashflowData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deactivateCashflow = async (
  token: string,
  deactivateCashflowData: {
    cash_flow_id: string;
  }
) => {
  let url = `${BASE_URL}/cash_flow/status-change`;
  try {
    const response = await axios.post(url, deactivateCashflowData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const deleteCashflow = async (
  token: string,
  deleteCashflowData: {
    cash_flow_id: string;
  }
) => {
  let url = `${BASE_URL}/cash_flow/delete`;
  try {
    const response = await axios.post(url, deleteCashflowData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const updateCashflow = async (
  token: string,
  updateCashflowData: {
    cash_flow_id: string;
    cash_flow_type: string;
    applied_to: string;
    percentage: number;
  }
) => {
  let url = `${BASE_URL}/cash_flow/edit`;
  try {
    const response = await axios.post(url, updateCashflowData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllCashflow,
  createCashflow,
  activateCashflow,
  deactivateCashflow,
  deleteCashflow,
  updateCashflow,
  getCashflowDetails,
};
