import React, { useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { GiMoneyStack, GiPayMoney, GiReturnArrow } from "react-icons/gi";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TableFooter,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import CustomComboBox from "../../../components/ui/CustomComboBox";
import PaidClaimPDFModal from "../../../components/financials/PaidClaimPDFModal";
import { AiOutlineBank, AiOutlinePhone } from "react-icons/ai";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import { LuReceipt } from "react-icons/lu";

const ViewPaidClaims = () => {
  const headers = ["Qty", "Claim Item", "Unit Cost", "Total Cost"];
  const [isBenefitClicked, setIsBenefitClicked] = useState(false);

  const rows = [
    {
      qty: "1",
      claim_item: "Medication",
      unit_cost: "200.00",
      total_cost: "600.00",
    },
    {
      qty: "4",
      claim_item: "Consultation",
      unit_cost: "200.00",
      total_cost: "800.00",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Financials",
                  url: "/fn-approved-claims",
                },
                {
                  title: "Paid HSP Claims",
                  url: "/fn-approved-claims-batch-view",
                },

                {
                  title: "View HSP Transaction Details",
                  url: "/fn-view-approved-claims-details",
                },
              ]}
            />
            <div className="row">
              <div
                className={`${isBenefitClicked ? "col-8" : "col-11 mx-auto "}`}
              >
                <div className="card shadow">
                  <div className="card-body">
                    <div className="tab-content">
                      <div className="policy-details">
                        <div className="card-header text center">
                          <a>
                            <img
                              className="avatar mx-auto d-block rounded-circle mb-2"
                              style={{ width: "50px" }}
                              src="assets/images/img1.jpg"
                              alt="PAPA"
                            />
                          </a>
                          <div className="text-center">
                            <h3 className="font-bold text-2xl">
                              Francis Amoah
                            </h3>
                            <p className="text-xl">Male| 64yrs | Father </p>
                            <p className="text-xl text-primary font-bold">
                              Francis Hospital | Batch 003{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* pane */}
                      <div
                        className="tab-pane process_claim active"
                        id="claimdetails"
                      >
                        <form className="w-[80%] mx-auto">
                          <fieldset>
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="rpmldwl2ExHUhNG3hiOaW3ZSgSWq28JKJKYGR5Nx"
                            />
                            <a href="#">
                              <input
                                type="hidden"
                                name="id"
                                defaultValue={796}
                              />
                            </a>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fas fa-calendar mr-1" />
                                    Date Paid
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="datetime-local"
                                      id="staff_id"
                                      name="staff_id"
                                      className="form-control"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="flex items-center">
                                      <AiOutlinePhone className="mr-1 feather-icon" />
                                      Depositor's Contact
                                    </span>
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="tel"
                                      id="Depositor's Contact"
                                      name="Depositor's Contact"
                                      className="form-control"
                                      placeholder="024XXXXXXX"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i className="fa fa-user mr-1" />
                                    Depositor's Name
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="Depositor's Name"
                                      name="Depositor's Name"
                                      className="form-control"
                                      placeholder="Depositor's Name"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="flex items-center">
                                      <BsFillCreditCard2FrontFill className="mr-1 " />
                                      HSP Account No.
                                    </label>
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="Acount No."
                                      name="Acount No."
                                      className="form-control"
                                      placeholder="HSP Acount No./MoMo No."
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="flex items-center">
                                      <GiPayMoney className="mr-1 " />
                                      Amount Paid{" "}
                                    </label>
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="GHS 0.00"
                                      name="GHS 0.00"
                                      className="form-control"
                                      placeholder="GHS 0.00"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label
                                      htmlFor=""
                                      className="flex items-cent"
                                    >
                                      <AiOutlineBank className="mr-1 " />
                                      Method of Payment
                                    </label>
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="Payment Method"
                                      name="Payment Method"
                                      className="form-control"
                                      placeholder="Payment Method"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="flex items-center">
                                      <LuReceipt className="mr-1 " />
                                      Cheque No.
                                    </label>
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="transaction-id"
                                      name="cheque-no"
                                      className="form-control"
                                      placeholder="XXXXXXXX"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="flex items-center">
                                      <LuReceipt className="mr-1 " />
                                      Transaction ID
                                    </label>
                                  </td>
                                  <td className="flex gap-4">
                                    <input
                                      type="text"
                                      id="transaction-id"
                                      name="GHS 0.00"
                                      className="form-control"
                                      placeholder="Transaction ID"
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div className="bg-[#F7F7F7] p-4 card-title text-xl">
                            <h2>Documents</h2>
                          </div>
                          <fieldset>
                            <table className="table" id="dataTable">
                              <tbody>
                                <tr>
                                  <td className="font-bold">
                                    Payment Advise Document
                                  </td>
                                  <td>
                                    <i
                                      className="fas fa-file-pdf mr-1 cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#pdf-modal"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Claim Document</td>
                                  <td>
                                    <i
                                      className="fas fa-file-pdf mr-1 cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#pdf-modal"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PaidClaimPDFModal />

          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </TableContainer>
  );
};

export default ViewPaidClaims;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
