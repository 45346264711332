import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import ViewNewClaimTable from "../../../components/tables/ViewNewClaimsTable";
import { TableContainer, Paper } from "@mui/material";
import BreadCrumbsComponent from "../../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import batchClaimsApi from "../../../api/batchClaimsApi";
import MasterTableComponent from "../../../components/ui/MasterTableComponent";
import { StyledSearch } from "../../../components/StyledSearch";
import formatDate from "../../../utils/formatDate";
import formatMoney from "../../../utils/formatMoney";

const ViewNewBatchClaims = () => {
  const toast = useRef<Toast>(null);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [fetchedClaims, setFetchedClaims] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [selectedbatchClaim, setSelectedbatchClaim] = useState<any>({});
  const [selectedClaim, setSelectedClaim] = useState<any>({});

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleBatchClaimDetails = () => {
    setIsFetching(true);
    const selectedNewBatchClaims = JSON.parse(
      sessionStorage.getItem("selectedNewBatchClaim")!
    );
    setSelectedbatchClaim(selectedNewBatchClaims);
    batchClaimsApi
      .getClaimsByBatchId(
        localStorage.getItem("token") as string,
        selectedNewBatchClaims.id as string
      )
      .then((res) => {
        if (res?.status === 200) {
          setFetchedClaims(res.info);
        } else {
          show("error", res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        show("error", "An error occured while fetching claimsF");
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    handleBatchClaimDetails();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = fetchedClaims?.filter((data: any) => {
      return (
        data?.hsp_name?.toLowerCase()?.includes(query.toLowerCase()) ||
        data?.claims_unique_number
          ?.toLowerCase()
          ?.includes(query.toLowerCase()) ||
        data?.total_amount?.toLowerCase()?.includes(query.toLowerCase())
      );
    });

    setFetchedClaims(filteredData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedClaims;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedClaims]);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* ============================================================== */}
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "New Claims",
                  url: "/new-claims",
                },
                {
                  title: "Batch Claims",
                  url: "/new-claims-batch-view",
                },
              ]}
            />
            {/* Bread crumb and right sidebar toggle */}
            {/* ============================================================== */}

            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="flex justify-center items-center">
                      <h1 className="card-title text-xl font-bold mb-3">
                        Batch Number: {selectedbatchClaim.batch_number}
                      </h1>
                    </div>
                    <div className="table-responsive" id="myTable">
                      <div className="flex justify-between items-center col-md-12 mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          New Claims
                        </h3>
                        <div className="flex flex-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by HSP Name, claim number"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Claim Number",
                          // <h2>Staff Name{"/"}ID</h2>,
                          "Patient Name",
                          "Patient ID",
                          "Date Attended",
                          "Total Amount",
                          "Status",
                          "Actions",
                        ]}
                        loading={isFetching}
                        emptyTableMessage="No claims found"
                        tableRows={
                          tableRows && tableRows.length > 0
                            ? tableRows?.map((claim: any, idx: number) => {
                                return {
                                  SN: idx + 1,
                                  "Claim Number": claim?.claims_unique_number,
                                  "Patient Name": claim?.patient_name,
                                  "Patient ID": claim?.staff_or_dependents_id,
                                  "Date Attended": `${formatDate(
                                    claim.claims_date_attended_start
                                  )} - ${formatDate(
                                    claim.claims_date_attended_end
                                  )}`,
                                  "Total Amount": formatMoney(
                                    claim?.total_cost_of_services || 0
                                  ),
                                  Status: claim?.progress_status,
                                  Actions: (
                                    <a
                                      href={`/view-new-claims-details?id=${
                                        claim?.id || ""
                                      }`}
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "selectedNewClaim",
                                          JSON.stringify(claim)
                                        );
                                      }}
                                    >
                                      <i className="fas fa-eye text-info cursor-pointer ml-2" />
                                    </a>
                                  ),
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="vet-claim-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Confirm Claim receipt
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <h4 className="text-center mb-4">
                      You are Approving{" "}
                      <span className="text-primary">10/10</span> claims from{" "}
                      <span className="font-20 text-primary">
                        Francis Hospital
                      </span>{" "}
                      with{" "}
                      <span className="font-20 text-primary">
                        Batch No. 001{" "}
                      </span>{" "}
                      for payment
                    </h4>
                    <div className="buttons float-right mt-3 flex gap-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm bg-[#5F76E8] flex items-center justify-center"
                      >
                        <i className="fas fa-check mr-2" />
                        Proceed
                      </button>
                      <button
                        className="btn btn-danger btn-sm closs flex items-center justify-center"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times mr-2" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default ViewNewBatchClaims;
