import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import AccreditedHspApi from "../../api/accreditedHspApi";
import { Toast } from "primereact/toast";
import { StyledSearch } from "../../components/StyledSearch";
import ActivateHspModal from "../../components/hsp/accredited-hsp-modals/ActivateHspModal";
import DeactivateHspModal from "../../components/hsp/accredited-hsp-modals/DeactivateHspModal";
import DeleteHspModal from "../../components/hsp/accredited-hsp-modals/DeleteHspModal";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import EditHspModal from "../../components/hsp/accredited-hsp-modals/EditHspModal";

const AccreditedHsp = () => {
  const [token, setToken] = useState("");
  const toast = useRef<Toast>(null);

  const [fetchedHSPs, setFetchedHSPs] = useState([{}]);
  const [selectedHSP, setSelectedHSP] = useState<any>({});
  const [hspImage, setHspImage] = useState<null | File>(null);
  const [createNewHspBody, setCreateNewHspBody] = useState({
    hsp_name: "",
    business_reg_no: "",
    tax_identification_number: "",
    hsp_location: "",
    gps_address: "",
    region: "",
    email: "",
    contact: "",
    hsp_type: "",
    administrator_name: "",
    administrator_email: "",
    administrator_phone: "",
    medical_director_name: "",
    medical_director_email: "",
    medical_director_phone: "",
    claims_officer_name: "",
    claims_officer_email: "",
    claims_officer_phone: "",
    account_name: "",
    account_number: "",
    bank_name: "",
    bank_branch: "",
    network_provider: "",
    mobile_number: "",
    mobile_account_name: "",
  });
  const [selectedHSPDetails, setSelectedHSPDetails] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(1);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handlePrevious = () => {
    if (currentStep !== 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (currentStep != 3) {
      setCurrentStep(currentStep + 1);
    }
  };
  // ---------------------------- Functions ----------------------------

  const handleFetchAllHSPs = async () => {
    let tk = sessionStorage.getItem("token");
    if (!tk) {
      tk = "";
    }

    const response = await AccreditedHspApi.getAllHSP(tk);
    console.log(response);
    setFetchedHSPs(response.info || []);
  };
  const handleFetchHSPDetails = async () => {
    const response = await AccreditedHspApi.getHspDetails(
      token,
      selectedHSP?.hsp_id
    );
    setSelectedHSPDetails(response.data);
  };

  const handleActivateHSP = async () => {
    const response = await AccreditedHspApi.activateHSP(token, selectedHSP?.id);
    if (response.status === 200) {
      show("success", "HSP activated successfully");
      // close modal and remove backdrop
      document.getElementById("activateHSPModal")?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      handleFetchAllHSPs();
    } else {
      show("error", "Failed to deactivate HSP");
    }
  };

  const handleDeactivateHSP = async () => {
    const response = await AccreditedHspApi.deactivateHSP(
      token,
      selectedHSP?.id
    );
    if (response.status === 200) {
      show("success", "HSP deactivated successfully");
      document.getElementById("deactivateHSPModal")?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      handleFetchAllHSPs();
    } else {
      show("error", "Failed to deactivate HSP");
    }
  };
  const handleDeleteHSP = async () => {
    const response = await AccreditedHspApi.deleteHSP(token, selectedHSP?.id);
    if (response.status === 200) {
      show("success", "HSP delete successfully");
      document.getElementById("deactivateHSPModal")?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      handleFetchAllHSPs();
    } else {
      show("error", "Failed to delete HSP");
    }
  };

  const handleEditHsp = async () => {};

  const handleCreateNewHSP = async (e: any) => {
    e.preventDefault();
    // check if every field is filled
    // hsp name
    if (createNewHspBody.hsp_name === "") {
      show("error", "HSP Name is required");
      return;
    }
    // business reg no
    if (createNewHspBody.business_reg_no === "") {
      show("error", "Business Registration No. is required");
      return;
    }
    // tax identification number
    if (createNewHspBody.tax_identification_number === "") {
      show("error", "Tax Identification No. is required");
      return;
    }
    // hsp location
    if (createNewHspBody.hsp_location === "") {
      show("error", "HSP Location is required");
      return;
    }
    // gps address
    if (createNewHspBody.gps_address === "") {
      show("error", "GPS Address is required");
      return;
    }
    // region
    if (createNewHspBody.region === "") {
      show("error", "Region is required");
      return;
    }
    // email
    if (createNewHspBody.email === "") {
      show("error", "Email is required");
      return;
    }
    // contact
    if (createNewHspBody.contact === "") {
      show("error", "Contact is required");
      return;
    }
    // hsp type
    if (createNewHspBody.hsp_type === "") {
      show("error", "HSP Type is required");
      return;
    }
    // administrator name
    if (createNewHspBody.administrator_name === "") {
      show("error", "Administrator Name is required");
      return;
    }
    // administrator email
    if (createNewHspBody.administrator_email === "") {
      show("error", "Administrator Email is required");
      return;
    }
    // administrator phone
    if (createNewHspBody.administrator_phone === "") {
      show("error", "Administrator Phone is required");
      return;
    }
    // medical director name
    if (createNewHspBody.medical_director_name === "") {
      show("error", "Medical Director Name is required");
      return;
    }
    // medical director email
    if (createNewHspBody.medical_director_email === "") {
      show("error", "Medical Director Email is required");
      return;
    }

    const formData = new FormData();
    formData.append("hsp_name", createNewHspBody.hsp_name);
    // formData.append("hsp_id", createNewHspBody.hsp_id);
    formData.append("business_reg_no", createNewHspBody.business_reg_no);
    formData.append(
      "tax_identification_no",
      createNewHspBody.tax_identification_number
    );
    formData.append("hsp_location", createNewHspBody.hsp_location);
    formData.append("gps_address", createNewHspBody.gps_address);
    formData.append("region", createNewHspBody.region);
    formData.append("email", createNewHspBody.email);
    formData.append("contact", createNewHspBody.contact);
    formData.append("hsp_type", createNewHspBody.hsp_type);
    formData.append("administrator_name", createNewHspBody.administrator_name);
    formData.append(
      "administrator_email",
      createNewHspBody.administrator_email
    );
    formData.append(
      "administrator_phone",
      createNewHspBody.administrator_phone
    );
    formData.append(
      "medical_director_name",
      createNewHspBody.medical_director_name
    );
    formData.append(
      "medical_director_email",
      createNewHspBody.medical_director_email
    );
    formData.append(
      "medical_director_phone",
      createNewHspBody.medical_director_phone
    );
    formData.append(
      "claims_officer_name",
      createNewHspBody.claims_officer_name
    );
    formData.append(
      "claims_officer_email",
      createNewHspBody.claims_officer_email
    );
    formData.append(
      "claims_officer_phone",
      createNewHspBody.claims_officer_phone
    );
    formData.append("account_name", createNewHspBody.account_name);
    formData.append("account_number", createNewHspBody.account_number);
    formData.append("bank_name", createNewHspBody.bank_name);
    formData.append("bank_branch", createNewHspBody.bank_branch);
    formData.append("network_provider", createNewHspBody.network_provider);
    formData.append("mobile_number", createNewHspBody.mobile_number);
    formData.append(
      "mobile_account_name",
      createNewHspBody.mobile_account_name
    );
    // formData.append("hsp_logo", hspImage!);

    const response = await AccreditedHspApi.createHSP(token, formData);
    console.log(response);
    if (response.status === 200) {
      show("success", "HSP Created Successfully");
      handleFetchAllHSPs();
      // close modal and remove backdrop
      document.getElementById("setupHspModal")?.classList.remove("show");
      let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      backdrop?.remove();
      // set current step to 1 and clear form
      setCurrentStep(1);
      setCreateNewHspBody({
        hsp_name: "",
        business_reg_no: "",
        tax_identification_number: "",
        hsp_location: "",
        gps_address: "",
        region: "",
        email: "",
        contact: "",
        hsp_type: "",
        administrator_name: "",
        administrator_email: "",
        administrator_phone: "",
        medical_director_name: "",
        medical_director_email: "",
        medical_director_phone: "",
        claims_officer_name: "",
        claims_officer_email: "",
        claims_officer_phone: "",
        account_name: "",
        account_number: "",
        bank_name: "",
        bank_branch: "",
        network_provider: "",
        mobile_number: "",
        mobile_account_name: "",
      });
    } else {
      show("error", "HSP Creation Failed");
    }
  };
  // ---------------------------- UseEffect ----------------------------
  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
    handleFetchAllHSPs();
  }, []);

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filData: any[] = fetchedHSPs.filter((data: any) => {
      return (
        data?.hsp_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.hsp_id.toLowerCase().includes(query.toLowerCase()) ||
        data?.hsp_type.toLowerCase().includes(query.toLowerCase()) ||
        data?.region.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filData);
  };

  useEffect(() => {
    const dataToRender = searchQuery ? filteredData : fetchedHSPs;
    setTableRows(dataToRender);
  }, [searchQuery, fetchedHSPs]);
  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        {/* Topbar header - style you can find in pages.scss */}
        {/* ============================================================== */}
        <Header />
        {/* ============================================================== */}
        {/* End Topbar header */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        <Sidebar />
        {/* ============================================================== */}
        {/* End Left Sidebar - style you can find in sidebar.scss  */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Page wrapper  */}
        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          {/* Container fluid  */}
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Manage HSP",
                  url: "/accredited-hsp",
                },
                {
                  title: "Accredited HSP",
                  url: "/accredited-hsp",
                },
              ]}
            />

            {/*  ---------------------- SET UP HSP MODAL */}
            <div
              className="modal fade"
              id="setupHspModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="myLargeModalLabel">
                      {" "}
                      Setup HPS
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="card-body">
                      <form id="signUpForm" onSubmit={handleCreateNewHSP}>
                        <div className="form-body">
                          {/* step one */}
                          {currentStep === 1 && (
                            <div className="">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>
                                    <i className="fas fa-arrow-up mx-2" />
                                    Logo
                                  </label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Upload
                                      </span>
                                    </div>
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        onChange={(e) => {
                                          if (e.target.files) {
                                            setHspImage(e.target.files[0]);
                                          }
                                        }}
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="inputGroupFile01"
                                      >
                                        Choose file
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-hospital" /> HSP Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Company Name"
                                      value={createNewHspBody.hsp_name}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          hsp_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-hashtag" /> Bussiness
                                      Registration No.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Bussiness Registration No."
                                      value={createNewHspBody.business_reg_no}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          business_reg_no: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-hashtag" /> Tax
                                      Identification No.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Tax Identification No. (TIN)"
                                      value={
                                        createNewHspBody.tax_identification_number
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          tax_identification_number:
                                            e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-map-marker-alt" />{" "}
                                      Location
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="27 Tanganga St."
                                      value={createNewHspBody.hsp_location}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          hsp_location: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-map-marker-alt" />{" "}
                                      Region
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleFormControlSelect1"
                                      value={createNewHspBody.region}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          region: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Select Region</option>
                                      <option value="Ahafo Region">
                                        Ahafo Region
                                      </option>
                                      <option value="Ashanti Region">
                                        Ashanti Region
                                      </option>
                                      <option value="Bono East Regio">
                                        Bono East Region
                                      </option>
                                      <option value="Brong Ahafo Region">
                                        Brong Ahafo Region
                                      </option>
                                      <option value="Central Region">
                                        Central Region
                                      </option>
                                      <option value="Eastern Region">
                                        Eastern Region
                                      </option>
                                      <option value="Greater Accra Region">
                                        Greater Accra Region
                                      </option>
                                      <option value="North East Region">
                                        North East Region
                                      </option>
                                      <option value="Northern Region">
                                        Northern Region
                                      </option>
                                      <option value="Oti Region">
                                        Oti Region
                                      </option>
                                      <option value="Savannah Region">
                                        Savannah Region
                                      </option>
                                      <option value="Upper East Region">
                                        Upper East Region
                                      </option>
                                      <option value="Upper West Region">
                                        Upper West Region
                                      </option>
                                      <option value="Western Region">
                                        Western Region
                                      </option>
                                      <option value="Western North Region">
                                        Western North Region
                                      </option>
                                      <option value="Volta Region">
                                        Volta Region
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-map-marker-alt" />{" "}
                                      GPS Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="27 Tanganga St."
                                      value={createNewHspBody.gps_address}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          gps_address: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-envelope" /> E-mail
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="example@gmail.com"
                                      value={createNewHspBody.email}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          email: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-phone" /> Contact
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control"
                                      placeholder={"020XXXXXXX"}
                                      value={createNewHspBody.contact}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          contact: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>{" "}
                                <div className="col-md-6">
                                  <div className="form-group mb-4">
                                    <label htmlFor="exampleFormControlSelect1">
                                      <i className="fas fa-users" /> HSP Type
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleFormControlSelect1"
                                      value={createNewHspBody.hsp_type}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          hsp_type: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Select HSP type</option>
                                      <option value="Hospital/Clinic">
                                        Hospital/Clinic
                                      </option>
                                      <option value="Labs & Diagnostics">
                                        Labs & Diagnostics
                                      </option>
                                      <option value="Pharmacy">Pharmacy</option>
                                      <option value="Dental Service Provider">
                                        Dental Service Provider
                                      </option>
                                      <option value="Optical Service Provider">
                                        Optical Service Provider
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* step two */}
                          {currentStep === 2 && (
                            <div className="">
                              <h4 className="card-title py-3">Administrator</h4>
                              <hr className="bg-dark" />
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-user" /> Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Administrator Name"
                                      value={
                                        createNewHspBody.administrator_name
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          administrator_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-envelope" /> E-mail
                                      ID
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="exmaple@example.com"
                                      value={
                                        createNewHspBody.administrator_email
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          administrator_email: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-phone" /> Phone
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control"
                                      placeholder={"020XXXXXXX"}
                                      value={
                                        createNewHspBody.administrator_phone
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          administrator_phone: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <h4 className="card-title">Medical Director</h4>
                              <hr className="bg-dark" />
                              <div className="row py-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-user" /> Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Medical Director Name"
                                      value={
                                        createNewHspBody.medical_director_name
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          medical_director_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-envelope" /> E-mail
                                      ID
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="example@example.com"
                                      value={
                                        createNewHspBody.medical_director_email
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          medical_director_email:
                                            e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-phone" /> Phone
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control"
                                      placeholder={"024XXXXXXX"}
                                      value={
                                        createNewHspBody.medical_director_phone
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          medical_director_phone:
                                            e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <h4 className="card-title"> Claims Officer</h4>
                              <hr className="bg-dark" />
                              <div className="row py-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-user" /> Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Claims Officer Name"
                                      value={
                                        createNewHspBody.claims_officer_name
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          claims_officer_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-envelope" /> E-mail
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="example@example.com"
                                      value={
                                        createNewHspBody.claims_officer_email
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          claims_officer_email: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-phone" /> Phone
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder={"024XXXXXXX"}
                                      value={
                                        createNewHspBody.claims_officer_phone
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          claims_officer_phone: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* step three */}
                          {currentStep === 3 && (
                            <div className="">
                              <h4 className="card-title py-3">
                                Financial Info
                              </h4>

                              <h4 className=" py-3">Bank Details</h4>
                              <hr className="bg-dark mb-2" />
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-user" />
                                      Account Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Account Name"
                                      value={createNewHspBody.account_name}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          account_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-envelope" /> Account
                                      Number
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder={"XXXXXXXXXX"}
                                      value={createNewHspBody.account_number}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          account_number: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-user" />
                                      Bank Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Bank Name"
                                      value={createNewHspBody.bank_name}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          bank_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-phone" /> Bank Branch
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Bank Branch"
                                      value={createNewHspBody.bank_branch}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          bank_branch: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <h4 className=" pt-3">Mobile Money Details</h4>
                              <hr className="bg-dark mt-3 mb-2" />
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-user" />
                                      Network Provider
                                    </label>
                                    <select
                                      className="form-control"
                                      value={createNewHspBody.network_provider}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          network_provider: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Select Provider</option>
                                      <option value={"MTN"}>MTN</option>
                                      <option value={"Vodafone"}>
                                        Vodafone
                                      </option>
                                      <option value={"AT"}>
                                        AirtelTigo {"("}AT{")"}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-phone" /> Mobile
                                      Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={"024XXXXXXX"}
                                      value={createNewHspBody.mobile_number}
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          mobile_number: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <i className="fas fa-envelope" /> Account
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={"account name"}
                                      value={
                                        createNewHspBody.mobile_account_name
                                      }
                                      onChange={(e) => {
                                        setCreateNewHspBody({
                                          ...createNewHspBody,
                                          mobile_account_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* start previous / next buttons */}
                          <div className="form-footer d-flex justify-content-between">
                            <div className="mt-1">
                              {currentStep != 1 && (
                                <button
                                  type="button"
                                  id="prevBtn"
                                  className="text-start btn-primary text-white"
                                  onClick={handlePrevious}
                                >
                                  <span>
                                    <i className="fa fa-arrow-left" />
                                  </span>{" "}
                                  Previous{" "}
                                </button>
                              )}
                            </div>
                            {currentStep === 3 ? (
                              <div className="mt-1">
                                <button
                                  type="submit"
                                  onClick={handleCreateNewHSP}
                                  className="text-start btn-primary text-white"
                                >
                                  <span>
                                    <i className="fa fa-save mr-2" />
                                  </span>
                                  Save
                                </button>
                              </div>
                            ) : (
                              <div className="mt-1">
                                <div
                                  // type="button"
                                  id="nextBtn"
                                  className="btn btn-primary text-white rounded-pill"
                                  onClick={handleNext}
                                >
                                  Next{" "}
                                  <span>
                                    <i className="fa fa-arrow-right" />
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* end previous / next buttons */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* /.modal-content */}
              </div>
              {/* /.modal-dialog */}
            </div>
            {/* /.modal */}

            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="col-md-12 mb-2 flex justify-end gap-4">
                        <StyledSearch
                          onChange={handleSearchChange}
                          value={searchQuery}
                          placeholder="🔍 by name, ID, Type, Region"
                        />
                        <button
                          type="button"
                          className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                          data-toggle="modal"
                          data-target="#setupHspModal"
                        >
                          <i className="fas fa-tasks" /> Setup HSP
                        </button>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "HSP Name",
                          "HSP ID",
                          "HSP Type",
                          "Region",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          tableRows &&
                          tableRows.map((hsp: any, index: number) => ({
                            sn: index + 1,
                            hsp_name: hsp?.hsp_name,
                            hsp_id: hsp?.hsp_id,
                            hsp_type: hsp?.hsp_type,
                            region: hsp?.region,
                            status: hsp?.status,
                            action: (
                              <div className="flex">
                                <a
                                  href="/view-hsp"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "selectedHSP",
                                      JSON.stringify(hsp)
                                    );
                                  }}
                                >
                                  <i className="fas fa-eye text-primary  mr-2" />
                                </a>
                                <a
                                  href="#"
                                  style={{ zIndex: 200 }}
                                  className="text-primary mr-2"
                                  data-toggle="modal"
                                  data-target="#editHspModal"
                                >
                                  <i className="fas fa-edit text-primary" />
                                </a>
                                {hsp?.status?.toLowerCase() === "active" ? (
                                  <button
                                    data-toggle="modal"
                                    data-target="#deactivateHSPModal"
                                    onClick={() => {
                                      setSelectedHSP(hsp);
                                    }}
                                  >
                                    <i className="fas fa-times text-primary mr-2" />
                                  </button>
                                ) : (
                                  <button
                                    data-toggle="modal"
                                    data-target="#activateHSPModal"
                                    onClick={() => {
                                      setSelectedHSP(hsp);
                                    }}
                                  >
                                    <i className="fas fa-power-off text-primary mr-2" />
                                  </button>
                                )}
                                <button
                                  data-toggle="modal"
                                  data-target="#deleteHSPModal"
                                  onClick={() => {
                                    setSelectedHSP(hsp);
                                  }}
                                >
                                  <i className="fas fa-trash text-primary mr-2" />
                                </button>
                              </div>
                            ),
                          }))
                        }
                        emptyTableMessage="No HSPs Available"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* delete HSP Modal */}
          <DeleteHspModal
            handleDeleteHSP={handleDeleteHSP}
            selectedHSP={selectedHSP}
          />
          {/* deactivate HSP Modal */}
          {/* delete HSP Modal */}
          <EditHspModal
            handleEditHsp={handleEditHsp}
            selectedHSP={selectedHSP}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            createNewHspBody={createNewHspBody}
            setCreateNewHspBody={setCreateNewHspBody}
            setHspImage={setHspImage}
          />
          {/* deactivate HSP Modal */}
          <DeactivateHspModal
            handleDeactivateHSP={handleDeactivateHSP}
            selectedHSP={selectedHSP}
          />
          {/* -----end ------ */}
          {/* activate HSP Modal */}
          <ActivateHspModal
            handleActivateHSP={handleActivateHSP}
            selectedHSP={selectedHSP}
          />
          {/* -----end ------ */}
          {/* ============================================================== */}
          {/* End Container fluid  */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* footer */}
          {/* ============================================================== */}
          <Footer />
          {/* ============================================================== */}
          {/* End footer */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Page wrapper  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};

export default AccreditedHsp;
