import axios from "axios";
const BASE_URL = process.env.REACT_APP_Base_url;
import { CreateNewAuthorizationBodyType } from "../types/data";

const getAllAuthorization = async (token: string) => {
  let url = `${BASE_URL}/authorization/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const createAuthorization = async (
  token: string,
  authorizationData: CreateNewAuthorizationBodyType
) => {
  let url = `${BASE_URL}/authorization/`;
  try {
    const response = await axios.post(url, authorizationData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const denyAuthorization = async (token: string, authorizationId: string) => {
  let url = `${BASE_URL}/authorization/deny`;
  try {
    const response = await axios.post(
      url,
      { authorization_id: authorizationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const approveAuthorization = async (token: string, authorizationId: string) => {
  let url = `${BASE_URL}/authorization/approve`;
  try {
    const response = await axios.post(
      url,
      { authorization_id: authorizationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const editAuthorization = async (
  token: string,
  authorizationId: string,
  authorizationData: CreateNewAuthorizationBodyType
) => {
  let url = `${BASE_URL}/authorization/edit`;
  try {
    const data = {
      ...authorizationData,
      authorization_id: authorizationId,
    };
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getAllAuthorization,
  createAuthorization,
  denyAuthorization,
  approveAuthorization,
  editAuthorization,
};
