import React from "react";
import capitalizeText from "../../../utils/capitalizeText";

interface DeleteStaffModalProps {
  staff?: any;
  handleDeleteStaff: (staff: any) => void;
}

function DeleteStaffModal({ staff, handleDeleteStaff }: DeleteStaffModalProps) {
  return (
    <div
      className="modal fade"
      id="deleteStaffModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Delete Staff
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <p className="text-center text-sm">
                      Are you sure you want to delete. <br />
                      deleting staff:{" "}
                      <span className="text-red-500">
                        {capitalizeText(staff?.staff_first_name || "")}{" "}
                        {capitalizeText(staff?.staff_last_name || "")}
                      </span>{" "}
                      with{" "}
                      <span className="text-red-500">{staff?.staff_id}</span>
                    </p>
                    <div className="flex justify-end gap-4 mt-4">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-danger btn-sm float-right bg-danger rounded-pill"
                      >
                        <i className="fas fa-times" />{" "}
                        {<span className="mx-2">Cancel</span>}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm float-right bg-[#5F76E8] rounded-pill"
                        onClick={handleDeleteStaff}
                      >
                        <i className="fas fa-trash" />{" "}
                        {<span className="mx-2">Delete </span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteStaffModal;
