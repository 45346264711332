import React from "react";

interface EditHspModalProps {
  selectedHSP: any;
  handleEditHsp: () => void;
  handleNext: () => void;
  handlePrevious: () => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  createNewHspBody: any;
  setCreateNewHspBody: (body: any) => void;
  setHspImage: (image: any) => void;
}

function EditHspModal({
  selectedHSP,
  handleEditHsp,
  handleNext,
  handlePrevious,
  currentStep,
  setCurrentStep,
  createNewHspBody,
  setCreateNewHspBody,
  setHspImage,
}: EditHspModalProps) {
  return (
    <div
      className="modal fade"
      id="editHspModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {" "}
              Setup HPS
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>

          <div className="modal-body">
            <div className="card-body">
              <form id="signUpForm" onSubmit={handleEditHsp}>
                <div className="form-body">
                  {/* step one */}
                  {currentStep === 1 && (
                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <label>
                            <i className="fas fa-arrow-up mx-2" />
                            Logo
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Upload</span>
                            </div>
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="inputGroupFile01"
                                onChange={(e) => {
                                  if (e.target.files) {
                                    setHspImage(e.target.files[0]);
                                  }
                                }}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="inputGroupFile01"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-hospital" /> HSP Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              value={createNewHspBody.hsp_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  hsp_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-hashtag" /> Bussiness
                              Registration No.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bussiness Registration No."
                              value={createNewHspBody.business_reg_no}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  business_reg_no: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-hashtag" /> Tax
                              Identification No.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Tax Identification No. (TIN)"
                              value={createNewHspBody.tax_identification_number}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  tax_identification_number: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-map-marker-alt" /> Location
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="27 Tanganga St."
                              value={createNewHspBody.hsp_location}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  hsp_location: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-map-marker-alt" /> Region
                            </label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              value={createNewHspBody.region}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  region: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select Region</option>
                              <option value="Ahafo Region">Ahafo Region</option>
                              <option value="Ashanti Region">
                                Ashanti Region
                              </option>
                              <option value="Bono East Regio">
                                Bono East Region
                              </option>
                              <option value="Brong Ahafo Region">
                                Brong Ahafo Region
                              </option>
                              <option value="Central Region">
                                Central Region
                              </option>
                              <option value="Eastern Region">
                                Eastern Region
                              </option>
                              <option value="Greater Accra Region">
                                Greater Accra Region
                              </option>
                              <option value="North East Region">
                                North East Region
                              </option>
                              <option value="Northern Region">
                                Northern Region
                              </option>
                              <option value="Oti Region">Oti Region</option>
                              <option value="Savannah Region">
                                Savannah Region
                              </option>
                              <option value="Upper East Region">
                                Upper East Region
                              </option>
                              <option value="Upper West Region">
                                Upper West Region
                              </option>
                              <option value="Western Region">
                                Western Region
                              </option>
                              <option value="Western North Region">
                                Western North Region
                              </option>
                              <option value="Volta Region">Volta Region</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-map-marker-alt" /> GPS
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="27 Tanganga St."
                              value={createNewHspBody.gps_address}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  gps_address: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-envelope" /> E-mail
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="example@gmail.com"
                              value={createNewHspBody.email}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-phone" /> Contact
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              placeholder={"020XXXXXXX"}
                              value={createNewHspBody.contact}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  contact: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <label htmlFor="exampleFormControlSelect1">
                              <i className="fas fa-users" /> HSP Type
                            </label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              value={createNewHspBody.hsp_type}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  hsp_type: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select HSP Type</option>
                              <option value="Labs & Diagnostics">
                                Labs{" & "}Diagnostics
                              </option>
                              <option value="Pharmacy">Pharmacy</option>
                              <option value="Hospital/Clinic">
                                Hospital{"/"}Clinic
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* step two */}
                  {currentStep === 2 && (
                    <div className="">
                      <h4 className="card-title py-3">Administrator</h4>
                      <hr className="bg-dark" />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-user" /> Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Administrator Name"
                              value={createNewHspBody.administrator_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  administrator_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-envelope" /> E-mail ID
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="exmaple@example.com"
                              value={createNewHspBody.administrator_email}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  administrator_email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-phone" /> Phone
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              placeholder={"020XXXXXXX"}
                              value={createNewHspBody.administrator_phone}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  administrator_phone: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <h4 className="card-title">Medical Director</h4>
                      <hr className="bg-dark" />
                      <div className="row py-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-user" /> Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Medical Director Name"
                              value={createNewHspBody.medical_director_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  medical_director_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-envelope" /> E-mail ID
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="example@example.com"
                              value={createNewHspBody.medical_director_email}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  medical_director_email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-phone" /> Phone
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              placeholder={"024XXXXXXX"}
                              value={createNewHspBody.medical_director_phone}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  medical_director_phone: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <h4 className="card-title"> Claims Officer</h4>
                      <hr className="bg-dark" />
                      <div className="row py-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-user" /> Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Claims Officer Name"
                              value={createNewHspBody.claims_officer_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  claims_officer_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-envelope" /> E-mail
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="example@example.com"
                              value={createNewHspBody.claims_officer_email}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  claims_officer_email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-phone" /> Phone
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={"024XXXXXXX"}
                              value={createNewHspBody.claims_officer_phone}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  claims_officer_phone: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* step three */}
                  {currentStep === 3 && (
                    <div className="">
                      <h4 className="card-title py-3">Financial Info</h4>

                      <h4 className=" py-3">Bank Details</h4>
                      <hr className="bg-dark mb-2" />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-user" />
                              Account Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Account Name"
                              value={createNewHspBody.account_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  account_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-envelope" /> Account Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={"XXXXXXXXXX"}
                              value={createNewHspBody.account_number}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  account_number: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-user" />
                              Bank Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bank Name"
                              value={createNewHspBody.bank_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  bank_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-phone" /> Bank Branch
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bank Branch"
                              value={createNewHspBody.bank_branch}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  bank_branch: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <h4 className=" pt-3">Mobile Money Details</h4>
                      <hr className="bg-dark mt-3 mb-2" />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-user" />
                              Network Provider
                            </label>
                            <select
                              className="form-control"
                              value={createNewHspBody.network_provider}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  network_provider: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select Provider</option>
                              <option value={"MTN"}>MTN</option>
                              <option value={"Vodafone"}>Vodafone</option>
                              <option value={"AT"}>
                                AirtelTigo {"("}AT{")"}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-phone" /> Mobile Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={"024XXXXXXX"}
                              value={createNewHspBody.mobile_number}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  mobile_number: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <i className="fas fa-envelope" /> Account Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={"account name"}
                              value={createNewHspBody.mobile_account_name}
                              onChange={(e) => {
                                setCreateNewHspBody({
                                  ...createNewHspBody,
                                  mobile_account_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* start previous / next buttons */}
                  <div className="form-footer d-flex justify-content-between">
                    <div className="mt-1">
                      {currentStep != 1 && (
                        <button
                          type="button"
                          id="prevBtn"
                          className="text-start btn-primary text-white"
                          onClick={handlePrevious}
                        >
                          <span>
                            <i className="fa fa-arrow-left" />
                          </span>{" "}
                          Previous{" "}
                        </button>
                      )}
                    </div>
                    {currentStep === 3 ? (
                      <div className="mt-1">
                        <button
                          type="submit"
                          onClick={handleEditHsp}
                          className="text-start btn-primary text-white"
                        >
                          <span>
                            <i className="fa fa-save mr-2" />
                          </span>
                          Save
                        </button>
                      </div>
                    ) : (
                      <div className="mt-1">
                        <div
                          // type="button"
                          id="nextBtn"
                          className="btn btn-primary text-white rounded-pill"
                          onClick={handleNext}
                        >
                          Next{" "}
                          <span>
                            <i className="fa fa-arrow-right" />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end previous / next buttons */}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default EditHspModal;
