import React, { useEffect, useRef, useState } from "react";
import CustomComboBox from "../ui/CustomComboBox";
import batchClaimsApi from "../../api/batchClaimsApi";
import {
  OptionsType,
  TrimmedAccreditedHspType,
  createNewBatchType,
} from "../../types/data";
import accreditedHspApi from "../../api/accreditedHspApi";
import CustomSelector from "../ui/CustomSelector";
import { Toast } from "primereact/toast";
import closeAnyModal from "../../utils/closeAnyModal";

interface AddBatchClaimsModalProps {
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  triggerRefresh: boolean;
}

function AddBatchClaimsModal({
  setTriggerRefresh,
  triggerRefresh,
}: AddBatchClaimsModalProps) {
  const toast = useRef<Toast>(null);

  const [batchClaimMonth, setBatchClaimMonth] = useState({
    label: "",
    value: "",
  });
  const [accreditedHspId, setAccreditedHspId] = useState<string | null>("");
  const [hspType, setHspType] = useState<string | null>("");
  const [selectedBatch, setSelectedBatch] = useState<OptionsType | null>(null);
  const [fetchedAccreditedHSPs, setFetchedAccreditedHSPs] = useState<
    TrimmedAccreditedHspType[]
  >([]);

  const handleCreateNewBatch = async (e: any) => {
    e.preventDefault();
    if (!accreditedHspId) {
      show("error", "Please select HSP");
      return;
    }
    if (batchClaimMonth.value === "") {
      show("error", "Please select claim month");
      return;
    }
    if (!hspType) {
      show("error", "Please select HSP type");
      return;
    }
    const data: createNewBatchType = {
      accredited_hsp_id: accreditedHspId,
      batch_claim_month: batchClaimMonth.value,
      hsp_type: hspType,
    };
    const response = await batchClaimsApi.createNewBatch(
      sessionStorage.getItem("token") || "",
      data
    );
    if (response.status === 200) {
      setTriggerRefresh(!triggerRefresh);
      show("success", "Batch created successfully");
      closeAnyModal("add-batch-claims-modal");
      // document
      //   .getElementById("add-batch-claims-modal")
      //   ?.classList.remove("show");
      // let backdrop = document.getElementsByClassName("modal-backdrop")[0];
      // backdrop?.remove();
    } else {
      show("error", `Error creating batch: ${response?.message}`);
    }
  };

  const getAllAccreditedHSPs = async () => {
    const response = await accreditedHspApi.getAllHSP(
      sessionStorage.getItem("token") || ""
    );
    if (response.status === 200) {
      if (response?.info?.length === 0) {
        // show("info", "No HSPs found");
        return;
      }
      if (response?.info?.length > 0) {
        const temp: TrimmedAccreditedHspType[] = response.info?.map(
          (item: any) => ({
            hsp_id: item.hsp_id,
            hsp_name: item.hsp_name,
            hsp_type: item.hsp_type,
            id: item.id,
          })
        );
        setFetchedAccreditedHSPs(temp);
      }
    }
  };

  const show = (code: string, message: string) => {
    toast.current?.show({
      severity: code as "success" | "info" | "warn" | "error" | undefined,
      summary: "Info",
      detail: message,
    });
  };

  useEffect(() => {
    getAllAccreditedHSPs();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div
        className="modal fade"
        id="add-batch-claims-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                Batch Claims
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <form onSubmit={handleCreateNewBatch}>
                  <div className="form-body">
                    <div className="col-12 mb-3">
                      <label htmlFor="">HSP Type</label>
                      <select
                        className="form-control"
                        onChange={(e) => setHspType(e.target.value)}
                      >
                        <option value="">Select HSP type</option>
                        <option value="Hospital/Clinic">Hospital/Clinic</option>
                        <option value="Labs & Diagnostics">
                          Labs & Diagnostics
                        </option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Dental Service Provider">
                          Dental Service Provider
                        </option>
                        <option value="Optical Service Provider">
                          Optical Service Provider
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <label htmlFor="">HSP Name</label>
                      <div className="w-full">
                        <CustomSelector
                          selecterFor="hsp"
                          setAccreditedHspId={setAccreditedHspId}
                          options={fetchedAccreditedHSPs?.map((item) => ({
                            value: item.hsp_id,
                            label: `${item.hsp_name} - ${item.hsp_type}`,
                            id: item.id,
                          }))}
                          selected={selectedBatch}
                          setSelected={setSelectedBatch}
                          placeholder="Select HSP"
                          searchPlaceholder="Search HSP"
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <label htmlFor="">Claim Month</label>
                      <input
                        type="date"
                        className="form-control"
                        value={batchClaimMonth.label}
                        onChange={(e) => {
                          let date = new Date(e.target.value);
                          let day = date.getDate();
                          let month = date.getMonth();
                          let year = date.getFullYear();
                          // if month is less than 10, add 0 before month
                          if (month < 10) {
                            setBatchClaimMonth({
                              label: e.target.value,
                              value: `${year}-0${month + 1}-${day}`,
                            });
                            return;
                          }
                          // if day is less than 10, add 0 before day
                          if (day < 10) {
                            setBatchClaimMonth({
                              label: e.target.value,
                              value: `${year}-${month + 1}-0${day}`,
                            });
                            return;
                          }
                          setBatchClaimMonth({
                            label: e.target.value,
                            value: `${year}-${month + 1}-${day}`,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="buttons float-right mt-6 flex gap-4">
                    <button
                      className="btn btn-danger btn-sm closs flex items-center justify-center rounded-pill"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-times mr-2" />
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={handleCreateNewBatch}
                      className="btn btn-primary btn-sm bg-primary flex items-center justify-center rounded-pill"
                    >
                      <i className="fas fa-check mr-2" />
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
}

export default AddBatchClaimsModal;
