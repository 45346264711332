import { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import axios from "axios";
import { RadiologyType } from "../../types/data";
import { StyledSearch } from "../../components/StyledSearch";
import radiologyApi from "../../api/radiologyApi";
import BreadCrumbsComponent from "../../components/ui/BreadCrumbsComponent";
import { Toast } from "primereact/toast";
import convertTimestampToDate from "../../utils/convertTimestampToDate";
import formatDate from "../../utils/formatDate";
import MasterTableComponent from "../../components/ui/MasterTableComponent";
import BulkUploadModal from "../../components/BulkUploadModal";
import closeAnyModal from "../../utils/closeAnyModal";

const Radiology = () => {
  const [category, setCategory] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [categoryData, setCategoryData] = useState<any>([]);
  const [bulkUploadBody, setBulkUploadBody] = useState<File | null>(null);
  const [bulkUploading, setBulkUploading] = useState(false);

  const [radiology_name, setRadiologyName] = useState<string>("");
  const [medication_category, setMedicationCategory] = useState<string>("");
  const [radiologyData, setRadiologyData] = useState<any>([]);
  const [selectedRadiology, setSelectedRadiology] = useState<any>({});
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [tableRows, setTableRows] = useState<RadiologyType[]>([]);
  const [selectedCategoryDateAndStatus, setSelectedCategoryDateAndStatus] =
    useState({
      date: "",
      status: "",
    });

  const [token, setToken] = useState<string>("");
  const [enteredRadiologyCategoryCode, setEnteredRadiologyCategoryCode] =
    useState<string>("");
  const toast = useRef<Toast>(null);

  const handleCategory = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/category/`,
        {
          radiology_category: category,
          radiology_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setCategory("");
          setInitials("");
          handleFetchRadiologyCategory();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRadiology = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/`,
        {
          radiology_name,
          radiology_category: medication_category,
          radiology_code: enteredRadiologyCategoryCode,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setRadiologyName("");
          setMedicationCategory("");
          handleFetchRadiology();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActivateRadiology = async (id: string) => {
    const res = await radiologyApi.activateRadiology(token, {
      radiology_id: id,
    });
    if (res.status === 200) {
      toast.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: res.message,
        life: 3000,
      });
      handleFetchRadiology();
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: res.data.message,
        life: 3000,
      });
    }
  };

  const handleDeactivateRadiology = async (id: string) => {
    const res = await radiologyApi.deactivateRadiology(token, {
      radiology_id: id,
    });
    if (res.status === 200) {
      toast.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: res.message,
        life: 3000,
      });
      handleFetchRadiology();
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: res.data.message,
        life: 3000,
      });
    }
  };

  const handleFetchRadiologyCategory = () => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/category/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setCategoryData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchRadiology = () => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup-services/radiology/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setRadiologyData(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const FilterCategoryFromCategories = (categoryId: string) => {
    let temp = categoryData.filter(
      (category: any) => category.id != categoryId
    );
    setCategoryData(temp);
  };

  const handleUpdateCategory = (categoryId: string) => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/category/edit`,
        {
          radiology_category_id: categoryId,
          radiology_category: category,
          radiology_initials: initials,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          setIsEditable(false);
          setCategory("");
          setInitials("");
          handleFetchRadiologyCategory();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/category/delete`,
        {
          radiology_category_id: categoryToDelete.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data.message,
            life: 3000,
          });
          FilterCategoryFromCategories(categoryToDelete.id);
          setIsDeleteCategory(false);
          setCategoryToDelete({});
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data.message,
            life: 3000,
          });
        }
      });
  };

  const handleDeleteRadiology = () => {
    // /surgery/delete_surgery_category
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup-services/radiology/category/delete`,
        {
          radiology_id: selectedRadiology.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.data?.message,
            life: 3000,
          });
          handleFetchRadiology();
          // close modal and remove backdrop
          document.getElementById("deleteModal")?.classList.remove("show");
          let backdrop = document.getElementsByClassName("modal-backdrop")[0];
          backdrop?.remove();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.data?.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBulkUpload = () => {
    if (!bulkUploadBody) {
      toast.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select a file",
        life: 3000,
      });
      return;
    }

    setBulkUploading(true);
    const formData = new FormData();
    formData.append("excel_file", bulkUploadBody);
    radiologyApi
      .bulkRadiologyUpload(sessionStorage.getItem("token") || "", formData)

      .then((res) => {
        setBulkUploading(false);
        if (res.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success Message",
            detail: res.message,
            life: 3000,
          });
          handleFetchRadiology();
          // close modal and remove backdrop
          closeAnyModal("radiologyBulkUploadModal");
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error Message",
            detail: res.message,
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: "An error occured",
          life: 3000,
        });
      });
  };

  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the medicationData based on the search query
    const filteredData = radiologyData.filter((data: any) => {
      return (
        data?.radiology_name.toLowerCase().includes(query.toLowerCase()) ||
        data?.radiology_code.toLowerCase().includes(query.toLowerCase()) ||
        data?.radiology_category.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredServiceData(filteredData);
  };

  useEffect(() => {
    handleFetchRadiologyCategory();
    handleFetchRadiology();
    const tk = sessionStorage.getItem("token");
    if (tk) {
      setToken(tk);
    }
  }, []);

  useEffect(() => {
    const dataToRender = searchQuery ? filteredServiceData : radiologyData;
    setTableRows(dataToRender);
  }, [searchQuery, radiologyData]);

  return (
    <>
      <Toast ref={toast} />

      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        {/* ============================================================== */}
        <Header />

        {/* ============================================================== */}
        <Sidebar />

        {/* ============================================================== */}
        <div className="page-wrapper">
          {/* ============================================================== */}
          <div className="container-fluid">
            <BreadCrumbsComponent
              breadcrumbs={[
                {
                  title: "Setup Service",
                  url: "/radiology",
                },
                {
                  title: "Radiology",
                  url: "/radiology",
                },
              ]}
            />
            <div className="row">
              <div className="col-5 align-self-center mb-3">
                <div className="customize-input float-right">
                  <div
                    className="modal fade"
                    id="deleteModal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-md">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title" id="myLargeModalLabel">
                            {" "}
                            Delete Radiology
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="text-center text-sm">
                            Are you sure you want to delete.
                            <br />
                            Deleting radiology service:{" "}
                            <span className="text-red-500 text-center">
                              {selectedRadiology?.radiology_name} <br />
                            </span>
                            cannot be reversed.
                          </p>

                          <div className="flex justify-end mt-4 gap-4">
                            <button
                              type="button"
                              className="btn btn-danger rounded-pill bg-danger "
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="fa fa-times mr-2"></i>Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-success rounded-pill bg-primary"
                              onClick={handleDeleteRadiology}
                            >
                              <i className="fa fa-save mr-2"></i>Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>

                  {/*  Modal content for Add Radiology */}
                  <div
                    className="modal fade"
                    id="bs-example-modal-lg"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title" id="myLargeModalLabel">
                            {" "}
                            Radiology Setup
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-body">
                                  <form onSubmit={handleRadiology}>
                                    <div className="form-body">
                                      {/* step one */}
                                      <div className="step">
                                        <div className="row">
                                          <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group mb-3">
                                              <label htmlFor="plan_type">
                                                Category
                                              </label>
                                              <br />
                                              <select
                                                className="form-control plantype"
                                                name="plan_type"
                                                id="plan_type"
                                                value={medication_category}
                                                onChange={(e) =>
                                                  setMedicationCategory(
                                                    e.target.value
                                                  )
                                                }
                                                // placeholder="Select Type"
                                              >
                                                <option disabled selected>
                                                  Select
                                                </option>
                                                {categoryData.map(
                                                  (category_data: any) => (
                                                    <option
                                                      key={category_data.id}
                                                      value={
                                                        category_data.radiology_category
                                                      }
                                                      className="generic"
                                                    >
                                                      {
                                                        category_data.radiology_category
                                                      }
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group mb-3">
                                              <label htmlFor="benefit">
                                                Name of Radiology
                                              </label>
                                              <br />
                                              <input
                                                type="text"
                                                className="form-control"
                                                required
                                                placeholder="Name of Radiology"
                                                value={radiology_name}
                                                onChange={(e) =>
                                                  setRadiologyName(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group mb-3">
                                              <label htmlFor="benefit">
                                                Code
                                              </label>
                                              <br />
                                              <input
                                                type="text"
                                                className="form-control"
                                                aria-label="Amoun"
                                                placeholder="Code"
                                                value={
                                                  enteredRadiologyCategoryCode
                                                }
                                                onChange={(e) => {
                                                  // setMedicationCategory(
                                                  //   e.target.value
                                                  // );
                                                  setEnteredRadiologyCategoryCode(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit-btn">
                                        <button
                                          type="submit"
                                          className="btn btn-primary btn-sm float-right bg-primary rounded-pill"
                                        >
                                          <i className="fas fa-paper-plane" />{" "}
                                          Save
                                        </button>
                                      </div>
                                      {/* end previous / next buttons */}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>
                  {/* /.modal */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="col-md-12 flex justify-between mb-4">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                          Radiology Setup
                        </h3>
                        <div className="flex justify-end gap-4">
                          <StyledSearch
                            className="placeholder:text-sm"
                            placeholder="🔍 by Name, Category, Code"
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] mx-1 rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example-modal-lg"
                          >
                            <i className="fas fa-tasks" /> Add Radiology
                          </button>
                          <button
                            type="button"
                            className="btn btn-info btn-sm bg-[#5F76E8] mx-1 rounded-pill"
                            data-toggle="modal"
                            data-target="#bs-example"
                          >
                            <i className="fas fa-tasks" /> Set Category
                          </button>
                          <button
                            className="btn btn-info btn-sm bg-[#5F76E8] rounded-pill"
                            data-toggle="modal"
                            data-target="#radiologyBulkUploadModal"
                          >
                            Bulk Upload
                          </button>
                        </div>
                      </div>
                      <MasterTableComponent
                        headers={[
                          "SN",
                          "Code",
                          "Name",
                          "Category",
                          "Date Added",
                          "Status",
                          "Action",
                        ]}
                        tableRows={
                          tableRows &&
                          tableRows.map(
                            (radiology: RadiologyType, index: number) => ({
                              SN: index + 1,
                              Code: radiology.radiology_code,
                              Name: radiology.radiology_name,
                              Category: radiology.radiology_category,
                              "Date Added": Number(radiology.created_at)
                                ? convertTimestampToDate(
                                    Number(radiology.created_at)
                                  )
                                : formatDate(radiology.created_at),
                              Status: radiology.status,
                              Action: (
                                <div className="flex gap-2">
                                  <a
                                    className="mx-1"
                                    href={`/view-radiology?radiology_id=${radiology.id}&action_type=view`}
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "selectedRadiology",
                                        JSON.stringify(radiology)
                                      );
                                    }}
                                  >
                                    <i className="fas fa-eye text-primary delplan"></i>
                                  </a>

                                  <a
                                    className="mx-1"
                                    href={`/view-radiology?radiology_id=${radiology.id}&action_type=edit`}
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "selectedRadiology",
                                        JSON.stringify(radiology)
                                      );
                                    }}
                                  >
                                    <i className="fas fa-edit text-primary" />
                                  </a>
                                  {radiology.status.toLowerCase() !=
                                  "active" ? (
                                    <button
                                      onClick={() => {
                                        setSelectedRadiology(radiology);
                                        handleActivateRadiology(radiology?.id);
                                      }}
                                    >
                                      <i className="fas fa-power-off text-primary delplan" />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setSelectedRadiology(radiology);
                                        handleDeactivateRadiology(
                                          radiology?.id
                                        );
                                      }}
                                    >
                                      <i className="fas fa-times text-primary delplan" />
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    data-target="#deleteModal"
                                    data-toggle="modal"
                                    className="mx-1"
                                    onClick={() => {
                                      setSelectedRadiology(radiology);
                                    }}
                                  >
                                    <i className="fas fa-trash text-primary delplan" />
                                  </button>
                                </div>
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Modal content for the above example */}
          <div
            className="modal fade"
            id="bs-example"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myLargeModalLabel">
                    {" "}
                    Set Category
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <form onSubmit={handleCategory}>
                            <div className="form-body">
                              {/* step one */}
                              {!isDeleteCategory && (
                                <>
                                  <div className="step">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Category
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="radiology_category"
                                            placeholder="Category Name"
                                            value={category}
                                            onChange={(e) =>
                                              setCategory(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="benefit">
                                            Code Initials{" "}
                                          </label>
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="radiology_initials"
                                            placeholder="Code Initials"
                                            value={initials}
                                            onChange={(e) =>
                                              setInitials(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    <button
                                      onClick={handleCategory}
                                      type="submit"
                                      className="btn btn-primary btn-sm float-right mb-4 bg-[#5F76E8]"
                                    >
                                      <i className="fas fa-paper-plane" /> Save
                                    </button>
                                  </div>
                                </>
                              )}
                              {isDeleteCategory && (
                                <div className="border border-danger mb-5 p-2">
                                  <p className="text-[16px] font-normal my-2">
                                    Confirm Deletion of <br />
                                    <span className="text-danger">
                                      {categoryToDelete.radiology_category}
                                    </span>
                                    <br />
                                    This action cannot be undone.
                                  </p>
                                  <div className="flex justify-end gap-4">
                                    <div
                                      onClick={() => {
                                        setIsDeleteCategory(false);
                                        setCategoryToDelete({});
                                      }}
                                      className="btn btn-danger rounded-pill bg-danger"
                                    >
                                      <i className="fa fa-times mx-1"></i>
                                      Cancel
                                    </div>
                                    <div
                                      className="btn btn-danger rounded-pill bg-danger"
                                      onClick={handleDelete}
                                    >
                                      <i className="fa fa-trash mx-1"></i>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="table-responsive">
                                <div className="col-md-12"></div>
                                <table
                                  id="default_order"
                                  className="table datatable table-striped table-hover table-sm"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Code Initials</th>
                                      <th>Category</th>
                                      <th>Date Added</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  {categoryData && (
                                    <tbody>
                                      {isEditable && (
                                        <tr className="border-2 border-primary">
                                          <td>
                                            <input
                                              defaultValue={initials}
                                              onChange={(e) => {
                                                setInitials(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              defaultValue={category}
                                              onChange={(e) => {
                                                setCategory(e.target.value);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            {Number(
                                              selectedCategoryDateAndStatus.date
                                            )
                                              ? convertTimestampToDate(
                                                  Number(
                                                    selectedCategoryDateAndStatus.date
                                                  )
                                                )
                                              : formatDate(
                                                  selectedCategoryDateAndStatus.date
                                                )}
                                          </td>
                                          <td>
                                            {
                                              selectedCategoryDateAndStatus.status
                                            }
                                          </td>
                                          <td className="align-center">
                                            <span>
                                              {!isLoading ? (
                                                <>
                                                  <i
                                                    onClick={() => {
                                                      handleUpdateCategory(
                                                        selectedCategoryId
                                                      );
                                                    }}
                                                    className="fa fa-save text-primary mx-1"
                                                  ></i>
                                                  <i
                                                    onClick={() => {
                                                      setIsEditable(false);
                                                    }}
                                                    className="fa fa-times text-warning"
                                                  ></i>
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fa fa-spinner fa-spin text-primary"></i>
                                                </>
                                              )}{" "}
                                            </span>
                                          </td>
                                        </tr>
                                      )}
                                      {categoryData.map(
                                        (
                                          category: RadiologyType,
                                          index: number
                                        ) => (
                                          <tr
                                            key={category.id}
                                            className="clickable-row"
                                          >
                                            <td>
                                              {category?.radiology_initials}
                                            </td>
                                            <td>
                                              {" "}
                                              {category.radiology_category}
                                            </td>
                                            <td>
                                              {Number(category.created_at)
                                                ? convertTimestampToDate(
                                                    Number(category.created_at)
                                                  )
                                                : formatDate(
                                                    category.created_at
                                                  )}
                                            </td>
                                            <td>{category?.status}</td>
                                            <td>
                                              <span
                                                className="mx-1"
                                                onClick={() => {
                                                  setCategory(
                                                    category.radiology_category
                                                  );
                                                  setInitials(
                                                    category.radiology_initials ||
                                                      ""
                                                  );
                                                  setSelectedCategoryDateAndStatus(
                                                    {
                                                      date: category.created_at,
                                                      status: category.status,
                                                    }
                                                  );
                                                  setIsEditable(true);
                                                  setSelectedCategoryId(
                                                    category.id
                                                  );
                                                  FilterCategoryFromCategories(
                                                    category.id
                                                  );
                                                }}
                                              >
                                                <i className="fas fa-edit text-primary" />
                                              </span>
                                              <button
                                                type="button"
                                                className="mx-1"
                                                onClick={() => {
                                                  setIsDeleteCategory(true);
                                                  setCategoryToDelete(category);
                                                }}
                                              >
                                                <i className="fas fa-trash text-primary delplan" />
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                              {/* end previous / next buttons */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>

          {/* -------------- BULK UPLOAD MODAL */}
          <BulkUploadModal
            title="Radiology"
            selector="radiologyBulkUploadModal"
            setBulkUploadBody={setBulkUploadBody}
            handleBulkUpload={handleBulkUpload}
            loading={bulkUploading}
          />
          {/* ============================================================== */}
          <Footer />

          {/* ============================================================== */}
        </div>

        {/* ============================================================== */}
      </div>
    </>
  );
};

export default Radiology;
